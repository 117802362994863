import cx from "classnames"

import SelectMenu, { OptionType } from "../../components/SelectMenu"
import EmptyInbox from "./EmptyInbox"
import MessagesTabsContent from "./MessagesTabsContent"
import { NotificationTypes } from "@/types/notifications"
import { ActiveViewType, MessageType } from "@/pages/Inbox"
import { Dispatch, SetStateAction } from "react"
import { UseMutateAsyncFunction } from "@tanstack/react-query"

interface MessagesTabsTypes {
  activeView: ActiveViewType
  setActiveView: Dispatch<SetStateAction<ActiveViewType>>
  messages: NotificationTypes[] | undefined
  messageType: MessageType
  setMessageType: Dispatch<SetStateAction<MessageType>>
  activeMessages: NotificationTypes[] | undefined
  activeTab: string | number | null
  setActiveTab: Dispatch<SetStateAction<string | number | null>>
  viewNotification: UseMutateAsyncFunction<unknown, Error, unknown, unknown>
}

const dropdownOptions = ["All Messages", "Read Messages"]

const MessagesTabs = ({
  activeView,
  messages,
  messageType,
  setMessageType,
  activeMessages,
  activeTab,
  setActiveTab,
  setActiveView,
  viewNotification,
}: MessagesTabsTypes) => (
  <div
    className={cx(
      "w-full lg:w-[344px] block sm:w-auto grow border border-dusk-50",
      {
        "hidden lg:block": activeView === "thread",
      }
    )}
  >
    <SelectMenu
      options={dropdownOptions}
      label={messageType}
      setSelectedOption={setMessageType as (e: OptionType) => void}
      className="w-full flex justify-between items-center rounded-none border-0 p-4 h-14"
      menuClassName="rounded-none"
      fullWidthMenu
    />

    <div className="h-[484px] overflow-auto border-t border-dusk-50">
      {messages?.length === 0 ? (
        <EmptyInbox />
      ) : (
        <MessagesTabsContent
          messages={activeMessages}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          setActiveView={setActiveView}
          viewNotification={viewNotification}
        />
      )}
    </div>
  </div>
)

export default MessagesTabs
