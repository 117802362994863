import { useState, useRef, useEffect, useMemo } from "react"

import { useProjectsStore } from "../../../stores/useProjectsStore"
import FiltersOpen from "./FiltersOpen"
import ProjectFiltersDrawer from "./ProjectFiltersDrawer"

interface ProjectFiltersTypes {
  filteredNumber: number
  handleFirstPageRedirect: () => void
}

const ProjectFilters = ({
  filteredNumber,
  handleFirstPageRedirect,
}: ProjectFiltersTypes) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  const filtersRef = useRef<HTMLDivElement>(null)
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const {
    govtProjectsOn,
    ineligibleProjectsOn,
    hasLandownerCost,
    termLengthFilter,
    paymentTypes,
    setTermLengthFilter,
  } = useProjectsStore()

  const [termLength, setTermLength] = useState(termLengthFilter)

  useEffect(() => {
    setTermLengthFilter(termLength)
  }, [setTermLengthFilter, termLength])

  const activeFilters = useMemo(
    () => [
      !ineligibleProjectsOn,
      !govtProjectsOn,
      !hasLandownerCost,
      termLengthFilter[0] > 0 || termLengthFilter[1] < 100,
      Object.values(paymentTypes).includes(true),
    ],
    [
      ineligibleProjectsOn,
      govtProjectsOn,
      hasLandownerCost,
      termLengthFilter,
      paymentTypes,
    ]
  )

  const activeFiltersNum = useMemo(
    () => activeFilters.filter(Boolean).length,
    [activeFilters]
  )

  const handleTermLength = (e: [number, number]) => {
    setTermLength(e)
  }

  const handleOpen = () => {
    setFiltersOpen(true)
  }

  const handleClose = () => {
    setFiltersOpen(false)
  }

  return (
    <div className="shrink-0">
      <FiltersOpen onClick={handleOpen} activeFiltersNum={activeFiltersNum} />

      <ProjectFiltersDrawer
        overlayRef={overlayRef}
        filtersRef={filtersRef}
        filtersOpen={filtersOpen}
        handleClose={handleClose}
        handleFirstPageRedirect={handleFirstPageRedirect}
        handleTermLength={handleTermLength}
        filteredNumber={filteredNumber}
      />
    </div>
  )
}

export default ProjectFilters
