import { ReactElement } from "react"
import { Form, FormikProps } from "formik"

import ErrorCard from "../../components/ErrorCard"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import { AccountTypes } from "../../types/account"
import { InitialAccountValuesTypes } from "../../pages/accounts/AccountInfo"

export interface AccountInfoFormComponentProps {
  backLink: ReactElement<any, any>
  errorMessage: string | null
  formikProps: FormikProps<AccountTypes | InitialAccountValuesTypes>
  isOnboardingPage: boolean
  submitText: string
}

const AccountInfoFormComponent = ({
  backLink,
  errorMessage,
  formikProps,
  isOnboardingPage,
  submitText,
}: AccountInfoFormComponentProps) => (
  <Form className="max-w-xs  md:max-w-[39rem]">
    {errorMessage && <ErrorCard className="mb-6">{errorMessage}</ErrorCard>}
    <div className="mb-4 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
      <div>
        <WrappedInput
          label="Account name"
          placeholder="Account name"
          type="text"
          name="account_name"
          id="account_name"
        />
      </div>
    </div>

    {isOnboardingPage ? (
      <div className="mt-10">
        <p className="text-carbon font-semibold">
          Landowner or other contact for this client account
        </p>

        <p className="mt-1">
          <small className="text-sm leading-[1.50] tracking-[0.01em] text-dusk-500">
            This information is optional and is only for the purpose of your own
            recordkeeping.
          </small>
        </p>
      </div>
    ) : (
      <div className="text-carbon font-semibold mt-10">
        Primary contact{" "}
        <small className="text-sm leading-[1.50] tracking-[0.01em] text-dusk-500 font-normal ml-1">
          Optional
        </small>
      </div>
    )}

    <div className="my-4 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
      <div>
        <WrappedInput
          label="First name"
          placeholder="First name"
          type="text"
          name="optional_contact_first_name"
          id="optional_contact_first_name"
        />
      </div>

      <div>
        <WrappedInput
          label="Last name"
          placeholder="Last name"
          type="text"
          name="optional_contact_last_name"
          id="optional_contact_last_name"
        />
      </div>
    </div>

    <div className="mb-6 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
      <div>
        <WrappedInput
          label="Email"
          placeholder="Email"
          type="text"
          name="optional_contact_email"
          id="optional_contact_email"
          as="email"
        />
      </div>

      <div>
        <WrappedInput
          label="Phone number"
          placeholder="Phone number"
          type="text"
          name="optional_contact_phone_number"
          id="optional_contact_phone_number"
        />
      </div>
    </div>

    <p>
      <backLink.type
        {...backLink.props}
        className="btn2 btn2-outline-primary font-semibold"
      />

      <ActionPermissionWrapper
        accountRole={formikProps.values.role}
        action="editAccount"
      >
        <SubmitButton
          isSubmitting={formikProps.isSubmitting}
          className="btn2 btn2-primary font-semibold ml-2"
        >
          {submitText}
        </SubmitButton>
      </ActionPermissionWrapper>
    </p>
  </Form>
)

export default AccountInfoFormComponent
