export interface ProjectDetailsHeroInactiveProgramProps {
  show: boolean
}

const ProjectDetailsHeroInactiveProgram = ({
  show,
}: ProjectDetailsHeroInactiveProgramProps) => {
  if (!show) {
    return null
  }

  return (
    <h3 className="text-[#f9303f] text-center font-semibold uppercase absolute top-3 left-0 right-0">
      Inactive program
    </h3>
  )
}

export default ProjectDetailsHeroInactiveProgram
