import { useQueryClient } from "@tanstack/react-query"
import { Form, Formik, FormikValues } from "formik"
import { DialogStateReturn } from "reakit/Dialog"
import * as yup from "yup"

import { Modal } from "../../../components/Modal"
import { InputSelect, OptionType } from "../../../components/MultiSelect"
import { SubmitButton } from "../../../components/SubmitButton"
import { ButtonPair } from "../../../components/ButtonPair"
import { Toast } from "../../../components/Toast"
import { useUpdatePartnerAssignees } from "../../../hooks"
import { genericErrMsg } from "../../../api/auth"
import { isValidNewEmail } from "./helpers"
import { AxiosError } from "axios"

interface InviteDealOwnerModalTypes {
  dialog: DialogStateReturn
  dealOwnerRowId: number | null
  partnerAssignees: string[] | undefined
}

const validationSchema = yup.object().shape({
  email: yup.mixed().test("valid-email", function (value: any) {
    if (!value || (typeof value === "object" && !value.value)) {
      return this.createError({
        path: "email",
        message: "Email address is required",
      })
    } else if (typeof value === "object" && value.value) {
      return (
        yup.string().email().isValidSync(value.value) ||
        this.createError({
          path: "email",
          message: "Please provide a valid email address",
        })
      )
    } else {
      return true
    }
  }),
})

const InviteDealOwnerModal = ({
  dialog,
  dealOwnerRowId,
  partnerAssignees,
}: InviteDealOwnerModalTypes) => {
  const queryClient = useQueryClient()

  const { mutateAsync: updatePartnerAssignees } = useUpdatePartnerAssignees(
    queryClient,
    dealOwnerRowId as number,
    {
      onSuccess: () => {
        dialog.hide()
        Toast.success("Deal owner has been invited successfully.")
      },
      onError: (error: AxiosError) => {
        Toast.error(error?.message || genericErrMsg)
      },
    }
  )

  const handleInviteDealOwner = async (values: FormikValues) => {
    await updatePartnerAssignees({ email: values.email.value })
  }

  return (
    <Modal
      header="Assign Deal Owner"
      aria-label="Assign Deal Owner"
      dialog={dialog}
    >
      <p className="text-charcoal-500 text-base leading-[130%] tracking-[0.32px] mb-6">
        To invite and assign a deal owner to this landowner, enter their email
        address below.
      </p>

      <Formik<{ email: OptionType | null }>
        initialValues={{
          email: null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleInviteDealOwner}
        enableReinitialize
      >
        {(formikProps) => {
          const disabled = !(formikProps.isValid && formikProps.dirty)
          const isPartnerAssignee = partnerAssignees?.includes(
            formikProps.values.email?.value as string
          )

          return (
            <Form>
              <InputSelect
                className="w-[304px] max-w-full"
                labelClass="label font-bold text-charcoal-500 leading-[18px] mb-2"
                aria-label="Email address"
                label="Email address"
                name="email"
                placeholder="Enter email address of deal owner"
                options={partnerAssignees as string[]}
                isValidNewOption={isValidNewEmail}
                isClearable
              />

              <ButtonPair
                className="mt-6"
                // eslint-disable-next-line react/no-unstable-nested-components
                primary={(primaryProps) => (
                  <SubmitButton
                    disabled={disabled}
                    isSubmitting={formikProps.isSubmitting}
                    {...primaryProps}
                  >
                    {disabled || isPartnerAssignee ? "Assign" : "Send Invite"}
                  </SubmitButton>
                )}
                secondary={
                  <button type="button" onClick={dialog.hide}>
                    Cancel
                  </button>
                }
              />
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default InviteDealOwnerModal
