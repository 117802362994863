import { ReactNode, ButtonHTMLAttributes } from "react"
import { Button as RButton } from "reakit/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

export interface MapMainMenuButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  fullWidth?: boolean
  className?: string
  isActive?: boolean
  isDisabled?: boolean
  onClick?: () => void
  icon?: IconDefinition
}

const MapMainMenuButton = ({
  children,
  fullWidth = true,
  className,
  isActive,
  isDisabled,
  onClick,
  icon,
}: MapMainMenuButtonProps) => (
  <RButton
    className={cx(
      "btn-secondary rounded-none p-2 flex first:rounded-t-md last:rounded-b-md hover:cursor-pointer",
      className,
      {
        "shadow-inner bg-gray-300": isActive,
        "bg-white": !isActive,
        "text-gray-300": isDisabled,
        "w-full": fullWidth,
      }
    )}
    onClick={onClick}
    disabled={isDisabled}
  >
    {icon && (
      <div className="inline ml-0 mr-2 w-7">
        <span className="text-xl leading-[1.50] tracking-[0.03em] pointer-events-none">
          <FontAwesomeIcon icon={icon} title={icon.iconName} />
        </span>
      </div>
    )}
    {children}
  </RButton>
)

export default MapMainMenuButton
