import {
  faMousePointer,
  faHandPointer,
  faDrawPolygon,
  faUpload,
  faPenToSquare,
  faTrashCan,
  faArrowLeft,
} from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import MapMainMenuButton from "./MapMainMenuButton"

export interface MapMainMenuProps {
  show: boolean
  className?: string
  mode?: string
  isSelectVisible?: boolean
  isParcelSelectVisible?: boolean
  isDrawPolygonVisible?: boolean
  isFileUploadVisible?: boolean
  isEditVisible?: boolean
  isTrashVisible?: boolean
  isBackVisible?: boolean
  isClearVisible?: boolean
  onClickSelect?: () => void
  onClickParcelSelect?: () => void
  onClickDrawPolygon?: () => void
  onClickFileUpload?: () => void
  onClickEdit?: () => void
  onClickTrash?: () => void
  onClickBack?: () => void
  onClickClear?: () => void
}

export const MapMainMenu = ({
  show,
  className,
  mode,
  isSelectVisible,
  isParcelSelectVisible,
  isDrawPolygonVisible,
  isFileUploadVisible,
  isEditVisible,
  isTrashVisible,
  isBackVisible,
  isClearVisible,
  onClickSelect,
  onClickParcelSelect,
  onClickDrawPolygon,
  onClickFileUpload,
  onClickEdit,
  onClickTrash,
  onClickBack,
  onClickClear,
}: MapMainMenuProps) => {
  if (!show) {
    return null
  }

  return (
    <div className={cx(className, "flex flex-col gap-4")}>
      <div>
        {isSelectVisible && (
          <MapMainMenuButton
            isActive={mode === "simple_select"}
            onClick={onClickSelect}
            icon={faMousePointer}
          >
            Select Shapes
          </MapMainMenuButton>
        )}

        {isParcelSelectVisible && (
          <MapMainMenuButton
            isActive={mode === "parcel_select"}
            onClick={onClickParcelSelect}
            icon={faHandPointer}
          >
            Select Parcels
          </MapMainMenuButton>
        )}

        {isDrawPolygonVisible && (
          <MapMainMenuButton
            isActive={mode === "draw_polygon"}
            onClick={onClickDrawPolygon}
            icon={faDrawPolygon}
          >
            Draw Shapes
          </MapMainMenuButton>
        )}

        {isFileUploadVisible && (
          <MapMainMenuButton
            isActive={mode === "file_upload"}
            onClick={onClickFileUpload}
            icon={faUpload}
          >
            Upload File
          </MapMainMenuButton>
        )}

        {isEditVisible && (
          <MapMainMenuButton
            isActive={mode === "direct_select"}
            onClick={onClickEdit}
            icon={faPenToSquare}
          >
            Edit Shape
          </MapMainMenuButton>
        )}

        {isTrashVisible && (
          <MapMainMenuButton onClick={onClickTrash} icon={faTrashCan}>
            Delete {mode === "direct_select" ? "Points" : "Shapes"}
          </MapMainMenuButton>
        )}
      </div>

      {isBackVisible && (
        <div>
          <MapMainMenuButton onClick={onClickBack} icon={faArrowLeft}>
            Back To Tools
          </MapMainMenuButton>
        </div>
      )}

      {isClearVisible && (
        <div>
          <MapMainMenuButton
            onClick={onClickClear}
            className="place-content-center"
          >
            Clear All Bounds
          </MapMainMenuButton>
        </div>
      )}
    </div>
  )
}
