import PropertyMap from "../PropertyMap"
import { useAccountId } from "../../hooks"
import { TileURLsTypes } from "@/types/tiles"

export interface SPBMapProps {
  tileData: TileURLsTypes | undefined
  lowText: string
  moderateText: string
  highText: string
  description: string
}

const SPBMap = ({
  tileData,
  lowText,
  moderateText,
  highText,
  description,
}: SPBMapProps) => {
  const accountId = useAccountId()

  return (
    <>
      <div className="[&>div:first-child]:!my-0" data-testid="spb-map">
        <PropertyMap accountId={accountId} tileData={tileData} enableZoomPan />
      </div>

      <div className="flex flex-wrap gap-3">
        <p className="bg-[#E13530] text-white text-sm leading-[24px] tracking-[0.84px] rounded-sm py-0.5 px-2.5">
          {lowText}
        </p>

        <p className="bg-[#7B1FA2] text-white text-sm leading-[24px] tracking-[0.84px] rounded-sm py-0.5 px-2.5">
          {moderateText}
        </p>

        <p className="bg-[#228DC8] text-white text-sm leading-[24px] tracking-[0.84px] rounded-sm py-0.5 px-2.5">
          {highText}
        </p>
      </div>

      <p className="text-sm font-body leading-[140%] tracking-[0.84px]">
        {description}
      </p>
    </>
  )
}

export default SPBMap
