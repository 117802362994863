import { Link, useLocation, useParams } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"

import { useAccountUrlPrefix } from "../../hooks"
import { useHeapTrackOnView } from "../../hooks/useHeapTrackOnView"

export interface ProjectDetailsMembershipBannerProps {
  isMember: boolean | undefined
}

const ProjectDetailsMembershipBanner = ({
  isMember,
}: ProjectDetailsMembershipBannerProps) => {
  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()
  const { projectId } = useParams()

  const membershipBannerRef = useHeapTrackOnView({
    event: "componentview-program-membership-banner",
    properties: { program: projectId },
    observerOptions: { threshold: 0.5 },
  })

  if (isMember) {
    return null
  }

  return (
    <div ref={membershipBannerRef} className="w-full py-7 bg-sand">
      <div className="container flex gap-4 px-5 xl:max-w-[1120px]">
        <div className="text-yellow-600 text-2xl leading-[1.40] tracking-[0.03em]">
          <FontAwesomeIcon icon={faTriangleExclamation} />
        </div>

        <div>
          <p className="text-charcoal-500 text-2xl font-bold leading-[130%] tracking-[0.378px]">
            Attention: Your access to important program details is restricted.
          </p>

          <p className="text-charcoal-500 text-base leading-[130%] tracing-0.32 mt-3">
            <Link
              to={`${accountUrlPrefix}/become-a-member`}
              className="program-membership-banner-link link"
              state={{ scrollToTop: true, prevPath: location.pathname }}
            >
              Become an NCX member
            </Link>{" "}
            to view earnings, penalties, restrictions, and more for every
            program.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProjectDetailsMembershipBanner
