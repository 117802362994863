import cx from "classnames"

import { useProjectsStore } from "../../stores/useProjectsStore"
import { ProjectDataTypes } from "@/types/program"
import { Checkbox } from "../Checkbox"

export interface ProjectCardCompareCheckboxProps {
  compare: boolean
  programData: ProjectDataTypes
  isActive: boolean | null | undefined
  isMember: boolean | undefined
}

const ProjectCardCompareCheckbox = ({
  compare,
  programData,
  isActive,
  isMember,
}: ProjectCardCompareCheckboxProps) => {
  const { comparison, toggleComparison } = useProjectsStore()

  if (!compare || !isActive) {
    return null
  }

  const isChecked = comparison.some((i) => i.id === programData.id)
  const disabled = (comparison.length === 3 && !isChecked) || isMember === false

  const handleToggleComparison = () => {
    toggleComparison(programData)
  }

  return (
    <Checkbox
      id={`program-${programData.id}`}
      label="Compare"
      checked={isChecked}
      className={cx("absolute top-[18px] right-14", {
        "pointer-events-none": disabled,
      })}
      labelClass={cx("tracking-[0.32px]", { "text-gray2": disabled })}
      onChange={handleToggleComparison}
      disabled={disabled}
    />
  )
}

export default ProjectCardCompareCheckbox
