import { CSSProperties } from "react"
import { faCircleSmall } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"

import { TooltipValueTextProps } from "./types"

const RoiChartTooltipValueText = ({
  dataKey,
  value,
  className,
}: TooltipValueTextProps) => (
  <p
    className={cx(
      "flex items-center text-base leading-[130%] tracking-[0.32px]",
      className
    )}
  >
    {dataKey === "revenue" ? "Revenue " : "Profit "}
    <FontAwesomeIcon
      icon={faCircleSmall}
      style={
        {
          fontSize: "5px",
          padding: "0 6px",
          "--fa-secondary-opacity": 1,
        } as CSSProperties
      }
    />{" "}
    {(value as number) < 0 ? "-" : ""}$
    {Math.abs(value as number).toLocaleString("en-US")}
  </p>
)

export default RoiChartTooltipValueText
