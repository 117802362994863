import { useMemo } from "react"
import cx from "classnames"

import SPBSelectApproachCard from "./SPBSelectApproachCard"
import { useSPBStore } from "../../stores/useSPBStore"
import { SPBWizardType, SelectedActionGroupType } from "@/types/wizards"
import { ProjectListTypes } from "@/types/program"
import { formatActions, getActionFromGroup } from "./helpers"

export interface SPBSelectApproachCardsProps {
  wizardData: SPBWizardType | undefined
  projectList: ProjectListTypes | undefined
  actionGroupOptions: SelectedActionGroupType[]
}

const SPBSelectApproachCards = ({
  wizardData,
  projectList,
  actionGroupOptions,
}: SPBSelectApproachCardsProps) => {
  const { selectedActionGroup, setSelectedActionGroup } = useSPBStore()

  // DEV: hopefully temporary hardcoding of the actions list while we figure out proper ordering in backend
  const spbMonitorActionList = getActionFromGroup("spb_monitor")
  const spbThinningActionList = getActionFromGroup("spb_thinning")
  const spbReplantActionList = getActionFromGroup("spb_replant")

  const spbMonitorActions = useMemo(() => {
    return formatActions(spbMonitorActionList)
  }, [spbMonitorActionList])

  if (!wizardData) {
    return null
  }

  const spbThinningImprovement =
    wizardData?.action_groups[1].new_scores.spb_overall.new_score -
    wizardData?.action_groups[1].new_scores.spb_overall.current_score

  const spbReplantImprovement =
    wizardData?.action_groups[2].new_scores.spb_overall.new_score -
    wizardData?.action_groups[2].new_scores.spb_overall.current_score

  const gridClass =
    actionGroupOptions.length === 2
      ? "grid grid-cols-1 md:grid-cols-2 mx-auto w-[880px]"
      : "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-[1460px]"

  return (
    <div
      className={cx(
        "container max-w-full gap-6 px-5 lg:px-10 mt-10",
        gridClass
      )}
    >
      <SPBSelectApproachCard
        show={
          actionGroupOptions.indexOf("spb_monitor") !== -1 ||
          selectedActionGroup === "spb_monitor"
        }
        selected={selectedActionGroup === "spb_monitor"}
        heading="Monitor Only"
        description={
          <>
            <strong>Accept risks</strong> to maximize long term timber revenue
          </>
        }
        subheading="No expected impact."
        currentScore={
          wizardData?.action_groups[0].new_scores.spb_overall.current_score
        }
        maxScore={wizardData?.action_groups[0].new_scores.spb_overall.max_score}
        newScore={wizardData?.action_groups[0].new_scores.spb_overall.new_score}
        actions={spbMonitorActions}
        projectIds={wizardData?.action_groups[0].project_ids}
        projectList={projectList}
        onClick={() => setSelectedActionGroup("spb_monitor")}
      />

      <SPBSelectApproachCard
        show={
          actionGroupOptions.indexOf("spb_thinning") !== -1 ||
          selectedActionGroup === "spb_thinning"
        }
        selected={selectedActionGroup === "spb_thinning"}
        heading="Enhanced Resilience"
        description={
          <>
            <strong>Balance risk</strong> reduction with long term timber
            revenue
          </>
        }
        subheading={
          spbThinningImprovement > 0
            ? `Estimated ${spbThinningImprovement} point improvement in resilience score`
            : "No expected impact."
        }
        currentScore={
          wizardData?.action_groups[1].new_scores.spb_overall.current_score
        }
        maxScore={wizardData?.action_groups[1].new_scores.spb_overall.max_score}
        newScore={wizardData?.action_groups[1].new_scores.spb_overall.new_score}
        actions={spbThinningActionList}
        projectIds={wizardData?.action_groups[1].project_ids}
        projectList={projectList}
        onClick={() => setSelectedActionGroup("spb_thinning")}
      />

      <SPBSelectApproachCard
        show={
          actionGroupOptions.indexOf("spb_replant") !== -1 ||
          selectedActionGroup === "spb_replant"
        }
        selected={selectedActionGroup === "spb_replant"}
        heading="Maximum Protection"
        description={
          <>
            <strong>Reduce risk</strong> to protect against timber revenue loss
          </>
        }
        subheading={
          spbReplantImprovement > 0
            ? `Estimated ${spbReplantImprovement} point improvement in resilience score`
            : "No expected impact."
        }
        currentScore={
          wizardData?.action_groups[2].new_scores.spb_overall.current_score
        }
        maxScore={wizardData?.action_groups[2].new_scores.spb_overall.max_score}
        newScore={wizardData?.action_groups[2].new_scores.spb_overall.new_score}
        actions={spbReplantActionList}
        projectIds={wizardData?.action_groups[2].project_ids}
        projectList={projectList}
        onClick={() => setSelectedActionGroup("spb_replant")}
      />
    </div>
  )
}

export default SPBSelectApproachCards
