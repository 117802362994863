import { ReactNode } from "react"
import cx from "classnames"

export interface ToggleSwitchTypes {
  label: ReactNode
  value: boolean
  onChange: () => void
  disabled?: boolean
  className?: string
}

const ToggleSwitch = ({
  label,
  value,
  onChange,
  disabled = false,
  className,
}: ToggleSwitchTypes) => (
  <label
    className={cx(
      "flex items-center gap-2 text-base font-bold leading-[130%] tracking-[0.32px] hover:cursor-pointer",
      { "cursor-not-allowed!": disabled },
      className
    )}
  >
    {label}

    <input
      className={cx(
        "toggle-switch-input",
        "relative h-6 w-12 appearance-none border-0 rounded-full bg-charcoal-50 hover:bg-charcoal-50 hover:cursor-pointer",
        "after:h-[18px] after:w-[18px] after:top-[3px] after:left-[3px] after:absolute after:content-[''] after:rounded-full after:bg-white after:transition-transform",
        "checked:bg-leaf checked:bg-none checked:hover:bg-leaf checked:after:translate-x-[24px]",
        "disabled:bg-charcoal-50 disabled:hover:bg-charcoal-50 disabled:hover:cursor-not-allowed",
        "focus:outline-hidden focus:ring-3 focus:ring-blue-400/[.45] focus:ring-offset-0 focus:checked:bg-leaf"
      )}
      type="checkbox"
      role="switch"
      value={value.toString()}
      onChange={onChange}
      checked={value}
      disabled={disabled}
    />
  </label>
)

export default ToggleSwitch
