import { Link, useLocation } from "react-router"

import { stripeDashboardLink } from "./constants"
import { useAccountUrlPrefix } from "../../hooks"

export interface SubscriptionManagementLinkProps {
  isMember: boolean | undefined
}

const SubscriptionManagementLink = ({
  isMember,
}: SubscriptionManagementLinkProps) => {
  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()

  if (isMember) {
    return (
      <a
        href={stripeDashboardLink}
        target="_blank"
        rel="noopener noreferrer"
        className="manage-subscription link"
      >
        Manage Subscription
      </a>
    )
  }

  return (
    <Link
      to={`${accountUrlPrefix}/become-a-member`}
      className="become-a-member link"
      state={{ scrollToTop: true, prevPath: location.pathname }}
    >
      Become a member
    </Link>
  )
}

export default SubscriptionManagementLink
