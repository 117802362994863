export interface ProjectFiltersDrawerFooterProps {
  handleClose: () => void
  filteredNumber: number
}

const ProjectFiltersDrawerFooter = ({
  handleClose,
  filteredNumber,
}: ProjectFiltersDrawerFooterProps) => (
  <div className="flex bg-dusk-50 p-4 mt-auto mb-0">
    <button
      type="button"
      className="btn2 btn2-primary font-bold w-full"
      onClick={handleClose}
      aria-label={`Show ${filteredNumber} programs`}
    >
      Show {filteredNumber} Programs
    </button>
  </div>
)

export default ProjectFiltersDrawerFooter
