import { useEffect } from "react"
import { Source, Layer } from "@urbica/react-map-gl"

import { onSuccessCallbacks } from "./parcelSelectMode"
import { PARCEL_LAYER_MIN_ZOOM, VECTOR_URL } from "./constants"
import { getLayerPaint } from "./helpers"
import mapVisualizationDrawStyles from "../../shared/mapVisualizationDrawStyles"
import { ParcelSelectLayerProps } from "./types"

const ParcelSelectLayer = ({
  mapRef,
  layer,
  features,
  mode,
}: ParcelSelectLayerProps) => {
  useEffect(() => {
    const map = mapRef.current.getMap()

    const onceSourceLoaded = () => {
      if (
        map.getSource("parcel-select-features") &&
        map.isSourceLoaded("parcel-select-features")
      ) {
        if (onSuccessCallbacks.length) {
          onSuccessCallbacks.forEach((fn) => fn())
          onSuccessCallbacks.length = 0
        }
        map.off("sourcedata", onceSourceLoaded)
      }
    }
    map.on("sourcedata", onceSourceLoaded)
  }, [features, mapRef])

  const getPaintOrDefault = (
    id: string,
    defaultPaint: Record<string, never>
  ) => {
    const style = mapVisualizationDrawStyles.find((style) => style.id === id)
    return style ? style.paint : defaultPaint
  }

  const getLayoutOrDefault = (
    id: string,
    defaultLayout: Record<string, never>
  ) => {
    const style = mapVisualizationDrawStyles.find((style) => style.id === id)
    return style ? style.layout : defaultLayout
  }

  if (mode !== "parcel_select") {
    return null
  }

  return (
    <>
      <Source
        id="parcel-select-features"
        type="geojson"
        data={{
          type: "FeatureCollection",
          features,
        }}
      />
      <Layer
        id="parcel-select-features-fill"
        type="fill"
        source="parcel-select-features"
        paint={getPaintOrDefault("gl-draw-polygon-fill-inactive", {})}
      />
      <Layer
        id="parcel-select-features-line"
        type="line"
        source="parcel-select-features"
        layout={getLayoutOrDefault("gl-draw-line-inactive", {})}
        paint={getPaintOrDefault("gl-draw-line-inactive", {})}
      />

      {/* Provide our actual custom visuals for this layer */}
      <Source
        id="parcels"
        type="vector"
        tiles={[VECTOR_URL]}
        minzoom={PARCEL_LAYER_MIN_ZOOM}
        maxzoom={17}
        promoteId={{ parcels: "robust_id" }}
      />
      <Layer
        id="parcels-fill"
        type="fill"
        source="parcels"
        source-layer="parcels"
        paint={getLayerPaint()}
      />
      <Layer
        id="parcels-line"
        type="line"
        source="parcels"
        source-layer="parcels"
        paint={{
          "line-color": layer === "aerial" ? "#ffffe0" : "#1b1e23",
          "line-width": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            4,
            2,
          ],
        }}
      />
    </>
  )
}

export default ParcelSelectLayer
