import { TooltipProps } from "recharts"
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent"

import RoiChartTooltipValueText from "./RoiChartTooltipValueText"

const RoiChartCustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white border border-charcoal-50 rounded-sm p-2">
        <p className="text-base charcoal-500 font-semibold leading-[130%] tracking-[0.32px] mb-3">
          {label} years
        </p>

        <RoiChartTooltipValueText
          dataKey={payload[0].dataKey}
          value={payload[0].value}
          className="text-leaf mb-1"
        />

        <RoiChartTooltipValueText
          dataKey={payload[1].dataKey}
          value={payload[1].value}
          className="text-dusk-500"
        />
      </div>
    )
  }

  return null
}

export default RoiChartCustomTooltip
