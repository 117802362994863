import { useParams } from "react-router"
import { useDialogState } from "reakit"
import { FeatureCollection, Geometry, Properties } from "@turf/helpers"
import cx from "classnames"

import Skeleton from "./Skeleton"
import { useAccountId, useIsMultiAccount } from "../../hooks"
import MainLayout from "../../pages/_layouts/Main"
import BackToTop from "../../components/BackToTop"
import BecomeAMemberOverlay from "../../components/BecomeAMemberOverlay"
import ProjectNotification from "./ProjectNotification"
import ProjectDetailsHero from "./ProjectDetailsHero"
import InvitationBanner from "./InvitationBanner"
import ProjectDetailsContent from "./ProjectDetailsContent/ProjectDetailsContent"
import ProjectDetailsSidebar from "./ProjectDetailsSidebar"
import ProjectMap from "./ProjectMap/ProjectMap"
import HowWeAssessYourLandModal from "./ProjectMap/HowWeAssessYourLandModal"
import RoiModal from "./Roi/RoiModal"
import ProjectDetailsMembershipBanner from "./ProjectDetailsMembershipBanner"
import ProjectDetailsPagination from "./ProjectDetailsPagination"
import ProjectDetailsSimilarPrograms from "./ProjectDetailsSimilarPrograms"
import { LANDOWNER_STATUS } from "../../shared/constants"
import { ProgramType } from "@/types/program"
import { Profile } from "@/types"
import { AttestationsType } from "@/types/attestations"
import { AccountRoleType, AccountTypes } from "@/types/account"
import { Dispatch, SetStateAction } from "react"
import { NotificationTypes } from "@/types/notifications"
import { CalculatorInputsTypes, RoiTypes } from "@/types/roi"
import { TileURLsTypes } from "@/types/tiles"
import { AccountProperty } from "@/types/property"

interface ProjectDetailsMainTypes {
  isLoading: boolean[]
  isMapError: boolean
  projectsData: ProgramType[]
  profile: Profile | undefined
  projectData: ProgramType | undefined
  attestationsData: AttestationsType | undefined
  account: AccountTypes | undefined
  similarPrograms: ProgramType[]
  stackablePrograms: ProgramType[]
  acceptInvite: boolean | null
  setAcceptInvite: Dispatch<SetStateAction<boolean | null>>
  invite: NotificationTypes | undefined
  filteredProjectsData: ProgramType[]
  roiData: RoiTypes
  showRoi: boolean
  tileIsLoading: boolean
  parcelIsLoading: boolean
  tileData: TileURLsTypes | undefined
  parcelData: FeatureCollection<Geometry, Properties> | undefined
  property: AccountProperty | undefined
  roiCalcDefaults: CalculatorInputsTypes
}

const ProjectDetailsMain = ({
  isLoading,
  isMapError,
  projectsData,
  profile,
  projectData,
  attestationsData,
  account,
  similarPrograms,
  stackablePrograms,
  acceptInvite,
  setAcceptInvite,
  invite,
  filteredProjectsData,
  roiData,
  showRoi,
  tileIsLoading,
  parcelIsLoading,
  tileData,
  parcelData,
  property,
  roiCalcDefaults,
}: ProjectDetailsMainTypes) => {
  const isMultiAccount = useIsMultiAccount()
  const { projectId } = useParams()
  const accountId = useAccountId()

  const howWeAssessYourLandDialog = useDialogState({ animated: true })
  const eligibilityDialog = useDialogState({ animated: true })
  const roiDialog = useDialogState({ animated: true })

  const isUrgent = projectData?.is_urgent || false

  return (
    <MainLayout isLoading={isLoading} loader={<Skeleton />}>
      {profile && projectData && (
        <div
          className={cx("bg-cloud-50 overflow-x-hidden", {
            "pb-10": !account?.is_member,
          })}
        >
          <ProjectNotification
            projectsData={projectsData}
            projectData={projectData}
            projectId={projectId as string}
          />

          <ProjectDetailsHero
            data={projectData}
            attestationsData={attestationsData}
            accountId={accountId}
            projectId={projectId as string}
            accountRole={account?.role as AccountRoleType}
            profile={profile}
            similarPrograms={similarPrograms}
            stackablePrograms={stackablePrograms}
            isMultiAccount={isMultiAccount}
            eligibilityDialog={eligibilityDialog}
            acceptInvite={acceptInvite}
            setAcceptInvite={setAcceptInvite}
            invite={invite}
            isMember={account?.is_member}
          />

          <ProjectDetailsMembershipBanner isMember={account?.is_member} />

          <ProjectDetailsPagination
            filteredProjectsData={filteredProjectsData}
          />

          <InvitationBanner
            invite={invite}
            setAcceptInvite={setAcceptInvite}
            landownerStatus={projectData?.landowner_status}
          />

          <div className="container px-5 lg:flex xl:max-w-[1120px] lg:justify-between">
            <ProjectDetailsContent
              overviewInformation={projectData?.overview_information}
              contractDetails={projectData?.contract_details}
              isMember={account?.is_member}
              isGovernmentProgram={projectData?.is_government_program}
              isSponsored={projectData?.is_sponsored}
              isInvited={invite !== undefined}
              isVerified={projectData?.is_verified}
              ctaOverrideUrl={projectData?.cta_override_url}
            />

            <ProjectDetailsSidebar
              projectData={projectData}
              roiData={roiData}
              roiDialog={roiDialog}
              showRoi={showRoi}
              stackablePrograms={stackablePrograms}
              isMultiAccount={isMultiAccount}
              accountId={accountId}
              isMember={account?.is_member}
            />
          </div>

          <ProjectMap
            isLoading={tileIsLoading || parcelIsLoading}
            isMapError={isMapError}
            tileData={tileData}
            parcelData={parcelData}
            dialog={howWeAssessYourLandDialog}
            totalAcreage={property?.acreage}
            eligibleAcres={projectData?.eligible_acres}
            isIneligible={
              projectData?.badge_display === LANDOWNER_STATUS.ineligible
            }
            ineligibilityReasons={projectData?.ineligibility_reasons}
            badgeDisplay={projectData?.badge_display}
            eligibilityDialog={eligibilityDialog}
            overlay={
              <BecomeAMemberOverlay show={!account?.is_member} absolute />
            }
            isMember={account?.is_member}
          />

          <ProjectDetailsSimilarPrograms similarPrograms={similarPrograms} />
        </div>
      )}

      <BackToTop scrollY={400} />

      <HowWeAssessYourLandModal
        dialog={howWeAssessYourLandDialog}
        assessmentCriteria={projectData?.algorithmic_requirements}
      />

      {showRoi ? (
        <RoiModal
          roiDialog={roiDialog}
          roiData={roiData}
          isUrgent={isUrgent}
          roiCalcDefaults={roiCalcDefaults}
        />
      ) : null}
    </MainLayout>
  )
}

export default ProjectDetailsMain
