import cx from "classnames"

import UNVERIFIED_BADGE from "../../images/unverified-badge.svg"
import VERIFIED_BADGE from "../../images/verified-badge.svg"
import { ProjectVerificationBadgeTypes } from "./types"
import ProjectVerificationBadgeContentLabel from "./ProjectVerificationBadgeContentLabel"

const ProjectVerificationBadgeContent = ({
  isVerified,
  includeLabel,
}: ProjectVerificationBadgeTypes) => (
  <div className="flex items-center gap-2">
    <img
      className={cx("inline-block shrink-0")}
      src={isVerified ? VERIFIED_BADGE : UNVERIFIED_BADGE}
      alt={isVerified ? "verified" : "unverified"}
    />

    <ProjectVerificationBadgeContentLabel
      isVerified={isVerified}
      includeLabel={includeLabel}
    />
  </div>
)

export default ProjectVerificationBadgeContent
