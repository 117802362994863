import { ProgramCategoryType } from "../../../types/constants"
import { ProjectDataTypes } from "@/types/program"
import { SCORE_CARD_RATINGS } from "./constants"

type ScoreCardRatingsType = { [key: number]: string }

export const getScoreRating = (currentScore: number) => {
  return (SCORE_CARD_RATINGS as ScoreCardRatingsType)[currentScore]
}

export const getScoreColorClass = (currentScore: number): string => {
  if (currentScore < 4) return "bg-[#E13530]"
  if (currentScore < 7) return "bg-[#7B1FA2]"
  return "bg-[#228DC8]"
}

export const getTopItemsByPotentialEarnings = (
  items: ProjectDataTypes[],
  type: ProgramCategoryType | null,
  count: number
): ProjectDataTypes[] => {
  return items
    .filter(
      (item) =>
        item.type === type &&
        item.is_eligible &&
        !!item?.potential_earnings &&
        item?.potential_earnings > 0
    )
    .sort((a, b) => b.potential_earnings - a.potential_earnings)
    .slice(0, count)
}

export const getCategoryFromType = (
  item: string
): ProgramCategoryType | null => {
  let itemType = null

  if (item === "carbon_potential") {
    itemType = ProgramCategoryType.harvest_deferral
  } else if (item === "forest_health") {
    itemType = ProgramCategoryType.wildfire
  } else if (item === "timber_volume") {
    itemType = ProgramCategoryType.timber
  }

  return itemType
}
