import { Link } from "react-router"
import cx from "classnames"

import ProjectCardInnactiveBadge from "./ProjectCardInnactiveBadge"
import ProjectCardVerificationBadge from "./ProjectCardVerificationBadge"
import ProjectCardStatusBadge from "./ProjectCardStatusBadge"
import ProjectCardImage from "./ProjectCardImage"
import ProjectCardName from "./ProjectCardName"
import ProjectCardDescription from "./ProjectCardDescription"
import ProjectCardIcons from "./ProjectCardIcons"
import ProjectCardCompareCheckbox from "./ProjectCardCompareCheckbox"
import { getProjectBadgeType, getProjectBadgeText } from "../../shared/utils"
import { useAccountUrlPrefix } from "../../hooks"
import { useProjectsStore } from "../../stores/useProjectsStore"
import { ProjectDataTypes } from "@/types/program"

export interface ProjectCardTypes {
  className?: string
  programData: ProjectDataTypes
  showMinimalInfo?: boolean
  shortened?: boolean
  onClick?: () => void
  disabled?: boolean
  compare?: boolean
  isMember?: boolean | undefined
}

const ProjectCard = ({
  className,
  programData,
  showMinimalInfo = false,
  shortened = false,
  onClick,
  disabled = false,
  compare = false,
  isMember,
}: ProjectCardTypes) => {
  const { comparison } = useProjectsStore()
  const accountUrlPrefix = useAccountUrlPrefix()
  const badgeType = getProjectBadgeType(programData.badge_display)
  const badgeText = getProjectBadgeText(programData.badge_display)

  const isChecked = comparison.some((i) => i.id === programData.id)

  return (
    <div
      className={cx("project-card-container relative flex", {
        "pointer-events-none": disabled,
      })}
    >
      <ProjectCardInnactiveBadge
        showMinimalInfo={showMinimalInfo}
        isActive={programData?.is_active}
      />

      <ProjectCardCompareCheckbox
        compare={compare}
        programData={programData}
        isActive={programData?.is_active}
        isMember={isMember}
      />

      <ProjectCardVerificationBadge
        showMinimalInfo={showMinimalInfo}
        isVerified={programData.is_verified}
      />

      <Link
        to={`${accountUrlPrefix}/programs/${programData.id}`}
        className={cx(
          "project-card",
          "flex flex-col rounded-sm bg-cloud-50 border border-solid border-dusk-50 p-4 select-none min-w-full",
          "transition-shadow duration-200 [transition-timing-function:cubic-bezier(0.4,0,0.2,1)]",
          "hover:cursor-pointer hover:shadow-md active:shadow-xs",
          programData?.is_active === false
            ? "outline-[#f9303f] outline-4 -outline-offset-4"
            : "",
          compare && isChecked
            ? "outline-leaf outline-4 -outline-offset-4"
            : "",
          className
        )}
        aria-label={programData.name}
        onClick={onClick}
      >
        <ProjectCardStatusBadge
          showMinimalInfo={showMinimalInfo}
          badgeType={badgeType}
          badgeText={badgeText}
        />

        <ProjectCardImage
          imageUrl={programData.image_url}
          isVerified={programData.is_verified}
          alt={programData.name}
        />

        <ProjectCardName name={programData.name} />

        <ProjectCardDescription
          showMinimalInfo={showMinimalInfo}
          description={programData.description_short}
        />

        <ProjectCardIcons
          showMinimalInfo={showMinimalInfo}
          shortened={shortened}
          programData={programData}
        />
      </Link>
    </div>
  )
}

export default ProjectCard
