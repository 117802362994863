import { ReactElement, useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import * as Yup from "yup"
import { Formik, FormikValues } from "formik"

import { genericErrMsg } from "../../api/auth"
import { useUpdateProfile } from "../../hooks"
import { phoneRegex } from "../../shared/constants"
import { Profile } from "@/types"
import { AxiosError } from "axios"
import ContactInfoFormComponent from "./ContactInfoFormComponent"

export interface ContactInfoFormProps {
  initialValues: Profile
  onSuccess: () => void
  backLink: ReactElement
  submitText: string
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Please provide a valid email address"),
  first_name: Yup.string().required("Please provide first name"),
  last_name: Yup.string().required("Please provide last name"),
  phone_number: Yup.string()
    .matches(phoneRegex, "Please provide a valid phone number")
    .required("Please provide a phone number"),
  preferred_contact_method: Yup.array()
    .min(1, "Contact preference required")
    .of(Yup.string().required())
    .required("Please provide a preferred contact method"),
})

const ContactInfoForm = ({
  initialValues,
  onSuccess,
  backLink,
  submitText,
}: ContactInfoFormProps) => {
  const queryClient = useQueryClient()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient, {
    onSuccess,
    onError: (error: AxiosError) => {
      setErrorMessage(error?.message || genericErrMsg)
    },
  })

  const handleSubmit = async (values: FormikValues) => {
    setErrorMessage(null)
    await updateProfile(values)
  }

  return (
    <div
      className="grid grid-cols-1 gap-4 mb-10  md:mb-0 md:order-2 md:w-full md:grid-cols-3"
      data-testid="contact-info-form"
    >
      <div className="md:order-1 md:col-span-full">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={true}
          validateOnChange={true}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <ContactInfoFormComponent
              errorMessage={errorMessage}
              formikProps={formikProps}
              backLink={backLink}
              submitText={submitText}
            />
          )}
        </Formik>

        <div className="block mt-[3.75rem]">
          <a
            href="https://ncx.com/privacy"
            className="link font-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>

          <span className="inline-block text-leaf font-bold mx-1">|</span>

          <a
            href="https://ncx.com/terms"
            className="link font-bold"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactInfoForm
