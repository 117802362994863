import { Form, FormikProps } from "formik"
import cx from "classnames"

import ErrorCard from "../../components/ErrorCard"
import { WrappedInput } from "../../components/Input"
import { SubmitButton } from "../../components/SubmitButton"
import { DialogDisclosure, DialogStateReturn } from "reakit/Dialog"
import { ReactElement } from "react"
import { RowDataTypes } from "@/components/Table/types"

const ROLES = [
  {
    value: "admin",
    label: "Admin",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">Admin</div>
        <div>
          Can manage user roles and permissions, update property boundaries,
          manage subscription settings, and adjust account settings for all
          membership tiers.
        </div>
      </>
    ),
  },
  {
    value: "editor",
    label: "Editor",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">Editor</div>
        <div>
          Can update property boundaries and manage subscription settings.
          Cannot modify user roles and permissions.
        </div>
      </>
    ),
  },
  {
    value: "viewer",
    label: "Viewer",
    popoverContent: () => (
      <>
        <div className="font-semibold mb-1">Viewer</div>
        <div>
          Can view property boundaries and membership details. Cannot make
          changes to property boundaries, manage subscription settings, or
          handle user roles and permissions.
        </div>
      </>
    ),
  },
]

interface UserFormComponentTypes {
  backLink: ReactElement<any, any>
  className: string
  deleteDisabled: boolean
  errorMessage?: string | null
  formikProps: FormikProps<RowDataTypes>
  isNew: boolean
  removeUserDialog: DialogStateReturn
  submitText: string
  updateDisabled: boolean
}

const UserFormComponent = ({
  backLink,
  className,
  deleteDisabled,
  errorMessage,
  formikProps,
  isNew,
  removeUserDialog,
  submitText,
  updateDisabled,
}: UserFormComponentTypes) => (
  <Form className={cx("max-w-[664px]  sm:max-w-[39rem]", className)}>
    {errorMessage && <ErrorCard className="mb-4">{errorMessage}</ErrorCard>}
    <div className="mb-6 grid grid-cols-1 gap-4  sm:grid-cols-4 sm:gap-3">
      <div className="sm:col-span-3">
        <WrappedInput
          // Disallow editing email for existing models
          disabled={!isNew || updateDisabled}
          label="Email"
          name="email"
          type="text"
          as="email"
        />
      </div>

      <div>
        <WrappedInput
          aria-label="User roles"
          as="combobox"
          disabled={updateDisabled}
          label="Role"
          name="role"
          options={ROLES}
          popoverClassName="sm:w-96!"
        />
      </div>
    </div>

    <div className="flex flex-row flex-wrap justify-between">
      {/* https://reactjs.org/docs/react-api.html#cloneelement */}
      <div>
        {backLink && (
          <backLink.type
            {...backLink.props}
            className="mb-2 mr-2 btn2 btn2-outline-primary font-semibold"
          />
        )}
        <SubmitButton
          isSubmitting={formikProps.isSubmitting}
          className="mb-2 btn2 btn2-primary font-semibold"
          disabled={updateDisabled}
        >
          {submitText}
        </SubmitButton>
      </div>

      {!isNew && (
        <div className="text-right w-full sm:w-auto">
          <DialogDisclosure
            {...removeUserDialog}
            className="mb-2 ml-2 btn2 btn2-error font-semibold"
            disabled={deleteDisabled}
          >
            Remove User
          </DialogDisclosure>
        </div>
      )}
    </div>
  </Form>
)

export default UserFormComponent
