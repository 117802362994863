import { Link, useLocation } from "react-router"

import ContentCard from "../../components/ContentCard"
import MembershipBannerHeading from "./MembershipBanner/MembershipBannerHeading"
import MembershipBannerText from "./MembershipBanner/MembershipBannerText"
import { useAccountUrlPrefix } from "../../hooks"

export interface MembershipBannerProps {
  isMember: boolean | undefined
}

const MembershipBanner = ({ isMember }: MembershipBannerProps) => {
  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()

  if (isMember) {
    return null
  }

  return (
    <ContentCard className="shadow-sm md:col-span-3" roundedMd>
      <MembershipBannerHeading />

      <MembershipBannerText />

      <Link
        to={`${accountUrlPrefix}/become-a-member`}
        className="membership-banner-join-btn btn2 btn2-primary font-semibold mt-4"
        state={{ scrollToTop: true, prevPath: location.pathname }}
      >
        Join now
      </Link>
    </ContentCard>
  )
}

export default MembershipBanner
