import { ParcelSelectModeProps } from "./types"

let hoveredFeature: any = null
let hoverListener: ((e: any) => void) | null = null
let leaveListener: ((e: any) => void) | null = null
let addedParcelIds: Set<number> | null = null
let hoveredAndClickedParcelId: number | null = null
export let onSuccessCallbacks: (() => void)[] = []

export const parcelSelectMode: ParcelSelectModeProps = {
  onSetup: function (opts: { reactState?: { addedParcelIds: number[] } }) {
    const map = this.map

    let hoveredParcelId = this.hoveredParcelId

    addedParcelIds = new Set(opts.reactState?.addedParcelIds || [])

    addedParcelIds.forEach((parcelId) => {
      map.setFeatureState(
        {
          source: "parcels",
          sourceLayer: "parcels",
          id: parcelId,
        },
        { added: true }
      )
    })

    hoverListener = (e) => {
      map.getCanvas().style.cursor = "pointer"
      if (e.features?.length > 0) {
        if (hoveredParcelId) {
          map.setFeatureState(
            {
              source: "parcels",
              sourceLayer: "parcels",
              id: hoveredParcelId,
            },
            { hover: false }
          )
        }

        hoveredFeature = e.features[0]
        hoveredParcelId = hoveredFeature.id

        if (hoveredParcelId && hoveredParcelId !== hoveredAndClickedParcelId) {
          hoveredAndClickedParcelId = null

          map.setFeatureState(
            {
              source: "parcels",
              sourceLayer: "parcels",
              id: hoveredParcelId,
            },
            { hover: true }
          )
        }
      }
    }
    map.on("mousemove", "parcels-fill", hoverListener)

    leaveListener = () => {
      map.getCanvas().style.cursor = ""
    }
    map.on("mouseleave", "parcels-fill", leaveListener)

    return {}
  },

  onStop: function () {
    this.map.off("mousemove", "parcels-fill", hoverListener)
    this.map.off("mouseleave", "parcels-fill", leaveListener)
    hoverListener = null
    leaveListener = null
    addedParcelIds = null
    onSuccessCallbacks = []
  },

  onClick: function (_opts, e) {
    const map = this.map
    const clickedFeature = map.queryRenderedFeatures(e.point)[0]

    if (clickedFeature && clickedFeature.sourceLayer === "parcels") {
      const clickedParcelId: number = clickedFeature.id

      const added = addedParcelIds?.has(clickedParcelId)

      let action: "add" | "delete" | null = null
      let _onSuccess
      map.setFeatureState(clickedFeature, {
        adding: false,
        added: false,
        removing: false,
        removed: false,
        hover: false,
      })
      if (added) {
        action = "delete"
        addedParcelIds?.delete(clickedParcelId)
        map.setFeatureState(clickedFeature, { removing: true })
        _onSuccess = () => {
          if (map.style && map.getFeatureState(clickedFeature).removing) {
            map.setFeatureState(clickedFeature, {
              removing: false,
              removed: true,
            })
          }
        }
      } else {
        action = "add"
        addedParcelIds?.add(clickedParcelId)

        map.setFeatureState(clickedFeature, { adding: true })
        _onSuccess = () => {
          if (map.style && map.getFeatureState(clickedFeature).adding) {
            map.setFeatureState(clickedFeature, {
              adding: false,
              added: true,
            })
          }
        }
      }

      map.fire("draw.selectionchange", {
        action,
        parcelId: clickedParcelId,
        reactState: { addedParcelIds },
      })

      onSuccessCallbacks.push(() => {
        _onSuccess()
      })

      hoveredAndClickedParcelId = clickedParcelId
    }
  },

  onMouseOut: function (_opts, _e) {
    const hoveredParcelId: number = this.hoveredParcelId
    if (hoveredParcelId && !addedParcelIds?.has(hoveredParcelId)) {
      this.map.removeFeatureState({
        source: "parcels",
        sourceLayer: "parcels",
        id: hoveredParcelId,
      })
    }
  },

  onKeyUp: function (opts, e) {
    if (e.keyCode === 27) return this.changeMode("simple_select")
  },

  toDisplayFeatures: function (opts, geojson, display) {
    display(geojson)
  },
}

parcelSelectMode.onTap = parcelSelectMode.onClick
