import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import {
  usePopoverState,
  Popover,
  PopoverDisclosure,
  PopoverArrow,
} from "reakit/Popover"

export interface InfoPopoverProps {
  text: string | undefined
}

const InfoPopover = ({ text }: InfoPopoverProps) => {
  const popover = usePopoverState({ animated: 250, placement: "top" })

  if (!text) {
    return null
  }

  return (
    <>
      <PopoverDisclosure
        {...popover}
        className="w-4 h-4 hover:cursor-pointer ml-1"
      >
        <FontAwesomeIcon
          icon={faCircleInfo}
          className="w-4 h-4 text-dusk-700"
        />
      </PopoverDisclosure>

      <Popover
        {...popover}
        tabIndex={0}
        aria-label="Reason"
        className="bg-charcoal-500 text-white rounded-sm ring-0 shadow-md p-3"
      >
        <PopoverArrow {...popover} size={20} className="fill-charcoal-500" />

        <p className="w-[250px] max-w-full text-white">{text}</p>
      </Popover>
    </>
  )
}

export default InfoPopover
