import { ColumnDef } from "@tanstack/react-table"

import { GetTableDataReturn } from "./types"

export const columns: ColumnDef<GetTableDataReturn>[] = [
  {
    accessorKey: "year",
    header: "Year",
    cell: (props) => <p>{props.getValue<number>()}</p>,
  },
  {
    accessorKey: "revenue",
    header: "Revenue",
    cell: (props) => <p>{props.getValue<number>().toLocaleString("en-US")}</p>,
  },
  {
    accessorKey: "cost",
    header: "Cost",
    cell: (props) => <p>{props.getValue<number>().toLocaleString("en-US")}</p>,
  },
  {
    accessorKey: "profit",
    header: "Profit",
    cell: (props) => <p>{props.getValue<number>().toLocaleString("en-US")}</p>,
  },
  {
    accessorKey: "total_npv",
    header: "Total NPV",
    cell: (props) => <p>{props.getValue<number>().toLocaleString("en-US")}</p>,
  },
]

export const CALCULATOR = "calculator"
export const CHART = "chart"
export const TABLE = "table"
