interface TextTypes {
  textArray: string[]
}

const Text = ({ textArray }: TextTypes) => {
  if (!textArray?.length) {
    return null
  }

  return textArray.map((txt) => (
    <p
      key={txt}
      className="text-sm leading-[1.50] tracking-[0.01em] text-dusk-500 [&+h5]:mt-4 [&+p]:mt-1"
    >
      {txt}
    </p>
  ))
}

export default Text
