import { ReactNode } from "react"
import { DialogBackdrop, DialogStateReturn } from "reakit"

export interface ModalBackdropProps {
  dialog: DialogStateReturn
  children: ReactNode
}

const ModalBackdrop = ({ dialog, children }: ModalBackdropProps) => (
  <DialogBackdrop
    {...dialog}
    className="fixed inset-0 perspective-midrange bg-gray-500/75 flex items-center justify-center z-2147483642 transition-opacity duration-300 opacity-0 data-enter:opacity-100"
    style={{ perspective: "800px" }}
  >
    {children}
  </DialogBackdrop>
)

export default ModalBackdrop
