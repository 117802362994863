import cx from "classnames"

export interface CornerRibbonProps {
  text: string
}

const CornerRibbon = ({ text }: CornerRibbonProps) => (
  <div className="absolute right-0 top-0 overflow-hidden w-[140px] h-[140px] pointer-events-none">
    <p
      className={cx(
        "absolute top-[30px] right-[-45px] transform rotate-45",
        "w-[198px] bg-leaf text-white text-lg text-center font-body leading-[22px] tracking-[0.02em] py-3"
      )}
    >
      {text}
    </p>
  </div>
)

export default CornerRibbon
