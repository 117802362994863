import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

interface ProjectComparisonModalSliderArrowProps {
  onClick: () => void
  disabled: boolean
  icon: IconDefinition
}

const ProjectComparisonModalSliderArrow = ({
  onClick,
  disabled,
  icon,
}: ProjectComparisonModalSliderArrowProps) => (
  <button
    onClick={onClick}
    className={cx("rounded-full", {
      "opacity-0": disabled,
    })}
    disabled={disabled}
  >
    <span className="h-6 w-6 flex items-center justify-center text-dusk-300">
      <FontAwesomeIcon icon={icon} />
    </span>
  </button>
)

export default ProjectComparisonModalSliderArrow
