import { useState } from "react"
import { DialogDisclosure, DialogStateReturn } from "reakit/Dialog"

import RoiCalculator from "./RoiCalculator"
import RoiChart from "./RoiChart"
import RoiTable from "./RoiTable"
import { CalculatorInputsTypes, RoiTypes } from "@/types/roi"
import { FormikProps } from "formik"

export interface RoiModalContentDesktopProps {
  roiData: RoiTypes
  whatIsThisNumberDialog: DialogStateReturn
  isMobile: boolean
  formikProps: FormikProps<CalculatorInputsTypes>
  handleReset: (resetForm: () => void) => Promise<void>
  isReseting: boolean
}

const RoiModalContentDesktop = ({
  roiData,
  whatIsThisNumberDialog,
  isMobile,
  formikProps,
  handleReset,
  isReseting,
}: RoiModalContentDesktopProps) => {
  const [activeYear, setActiveYear] = useState<number | null>(null)

  return (
    <div className="w-full flex justify-between lg:px-4">
      <RoiCalculator
        formikProps={formikProps}
        handleReset={handleReset}
        isReseting={isReseting}
      />

      <div className="w-[592px]">
        <div className="w-full text-center mb-4">
          <h3 className="text-3xl font-medium leading-[120%] tracking-[0.9px] mb-1">
            ${roiData.project_npv.toLocaleString("en-US")}
          </h3>

          <p className="text-base text-dusk-500 leading-[130%] tracking-[0.32px] mb-1">
            Time Adjusted Value
          </p>

          <div className="text-center">
            <DialogDisclosure
              {...whatIsThisNumberDialog}
              className="link text-sm font-semibold leading-[130%] tracking-[0.14px]"
            >
              What is this number?
            </DialogDisclosure>
          </div>
        </div>

        <RoiChart
          roiData={roiData}
          isMobile={isMobile}
          width={592}
          height={340}
          setActiveYear={setActiveYear}
        />

        <RoiTable
          roiData={roiData}
          isMobile={isMobile}
          activeYear={activeYear}
          setActiveYear={setActiveYear}
        />

        <p className="text-xs text-dusk-700 leading-[130%] mt-2 lg:ml-10">
          These calculations are informative projections, not financial advice.
          They are based on programs and do not guarantee specific returns.
        </p>
      </div>
    </div>
  )
}

export default RoiModalContentDesktop
