import { Link, useLocation } from "react-router"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLock } from "@fortawesome/pro-solid-svg-icons"
import {
  usePopoverState,
  Popover,
  PopoverDisclosure,
  PopoverArrow,
} from "reakit/Popover"

import { useAccountUrlPrefix } from "../../../hooks"

interface ProjectComparisonToggleSwitchLockProps {
  disabled: boolean
}

const ProjectComparisonToggleSwitchLock = ({
  disabled,
}: ProjectComparisonToggleSwitchLockProps) => {
  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()
  const popover = usePopoverState({ animated: 250, placement: "top" })

  if (!disabled) {
    return null
  }

  return (
    <>
      <PopoverDisclosure {...popover}>
        <FontAwesomeIcon icon={faLock} />
      </PopoverDisclosure>

      <Popover
        {...popover}
        tabIndex={0}
        aria-label="Locked"
        className="bg-charcoal-500 text-white rounded-sm ring-0 shadow-md p-3"
      >
        <PopoverArrow {...popover} size={20} className="fill-charcoal-500" />

        <p className="text-white">
          This is a paid feature{" "}
          <Link
            to={`${accountUrlPrefix}/become-a-member`}
            className="program-comparison-membership link"
            state={{ scrollToTop: true, prevPath: location.pathname }}
          >
            Upgrade Now
          </Link>
        </p>
      </Popover>
    </>
  )
}

export default ProjectComparisonToggleSwitchLock
