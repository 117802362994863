import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import CheckmarkListItem from "../../components/CheckmarkListItem"
import SPBCard from "./SPBCard"

export interface SPBTakeActionCardProps {
  selected: boolean
  heading: string
  subheading: string
  list: string[]
  onClick: () => void
}

const SPBTakeActionCard = ({
  selected,
  heading,
  subheading,
  list,
  onClick,
}: SPBTakeActionCardProps) => (
  <SPBCard>
    <div
      className={cx("relative h-full hover:cursor-pointer p-6", {
        "before:content-[''] before:absolute before:-inset-[2px] before:border-4 before:border-leaf before:rounded-2xl before:pointer-events-none":
          selected,
      })}
      onClick={onClick}
    >
      <h3
        className={cx(
          "flex items-center gap-3 text-2xl font-display font-medium leading-[124%] tracking-[0.48px]",
          {
            "text-charcoal-500": !selected,
            "text-leaf": selected,
          }
        )}
      >
        {heading}
        {selected ? <FontAwesomeIcon icon={faCircleCheck} /> : null}
      </h3>

      <p className="text-dusk-500 font-body leading-[140%] tracking-[0.32px] mt-2">
        {subheading}
      </p>

      <ul className="mt-[26px] mb-4">
        {list.map((item, i) => (
          <CheckmarkListItem key={item} className={i > 0 ? "mt-5" : ""}>
            {item}
          </CheckmarkListItem>
        ))}
      </ul>
    </div>
  </SPBCard>
)

export default SPBTakeActionCard
