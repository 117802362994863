import { Link } from "react-router"
import cx from "classnames"

export interface ExpandableContainerButtonProps {
  btnTextOpen: string
  btnTextClose: string
  expanded: boolean
  handleExpanded: () => void
  isOverflow: boolean
  overrideUrl?: string
  prevPath?: string
  overflowDisabled?: boolean
}

const ExpandableContainerButton = ({
  btnTextOpen,
  btnTextClose,
  expanded,
  handleExpanded,
  isOverflow,
  overrideUrl,
  prevPath,
  overflowDisabled,
}: ExpandableContainerButtonProps) => {
  if (!isOverflow) {
    return null
  }

  if (overrideUrl) {
    return (
      <div className="text-center mt-8">
        <Link
          to={overrideUrl}
          className={cx("text-leaf font-semibold focus:outline-hidden", {
            "hover:underline": !overflowDisabled,
          })}
          state={{ scrollToTop: true, prevPath }}
        >
          {btnTextOpen}
        </Link>
      </div>
    )
  }

  return (
    <div className="text-center mt-8">
      <button
        type="button"
        className={cx("text-leaf font-semibold focus:outline-hidden", {
          "hover:underline": !overflowDisabled,
        })}
        onClick={handleExpanded}
        disabled={overflowDisabled}
      >
        {expanded ? btnTextClose : btnTextOpen}
      </button>
    </div>
  )
}

export default ExpandableContainerButton
