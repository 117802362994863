import { ReactElement, useState } from "react"
import { Form, Formik, FormikValues } from "formik"
import * as Yup from "yup"
import { useQueryClient } from "@tanstack/react-query"
import { DialogDisclosure, useDialogState } from "reakit/Dialog"

import ErrorCard from "../../components/ErrorCard"
import { Modal } from "../../components/Modal"
import { SubmitButton } from "../../components/SubmitButton"
import UserFormComponent from "./UserFormComponent"
import {
  useAccountId,
  useAddAccountUser,
  useUpdateAccountUser,
  useRemoveAccountUser,
} from "../../hooks"
import { RowDataTypes } from "@/components/Table/types"
import { AccountUser } from "@/types/accountUsers"

interface UserFormTypes {
  initialValues: any
  onSuccess: (action: string, user?: RowDataTypes) => void
  onError: (error: Error) => void
  backLink: ReactElement<any, any>
  deleteDisabled: boolean
  isNew: boolean
  submitText: string
  updateDisabled: boolean
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide an email address"),
  role: Yup.string().required("Please select a role"),
})

// DEV: Minimize loading spinner for form by receiving `initialValues` from parent
// DEV: Use params to filter out props exclusive to this component (not FormComponent)
const UserForm = ({
  initialValues,
  onSuccess,
  onError,
  backLink,
  deleteDisabled,
  isNew,
  submitText,
  updateDisabled,
}: UserFormTypes) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [removeUserErrorMessage, setRemoveUserErrorMessage] = useState<
    string | null
  >(null)
  const removeUserDialog = useDialogState({ animated: true })

  // DEV: We can safely use `initialValues.email` as we disallow editing
  const useUpsertAccountUser = isNew
    ? useAddAccountUser.bind(this, queryClient, accountId)
    : useUpdateAccountUser.bind(
        this,
        queryClient,
        accountId,
        initialValues.email as string
      )
  const { mutateAsync: upsertAccountUser } = useUpsertAccountUser({
    onSuccess: (user: AccountUser) => {
      if (user) {
        onSuccess("save", {
          id: user.email ?? "",
          email: user.email ?? "",
          first_name: user.first_name,
          last_name: user.last_name,
          is_requester: user.is_requester ?? false,
          role: user.role ?? "viewer",
        })
      }
    },
    onError: (error) => onError(error as Error),
  })

  const handleUpsertSubmit = async (values: FormikValues) => {
    setErrorMessage(null)
    await upsertAccountUser(values)
  }

  const { mutateAsync: removeAccountUser } = useRemoveAccountUser(
    queryClient,
    accountId,
    initialValues.email as string,
    {
      onSuccess: () => onSuccess("remove"),
      onError: (error) => onError(error as Error),
    }
  )

  const handleRemoveSubmit = async () => {
    setRemoveUserErrorMessage(null)
    await removeAccountUser({})
  }

  return (
    // DEV: Avoid `enableReinitialize=true` as if we set up `window.refetchOnWindowFocus` */}
    //   then any form updates will be swept away on window refocus */}
    //   Can also be remedied by putting `useProfile` at same level as `Formik` component */}
    //   These comments may now be out of date after https://app.asana.com/0/0/1201367694639660/1201368096424394/f */}
    <Formik<RowDataTypes>
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={handleUpsertSubmit}
    >
      {(formikProps) => (
        <>
          <UserFormComponent
            className="mb-0 md:order-2 md:w-full"
            errorMessage={errorMessage}
            formikProps={formikProps}
            removeUserDialog={removeUserDialog}
            backLink={backLink}
            deleteDisabled={deleteDisabled}
            isNew={isNew}
            submitText={submitText}
            updateDisabled={updateDisabled}
          />
          {/* DEV: Modal lives at same level as `useDialogState` to avoid split bindings */}
          {/*   (i.e. see duplicate animations if we keep separate) */}
          <Modal
            // DEV: Use bottom margin inside instead of padding as it's ignored when we need to scroll
            className="px-6 pt-6 pb-0 max-w-[1280px] overflow-visible"
            header="Confirm Remove User"
            aria-label="Confirm Remove User"
            dialog={removeUserDialog}
          >
            <Formik initialValues={{}} onSubmit={handleRemoveSubmit}>
              {(removeUserFormikProps) => (
                <Form>
                  {removeUserErrorMessage && (
                    <ErrorCard className="mb-4">
                      {removeUserErrorMessage}
                    </ErrorCard>
                  )}
                  <div className="mb-6">
                    <div className="mb-6">
                      Are you sure you'd like to remove{" "}
                      <strong>{initialValues.email}</strong> from this account?
                    </div>
                    <div>
                      <DialogDisclosure
                        {...removeUserDialog}
                        className="mb-2 mr-2 btn2 btn2-outline-primary font-semibold"
                      >
                        Cancel
                      </DialogDisclosure>
                      <SubmitButton
                        isSubmitting={removeUserFormikProps.isSubmitting}
                        className="mb-2 btn2 btn2-error font-semibold"
                      >
                        Remove User
                      </SubmitButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      )}
    </Formik>
  )
}

export default UserForm
