import { useState } from "react"
import { faCalculator } from "@fortawesome/pro-solid-svg-icons"
import { faChartLineUp, faTableCells } from "@fortawesome/pro-duotone-svg-icons"

import RoiModalContentMobileTabButton from "./RoiModalContentMobileTabButton"
import RoiModalContentMobileTab from "./RoiModalContentMobileTab"
import { CALCULATOR, CHART, TABLE } from "./constants"
import { RoiModalContentMobileProps } from "./types"

const RoiModalContentMobile = ({
  roiData,
  isMobile,
  width,
  height,
  formikProps,
  handleReset,
  isReseting,
}: RoiModalContentMobileProps) => {
  const [activeTab, setActiveTab] = useState(CALCULATOR)
  const [activeYear, setActiveYear] = useState<number | null>(null)

  return (
    <>
      <div className="flex gap-1">
        <RoiModalContentMobileTabButton
          icon={faCalculator}
          onClick={() => setActiveTab(CALCULATOR)}
          active={activeTab === CALCULATOR}
          name={CALCULATOR}
        />

        <RoiModalContentMobileTabButton
          icon={faChartLineUp}
          onClick={() => setActiveTab(CHART)}
          active={activeTab === CHART}
          name={CHART}
        />

        <RoiModalContentMobileTabButton
          icon={faTableCells}
          onClick={() => setActiveTab(TABLE)}
          active={activeTab === TABLE}
          name={TABLE}
        />
      </div>

      <div>
        <RoiModalContentMobileTab
          activeTab={activeTab}
          roiData={roiData}
          isMobile={isMobile}
          width={width}
          height={height}
          formikProps={formikProps}
          handleReset={handleReset}
          isReseting={isReseting}
          activeYear={activeYear}
          setActiveYear={setActiveYear}
        />
      </div>
    </>
  )
}

export default RoiModalContentMobile
