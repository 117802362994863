import TREE_PLANTING from "../../../images/eligibility-quiz-tree_planting.webp"
import FOREST_CARBON from "../../../images/eligibility-quiz-forest_carbon.webp"
import RECREATION from "../../../images/eligibility-quiz-recreation.webp"
import REGEN_AG from "../../../images/eligibility-quiz-regen_ag.webp"
import RENEWABLE_INFRASTRUCTURE from "../../../images/eligibility-quiz-renewable_infrastructure.webp"
import WILDFIRE from "../../../images/eligibility-quiz-wildfire.webp"
import TIMBER from "../../../images/eligibility-quiz-timber.webp"
import ADVISORY_SERVICES from "../../../images/eligibility-quiz-advisory_services.webp"
import WATER from "../../../images/eligibility-quiz-water.jpg"
import GENERAL from "../../../images/eligibility-quiz-general.webp"
import { ProgramCategoryType } from "@/types/constants"

export const personalizationCardBg = (category: ProgramCategoryType) => {
  const categories = {
    tree_planting: TREE_PLANTING,
    renewable_infrastructure: RENEWABLE_INFRASTRUCTURE,
    timber: TIMBER,
    recreation: RECREATION,
    harvest_deferral: FOREST_CARBON,
    forest_carbon: FOREST_CARBON,
    water: WATER,
    biodiversity: GENERAL,
    wildfire: WILDFIRE,
    regen_ag: REGEN_AG,
    advisory_services: ADVISORY_SERVICES,
    other: ADVISORY_SERVICES,
  }

  return categories[category] || GENERAL
}
