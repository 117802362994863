import { ReactElement } from "react"
import { Form, FormikProps } from "formik"
import cx from "classnames"

import ErrorCard from "../../components/ErrorCard"
import { WrappedInput } from "../../components/Input"
import { SubmitButton } from "../../components/SubmitButton"
import { MultiSelect } from "../../components/MultiSelect"
import PreferredContactInput from "../../components/PreferredContactInput"
import { ButtonPair } from "../../components/ButtonPair"
import { Profile } from "@/types"

export interface ContactInfoFormComponentProps {
  backLink: ReactElement<any, any>
  className?: string
  errorMessage: string | null
  formikProps: FormikProps<Profile>
  submitText: string
}

const ContactInfoFormComponent = ({
  backLink,
  className,
  errorMessage,
  formikProps,
  submitText,
}: ContactInfoFormComponentProps) => (
  <Form className={cx("max-w-xs  md:max-w-[39rem]", className)}>
    {errorMessage && <ErrorCard className="mb-6">{errorMessage}</ErrorCard>}
    <div className="mb-4 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
      <div>
        <WrappedInput
          label="First name"
          placeholder="First name"
          type="text"
          id="first_name"
          name="first_name"
        />
      </div>

      <div>
        <WrappedInput
          label="Last name"
          placeholder="Last name"
          type="text"
          id="last_name"
          name="last_name"
        />
      </div>
    </div>

    <div className="mb-4 grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-3">
      <div>
        <WrappedInput
          as="email"
          label="Email"
          id="email"
          name="email"
          placeholder="Email"
          readOnly={true}
          type="text"
        />
      </div>

      <div>
        <WrappedInput
          label="Phone number"
          placeholder="Phone number"
          type="text"
          id="phone_number"
          name="phone_number"
        />
      </div>
    </div>

    <div className="mb-6 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-3">
      <div>
        <PreferredContactInput />
      </div>

      <div>
        <MultiSelect
          labelClass="label font-bold mb-2 leading-[18px]"
          aria-label="How did you hear about us?"
          label="How did you hear about us? (Optional)"
          id="referral_source"
          name="referral_source"
          placeholder="Select how you heard about us"
          options={[
            {
              label: "Friend/family/colleague",
              value: "friend_family_colleague",
            },
            {
              label: "Forestry professional",
              value: "forestry_professional",
            },
            {
              label: "Event/webinar",
              value: "event_webinar",
            },
            {
              label: "Newspaper/magazine",
              value: "newspaper_magazine",
            },
            {
              label: "Social media/internet search",
              value: "social_media_internet_search",
            },
            {
              label: "Postcard/direct mail",
              value: "postcard_direct_mail",
            },
            {
              label: "Other",
              value: "other",
            },
          ]}
        />
      </div>
    </div>

    <ButtonPair
      gapClass="gap-2"
      // eslint-disable-next-line react/no-unstable-nested-components
      primary={(primaryProps) => (
        <SubmitButton
          isSubmitting={formikProps.isSubmitting}
          disabled={!formikProps.dirty}
          {...primaryProps}
        >
          {submitText}
        </SubmitButton>
      )}
      secondary={
        <backLink.type
          {...backLink.props}
          className="btn2 btn2-outline-primary font-semibold"
        />
      }
    />
  </Form>
)

export default ContactInfoFormComponent
