import { ReactNode } from "react"
import cx from "classnames"

import ProjectComparisonModalNoRecommended from "./ProjectComparisonModalNoRecommended"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { RecommendationType } from "@/types/comparison"

interface ProjectComparisonModalFooterProps {
  PDFDownloadLink?: string | null
  recommendation: RecommendationType | undefined
  className?: string
  customButton?: ReactNode
}

const ProjectComparisonModalFooter = ({
  PDFDownloadLink,
  recommendation,
  className,
  customButton,
}: ProjectComparisonModalFooterProps) => {
  const { comparison } = useProjectsStore()
  const isMaxWidthMd = useMediaQuery("(max-width: 768px)")

  const heapTrackingClass = `download-compare-report-for-programs-${comparison
    .map((program) => program.id)
    .join("-")}`

  const showNoRecommended =
    isNaN(Number(recommendation?.recommended_project_id)) && !isMaxWidthMd

  return (
    <footer className={cx("flex md:mt-[18px]", className)}>
      <ProjectComparisonModalNoRecommended
        show={showNoRecommended}
        reason={recommendation?.reason}
      />

      {customButton ? (
        customButton
      ) : (
        <a
          href={PDFDownloadLink || ""}
          download="report.pdf"
          className={cx(heapTrackingClass, "btn2 btn2-primary ml-auto")}
        >
          Download PDF
        </a>
      )}
    </footer>
  )
}

export default ProjectComparisonModalFooter
