import { Dispatch, SetStateAction } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import cx from "classnames"

import OnboardingBtnContainer from "./OnboardingBtnContainer"
import OnboardingSurveyHeader from "./OnboardingSurveyHeader"
import { formSteps } from "./OnboardingFormSteps"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { useAccountId, useUpdateAccountInterest } from "../../hooks"
import { LAND_GOALS } from "../../shared/constants"
import ONBOARDING from "../../images/onboarding-survey.webp"
import { AccountInterestTypes } from "@/types/accountInterest"

interface OnboardingFormTypes {
  onboardingStep: number
  setOnboardingStep: Dispatch<SetStateAction<number>>
  setSurveyCompleted: Dispatch<SetStateAction<boolean>>
  accountInterest?: AccountInterestTypes
  fromDashboard?: boolean
}

export interface OnboardingFormValuesTypes {
  land_connection: string
  land_goals: string[]
  primary_land_goal: string
  economic_objectives: string
  willingness_financially_invest: string
  land_management_challenges: string[]
  additional_information?: string
}

const validationSchema = Yup.object().shape({
  land_connection: Yup.string().required(),
  // technically required but we handle in the form & submit as it is pulled from land_goals
  primary_land_goal: Yup.string(),
  land_goals: Yup.array()
    .max(3, "Please choose a max of 3 goals")
    .min(1, "Please choose at least one goal")
    .required(),
  economic_objectives: Yup.string().required(),
  willingness_financially_invest: Yup.string().required(),
  land_management_challenges: Yup.array()
    .min(1, "Please choose at least one option")
    .required(),
  additional_information: Yup.string(),
})

const OnboardingForm = ({
  onboardingStep,
  setOnboardingStep,
  setSurveyCompleted,
  accountInterest,
  fromDashboard,
}: OnboardingFormTypes) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()

  const { mutateAsync: updateAccountInterest } = useUpdateAccountInterest(
    queryClient,
    accountId,
    {
      onSuccess: () => {
        // If we are coming from the dashboard,send back to the dashboard after filling out form
        if (fromDashboard) {
          Toast.success("Goals updated")
        }
        setSurveyCompleted(true)
        queryClient.invalidateQueries({
          queryKey: ["accounts", accountId, "account-interest"],
        })
      },
      onError: (error: AxiosError) => {
        Toast.error(
          error?.message || "An error occurred while adding your information."
        )
      },
    }
  )

  const primaryChoicesFromLandGoals = (landGoals: string[]) =>
    landGoals.map((key) => ({
      value: key,
      label: LAND_GOALS[key as keyof typeof LAND_GOALS],
    }))

  const setOnboardingFormStep = (
    currentOnboardingStep: number,
    currentFormValues: OnboardingFormValuesTypes
  ) => {
    const currentFormStep =
      formSteps[currentOnboardingStep as keyof typeof formSteps].id
    // If land goals is only  1 selection, skip primary land goal selection
    if (
      currentFormStep === "land_goals" &&
      currentFormValues.land_goals.length === 1
    ) {
      return setOnboardingStep(currentOnboardingStep + 2)
    }
    // otherwise keep stepping by 1
    setOnboardingStep(currentOnboardingStep + 1)
  }

  const handleSubmit = async (values: OnboardingFormValuesTypes) => {
    const postData = {
      // We only allow 1 choice for most (for now) but backend expects most as list
      land_connection: [values.land_connection],
      economic_objectives: [values.economic_objectives],
      willingness_financially_invest: [values.willingness_financially_invest],
      land_management_challenges: values.land_management_challenges,
      additional_information: values.additional_information || null,
      primary_land_goal: values.primary_land_goal,
      other_land_goals: values.land_goals.filter(
        (goal) => goal !== values.primary_land_goal
      ),
    }
    if (values.land_goals.length === 1) {
      postData.primary_land_goal = values.land_goals[0]
      postData.other_land_goals = []
    }

    await updateAccountInterest(postData)
  }

  const questionKeys = Object.keys(formSteps)
  return (
    <Formik
      initialValues={{
        land_connection: accountInterest?.land_connection?.[0] || "",
        land_goals: (accountInterest?.other_land_goals || [])
          .concat([accountInterest?.primary_land_goal || ""])
          .filter((goal) => goal !== ""),
        primary_land_goal: accountInterest?.primary_land_goal || "",
        economic_objectives: accountInterest?.economic_objectives?.[0] || "",
        willingness_financially_invest:
          accountInterest?.willingness_financially_invest?.[0] || "",
        land_management_challenges:
          accountInterest?.land_management_challenges || [],
        additional_information: accountInterest?.additional_information || "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const fieldStep = formSteps[onboardingStep as keyof typeof formSteps]
        const fieldValue =
          formikProps.values[fieldStep.id as keyof OnboardingFormValuesTypes]

        const errorMessage = formikProps.errors[
          fieldStep.id as keyof OnboardingFormValuesTypes
        ] as string

        const showError =
          // has the user done anything with this field yet?
          formikProps.touched[
            fieldStep.id as keyof OnboardingFormValuesTypes
          ] === true && errorMessage !== undefined

        const disabled =
          showError ||
          (typeof fieldValue === "string" && fieldValue === "") ||
          (typeof fieldValue === "boolean" && fieldValue === false) ||
          (typeof fieldValue === "object" && fieldValue.length === 0) ||
          fieldValue === null ||
          fieldValue === undefined

        return (
          <Form>
            {questionKeys.map((questionIdx, index) => {
              const formStep =
                formSteps[Number(questionIdx) as keyof typeof formSteps]
              return (
                <div
                  key={formStep.id}
                  className={cx({
                    hidden: index !== onboardingStep,
                  })}
                >
                  <OnboardingSurveyHeader
                    text={formStep.headerText}
                    subheader={formStep.subheader}
                    headerImg={
                      <img
                        src={ONBOARDING}
                        alt="Ebony Tree Image"
                        className="w-24 sm:w-28 md:w-[184px] h-auto block"
                      />
                    }
                  />

                  {formStep.id === "primary_land_goal"
                    ? formStep.formInput(
                        primaryChoicesFromLandGoals(
                          formikProps.values.land_goals
                        ),
                        formikProps.values
                      )
                    : formStep.formInput(formStep.options, formikProps.values)}
                </div>
              )
            })}

            <OnboardingBtnContainer
              errorMessage={showError ? errorMessage : null}
            >
              {onboardingStep === questionKeys.length - 1 ? (
                <SubmitButton
                  className="btn2 btn2-primary font-semibold md:self-start md:my-10"
                  isSubmitting={formikProps.isSubmitting}
                >
                  {fromDashboard ? "Submit" : "Next"}
                </SubmitButton>
              ) : (
                <button
                  type="button"
                  className="btn2 btn2-primary font-semibold md:self-start md:my-10"
                  onClick={() => {
                    setOnboardingFormStep(onboardingStep, formikProps.values)

                    window.scrollTo({
                      top: 0,
                      behavior: "instant",
                    })
                  }}
                  disabled={disabled}
                >
                  Next
                </button>
              )}
            </OnboardingBtnContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default OnboardingForm
