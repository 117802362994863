export const selectStyles = (
  isError: boolean,
  isClearable: boolean = false
) => ({
  control: (baseStyles: any, { menuIsOpen }: any) => ({
    ...baseStyles,
    borderColor: menuIsOpen ? "#A4CAFE" : isError ? "#E02424" : "#E5E5E5",
    height: 40,
    overflow: "hidden",
    padding: "0 0 0 12px",
    boxShadow: menuIsOpen ? "0 0 0 3px rgba(164, 202, 254, 0.45)" : "none",
    ":hover": {
      borderColor: menuIsOpen ? "#A4CAFE" : isError ? "#E02424" : "#E5E5E5",
    },
  }),
  input: (baseStyles: any) => ({
    ...baseStyles,
    cursor: "text",
  }),
  placeholder: (baseStyles: any) => ({
    ...baseStyles,
    color: "#6B7280",
  }),
  valueContainer: (baseStyles: any) => ({
    ...baseStyles,
    height: 38,
    flexWrap: "no-wrap",
    padding: 0,
  }),
  dropdownIndicator: (baseStyles: any) => ({
    ...baseStyles,
    height: "38px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 12px",
    color: "#6B7280",
    ":hover": { color: "#585F6D" },
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  clearIndicator: () => ({
    display: isClearable ? "flex" : "none",
    height: "38px",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 0 0 6px",
    color: "#6B7280",
    ":hover": {
      color: "#585F6D",
      cursor: "pointer",
    },
  }),
  multiValue: (baseStyles: any) => ({
    ...baseStyles,
    backgroundColor: "#EAEFEC",
    margin: "0 8px 0 0",
    padding: "0 4px",
  }),
  multiValueLabel: (baseStyles: any) => ({
    ...baseStyles,
    fontSize: 16,
    lineHeight: "28px",
    padding: 0,
  }),
  multiValueRemove: (baseStyles: any) => ({
    ...baseStyles,
    ":hover": { backgroundColor: "#EAEFEC" },
  }),
  menu: (baseStyles: any) => ({
    ...baseStyles,
    boxShadow:
      "0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)",
    overflow: "hidden",
  }),
  menuList: (baseStyles: any) => ({
    ...baseStyles,
    border: "none",
    padding: 0,
  }),
  menuPortal: (baseStyles: any) => ({
    ...baseStyles,
    zIndex: 2147483642,
  }),
  option: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: state.isFocused
      ? "#EAEFEC"
      : state.isActive
        ? "#EAEFEC"
        : "transparent",
    alignItems: "center",
    color: "inherit",
    display: "flex",
    padding: "7px 8px",
    lineHeight: "23px",
    ":hover": {
      backgroundColor: "#EAEFEC",
      cursor: "pointer",
    },
  }),
})

export const createOption = (option: string) => ({
  label: option,
  value: option,
})

export const defaultOptions = (options: string[]) =>
  options.map((option) => createOption(option))
