import IMG from "../../images/paywall-info-img.jpg"

export interface SPBDynamicCheckoutInfoProps {
  isMS: boolean
}

const SPBDynamicCheckoutInfo = ({ isMS }: SPBDynamicCheckoutInfoProps) => (
  <>
    <h3 className="text-autumn text-3xl font-medium leading-[124%] tracking-[0.6px] mt-12">
      Start planning for known risks
    </h3>

    <p className="text-charcoal-500 text-base font-body leading-[130%] tracking-[0.32px] mt-2">
      Make sure you protect yourself and your land by planning ahead.
    </p>

    <div className="flex flex-col sm:flex-row gap-4 mt-7">
      <div className="w-[372px] max-w-full flex flex-col gap-[57px] sm:mt-3">
        <div>
          <h3 className="text-2xl text-charcoal-500 font-display font-bold leading-[124%] tracking-[0.48px]">
            {isMS ? "$96M Timber value loss" : "$100M+ Timber value loss"}
          </h3>

          <p className="text-lg text-charcoal-500 font-body font-bold leading-[140%] tracking-[0.36px]">
            {isMS
              ? "due to Southern Pine Beetle in 2023in Mississippi alone"
              : "annually due to Southern Pine Beetle"}
          </p>
        </div>

        <div>
          <h3 className="text-2xl text-charcoal-500 font-display font-bold leading-[124%] tracking-[0.48px]">
            {isMS ? "80K Acres of Timber" : "130M+ Board feet of timber"}
          </h3>

          <p className="text-lg text-charcoal-500 font-body font-bold leading-[140%] tracking-[0.36px]">
            {isMS
              ? "decimated by Southern Pine Beetle in 2023 in Mississippi alone"
              : "decimated by Southern Pine Beetle each year"}
          </p>
        </div>
      </div>

      <div className="w-full xs:w-auto xs:max-w-[326px] mt-10 sm:mt-0">
        <img src={IMG} alt="forest" className="block w-full h-auto" />
      </div>
    </div>
  </>
)

export default SPBDynamicCheckoutInfo
