import { components, OptionProps } from "react-select"
import cx from "classnames"

const Option = ({ children, isSelected, ...props }: OptionProps) => (
  <components.Option {...props} isSelected={isSelected}>
    <span className="flex mr-2 shrink-0 items-center relative">
      <div
        className={cx(
          "w-0 h-0 opacity-0 relative border-0 left-2",
          "[&.is-checked+span]:after:block [&.is-checked+span]:border-grass-900",
          {
            "is-checked": isSelected,
          }
        )}
      />

      <span
        className={cx(
          "relative block w-4 h-4 border border-charcoal-300 rounded-xs pointer-events-none bg-white",
          "after:content-[''] after:absolute after:hidden after:bg-checkmark after:top-[-1px] after:right-[-1px] after:w-4 after:h-4"
        )}
      />
    </span>
    <span>{children}</span>
  </components.Option>
)

export default Option
