import { usePopoverState } from "reakit"

import Popover from "../../../components/Popover"
import { mapIneligibilityReason } from "../../../shared/ineligibilityReason"
import { IneligibilityReasonsType } from "@/types/constants"

export interface ProjectDetailsHeroIneligibilityReasonsProps {
  show: boolean
  ineligibilityReasons: IneligibilityReasonsType[] | null | undefined
}

const ProjectDetailsHeroIneligibilityReasons = ({
  show,
  ineligibilityReasons,
}: ProjectDetailsHeroIneligibilityReasonsProps) => {
  const ineligibilityPopover = usePopoverState({ animated: 250 })

  if (!show) {
    return null
  }

  return (
    <Popover popover={ineligibilityPopover} label="Why am I ineligible?">
      <ul className="ul-custom max-w-[13.5rem] pl-4 text-sm leading-[150%] tracking-[0.14px]">
        {ineligibilityReasons?.map((reason) => (
          <li key={reason}>{mapIneligibilityReason(reason)}</li>
        ))}
      </ul>
    </Popover>
  )
}

export default ProjectDetailsHeroIneligibilityReasons
