import {
  faList,
  faHourglassEnd,
  faClock,
} from "@fortawesome/pro-solid-svg-icons"
import { faDollarSign } from "@fortawesome/pro-duotone-svg-icons"
import cx from "classnames"

import ProjectCardIconText from "./ProjectCardIconText"
import {
  getEnrollmentDeadline,
  getProjectType,
  getProjectEarningsNullValue,
} from "../../shared/utils"
import { formatCurrency } from "../../utils"
import { ProjectDataTypes } from "@/types/program"

export interface ProjectCardIconsProps {
  showMinimalInfo: boolean
  shortened: boolean
  programData: ProjectDataTypes
}

const ProjectCardIcons = ({
  showMinimalInfo,
  shortened,
  programData,
}: ProjectCardIconsProps) => {
  const projectType = getProjectType(programData.type, "short")
  const enrollmentDeadline = getEnrollmentDeadline(
    programData.enrollment_deadline_type,
    programData.enrollment_deadline_date
  )

  const yearsText =
    programData?.term && Number(programData.term) > 1 ? "years" : "year"
  const termNullText = shortened ? "Varies" : "Term length varies"
  const termText = shortened ? yearsText : "year term"

  return (
    <div
      className={cx(
        "grid grid-cols-[repeat(2,max-content)] gap-2 mt-auto mb-0",
        showMinimalInfo ? "grid-rows-1" : "grid-rows-2"
      )}
    >
      <ProjectCardIconText
        icon={faList}
        text={projectType}
        hide={showMinimalInfo}
      />

      <ProjectCardIconText
        icon={faHourglassEnd}
        text={enrollmentDeadline}
        hide={showMinimalInfo}
      />

      <ProjectCardIconText
        icon={faClock}
        text={
          programData.term === null
            ? termNullText
            : `${programData.term} ${termText}`
        }
      />

      <ProjectCardIconText
        icon={faDollarSign}
        text={
          programData.potential_earnings === null ||
          programData.potential_earnings === undefined
            ? getProjectEarningsNullValue(programData.type)
            : `${formatCurrency(programData.potential_earnings)}/acre`
        }
      />
    </div>
  )
}

export default ProjectCardIcons
