import cx from "classnames"

import { formInputClass } from "../../shared/constants"
import { InputFieldProps } from "./types"

const InputField = ({
  className,
  meta,
  field,
  prefix,
  ...rest
}: InputFieldProps) => (
  <input
    className={cx(
      formInputClass,
      "block w-full disabled:bg-gray-100",
      className,
      {
        "border-red-500 focus:border-red-300 focus:ring-3 focus:ring-red-300/[.45] [&::placeholder]:text-red-600 [&_.placeholder]:text-red-600":
          meta.touched && meta.error,
      },
      { "pl-11": prefix }
    )}
    data-testid="input-field"
    {...field}
    {...rest}
  />
)

export default InputField
