import { ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons"

import {
  SelectedActionGroupType,
  SelectedImplementationType,
  SPBWizardType,
} from "@/types/wizards"
import { formatToShortUSD } from "../../utils"

export const getOutbreakForecastColorClass = (forecast: string): string => {
  if (forecast === "High") {
    return "bg-[#E13530]"
  }

  if (forecast === "Medium") {
    return "bg-[#7B1FA2]"
  }

  return "bg-[#228DC8]"
}

export const getSelectedActionHeading = (
  selectedActionGroup: SelectedActionGroupType
) => {
  let selectedActionHeading = ""
  let selectedActionSubheading = ""

  if (selectedActionGroup == "spb_monitor") {
    selectedActionHeading = "Monitor Only"
    selectedActionSubheading =
      "Accept risks to maximize long term timber revenue"
  } else if (selectedActionGroup == "spb_thinning") {
    selectedActionHeading = "Enhanced Resilience"
    selectedActionSubheading =
      "Balance risk reduction with long term timber revenue"
  } else if (selectedActionGroup == "spb_replant") {
    selectedActionHeading = "Maximum Protection"
    selectedActionSubheading =
      "Reduce risk to protect against timber revenue loss"
  }

  return { selectedActionHeading, selectedActionSubheading }
}

export const getNextSteps = (
  selectedImplementation: SelectedImplementationType
): ReactNode[] => {
  if (selectedImplementation === "self") {
    return [
      <>
        Review NCX's{" "}
        <a
          href="https://ncx.com/southern-pine-beetle-guide/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Southern Pine Beetle Guide{" "}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </>,
      "Review eligible programs and apply as needed to secure funding",
      "Begin implementing actions at your convenience or once funding is secured",
      "Set reminders for regular monitoring and maintenance tasks",
      "Share with neighbors to help improve neighborhood resilience",
    ]
  }

  if (selectedImplementation === "ncx_services") {
    return [
      "NCX will contact you within 5 business days to discuss your plan and implementation options",
      <>
        Review NCX's{" "}
        <a
          href="https://ncx.com/southern-pine-beetle-guide/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Southern Pine Beetle Guide{" "}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </>,
      "Review eligible programs and apply as needed to secure funding",
    ]
  }

  return []
}

export const formatActions = (actions: string[] | undefined) => {
  if (!actions) return []

  return actions.map((action) =>
    action === "Review NCX’s Southern Pine Beetle Guide" ? (
      <>
        Review NCX's{" "}
        <a
          href="https://ncx.com/southern-pine-beetle-guide/"
          className="link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Southern Pine Beetle Guide{" "}
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </a>
      </>
    ) : (
      action
    )
  )
}

export const getActionFromGroup = (
  actionGroup: SelectedActionGroupType | undefined
): string[] => {
  if (!actionGroup) return []
  if (actionGroup === "spb_monitor") {
    return [
      "Review NCX’s Southern Pine Beetle Guide",
      "Visit lower resilience areas 1-2 times per month from March to September to identify potential outbreaks",
      "Check in with neighbors to identify nearby outbreaks",
    ]
  } else if (actionGroup === "spb_thinning") {
    return [
      "Thin immature dense pines",
      "Selectively harvest mature dense pine",
      "Check in with neighbors to identify nearby outbreaks",
    ]
  } else if (actionGroup === "spb_replant") {
    return [
      "Thin immature dense pines",
      "Clearcut harvest mature dense pine stands",
      "Replant loblolly pine at lower initial density for timber  or replant longleaf pine for native habitat restoration",
    ]
  }
  return []
}

export const getTimberValueRange = (wizardData: SPBWizardType | undefined) => {
  const timberValueMin = wizardData?.additional_data?.timber_value_range?.min
  const timberValueMax = wizardData?.additional_data?.timber_value_range?.max

  const timberValueRangeMin = formatToShortUSD(timberValueMin)
  const timberValueRangeMax = formatToShortUSD(timberValueMax)

  const timberValueRangeString =
    timberValueMin === timberValueMax
      ? `${timberValueRangeMax}`
      : `${timberValueRangeMin}-${timberValueRangeMax}`

  return {
    timberValueRangeMin,
    timberValueRangeMax,
    timberValueRangeString,
  }
}
