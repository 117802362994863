import { ReactNode } from "react"
import cx from "classnames"

export interface OrderedListProps {
  list: ReactNode[]
  className?: string
}

const OrderedList = ({ list, className }: OrderedListProps) => {
  if (list === null || list === undefined || list?.length === 0) {
    return null
  }

  return (
    <ol className={cx("list-none pl-9", className)}>
      {list.map((item, i) => (
        <li key={i} className="relative leading-[130%] tracking-[0.32px] mb-6">
          <span className="absolute w-6 h-6 flex items-center justify-center text-leaf text-base leading-[1.50] tracking-[0.02em] rounded-full border border-leaf top-0 left-[-36px]">
            {i + 1}
          </span>

          {item}
        </li>
      ))}
    </ol>
  )
}

export default OrderedList
