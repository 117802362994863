import { Form, FormikProps } from "formik"
import { DialogStateReturn } from "reakit"

import { ButtonPair } from "../../components/ButtonPair"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import PreferredContactInput from "../../components/PreferredContactInput"
import { ContactMethodType } from "@/types"

interface AcceptModalFormTypes {
  formikProps: FormikProps<{
    preferred_contact_method: ContactMethodType[]
    phone_number: string
  }>
  dialog: DialogStateReturn
}

const AcceptModalForm = ({ formikProps, dialog }: AcceptModalFormTypes) => {
  const showPhoneField =
    formikProps.values.preferred_contact_method.includes("phone") ||
    formikProps.values.preferred_contact_method.includes("text")

  return (
    <Form className="space-y-6">
      <div>
        <PreferredContactInput />
      </div>

      {showPhoneField ? (
        <div>
          <WrappedInput
            labelClass="label font-bold mb-2 leading-[18px]"
            aria-label="Phone number"
            label="Phone number"
            placeholder="Phone number"
            type="text"
            name="phone_number"
          />
        </div>
      ) : null}

      <ButtonPair
        // eslint-disable-next-line react/no-unstable-nested-components
        primary={(primaryProps) => (
          <SubmitButton
            disabled={!(formikProps.isValid && formikProps.dirty)}
            isSubmitting={formikProps.isSubmitting}
            {...primaryProps}
          >
            Accept
          </SubmitButton>
        )}
        secondary={
          <button type="button" onClick={dialog.hide}>
            Go Back
          </button>
        }
      />
    </Form>
  )
}

export default AcceptModalForm
