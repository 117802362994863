import { useQueryClient } from "@tanstack/react-query"
import { useNavigate } from "react-router"
import cx from "classnames"

import HelperCard from "../../components/HelperCard"
import { SubmitButton } from "../../components/SubmitButton"
import SPBHeader from "./SPBHeader"
import SPBScoreCard from "./SPBScoreCard"
import SPBFooter from "./SPBFooter"
import SPBDynamicCheckout from "./SPBDynamicCheckout"
import { getScoreRating } from "../ViewAccount/Scorecard/helpers"
import { useSPBStore } from "../../stores/useSPBStore"
import { useAccountId, useUpdateWizards } from "../../hooks"
import { SPBWizardType } from "@/types/wizards"
import { AccountTypes } from "@/types/account"

export interface SPBContentNoActionPlanSummaryProps {
  wizardData: SPBWizardType | undefined
  account: AccountTypes | undefined
}

const SPBContentNoActionPlanSummary = ({
  wizardData,
  account,
}: SPBContentNoActionPlanSummaryProps) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const { setActiveStep } = useSPBStore()

  const { mutateAsync: updateWizards, isPending: isPendingUpdateWizards } =
    useUpdateWizards(queryClient, accountId, {
      onSuccess: () => {
        navigate("/")
      },
    })

  const handleSubmit = () => {
    if (wizardData?.completed) {
      navigate("/")
    } else {
      // hardcoded because there is no action to take but we want an AccountWizard created
      const postData = {
        wizard_key: "spb",
        action_group_key: "spb_no_action",
        implementation: "self",
      }
      updateWizards(postData)
    }
  }

  const yourLandScore = wizardData?.scores?.[1]?.spb_direct?.current_score ?? 0
  const yourLandScoreRating = getScoreRating(Math.round(yourLandScore))

  return (
    <div
      className={cx("pt-10 lg:pt-13 pb-31", {
        "h-[calc(100vh-66px)] overflow-hidden": !account?.is_member,
      })}
    >
      <div className="pt-10 lg:pt-13 pb-31">
        <SPBHeader
          heading="Checkup complete!"
          subheading="There is no additional action recommended at this time."
        />

        <div className="container w-[752px] max-w-full flex flex-col gap-6 px-5 lg:px-10 mt-6">
          <div className="border border-dusk-50 rounded-lg px-6 py-4">
            <SPBScoreCard
              heading="Your Land's SPB Resilience"
              subheading={`Your forest has ${yourLandScoreRating.toLowerCase()} resilience to SPB.`}
              description="Based on your forest density, tree species, and management practices."
              currentScore={wizardData?.scores[1].spb_direct.current_score ?? 0}
              maxScore={wizardData?.scores[1].spb_direct.max_score ?? 0}
            />
          </div>

          <HelperCard className="!rounded-lg">
            <p className="text-charcoal-500 text-sm leading-[140%] tracking-[0.28px]">
              Have additional questions? Reach out to us at{" "}
              <a
                className="link"
                href="mailto:landowners@ncx.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                landowners@ncx.com
              </a>
              .
            </p>
          </HelperCard>
        </div>
      </div>

      <SPBFooter>
        <div className="flex gap-4 ml-auto">
          <button
            type="button"
            className="spb-plan-summary-no-action-start-over btn2 btn2-outline-primary font-bold tracking-[0.32px] whitespace-nowrap"
            onClick={() => setActiveStep(0)}
          >
            Start Over
          </button>

          <SubmitButton
            className="spb-take-action-save-and-continue btn2 btn2-primary font-bold tracking-[0.32px]"
            isSubmitting={isPendingUpdateWizards}
            onClick={handleSubmit}
          >
            Go to Dashboard
          </SubmitButton>
        </div>
      </SPBFooter>

      <SPBDynamicCheckout account={account} wizardData={wizardData} />
    </div>
  )
}

export default SPBContentNoActionPlanSummary
