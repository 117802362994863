import { ReactNode } from "react"
import cx from "classnames"

import ScoreCardHeading from "../ViewAccount/Scorecard/ScoreCardHeading"
import ScoreCardSubHeading from "../ViewAccount/Scorecard/ScoreCardSubHeading"
import ScoreCardDescription from "../ViewAccount/Scorecard/ScoreCardDescription"
import { getOutbreakForecastColorClass } from "./helpers"
import { OUTBREAK_RATING } from "./constants"
import { RegionalType } from "@/types/wizards"

export interface SPBOutbreakForecastCardProps {
  heading: string
  subheading: string
  description: ReactNode
  rating: RegionalType | undefined
}

const SPBOutbreakForecastCard = ({
  heading,
  subheading,
  description,
  rating,
}: SPBOutbreakForecastCardProps) => {
  if (!rating) {
    return null
  }

  const outbreakForecastColorClass = getOutbreakForecastColorClass(rating)

  return (
    <div className="scorecard-spb flex flex-col sm:flex-row w-full justify-between gap-4 pt-4 sm:pt-0">
      <div>
        <ScoreCardHeading className="flex">
          {heading}
          <span
            className={cx(
              "inline-block h-6 text-white text-sm font-normal rounded-sm leading-[24px] tracking-[0.28px] px-4 ml-2",
              outbreakForecastColorClass
            )}
          >
            {OUTBREAK_RATING[rating]}
          </span>
        </ScoreCardHeading>

        <ScoreCardSubHeading text={subheading} className="mt-2.5" />

        <ScoreCardDescription text={description} className="mt-1.5 mb-3" />
      </div>
    </div>
  )
}

export default SPBOutbreakForecastCard
