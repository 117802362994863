import { Link } from "react-router"
import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipReference,
  TooltipArrow,
} from "reakit/Tooltip"
import cx from "classnames"

import { AccountRoleType } from "@/types/account"

export interface BecomeAMemberWidgetJoinProps {
  price: string
  isPremium: boolean
  isMonthly: boolean
  priceYearly: string
  isMS: boolean
  isHighAcreage: boolean
  handleStripeSubmit: () => void
  role: AccountRoleType | undefined
  className?: string
  joinBtnClass?: string
}

const BecomeAMemberWidgetJoin = ({
  price,
  isPremium,
  isMonthly,
  priceYearly,
  isMS,
  isHighAcreage,
  handleStripeSubmit,
  role,
  className,
  joinBtnClass,
}: BecomeAMemberWidgetJoinProps) => {
  const tooltip = useTooltipState()

  return (
    <div className={className}>
      <h3 className="text-charcoal-500 text-2xl font-medium leading-[124%] tracking-[0.48px]">
        Price: {price}
        {!isPremium ? "/month" : ""}
        {!isMonthly && !isPremium ? (
          <span className="inline-block text-sm leading-[140%] font-body font-normal tracking-[0.28px] ml-2">
            billed {priceYearly}/yearly
          </span>
        ) : null}
      </h3>

      <p className="text-dusk-500 text-lg leading-[140%] tracking-[0.36px] mt-1">
        {isMS && isMonthly && !isPremium
          ? "Free for Mississippi landowners through 9/30/2025. Made possible by NCX's partnership with USDA NRCS."
          : `Membership renews ${
              !isMonthly || isPremium ? "annually" : "monthly"
            } at the Basic Member rate and can be canceled anytime.`}
      </p>

      {isHighAcreage && isPremium && (
        <p className="text-dusk-500 text-lg leading-[140%] tracking-[0.36px] mt-1">
          Due to the size of your property, your NCX Advisor will quote your
          Expert Plan after your consultation.
        </p>
      )}

      <TooltipReference {...tooltip} className="ring-0">
        <button
          className={cx(
            joinBtnClass,
            "member-widget-join-now max-w-40 btn2 btn2-primary font-semibold btn2-block mt-4 mb-0"
          )}
          onClick={() => {
            handleStripeSubmit()
          }}
          disabled={role !== "admin"}
        >
          Join Now
        </button>
      </TooltipReference>

      {role !== "admin" ? (
        <ReakitTooltip {...tooltip} className="st-tooltip">
          <div className="text-sm leading-[1.50] tracking-[0.01em] max-w-[664px] p-3 st-tooltip-inner">
            <TooltipArrow {...tooltip} className="fill-charcoal-500" />
            Only admins can subscribe.
          </div>
        </ReakitTooltip>
      ) : null}

      <Link
        to="/"
        className={cx(
          joinBtnClass,
          "member-widget-dashboard-link inline-block text-lg text-leaf leading-[140%] tracking-[0.36px] mt-3"
        )}
      >
        No thanks, bring me to my Dashboard
      </Link>
    </div>
  )
}

export default BecomeAMemberWidgetJoin
