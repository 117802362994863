import { ComponentType, createElement } from "react"
import cx from "classnames"

import Input from "./Input"
import { WrappedInputProps } from "./types"
import EmailSuggestion from "./EmailSuggestion"
import ErrorMessage from "./ErrorMessage"
import SelectInput from "./SelectInput"
import TextareaInput from "./TextareaInput"
import { Combobox } from "../Combobox"
import InputLabel from "./InputLabel"

const WrappedInput = ({
  label,
  labelClass,
  as,
  toggleShowPassword,
  instructions,
  ...props
}: WrappedInputProps) => {
  let component: ComponentType<any> = Input
  let suggestionComponent: ComponentType<any> | null = null

  if (as === "select") component = SelectInput
  if (as === "combobox") component = Combobox
  if (as === "textarea") component = TextareaInput
  if (as === "email") suggestionComponent = EmailSuggestion

  return (
    <>
      <InputLabel
        className={cx(labelClass ? labelClass : "mb-1 inline")}
        htmlFor={props.id || props.name}
      >
        {label}
      </InputLabel>

      {as === "password" ? (
        <span
          role="button"
          onClick={toggleShowPassword}
          className="btn2 btn2-plain float-right p-0 font-normal text-sm leading-[1.50] tracking-[0.01em]"
        >
          {props.type === "password" ? "Show Password" : "Hide Password"}
        </span>
      ) : null}

      {createElement(component, props)}

      {suggestionComponent &&
        createElement(suggestionComponent, {
          inputProps: props,
        })}

      {instructions ? (
        <p className="text-sm text-dusk-500 leading-[130%] tracking-[0.14px] mt-1">
          {instructions}
        </p>
      ) : null}

      {props.name ? (
        <ErrorMessage
          data-test={
            props["data-test" as keyof typeof props]
              ? `error-${props["data-test" as keyof typeof props]}`
              : null
          }
          name={props.name}
        />
      ) : null}
    </>
  )
}

export default WrappedInput
