import { HTMLAttributes, ReactNode } from "react"
import cx from "classnames"
import PlacesAutocomplete from "react-places-autocomplete"

import { formInputClass } from "../shared/constants"

interface SuggestionProps {
  children: ReactNode
  isActive: boolean
  id: string
}

export interface AddressAutocompleteProps {
  value: string
  onChange: (value: string) => void
  onSelect: (address: string) => void
  onError: (error: any) => void
  isLoading?: boolean
  className?: string
}

const Suggestion = ({
  children,
  isActive,
  ...rest
}: SuggestionProps & HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cx(
      "p-2 border-b border-gray-200 last:border-0 text-sm leading-[1.50] tracking-[0.01em]",
      {
        "bg-gray-100": isActive,
      }
    )}
    {...rest}
  >
    {children}
  </div>
)

// parcels data is available only for USA so restrict places suggestions to USA
const searchOptions = {
  componentRestrictions: {
    country: "US",
  },
}

export const AddressAutocomplete = ({
  value,
  onChange,
  onSelect,
  onError,
  isLoading,
  className,
}: AddressAutocompleteProps) => {
  if (typeof google?.maps?.places === "undefined") {
    return null
  }

  return (
    <div className="absolute xs:max-w-[356px] top-4 lg:top-12 inset-x-4 md:inset-x-0 md:mx-auto">
      {/* @ts-ignore  */}
      <PlacesAutocomplete
        value={value}
        onChange={onChange}
        onSelect={onSelect}
        onError={onError}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={cx(className, "relative")}>
            {isLoading && (
              <div className="absolute right-0 h-full flex items-center justify-center pr-3">
                <i className="block fad fa-spinner-third fa-spin text-green-light"></i>
              </div>
            )}

            <input
              {...getInputProps({
                placeholder: "Search map",
                className: cx(formInputClass, "w-full border-0 py-3"),
              })}
              disabled={isLoading}
            />

            {/* DEV: z-index is for mobile where dropdown rolls over buttons below it */}
            <div className="autocomplete-dropdown-container absolute left-0 right-0 w-full bg-white z-2147483641 rounded-xs shadow-sm">
              {loading && (
                <div className="p-4 text-gray-600 text-sm leading-[1.50] tracking-[0.01em]">
                  Loading...
                </div>
              )}

              {suggestions.map((suggestion) => {
                return (
                  <Suggestion
                    {...getSuggestionItemProps(suggestion)}
                    key={suggestion.placeId}
                  >
                    {suggestion.description}
                  </Suggestion>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}

export default AddressAutocomplete
