export interface SPBHeaderProps {
  heading: string
  subheading?: string
}

const SPBHeader = ({ heading, subheading }: SPBHeaderProps) => (
  <div className="container w-[752px] max-w-full flex flex-col gap-6 px-5 lg:px-10">
    <div>
      <h1 className="text-3xl font-display font-medium leading-[124%] tracking-[0.6px]">
        {heading}
      </h1>

      {subheading ? (
        <p className="text-dusk-700 font-body leading-[140%] tracking-[0.32px] mt-4">
          {subheading}
        </p>
      ) : null}
    </div>
  </div>
)

export default SPBHeader
