import cx from "classnames"

export interface ProjectTypeHolderProps {
  onClick: () => void
  isSelected: boolean
  text: string
}

const ProjectTypeHolder = ({
  onClick,
  isSelected,
  text,
}: ProjectTypeHolderProps) => (
  <div
    onClick={onClick}
    onKeyDown={(e) => (e.key === "Enter" || e.key === " " ? onClick() : null)}
    className={cx(
      "cursor-pointer select-none flex justify-center items-center w-fit border border-gray-300 shadow-sm focus:outline-hidden focus:ring-3 focus:ring-blue-400/[.45] font-semibold px-2 py-1 rounded-sm",
      { "bg-charcoal-500 text-charcoal-50": isSelected }
    )}
    role="button"
    tabIndex={0}
    aria-label={text}
  >
    {text}
  </div>
)

export default ProjectTypeHolder
