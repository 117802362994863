import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

import IconText from "../IconText"

export interface ProjectCardIconProps {
  icon: IconDefinition
  text: string
  hide?: boolean
}

const ProjectCardIconText = ({ icon, text, hide }: ProjectCardIconProps) => {
  if (hide) {
    return null
  }

  return (
    <IconText
      icon={icon}
      className="text-sm text-dusk-700 leading-6 tracking-[0.01em]"
      iconClass="text-base leading-[1.50] tracking-[0.02em] mr-1"
    >
      {text}
    </IconText>
  )
}

export default ProjectCardIconText
