import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export interface PaginationPrevProps {
  prevLinkText: string
}

const PaginationPrev = ({ prevLinkText }: PaginationPrevProps) => (
  <>
    <span
      className={cx(
        "flex w-10 h-10 border border-solid border-charcoal-50 rounded-full justify-center items-center",
        "mr-2"
      )}
    >
      <FontAwesomeIcon icon={faArrowLeft} title={faArrowLeft.iconName} />
    </span>

    <span className="text-base font-semibold leading-[1.50] tracking-[0.02em]">
      {prevLinkText || "Previous"}
    </span>
  </>
)

export default PaginationPrev
