import { Link } from "react-router"

import CornerRibbon from "../../components/CornerRibbon"
import SPBDynamicCheckoutCardItems from "./SPBDynamicCheckoutCardItems"
import BecomeAMemberWidgetOption from "../BecomeAMember/BecomeAMemberWidgetOption"
import { AccountRoleType } from "@/types/account"
import SPBDynamicCheckoutCardSubmit from "./SPBDynamicCheckoutCardSubmit"

export interface SPBDynamicCheckoutCardProps {
  role: AccountRoleType | undefined
  isMS: boolean
  price: string
  handleStripeSubmit: () => void
}

const SPBDynamicCheckoutCard = ({
  role,
  isMS,
  price,
  handleStripeSubmit,
}: SPBDynamicCheckoutCardProps) => (
  <BecomeAMemberWidgetOption
    heading={
      <span className="flex flex-col">
        NCX Membership
        <span className="block md:inline text-xl leading-[140%] tracking-[0.4px] mt-1 md:mt-0">
          {isMS ? (
            <>
              <s className="decoration-[#B64918] decoration-3">{price}/mo</s>{" "}
              $0/month
            </>
          ) : (
            `${price}/month`
          )}{" "}
          <span className="text-lg tracking-[0.36]">
            (yearly option available)
          </span>
        </span>
      </span>
    }
    subheading="Become an NCX member to access tools and insights to help you decide, plan and take action on your land goals."
    subheadingClass="w-[calc(100%-50px)] text-lg font-body leading-[140%] tracking-[0.36px]"
    enabled={false}
    active={true}
    className="!cursor-auto"
  >
    <CornerRibbon text="Most Popular" />

    <SPBDynamicCheckoutCardItems />

    <div className="flex flex-col items-center mt-2">
      <p className="text-charcoal-500 text-xs leading-[26px] tracking-[0.36px] mb-3">
        No long term contract. Cancel anytime.
      </p>

      <SPBDynamicCheckoutCardSubmit
        role={role}
        handleStripeSubmit={handleStripeSubmit}
      />

      <Link
        to="/"
        className="spb-wizard member-widget-dashboard-link inline-block text-lg text-leaf text-center font-bold leading-[140%] tracking-[0.36px] mt-3"
      >
        No thanks, bring me to my Dashboard
      </Link>
    </div>
  </BecomeAMemberWidgetOption>
)

export default SPBDynamicCheckoutCard
