import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export interface PaginationNextProps {
  nextLinkText: string
}

const PaginationNext = ({ nextLinkText }: PaginationNextProps) => (
  <>
    <span className="text-base font-semibold leading-[1.50] tracking-[0.02em]">
      {nextLinkText || "Next"}
    </span>

    <span
      className={cx(
        "flex w-10 h-10 border border-solid border-charcoal-50 rounded-full justify-center items-center",
        "ml-2"
      )}
    >
      <FontAwesomeIcon icon={faArrowRight} title={faArrowRight.iconName} />
    </span>
  </>
)

export default PaginationNext
