import { ReactNode } from "react"
import cx from "classnames"

import IconCard from "./IconCard"

export interface ErrorCardProps {
  className?: string
  children: ReactNode
}

const ErrorCard = ({ className, children }: ErrorCardProps) => (
  <IconCard
    className={cx(
      "rounded-md border p-4 bg-red-200 border-red-500 text-red-800",
      className
    )}
    icon={<i className="far fa-times-circle" />}
    dataTest="error-card"
  >
    {children}
  </IconCard>
)

export default ErrorCard
