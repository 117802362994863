import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"

export interface SPBNavigationItemProps {
  text: string
  disabled: boolean
  onClick: () => void
  active: boolean
  className: string
}

const SPBNavigationItem = ({
  text,
  disabled = true,
  onClick,
  active,
  className,
}: SPBNavigationItemProps) => (
  <button
    type="button"
    onClick={onClick}
    className="w-full text-left"
    disabled={disabled}
  >
    <span
      className={cx(
        className,
        "flex w-full items-center gap-1 text-xs lg:text-sm text-left uppercase font-body font-bold leading-[16px] tracking-[0.84px] whitespace-nowrap border-b-2 pb-2",
        "text-dusk-500 border-dusk-300 cursor-pointer",
        {
          "border-grass-600": !disabled || active,
          "!text-charcoal-500": active,
          "!cursor-default": disabled || active,
        }
      )}
    >
      {text}

      <FontAwesomeIcon
        icon={faCircleCheck}
        className={cx("inline-block text-[#86A393]", {
          invisible: disabled || active,
        })}
        style={{ fontSize: 16 }}
      />
    </span>
  </button>
)

export default SPBNavigationItem
