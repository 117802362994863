import { Field, FieldProps } from "formik"
import cx from "classnames"

import { formInputClass } from "../../shared/constants"
import { TextareaInputProps } from "./types"

const TextareaInput = ({ className, ...rest }: TextareaInputProps) => (
  <Field {...rest}>
    {({ field, meta }: FieldProps) => (
      <>
        <textarea
          rows={4}
          className={cx(
            formInputClass,
            "block w-full disabled:bg-gray-100",
            className,
            {
              "border-red-500 focus:border-red-300 focus:ring-3 focus:ring-red-300/[.45] [&::placeholder]:text-red-600 [&_.placeholder]:text-red-600":
                meta.touched && meta.error,
            }
          )}
          {...field}
          {...rest}
        />

        {rest?.maxLength !== undefined ? (
          <p className="text-dusk text-right text-xs leading-[130%] mt-2">
            {field?.value?.length}/{rest.maxLength}
          </p>
        ) : null}
      </>
    )}
  </Field>
)

export default TextareaInput
