import Select from "react-select"
import { FormikValues, useField, useFormikContext } from "formik"
import cx from "classnames"

import DropdownIndicator from "./DropdownIndicator"
import MultiValueRemove from "./MultiValueRemove"
import Option from "./Option"
import { selectStyles } from "./helpers"
import { MultiSelectProps, OptionType } from "./types"

const MultiSelect = ({
  name,
  label,
  labelClass,
  simpleValue = false,
  options,
  ...props
}: MultiSelectProps) => {
  const [field] = useField(name)
  const { setFieldValue, errors } = useFormikContext<FormikValues>()
  const isError = errors[name] !== undefined
  const flattenedOptions = options?.flatMap((o: OptionType) => o)
  const value = flattenedOptions?.filter((o: OptionType) =>
    field.value.includes(o.value)
  )

  return (
    <>
      {label && (
        <label className={cx(labelClass ? labelClass : "inline")}>
          {label}
        </label>
      )}
      <Select
        {...props}
        maxMenuHeight={130}
        value={value}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isSearchable={false}
        isMulti
        onChange={(newValue) => {
          const selectedOptions = newValue as OptionType[]
          const fieldValue = simpleValue
            ? selectedOptions.reduce((acc: string, o: OptionType) => {
                return `${acc}${!acc.length ? "" : ", "}${o.value}`
              }, "")
            : selectedOptions.map((o: OptionType) => o.value)

          setFieldValue(name, fieldValue)
        }}
        options={options}
        components={{
          Option: Option,
          MultiValueRemove: MultiValueRemove,
          DropdownIndicator: DropdownIndicator,
        }}
        styles={selectStyles(isError)}
      />
      {errors[name] !== undefined ? (
        <div className="text-sm leading-[1.50] tracking-[0.01em] text-red-600 mt-1">
          {errors[name] as string}
        </div>
      ) : null}
    </>
  )
}

export default MultiSelect
