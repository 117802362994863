import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import { Link } from "react-router"
import cx from "classnames"

import NavBarInboxLinkIndicator from "./NavBarInboxLinkIndicator"
import { useAccountId, useIsMultiAccount } from "../../hooks"
import { bellIcon } from "../../shared/icons"

export interface NavBarInboxLinkProps {
  isPartnersNav: boolean
  className?: string
  onClick: () => void
  showLinks: boolean
}

const NavBarInboxLink = ({
  isPartnersNav,
  className,
  onClick,
  showLinks,
}: NavBarInboxLinkProps) => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()

  if (
    !showLinks ||
    isPartnersNav ||
    (isMultiAccount && accountId === "_single")
  ) {
    return null
  }

  return (
    <Link
      to={isMultiAccount ? `/accounts/${accountId}/inbox` : "/inbox"}
      className={cx(
        "h-16 flex flex-row text-white items-center font-semibold hover:bg-nav-active px-3",
        className
      )}
      onClick={onClick}
    >
      <span className="relative flex items-center">
        <FontAwesomeIcon
          icon={bellIcon as IconProp}
          size="lg"
          title={bellIcon.iconName}
          className="pointer-events-none"
        />

        <NavBarInboxLinkIndicator />
      </span>
    </Link>
  )
}

export default NavBarInboxLink
