import cx from "classnames"

import { getTimberValueRange } from "./helpers"
import { SPBWizardType } from "@/types/wizards"

export interface SPBRiskNotificationProps {
  wizardData: SPBWizardType | undefined
  className?: string
}

const SPBRiskNotification = ({
  wizardData,
  className,
}: SPBRiskNotificationProps) => {
  const { timberValueRangeMin, timberValueRangeMax } =
    getTimberValueRange(wizardData)

  if (
    (timberValueRangeMin === null && timberValueRangeMax === null) ||
    (wizardData?.additional_data?.timber_value_range?.min === 0 &&
      wizardData?.additional_data?.timber_value_range?.max === 0)
  ) {
    return null
  }

  return (
    <div
      className={cx(
        "bg-orange-50 border border-[#ECA674] rounded-sm p-4",
        className
      )}
      data-testid="spb-risk-notification"
    >
      <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-[0.32px]">
        <strong>Timber value risk</strong> You have{" "}
        <strong>{timberValueRangeMax}</strong> of timber value at risk of a
        southern pine beetle infestation. See how to prevent loss of timber
        value with an NCX action plan.
      </p>
    </div>
  )
}

export default SPBRiskNotification
