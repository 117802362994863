import { useState, useEffect, CSSProperties } from "react"
import { createRoot } from "react-dom/client"
import cx from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"
import { Button } from "reakit/Button"
import { useAccessToken } from "../stores"

type Variant = "success" | "error"

export interface ToastTypes {
  variant: Variant
  message: string
  onClose: () => void
  duration?: number
}

export const Toast = ({
  variant,
  message,
  onClose,
  duration = 5000,
}: ToastTypes) => {
  const [visible, setVisible] = useState<boolean>(true)
  const timeouts: ReturnType<typeof setTimeout>[] = []
  const accessToken: string | undefined = useAccessToken()
  const isLoggedIn: boolean = !!accessToken

  // DEV: Prevents toast from persisting after user logs out
  useEffect(() => {
    if (!isLoggedIn) {
      onClose()
    }
  }, [isLoggedIn, onClose])

  // DEV: Close Toast after the duration time passes.
  useEffect(() => {
    const timeoutVisible = setTimeout(() => {
      setVisible(false)
    }, duration)

    // DEV: give it extra time to fade out before removing the element.
    const timeoutClose = setTimeout(() => {
      onClose()
    }, duration + 300)

    timeouts.push(timeoutVisible)
    timeouts.push(timeoutClose)

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    // DEV: clear timeouts on close so they don't interfere with other Toast instances.
    timeouts.forEach((timeout) => clearTimeout(timeout))

    setVisible(false)

    // DEV give it extra time to fade out before removing the element.
    setTimeout(() => {
      onClose()
    }, 300)
  }

  return (
    <div
      className={cx(
        "w-full max-w-[712px] relative overflow-hidden pointer-events-auto pl-4 pr-3 py-4",
        "flex justify-between gap-2",
        "rounded-sm shadow-xl text-white font-medium",
        { "bg-leaf": variant === "success" },
        { "bg-fire-500": variant === "error" },
        {
          "animate-[slide-in-top_0.3s_ease-in-out]": visible,
        },
        {
          "animate-[fade-out-scale_0.3s_ease-in-out]": !visible,
        }
      )}
    >
      {message}

      <Button
        onClick={handleClose}
        className={cx(
          "w-6 h-6 inline-flex items-center justify-center self-start shrink-0 text-white rounded-sm appearance-none",
          "hover:bg-[rgba(0, 0, 0, 0.1)] focus:outline-hidden"
        )}
        aria-label="Close"
      >
        <FontAwesomeIcon
          icon={faXmark}
          style={
            {
              "--fa-primary-opacity": 0,
              "--fa-secondary-opacity": 1,
            } as CSSProperties
          }
        />
      </Button>
    </div>
  )
}

const notify = (variant: Variant, message: string, duration?: number) => {
  const toastElement = document.createElement("div")
  toastElement.className =
    "toaster fixed top-6 left-0 right-0 w-full flex justify-center px-4 pointer-events-none z-2147483647"
  document.body.appendChild(toastElement)

  const handleClose = () => {
    const toastElement = document.body.querySelector(".toaster")

    // DEV: make sure toastElement exists before attempting to remove it.
    if (toastElement && document.body.contains(toastElement)) {
      document.body.removeChild(toastElement)
    }
  }

  const toaster = createRoot(toastElement)

  toaster.render(
    <Toast
      variant={variant}
      message={message}
      onClose={handleClose}
      duration={duration}
    />
  )
}

Toast.success = (message: string, duration?: number) =>
  notify("success", message, duration)
Toast.error = (message: string, duration?: number) =>
  notify("error", message, duration)
