import { Field, FieldProps } from "formik"

import InputField from "./InputField"
import { InputProps } from "./types"

const Input = ({ className, prefix, ...rest }: InputProps) => (
  <Field {...rest}>
    {({ field, meta }: FieldProps) => (
      <div className="relative">
        {prefix && (
          <span className="absolute top-0 bottom-0 left-3 w-6 flex justify-center items-center font-extrabold text-dusk-500">
            {prefix}
          </span>
        )}

        <InputField
          className={className}
          meta={meta}
          field={field}
          prefix={prefix}
          {...rest}
        />
      </div>
    )}
  </Field>
)

export default Input
