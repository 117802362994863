import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLightbulbOn } from "@fortawesome/pro-solid-svg-icons"

const EligibilityInterestModalFormNotification = () => (
  <div className="w-full lg:max-w-[344px] self-start border border-solid border-dusk-50 rounded-sm p-6 mb-9">
    <FontAwesomeIcon icon={faLightbulbOn} className="text-leaf mb-3" />
    <h4 className="text-base text-charcoal-900 font-semibold leading-snug mb-1">
      Need to make a change?
    </h4>
    <p className="text-base leading-snug tracking-[0.02em]">
      NCX saves your responses so you can edit these at any time on the program
      details page.
    </p>
  </div>
)

export default EligibilityInterestModalFormNotification
