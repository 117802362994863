import { useQueryClient } from "@tanstack/react-query"
import { DialogStateReturn } from "reakit"

import { ButtonPair } from "../../components/ButtonPair"
import { Modal } from "../../components/Modal"
import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { useUpdateAccountProject } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { LANDOWNER_STATUS } from "../../shared/constants"

export interface DeclineModalProps {
  dialog: DialogStateReturn
  accountId: string
  projectId: string
  sender: string | undefined
}

const DeclineModal = ({
  dialog,
  accountId,
  projectId,
  sender,
}: DeclineModalProps) => {
  const queryClient = useQueryClient()

  const {
    mutateAsync: updateAccountProject,
    status: updateAccountProjectStatus,
  } = useUpdateAccountProject(queryClient, accountId, projectId, {
    onSuccess: () => {
      dialog.hide()
      queryClient.invalidateQueries({
        queryKey: ["accounts", accountId, "notifications"],
      })
      Toast.success(`You declined an invitation from ${sender}.`)
    },
    onError: (error: { message: string }) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const onDecline = async () => {
    await updateAccountProject({
      status: LANDOWNER_STATUS.lo_not_interested,
    })
  }

  return (
    <Modal
      header="Decline invitation?"
      aria-label="Decline invitation?"
      dialog={dialog}
    >
      <p className="text-charcoal-500 text-base leading-[130%] tracking-[0.32px]">
        If you'd prefer to decline this request for a call, no worries! We'll
        move it to 'Read Messages' and change your status for this program to
        'Not Interested'.
      </p>

      <p className="text-charcoal-500 text-base leading-[130%] tracking-[0.32px] mt-3">
        You can change this back at any time on the program details.
      </p>

      <ButtonPair
        primary={
          <SubmitButton
            onClick={() => {
              onDecline()
            }}
            isSubmitting={updateAccountProjectStatus === "pending"}
            typeButton
          >
            Decline
          </SubmitButton>
        }
        secondary={
          <button type="button" onClick={dialog.hide}>
            Go Back
          </button>
        }
        className="mt-3"
      />
    </Modal>
  )
}
export default DeclineModal
