import { ReactNode } from "react"
import { Link } from "react-router"
import cx from "classnames"

import { useAccountUrlPrefix } from "../../../hooks"

export interface ProjectComparisonModalCardContainerProps {
  children: ReactNode
  reason: string | undefined
  id: number
  className?: string
  disabled?: boolean
}

const ProjectComparisonModalCardContainer = ({
  children,
  reason,
  id,
  className,
  disabled = false,
}: ProjectComparisonModalCardContainerProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  if (disabled) {
    return (
      <div
        className={cx(
          "grid grid-rows-subgrid gap-0 border border-dusk-50 rounded-sm p-6",
          { "border-[#ECA674]!": reason },
          className
        )}
      >
        {children}
      </div>
    )
  }

  return (
    <Link
      to={`${accountUrlPrefix}/programs/${id}`}
      className={cx(
        "grid grid-rows-subgrid gap-0 border border-dusk-50 rounded-sm hover:shadow-[0px_4px_6px_-1px_rgba(0,0,0,0.1),0px_2px_4px_-1px_rgba(0,0,0,0.06)] transition-shadow p-6",
        { "border-[#ECA674]!": reason },
        className
      )}
    >
      {children}
    </Link>
  )
}

export default ProjectComparisonModalCardContainer
