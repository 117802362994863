import { MultiSelect } from "./MultiSelect"
import { CONTACT_METHODS } from "../shared/constants"

// We have a legal requirement to include the text disclaimer for SMS opt-in
// so use this anywhere we collect contact preferences
const PreferredContactInput = () => (
  <>
    <MultiSelect
      labelClass="label font-bold mb-2 leading-[18px]"
      aria-label="Preferred contact method"
      label="Preferred contact method"
      name="preferred_contact_method"
      placeholder="Select contact preference"
      options={Object.entries(CONTACT_METHODS).map(([value, label]) => ({
        label,
        value,
      }))}
    />

    <div className="mt-0.5 text-xs leading-[1.50] text-dusk-500">
      Selecting text opts in to SMS texts from NCX. Reply STOP to opt out at any
      time. Text frequency varies and message/data rates apply.
    </div>
  </>
)

export default PreferredContactInput
