import { ReactNode } from "react"
import cx from "classnames"

export interface BecomeAMemberWidgetOptionProps {
  heading: ReactNode
  subheading: ReactNode
  subheadingClass?: string
  enabled?: boolean
  active: boolean
  onClick?: () => void
  className?: string
  children: ReactNode
}

const BecomeAMemberWidgetOption = ({
  heading,
  subheading,
  subheadingClass,
  enabled = true,
  active,
  onClick,
  className,
  children,
}: BecomeAMemberWidgetOptionProps) => {
  return (
    <div
      tabIndex={0}
      className={cx(
        "block relative border border-dusk-50 transition-colors rounded-sm select-none pt-5 pr-4 pb-[38px] pl-4 sm:pl-7",
        {
          "hover:border-dusk-100 cursor-pointer": enabled,
        },
        { "cursor-not-allowed": !enabled },
        {
          "bg-sand after:content-[''] after:absolute after:-inset-px after:border-[3px] after:border-solid after:border-autumn after:rounded-sm after:pointer-events-none":
            active,
        },
        className
      )}
      onClick={onClick}
    >
      <h3 className="text-charcoal-500 text-3xl font-body font-medium leading-[124%] tracking-[0.6px]">
        {heading}
      </h3>

      <p
        className={cx(
          "text-[#5C5C59] text-base leading-[140%] tracking-[0.32px] mt-2",
          subheadingClass
        )}
      >
        {subheading}
      </p>

      {children}
    </div>
  )
}

export default BecomeAMemberWidgetOption
