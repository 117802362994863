import OnboardingOverviewContainer from "./OnboardingOverviewContainer"
import OnbordingOverviewAsset from "./OnbordingOverviewAsset"
import OnboardingOverviewFooter from "./OnboardingOverviewFooter"
import FOREST_CARBON from "../../../images/onboarding-overview-forest-carbon.svg"
import TIMBER from "../../../images/onboarding-overview-timber.svg"
import FOREST_HEALTH from "../../../images/onboarding-overview-forest-health.svg"

export interface OnboardingOverviewAssetsProps {
  handleOverviewStep: () => void
}

const OnboardingOverviewAssets = ({
  handleOverviewStep,
}: OnboardingOverviewAssetsProps) => (
  <OnboardingOverviewContainer className="pt-[24px] md:pt-24 pb-[104px]">
    <div className="max-w-fit mx-auto">
      <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-[0.48px]">
        Your land is a Portfolio of Natural Capital Assets
      </h1>

      <p className="text-dusk-500 text-base leading-[140%] tracking-[0.32px] mt-3">
        Your portfolio is made up of assets like the ones below.
      </p>
    </div>

    <div className="flex flex-col md:flex-row md:justify-center md:items-end gap-6 md:gap-20 lg:gap-[140px] mt-[34px] md:mt-[116px]">
      <OnbordingOverviewAsset image={FOREST_CARBON} text="Forest Carbon" />

      <OnbordingOverviewAsset image={TIMBER} text="Timber" />

      <OnbordingOverviewAsset image={FOREST_HEALTH} text="Forest Health" />
    </div>

    <OnboardingOverviewFooter>
      <button
        className="btn2 btn2-primary ml-auto"
        onClick={() => handleOverviewStep()}
      >
        Next
      </button>
    </OnboardingOverviewFooter>
  </OnboardingOverviewContainer>
)

export default OnboardingOverviewAssets
