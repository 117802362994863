import { Link } from "react-router"
import cx from "classnames"

import SPBSummaryCard from "./SPBSummaryCard"
import SPBFooter from "./SPBFooter"
import SPBDynamicCheckout from "./SPBDynamicCheckout"
import { useSPBStore } from "../../stores/useSPBStore"
import { AccountProperty } from "@/types/property"
import { SPBWizardType, TileUrlsType } from "@/types/wizards"
import { ProjectListTypes } from "@/types/program"
import { AccountTypes } from "@/types/account"

export interface SPBContentPlanSummaryProps {
  property: AccountProperty | undefined
  wizardData: SPBWizardType | undefined
  account: AccountTypes | undefined
  projectList: ProjectListTypes | undefined
  spbActionMapsTileData: TileUrlsType | undefined
}

const SPBContentPlanSummary = ({
  property,
  wizardData,
  account,
  projectList,
  spbActionMapsTileData,
}: SPBContentPlanSummaryProps) => {
  const { selectedActionGroup, setActiveStep } = useSPBStore()

  const selectedActionGroupIndex =
    selectedActionGroup == "spb_monitor"
      ? 0
      : selectedActionGroup == "spb_thinning"
        ? 1
        : 2

  return (
    <div
      className={cx("pt-10 lg:pt-13 pb-31", {
        "h-[calc(100vh-66px)] overflow-hidden": !account?.is_member,
      })}
    >
      <SPBSummaryCard
        property={property}
        wizardData={wizardData}
        selectedActionGroupIndex={selectedActionGroupIndex}
        projectList={projectList}
        spbActionMapsTileData={spbActionMapsTileData}
      />

      <SPBFooter>
        <div className="flex gap-4 ml-auto">
          <button
            type="button"
            className="spb-plan-summary-edit-plan btn2 btn2-outline-primary font-bold tracking-[0.32px] whitespace-nowrap"
            onClick={() => setActiveStep(0)}
          >
            Edit Plan
          </button>

          <Link
            to="/"
            className="spb-plan-summary-exit-to-dashboard btn2 btn2-primary font-bold tracking-[0.32px] whitespace-nowrap"
          >
            Go to Dashboard
          </Link>
        </div>
      </SPBFooter>

      <SPBDynamicCheckout account={account} wizardData={wizardData} />
    </div>
  )
}

export default SPBContentPlanSummary
