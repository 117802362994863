import cx from "classnames"

import PaginationLink from "./PaginationLink"
import PaginationPrev from "./PaginationPrev"
import PaginationNext from "./PaginationNext"

export interface PaginationProps {
  prevTo: string | undefined
  nextTo: string | undefined
  prevLinkText: string
  nextLinkText: string
  className: string
}

const Pagination = ({
  prevTo,
  nextTo,
  prevLinkText,
  nextLinkText,
  className,
}: PaginationProps) => (
  <div className={cx("py-6 bg-cloud-300", className)} data-testid="pagination">
    <div className="container flex justify-between lg:justify-center items-center">
      <PaginationLink to={prevTo} className="lg:mr-8">
        <PaginationPrev prevLinkText={prevLinkText} />
      </PaginationLink>

      <PaginationLink to={nextTo} className="lg:ml-8">
        <PaginationNext nextLinkText={nextLinkText} />
      </PaginationLink>
    </div>
  </div>
)

export default Pagination
