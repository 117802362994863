import { useState, useRef, ReactNode } from "react"
import cx from "classnames"

import ExpandableContainerButton from "./ExpandableContainerButton"
import { useIsOverflow } from "../../hooks/useIsOverflow"
import ExpandableContainerOverlay from "./ExpandableContainerOverlay"
import { useLocation } from "react-router"

export interface ExpandableContainerProps {
  id: string
  btnTextOpen: string
  btnTextClose: string
  className?: string
  containerHeight?: number
  children: ReactNode
  overflowDisabled?: boolean
  overrideUrl?: string
}

const CONTAINER_HEIGHT = 492

const ExpandableContainer = ({
  id,
  children,
  btnTextOpen,
  btnTextClose,
  containerHeight = CONTAINER_HEIGHT,
  overflowDisabled = false,
  className,
  overrideUrl,
}: ExpandableContainerProps) => {
  const location = useLocation()
  const [expanded, setExpanded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const { isOverflow, scrollHeight } = useIsOverflow(ref, containerHeight)

  const handleExpanded = () => {
    setExpanded((oldExpanded) => !oldExpanded)
  }

  return (
    <div
      key={id}
      className={cx("relative", className)}
      data-testid="expandable-container"
    >
      <div
        ref={ref}
        className="overflow-hidden h-auto transition-[max-height] duration-300 ease-in-out"
        style={{
          maxHeight: expanded ? scrollHeight : containerHeight,
        }}
      >
        {children}
      </div>

      <ExpandableContainerOverlay show={!expanded && isOverflow} />

      <ExpandableContainerButton
        btnTextOpen={btnTextOpen}
        btnTextClose={btnTextClose}
        expanded={expanded}
        handleExpanded={handleExpanded}
        isOverflow={isOverflow}
        overrideUrl={overrideUrl}
        prevPath={location.pathname}
        overflowDisabled={overflowDisabled}
      />
    </div>
  )
}

export default ExpandableContainer
