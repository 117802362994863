import cx from "classnames"

import { InputLabelProps } from "./types"

const InputLabel = ({ className, ...props }: InputLabelProps) => (
  <label
    className={cx(
      "label",
      { "font-bold": !className.includes("font-normal") },
      className
    )}
    {...props}
  />
)

export default InputLabel
