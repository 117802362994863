import SPBRiskAssessmentCards from "./SPBRiskAssessmentCards"
import SPBFooter from "./SPBFooter"
import { useSPBStore } from "../../stores/useSPBStore"
import { SPBWizardType, TileUrlsType } from "@/types/wizards"

export interface SPBContentRiskAssessmentProps {
  wizardData: SPBWizardType | undefined
  spbOverallMapTileData: TileUrlsType | undefined
  spbActionMapsTileData: TileUrlsType | undefined
  handleNext: () => void
  handlePrevious: () => void
}

const SPBContentRiskAssessment = ({
  wizardData,
  spbOverallMapTileData,
  spbActionMapsTileData,
  handleNext,
  handlePrevious,
}: SPBContentRiskAssessmentProps) => {
  const { setActiveStep } = useSPBStore()

  const handleNextClick = () => {
    // if the LO has no relevant map tile data, skip right to end
    if (
      spbActionMapsTileData &&
      Object.keys(spbActionMapsTileData).length === 0
    ) {
      setActiveStep(4)
    } else {
      handleNext()
    }
  }

  return (
    <div className="pt-10 lg:pt-13 pb-31">
      <SPBRiskAssessmentCards
        wizardData={wizardData}
        spbOverallMapTileData={spbOverallMapTileData}
      />

      <SPBFooter>
        <div className="flex gap-4 ml-auto">
          <button
            type="button"
            className="spb-risk-assessment-go-back btn2 btn2-outline-primary font-bold tracking-[0.32px]"
            onClick={handlePrevious}
          >
            Go Back
          </button>

          <button
            type="button"
            className="spb-risk-assessment-next btn2 btn2-primary font-bold tracking-[0.32px]"
            onClick={handleNextClick}
          >
            Next
          </button>
        </div>
      </SPBFooter>
    </div>
  )
}

export default SPBContentRiskAssessment
