import CheckmarkListItem from "../../components/CheckmarkListItem"

const BecomeAMemberWidgetOptionList = () => (
  <ul className="mt-5" data-testid="become-a-member-widget-option-list">
    <CheckmarkListItem>
      Access all the programs your land is eligible for
    </CheckmarkListItem>

    <CheckmarkListItem className="mt-3">
      Compare program pros and cons
    </CheckmarkListItem>

    <CheckmarkListItem className="mt-3">
      Receive personalized recommendations
    </CheckmarkListItem>

    <CheckmarkListItem className="mt-3">
      View detailed earnings and contract information
    </CheckmarkListItem>

    <CheckmarkListItem className="mt-3">
      See how your land scores against others in your state
    </CheckmarkListItem>

    <CheckmarkListItem className="mt-3">
      Get automatically notified of tree loss on your property
    </CheckmarkListItem>
  </ul>
)

export default BecomeAMemberWidgetOptionList
