import IMG from "../../../images/talk-with-ncx.png"

const RequestConsultation = () => (
  <div className="bg-charcoal-500 mb-20 lg:mb-0">
    <div className="container xl:max-w-[1120px] flex gap-6 lg:gap-0 justify-between lg:grid lg:grid-cols-4 py-6 md:py-12 lg:pt-[42px] lg:pb-[83px]">
      <div className="flex items-center lg:pl-10">
        <img
          src={IMG}
          width={178}
          height={165}
          style={{ maxWidth: 178 }}
          className="w-full h-auto block"
          alt="deer"
        />
      </div>

      <div className="max-w-[210px] sm:max-w-full flex flex-col justify-center md:text-center lg:col-start-2 lg:col-end-4">
        <h4 className="text-lg text-white font-semibold leading-[130%] tracking-[0.378px] mb-2">
          Talk with an NCX expert about this program
        </h4>

        <p className="text-base text-white leading-[130%] tracking-[0.32px] mb-6">
          Get personalized support to find the right programs for you and your
          land.
        </p>

        <a
          href="https://calendly.com/ncx-landowner-team/ncx-landowner-advisory-services"
          target="_blank"
          rel="noopener noreferrer"
          className="request-consultation-link btn2 btn2-outline-primary text-center font-bold bg-white block sm:inline-block sm:self-start md:self-center px-0 sm:px-7"
        >
          Book Consultation
        </a>
      </div>
    </div>
  </div>
)

export default RequestConsultation
