import { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export interface ScrollToBottomTypes {
  scrollThreshold?: number
  className?: string
}

const ScrollToBottom = ({
  scrollThreshold = 20,
  className,
}: ScrollToBottomTypes) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight
      const scrollTop = window.scrollY
      const clientHeight = window.innerHeight

      if (scrollHeight - scrollTop - clientHeight > scrollThreshold) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    setTimeout(() => {
      handleScroll()
    }, 100)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [scrollThreshold])

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    })
  }

  return (
    <div
      className={cx(
        "hidden sm:flex sm:flex-col sm:items-center sm:gap-1.5 sm:fixed sm:left-9 sm:bottom-20 lg:bottom-14 sm:opacity-0 sm:pointer-events-none sm:z-2",
        {
          "sm:opacity-100 sm:!pointer-events-auto": isVisible,
        },
        className
      )}
      data-testid="scroll-to-bottom"
    >
      <button
        type="button"
        aria-label="scroll to bottom"
        className={cx(
          "h-[2.75rem] w-[2.75rem] flex items-center justify-center cursor-pointer",
          "bg-grass-700 hover:bg-grass-900 rounded-full",
          "font-black text-white text-base leading-[130%] tracking-[0.32px]",
          "transition ease-in-out duration-150 focus:outline-hidden focus:ring-3 focus:ring-blue-400/[.45]"
        )}
        onClick={scrollToBottom}
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </button>

      <span className="lg:block text-base text-charcoal-500 leading-6 tracking-[0.32px] pointer-events-none hidden">
        Scroll to bottom
      </span>
    </div>
  )
}

export default ScrollToBottom
