import SPBLandGoalItem from "./SPBLandGoalItem"
import { LAND_GOALS } from "../../shared/constants"
import INCOME_IMG from "../../images/spb-income-icon.svg"
import ENVIRONMENTAL_IMG from "../../images/spb-environmental-icon.svg"
import WILDLIFE_IMG from "../../images/spb-wildlife-icon.svg"
import HUNTING_IMG from "../../images/spb-hunting-icon.svg"
import RECREATION_NON_HUNTING_IMG from "../../images/spb-recreation-non-hunting.svg"
import LAND_IMPROVEMENT_IMG from "../../images/spb-land-improvement-icon.svg"
import UNKNOWN_IMG from "../../images/spb-unknown-icon.svg"

export interface SPBLandGoalsProps {
  primaryLandGoal: string | undefined | null
}

const SPBLandGoal = ({ primaryLandGoal }: SPBLandGoalsProps) => {
  if (primaryLandGoal === "income") {
    return <SPBLandGoalItem icon={INCOME_IMG} title={LAND_GOALS.income} />
  }

  if (primaryLandGoal === "environmental") {
    return (
      <SPBLandGoalItem
        icon={ENVIRONMENTAL_IMG}
        title={LAND_GOALS.environmental}
      />
    )
  }

  if (primaryLandGoal === "wildlife") {
    return <SPBLandGoalItem icon={WILDLIFE_IMG} title={LAND_GOALS.wildlife} />
  }

  if (primaryLandGoal === "hunting") {
    return <SPBLandGoalItem icon={HUNTING_IMG} title={LAND_GOALS.hunting} />
  }

  if (primaryLandGoal === "recreation_non_hunting") {
    return (
      <SPBLandGoalItem
        icon={RECREATION_NON_HUNTING_IMG}
        title={LAND_GOALS.recreation_non_hunting}
      />
    )
  }

  if (primaryLandGoal === "land_improvement") {
    return (
      <SPBLandGoalItem
        icon={LAND_IMPROVEMENT_IMG}
        title={LAND_GOALS.land_improvement}
      />
    )
  }

  return <SPBLandGoalItem icon={UNKNOWN_IMG} title={LAND_GOALS.unknown} />
}

export default SPBLandGoal
