import { ReactNode } from "react"

export interface OnboardingOverviewFooterProps {
  children: ReactNode
}

const OnboardingOverviewFooter = ({
  children,
}: OnboardingOverviewFooterProps) => (
  <div className="bg-white fixed z-[2] bottom-0 left-0 right-0 flex justify-between shadow-[0px_-2px_2px_0px_rgba(0,0,0,0.10)] px-6 lg:px-[70px] pb-4 pt-6">
    {children}
  </div>
)

export default OnboardingOverviewFooter
