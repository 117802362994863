export const getLayerPaint = () => {
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/#paint-property
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#feature-data
  // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case
  // Build fills procedurally (declarative requires separating fill from opacity, which less legible)
  const fillColors: unknown[] = ["case"]
  const fillOpacities: unknown[] = ["case"]

  const FEATURE_STATE_IS_ADDED = ["==", ["feature-state", "added"], true]
  const FEATURE_STATE_IS_ADDING = ["==", ["feature-state", "adding"], true]
  const FEATURE_STATE_IS_HOVERING = ["==", ["feature-state", "hover"], true]
  const FEATURE_STATE_IS_REMOVING = ["==", ["feature-state", "removing"], true]
  let condition

  // State: If we're removing or (hovering an added or adding feature) (thus hovering to remove), then use next line
  // DEV: Order matters, this overrides hover logic
  condition = [
    "any",
    FEATURE_STATE_IS_REMOVING,
    [
      "all",
      FEATURE_STATE_IS_HOVERING,
      ["any", FEATURE_STATE_IS_ADDED, FEATURE_STATE_IS_ADDING],
    ],
  ]
  fillColors.push(condition, "#dc2626") // "fire" in `tailwind.config.js`
  fillOpacities.push(condition, 0.7)

  // State: Otherwise if we're adding, then use next line
  condition = FEATURE_STATE_IS_ADDING
  fillColors.push(condition, "#3bb2d0") // Same as `MapVisualizationDrawStyles`
  fillOpacities.push(condition, 0.6)

  // State: Otherwise, if we're hover, then use next line
  condition = FEATURE_STATE_IS_HOVERING
  fillColors.push(condition, "#3bb2d0") // Same as `MapVisualizationDrawStyles`
  fillOpacities.push(condition, 0.3)

  // State: Default
  fillColors.push("transparent")
  fillOpacities.push(0.0)

  // Build our `paint`
  return {
    "fill-outline-color": "transparent",
    "fill-color": fillColors,
    "fill-opacity": fillOpacities,
  }
}
