import { LAND_GOALS } from "../../shared/constants"
import CheckboxGroup from "../../components/CheckboxGroup"
import { RadioGroup } from "../../components/RadioGroup"
import { ChoiceType } from "@/types"
import { WrappedInput } from "../../components/Input"
import { OnboardingFormValuesTypes } from "./OnboardingForm"

const questionClassname = "space-y-1 md:space-y-2 mt-8 overflow-y-auto md:h-60"

export const formSteps = {
  0: {
    id: "land_connection",
    headerText: (
      <>
        What best describes your connection to this land?{" "}
        <span className="inline-block">[Select one]</span>
      </>
    ),
    subheader:
      "Why we ask: Your relationship with the land shapes how we support you.",
    options: [
      { value: "bought", label: "I bought it myself" },
      { value: "inherit", label: "I inherited it or will inherit it" },
      { value: "prospective_buyer", label: "I'm looking to buy land" },
      {
        value: "friend_family",
        label: "I'm a friend or family member of the owner",
      },
      { value: "not_owner", label: "I'm not the current owner" },
    ],
    formInput: (
      options: ChoiceType[],
      initFormValues: OnboardingFormValuesTypes | undefined
    ) => (
      <RadioGroup
        name="land_connection"
        className={questionClassname}
        valueContentTuples={options.map((choice: ChoiceType) => [
          choice.value,
          choice.label,
        ])}
        state={initFormValues?.land_connection}
        aria-label="describe connection to land"
      />
    ),
  },
  1: {
    id: "land_goals",
    headerText: (
      <>
        What are your top goals for your land?{" "}
        <span className="inline-block">[Select up to 3]</span>
      </>
    ),
    subheader:
      "Why we ask: Knowing your goals helps us tailor personalized guidance.",
    options: [],
    formInput: (
      _options: ChoiceType[] | null,
      initFormValues: OnboardingFormValuesTypes | undefined
    ) => (
      <CheckboxGroup
        name="land_goals"
        className={questionClassname}
        valueContentTuples={Object.keys(LAND_GOALS)
          .filter((key) => key !== "unknown")
          .map((key) => [key, LAND_GOALS[key as keyof typeof LAND_GOALS]])}
        state={initFormValues?.land_goals}
      />
    ),
  },
  2: {
    id: "primary_land_goal",
    headerText: "Which of your top goals is most important to you?",
    subheader: null,
    options: [],
    // inputs will be determined by what was picked in last step
    formInput: (
      options: ChoiceType[],
      initFormValues: OnboardingFormValuesTypes | undefined
    ) => (
      <RadioGroup
        name="primary_land_goal"
        className={questionClassname}
        valueContentTuples={options.map((choice: ChoiceType) => [
          choice.value,
          choice.label,
        ])}
        state={initFormValues?.primary_land_goal}
        aria-label="primary land goal"
      />
    ),
  },
  3: {
    id: "economic_objectives",
    headerText: (
      <>
        How would you like your land to support your financial future?{" "}
        <span className="inline-block">[Select one]</span>
      </>
    ),
    subheader:
      "Why we ask: Understanding your financial vision helps us plan long-term strategies.",
    options: [
      { value: "income", label: "Generate steady income" },
      {
        value: "balance_income_long_term_value",
        label:
          "Find a balance between regular income and growing the land's long-term value",
      },
      {
        value: "long_term_value",
        label:
          "Maximize the long term value of my land or create generational wealth",
      },
      {
        value: "income_not_focus",
        label: "Income would be nice, but it's not my primary focus",
      },
    ],
    formInput: (
      options: ChoiceType[],
      initFormValues: OnboardingFormValuesTypes | undefined
    ) => (
      <RadioGroup
        name="economic_objectives"
        className={questionClassname}
        valueContentTuples={options.map((choice: ChoiceType) => [
          choice.value,
          choice.label,
        ])}
        state={initFormValues?.economic_objectives}
        aria-label="describe economic objective"
      />
    ),
  },
  4: {
    id: "willingness_financially_invest",
    headerText: (
      <>
        Are you willing to financially invest in your land to achieve your
        goals? <span className="inline-block">[Select one]</span>
      </>
    ),
    subheader:
      "Why we ask: This tells us what programs or opportunities fit your needs.",
    options: [
      {
        value: "yes_near_term_gain",
        label: "Yes, if there is a near term financial return",
      },
      {
        value: "yes_cover_costs",
        label: "Yes, if part of my cost may be covered",
      },
      {
        value: "yes_improve_long_term_value",
        label:
          "Yes, if the investments improve long term value, regardless of cost assistance",
      },
      {
        value: "no",
        label:
          "No, I'm not interested in activities that require additional financial investment",
      },
    ],
    formInput: (
      options: ChoiceType[],
      initFormValues: OnboardingFormValuesTypes | undefined
    ) => (
      <RadioGroup
        name="willingness_financially_invest"
        className={questionClassname}
        valueContentTuples={options.map((choice: ChoiceType) => [
          choice.value,
          choice.label,
        ])}
        state={initFormValues?.willingness_financially_invest}
        aria-label="are you willing to financially invest"
      />
    ),
  },
  5: {
    id: "land_management_challenges",
    headerText: (
      <>
        What are your biggest challenges in managing your land?{" "}
        <span className="inline-block">[Select all that apply]</span>
      </>
    ),
    subheader:
      "Why we ask: Identifying your struggles allows us to provide targeted solutions.",
    options: [
      { value: "knowledge", label: "Lack of knowledge or expertise" },
      { value: "time", label: "Lack of time" },
      { value: "finances", label: "Financial constraints" },
      { value: "application_process", label: "Navigating application process" },
      {
        value: "balance_land_use",
        label:
          "Balancing different land uses (e.g. conservation vs. income generation)",
      },
      {
        value: "co_owner_agreement",
        label: "Getting agreement among co-owners",
      },
      { value: "land_manager", label: "Finding a land manager or contractors" },
      {
        value: "timber_market",
        label: "Limited timber market or availability",
      },
      { value: "distance", label: "Distance from my land" },
      { value: "invasives", label: "Managing invasive species" },
    ],
    formInput: (
      options: ChoiceType[],
      initFormValues: OnboardingFormValuesTypes | undefined
    ) => (
      <CheckboxGroup
        name="land_management_challenges"
        className={questionClassname}
        valueContentTuples={options.map((choice: ChoiceType) => [
          choice.value,
          choice.label,
        ])}
        state={initFormValues?.land_management_challenges}
      />
    ),
  },
  6: {
    id: "additional_information",
    headerText: "Anything else for us to know about your land? [Optional]",
    subheader:
      "Tell us about what you'd like to do with your property or any additional context you think we should know.",
    options: [],
    formInput: () => (
      <WrappedInput
        name="additional_information"
        as="textarea"
        placeholder="What do you want to do with your land?"
        className={questionClassname}
        maxLength={500}
      />
    ),
  },
}
