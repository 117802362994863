import { ReactNode } from "react"
import cx from "classnames"

export interface SPBCardProps {
  children: ReactNode
  paddingSize?: "sm" | "md" | "md-y" | "lg" | "lg-y"
}

const SPBCard = ({ children, paddingSize }: SPBCardProps) => (
  <div
    className={cx("rounded-2xl border-2 border-dusk-100", {
      "p-6": paddingSize === "sm",
      "py-6 px-6 md:px-[50px]": paddingSize === "md",
      "py-12 px-6 md:px-[50px]": paddingSize === "md-y",
      "py-6 px-6 md:px-[84px]": paddingSize === "lg",
      "py-7 px-6 md:px-[84px]": paddingSize === "lg-y",
    })}
    data-testid="spb-card"
  >
    {children}
  </div>
)

export default SPBCard
