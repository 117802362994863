import { useParams } from "react-router"

import SimilarProgramsSlider from "../../components/SimilarProgramsSlider"
import { useHeapTrackOnView } from "../../hooks/useHeapTrackOnView"
import { ProgramType } from "@/types/program"

export interface ProjectDetailsSimilarProgramsProps {
  similarPrograms: ProgramType[]
}

const ProjectDetailsSimilarPrograms = ({
  similarPrograms,
}: ProjectDetailsSimilarProgramsProps) => {
  const { projectId } = useParams()

  const similarProgramsRef = useHeapTrackOnView({
    event: "componentview-program-similar-programs",
    properties: { program: projectId },
    observerOptions: { threshold: 0.5 },
  })

  if (!similarPrograms?.length) {
    return null
  }

  return (
    <div
      ref={similarProgramsRef}
      className="container sm:flex xl:max-w-[1120px] mt-20 sm:mt-[66px] px-5 lg:px-16"
    >
      <SimilarProgramsSlider
        programs={similarPrograms}
        header={
          <>
            <hr className="w-[calc(100vw-40px)] sm:w-full border-charcoal-50 mx-5 sm:mx-0 mb-12" />

            <h4 className="mb-6 px-5 sm:px-0 text-2xl leading-[120%] tracking-[0.48px]">
              Similar Programs
            </h4>
          </>
        }
      />
    </div>
  )
}

export default ProjectDetailsSimilarPrograms
