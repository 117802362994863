import { ReactNode } from "react"
import cx from "classnames"

export interface SPBListItemProps {
  item: ReactNode
}

const SPBListItem = ({ item }: SPBListItemProps) => (
  <li
    className={cx(
      "text-sm leading-[140%] tracking-[0.84px]",
      "relative before:content-['•'] before:absolute before:-left-3 before:top-[2px] before:text-sm"
    )}
  >
    {item}
  </li>
)

export default SPBListItem
