import { useRef } from "react"
import cx from "classnames"

export interface ChoiceToggleTypes {
  selection: boolean
  setSelection: (selected: boolean) => void
  leftText: string
  rightText: string
  additionalText?: string
  className?: string
  leftBtnClassName?: string
  rightBtnClassName?: string
}

const ChoiceToggle = ({
  selection,
  setSelection,
  leftText,
  rightText,
  additionalText,
  className,
  leftBtnClassName,
  rightBtnClassName,
}: ChoiceToggleTypes) => {
  const btnLeftRef = useRef<HTMLButtonElement>(null)
  const btnRightRef = useRef<HTMLButtonElement>(null)

  const btnClass =
    "rounded-full focus-visible:outline-hidden focus-visible:ring-3 focus-visible:ring-indigo-300 transition-colors duration-150 ease-in-out"

  return (
    <div
      className={cx("flex justify-start w-[255px] m-auto", className)}
      data-testid="choice-toggle"
    >
      <div className="relative flex p-1 bg-grass-700 rounded-full w-full">
        <span
          className="absolute inset-0 m-1 pointer-events-none"
          aria-hidden="true"
        >
          <span
            className={cx(
              "absolute inset-0 bg-white rounded-full transform transition-transform duration-150 ease-in-out px-4",
              selection ? "translate-x-0" : "translate-x-24"
            )}
            style={{
              width: selection
                ? btnLeftRef?.current?.offsetWidth || 96
                : btnRightRef?.current?.offsetWidth || 150,
            }}
          />
        </span>

        <button
          ref={btnLeftRef}
          className={cx(
            "relative flex-1 w-[90px] text-xs leading-[1.50] h-[26px] text-nowrap px-[11px]",
            btnClass,
            selection ? "text-charcoal-500" : "text-white",
            leftBtnClassName
          )}
          onClick={() => setSelection(true)}
          aria-pressed={selection}
          disabled={selection}
        >
          {leftText}
        </button>

        <button
          ref={btnRightRef}
          className={cx(
            "relative flex-1 w-[152px] text-xs leading-[1.50] h-[26px] text-nowrap px-[11px]",
            btnClass,
            selection ? "text-white" : "text-charcoal-500",
            rightBtnClassName
          )}
          onClick={() => setSelection(false)}
          aria-pressed={selection}
          disabled={!selection}
        >
          {rightText}
          {additionalText ? (
            <span className="inline-block bg-grass-100 text-charcoal-900 text-xs leading-[140%] tracking-[0.36px] rounded-sm px-[6px] ml-2">
              {additionalText}
            </span>
          ) : null}
        </button>
      </div>
    </div>
  )
}

export default ChoiceToggle
