import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"

import Figure from "../../components/Figure"
import SPBCard from "./SPBCard"
import SPBLandGoal from "./SPBLandGoal"
import SPBScoreCard from "./SPBScoreCard"
import SPBList from "./SPBList"
import useMediaQuery from "../../hooks/useMediaQuery"
import { getTimberValueRange } from "./helpers"
import { getScoreRating } from "../ViewAccount/Scorecard/helpers"
import SPB_OUTBREAK_IMG from "../../images/spb-outbreak.jpg"
import SPB_INFESTED_IMG from "../../images/spb-infested-tree.jpg"
import { SPBWizardType } from "@/types/wizards"
import { IMPACT_TO_YOUR_TIMBER } from "./constants"

export interface SPBIntroductionCardsProps {
  wizardData: SPBWizardType | undefined
  primaryLandGoal: string | null | undefined
}

const SPBIntroductionCards = ({
  wizardData,
  primaryLandGoal,
}: SPBIntroductionCardsProps) => {
  const isMaxWidthSm = useMediaQuery("(max-width: 664px)")

  const { timberValueRangeMin, timberValueRangeMax, timberValueRangeString } =
    getTimberValueRange(wizardData)

  const overallScore = wizardData?.scores[0].spb_overall.current_score ?? 0
  const overallScoreRating = getScoreRating(Math.round(overallScore))

  return (
    <>
      <SPBCard paddingSize={isMaxWidthSm ? "sm" : "lg-y"}>
        <div className="flex flex-col gap-6">
          <h2 className="text-2xl font-display font-medium leading-[124%] tracking-[0.48px]">
            Your personalized impact summary
          </h2>

          <div className="border border-dusk-50 rounded-lg px-6 py-4">
            <SPBScoreCard
              heading="Your Overall SPB Resilience"
              subheading={
                <span>
                  <strong>{overallScoreRating}</strong> resilience to SPB.
                </span>
              }
              description={
                <>
                  This rating is calculated using satellite data that monitors
                  tree species, age, and density on your land and surrounding
                  properties. See how we calculate this.{" "}
                  <a
                    href="https://help.ncx.com/hc/en-us/articles/34809317940379-Southern-Pine-Beetle-SPB-Checkup"
                    className="link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    See how we calculate this.
                  </a>
                </>
              }
              currentScore={
                wizardData?.scores[0].spb_overall.current_score ?? 0
              }
              maxScore={wizardData?.scores[0].spb_overall.max_score ?? 0}
            />
          </div>

          {timberValueRangeMin !== null && timberValueRangeMax !== null ? (
            <div className="flex gap-2 items-center border border-dusk-50 rounded-lg p-3">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="text-leaf"
                style={{ fontSize: "27px" }}
              />

              <p className="text-charcoal-500 text-lg font-body font-bold leading-[140%] tracking-[0.36px]">
                Estimated Timber Value at Risk: {timberValueRangeString}
              </p>
            </div>
          ) : null}
        </div>
      </SPBCard>

      <SPBCard paddingSize={isMaxWidthSm ? "sm" : "lg-y"}>
        <div className="flex flex-col gap-6">
          <h2 className="text-2xl font-display font-medium leading-[124%] tracking-[0.48px]">
            What is Southern Pine Beetle?
          </h2>

          <p className="text-sm font-body leading-[140%] tracking-[0.84px]">
            Southern Pine Beetles swarm during summer months to attack and kill
            pine trees, particularly loblolly, shortleaf, and Virginia pine.
            Beetles infest a tree, reproduce, and spread to nearby trees. These
            affected zones or “spots” can rapidly grow from just a few trees to
            encompass hundreds of acres.
          </p>

          <SPBLandGoal primaryLandGoal={primaryLandGoal} />
          {primaryLandGoal === undefined ? (
            <p className="text-sm font-body leading-[140%] tracking-[0.84px]">
              After you complete your Southern Pine Beetle checkup, we'll ask
              you more about your goals to provide personalized insights and
              recommendations about your land.
            </p>
          ) : (
            <SPBList
              caption="Impact to your timber:"
              list={
                IMPACT_TO_YOUR_TIMBER[
                  primaryLandGoal as keyof typeof IMPACT_TO_YOUR_TIMBER
                ]
              }
            />
          )}

          <SPBList
            caption="Key Facts"
            list={[
              "SPB prevention costs less than control or clean up.",
              "Loss is rapid once an outbreak starts.",
              "The denser your pines, the lower your resilience.",
            ]}
          />

          <div className="grid grid-cols-2 gap-[38px]">
            <Figure
              img={SPB_OUTBREAK_IMG}
              figcaption="Aerial image of SPB outbreak"
            />

            <Figure
              img={SPB_INFESTED_IMG}
              figcaption="Photo of SPB infested tree"
            />
          </div>
        </div>
      </SPBCard>

      <SPBCard paddingSize={isMaxWidthSm ? "sm" : "lg-y"}>
        <div className="flex flex-col gap-6">
          <h2 className="text-2xl font-display font-medium leading-[124%] tracking-[0.48px]">
            How can NCX help you
          </h2>

          <p className="font-body leading-[140%] tracking-[0.32px]">
            We are here to help protect you against timber revenue loss and
            more.
          </p>

          <div className="bg-grass-50 border border-grass-700 rounded-sm py-[30px] px-[25px]">
            <h3 className="font-body font-bold leading-[24px] tracking-[0.96px]">
              We build a personalized plan for you and help you take action.
            </h3>

            <p className="text-sm font-body leading-[140%] tracking-[0.28px]">
              Depending on risk severity of your land, we will give guidance
              such as monitoring, thinning trees, or even clearcutting and
              replanting.
            </p>

            <h3 className="font-body font-bold leading-[24px] tracking-[0.96px] mt-3">
              We sort through thousands of grants & subsidies to find the right
              ones for you.
            </h3>

            <p className="text-sm font-body leading-[140%] tracking-[0.28px]">
              This may include state specific tax credits, cost share assistance
              programs, or timber replanting and carbon programs.
            </p>

            <h3 className="font-body font-bold leading-[24px] tracking-[0.96px] mt-3">
              Act as your personal support team, all managed from your desktop
              or phone.
            </h3>

            <p className="text-sm font-body leading-[140%] tracking-[0.28px]">
              We assess your land, plan with you to maximize your land goals,
              and help you implement that plan, leaving you more time to enjoy
              it.
            </p>
          </div>
        </div>
      </SPBCard>
    </>
  )
}

export default SPBIntroductionCards
