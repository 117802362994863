export interface SPBLandGoalItemProps {
  icon: string
  title: string
}

const SPBLandGoalItem = ({ icon, title }: SPBLandGoalItemProps) => (
  <h3 className="flex gap-2 md:-ml-8">
    <img src={icon} />

    <span className="text-charcoal-500 text-xl font-body font-bold leading-[140%] tracking-[0.4px]">
      Your top goal: {title}
    </span>
  </h3>
)

export default SPBLandGoalItem
