export const FILTER_FNS = {
  includesString: "includesString",
  doesNotIncludeString: "doesNotIncludeString",
  equals: "equals",
  weakEquals: "weakEquals",
  isNot: "isNot",
  isEmpty: "isEmpty",
  isNotEmpty: "isNotEmpty",
  lessOrEqual: "lessOrEqual",
  moreOrEqual: "moreOrEqual",
  notEqual: "notEqual",
  isBefore: "isBefore",
  isAfter: "isAfter",
  isDate: "isDate",
  isNotDate: "isNotDate",
}

export const textFns = [
  {
    value: FILTER_FNS.includesString,
    label: "includes",
  },
  {
    value: FILTER_FNS.doesNotIncludeString,
    label: "does not include",
  },
  {
    value: FILTER_FNS.weakEquals,
    label: "is",
  },
  {
    value: FILTER_FNS.isNot,
    label: "is not",
  },
  {
    value: FILTER_FNS.isEmpty,
    label: "is empty",
  },
  {
    value: FILTER_FNS.isNotEmpty,
    label: "is not empty",
  },
]

export const numberFns = [
  {
    value: FILTER_FNS.lessOrEqual,
    label: "<=",
  },
  {
    value: FILTER_FNS.moreOrEqual,
    label: ">=",
  },
  {
    value: FILTER_FNS.weakEquals,
    label: "=",
  },
  {
    value: FILTER_FNS.notEqual,
    label: "!=",
  },
  {
    value: FILTER_FNS.isEmpty,
    label: "is empty",
  },
  {
    value: FILTER_FNS.isNotEmpty,
    label: "is not empty",
  },
]

export const dateFns = [
  {
    value: FILTER_FNS.isBefore,
    label: "is before",
  },
  {
    value: FILTER_FNS.isAfter,
    label: "is after",
  },
  {
    value: FILTER_FNS.isDate,
    label: "is",
  },
  {
    value: FILTER_FNS.isNotDate,
    label: "is not",
  },
  {
    value: FILTER_FNS.isEmpty,
    label: "is empty",
  },
  {
    value: FILTER_FNS.isNotEmpty,
    label: "is not empty",
  },
]

export const URL_PARAM_SUFFIX = {
  [FILTER_FNS.includesString]: "",
  [FILTER_FNS.doesNotIncludeString]: "_excludes",
  [FILTER_FNS.isNot]: "_is_not",
  [FILTER_FNS.weakEquals]: "_exact",
  [FILTER_FNS.isEmpty]: "_isnull",
  [FILTER_FNS.isNotEmpty]: "_exists",
  [FILTER_FNS.lessOrEqual]: "_max",
  [FILTER_FNS.moreOrEqual]: "_min",
  [FILTER_FNS.notEqual]: "_is_not",
  [FILTER_FNS.isBefore]: "_before",
  [FILTER_FNS.isAfter]: "_after",
}
