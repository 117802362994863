import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition as IconDefinitionSvg } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

export interface ArrowTypes {
  onClick: () => void
  icon: IconDefinitionSvg
}

const SimilarProgramsSliderArrow = ({ onClick, icon }: ArrowTypes) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(
      "h-10 w-10 relative select-none cursor-pointer hidden",
      "border border-charcoal-50 rounded-full text-leaf",
      "focus:outline-hidden",
      "md:flex md:justify-center md:items-center"
    )}
    data-testid={icon.iconName}
  >
    <FontAwesomeIcon icon={icon} title={icon.iconName} />
  </button>
)

export default SimilarProgramsSliderArrow
