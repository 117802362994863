import { Dispatch, SetStateAction, useContext, useState } from "react"
import { Formik, FormikValues } from "formik"
import * as Yup from "yup"
import { OptimizelyContext } from "@optimizely/react-sdk"
import { usePostHog } from "posthog-js/react"

import { genericErrMsg } from "../../api/auth"
import {
  identifyUserForIntegrations,
  sendAnalyticsEvent,
} from "../../api/integrations"
import { useCreateUser } from "../../hooks"
import SignUpFormComponent from "./SignUpFormComponent"
import { useSessionStore } from "../../stores"
import { AuthDataTypes } from "@/types/auth"

export interface SignUpFormTypes {
  authData: AuthDataTypes
  inputEmail: string
  setIsUserCreated: Dispatch<SetStateAction<boolean>>
  setInputEmail: Dispatch<SetStateAction<string>>
  setAuthData: Dispatch<SetStateAction<AuthDataTypes>>
}

const validationSchema = Yup.object().shape({
  profile_type: Yup.string().required("Please choose an account type."),
  first_name: Yup.string().required("Please provide your first name."),
  last_name: Yup.string().required("Please provide your last name."),
  email: Yup.string()
    .email("Please provide a valid email address.")
    .required("Please provide your email address."),
  password: Yup.string()
    .required("Please provide a password.")
    .min(8, "Password must be at least 8 characters."),
})

const SignUpForm = ({
  authData,
  inputEmail,
  setAuthData,
  setIsUserCreated,
}: SignUpFormTypes) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { optimizely } = useContext(OptimizelyContext)
  const posthog = usePostHog()
  const { identified, setIdentified } = useSessionStore((state) => state)

  const { mutateAsync: createUser } = useCreateUser({
    onSuccess: (_data: any, variables: { email: string }) => {
      identifyUserForIntegrations(
        variables.email,
        optimizely,
        posthog,
        identified,
        setIdentified
      )
      sendAnalyticsEvent({ action: "signup", label: "Landowners Signup" })
      setIsUserCreated(true)
    },
    onError: (error: { detail: string }) => {
      setErrorMessage(error?.detail || genericErrMsg)
    },
  })

  const onSubmit = async (_values: FormikValues) => {
    const values = { ..._values }
    await createUser(values)
  }

  return (
    <Formik
      initialValues={{
        profile_type: "multi_account",
        first_name: "",
        last_name: "",
        email: inputEmail || "",
        password: "",
        ...authData,
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onSubmit}
    >
      {(formikProps) => (
        <SignUpFormComponent
          {...formikProps}
          errorMessage={errorMessage}
          setAuthData={setAuthData}
        />
      )}
    </Formik>
  )
}
export default SignUpForm
