import OnboardingOverviewFooter from "./OnboardingOverviewFooter"
import BLOB from "../../../images/blob.png"
import DEER from "../../../images/deer.gif"

export interface OnboardingOverviewIntroProps {
  handleOverviewStep: () => void
}

const OnboardingOverviewIntro = ({
  handleOverviewStep,
}: OnboardingOverviewIntroProps) => (
  <div className="container xl:max-w-[1396px] pt-6 sm:pt-[118px] pb-[104px] mx-auto">
    <div className="flex flex-col-reverse sm:flex-row justify-between gap-8">
      <div className="flex justify-center w-auto max-w-[528px]">
        <div className="relative w-[242px] md:w-auto max-w-full">
          <img src={BLOB} className="w-full" alt="blob" />

          <img
            src={DEER}
            alt="deer"
            className="w-[77.589%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      </div>

      <div className="md:w-[571px] max-w-full flex flex-col justify-center">
        <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-[0.48px]">
          Thank you for sharing! We're assembling your personalized NCX
          dashboard now.
        </h1>

        <p className="text-dusk-500 text-base leading-[140%] tracking-[0.32px] mt-3">
          Let's learn some key concepts while we work on that.
        </p>
      </div>
    </div>

    <OnboardingOverviewFooter>
      <button
        className="btn2 btn2-primary ml-auto"
        onClick={() => handleOverviewStep()}
      >
        Next
      </button>
    </OnboardingOverviewFooter>
  </div>
)

export default OnboardingOverviewIntro
