import OnboardingOverviewContainer from "./OnboardingOverviewContainer"
import OnboardingOverviewScoresImage from "./OnboardingOverviewScoresImage"
import OnboardingOverviewFooter from "./OnboardingOverviewFooter"
import ScoreCardItem from "../../../sections/ViewAccount/Scorecard/ScoreCardItem"
import useViewport from "../../../hooks/useViewport"

export interface OnboardingOverviewScoresProps {
  handleOverviewStep: () => void
}

const OnboardingOverviewScores = ({
  handleOverviewStep,
}: OnboardingOverviewScoresProps) => {
  const { width } = useViewport()

  return (
    <OnboardingOverviewContainer className="flex flex-col lg:flex-row lg:justify-between gap-8 pt-[24px] lg:pt-24 pb-[104px]">
      {width > 1024 ? <OnboardingOverviewScoresImage /> : null}

      <div className="lg:w-[500px] max-w-full">
        <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-[0.48px]">
          Your Natural Capital Asset Scores can change with Actions.
        </h1>

        <p className="text-dusk-500 text-base font-body leading-[140%] tracking-[0.32px] mt-3">
          Each asset's score shows its current state and the potential for
          improvement. It's important to think about trade-offs, or how an
          action that increases one score might affect your other scores.
        </p>

        <h4 className="text-black font-body font-medium leading-[140%] tracking-[0.32px] mt-3">
          Example Score:
        </h4>

        <div className="border border-dusk-50 rounded-lg px-6 py-4 mt-3">
          <ScoreCardItem
            currentScore={7}
            maxScore={9}
            category="carbon_potential"
          />
        </div>

        {width <= 1024 ? <OnboardingOverviewScoresImage /> : null}
      </div>

      <OnboardingOverviewFooter>
        <button
          className="btn2 btn2-primary ml-auto"
          onClick={() => handleOverviewStep()}
        >
          Next
        </button>
      </OnboardingOverviewFooter>
    </OnboardingOverviewContainer>
  )
}

export default OnboardingOverviewScores
