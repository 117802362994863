import { ErrorMessage as FormikErrorMessage } from "formik"
import cx from "classnames"

import { ErrorMessageProps } from "./types"

const ErrorMessage = ({ className, ...rest }: ErrorMessageProps) => (
  <FormikErrorMessage
    component="div"
    className={cx(
      "text-sm leading-[1.50] tracking-[0.01em] text-red-600",
      className
    )}
    {...rest}
  />
)

export default ErrorMessage
