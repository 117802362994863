export interface ExpandableContainerOverlayProps {
  show: boolean
}

const ExpandableContainerOverlay = ({
  show,
}: ExpandableContainerOverlayProps) => {
  if (!show) {
    return null
  }

  return (
    <div
      className="h-32 absolute bottom-14 left-0 right-0 bg-linear-to-t from-cloud-50 to-transparent"
      data-testid="expandable-container-overlay"
    />
  )
}

export default ExpandableContainerOverlay
