import HelperCard from "../../components/HelperCard"
import SPBCard from "./SPBCard"
import SPBScoreCard from "./SPBScoreCard"
import SPBOutbreakForecastCard from "./SPBOutbreakForecastCard"
import SPBMap from "./SPBMap"
import { getScoreRating } from "../ViewAccount/Scorecard/helpers"
import useMediaQuery from "../../hooks/useMediaQuery"
import { OUTBREAK_RATING } from "./constants"
import { RegionalType, SPBWizardType, TileUrlsType } from "@/types/wizards"
import { TileURLsTypes } from "@/types/tiles"
import SPBRiskNotification from "./SPBRiskNotification"

export interface SPBRiskAssessmentCardsProps {
  wizardData: SPBWizardType | undefined
  spbOverallMapTileData: TileUrlsType | undefined
}

const SPBRiskAssessmentCards = ({
  wizardData,
  spbOverallMapTileData,
}: SPBRiskAssessmentCardsProps) => {
  const isMaxWidthSm = useMediaQuery("(max-width: 664px)")

  const yourLandScore = wizardData?.scores?.[1]?.spb_direct?.current_score ?? 0
  const yourLandScoreRating = getScoreRating(Math.round(yourLandScore))

  const neighborhoodScore =
    wizardData?.scores[2].spb_indirect.current_score ?? 0
  const neighborhoodScoreRating = getScoreRating(Math.round(neighborhoodScore))

  const outbreakRating =
    OUTBREAK_RATING[wizardData?.additional_data.regional as RegionalType]

  return (
    <div className="container w-[780px] max-w-full flex flex-col gap-6 px-5 lg:px-10">
      <SPBCard paddingSize={isMaxWidthSm ? "sm" : "md"}>
        <div className="flex flex-col gap-6">
          <h2 className="text-2xl font-display font-medium leading-[124%] tracking-[0.48px]">
            Your Southern Pine Beetle Resilience Map
          </h2>

          <p className="text-sm font-body leading-[140%] tracking-[0.28px]">
            This map shows Southern Pine Beetle resilience levels across your
            property and neighboring properties. Interact with the map to see
            detailed information for specific areas.
          </p>

          <SPBRiskNotification wizardData={wizardData} />

          <SPBMap
            tileData={
              {
                url: spbOverallMapTileData?.spb_overall,
              } as TileURLsTypes
            }
            lowText="Low Resilience"
            moderateText="Moderate Resilience"
            highText="High Resilience"
            description="This map shows Southern Pine Beetle resilience levels across your property and neighboring properties. Interact with the map to see detailed information for specific areas."
          />

          <HelperCard className="!rounded-lg !pl-3">
            <p className="text-charcoal-500 text-sm leading-[140%] tracking-[0.28px]">
              <strong>Low Resilience</strong> this means your timber might get
              hit harder and cause more short and long term damage.
            </p>

            <p className="text-charcoal-500 text-sm leading-[140%] tracking-[0.28px] mt-3">
              <strong>High Resilience</strong> means your timber is healthy or
              the tree species is unaffected.
            </p>

            <p className="text-charcoal-500 text-sm leading-[140%] tracking-[0.28px] mt-3">
              <a
                href="https://help.ncx.com/hc/en-us/articles/34809317940379-Southern-Pine-Beetle-SPB-Checkup"
                className="link"
                rel="noopener noreferrer"
              >
                Learn more about Southern Pine Beetle resilience
              </a>
            </p>
          </HelperCard>
        </div>
      </SPBCard>

      <SPBCard paddingSize={isMaxWidthSm ? "sm" : "md"}>
        <div className="flex flex-col gap-6">
          <h2 className="text-2xl font-display font-medium leading-[124%] tracking-[0.48px]">
            Your Southern Pine Beetle Resilience Assessment
          </h2>

          <div className="border border-dusk-50 rounded-lg px-6 py-4">
            <SPBScoreCard
              heading="Your Land's SPB Resilience"
              subheading={`Your forest has ${yourLandScoreRating.toLowerCase()} resilience to SPB.`}
              description="Based on your forest density, tree species, and management practices."
              currentScore={wizardData?.scores[1].spb_direct.current_score ?? 0}
              maxScore={wizardData?.scores[1].spb_direct.max_score ?? 0}
            />
          </div>

          <div className="border border-dusk-50 rounded-lg px-6 py-4">
            <SPBScoreCard
              heading="Neighborhood SPB Resilience"
              subheading={`Surrounding forests have ${neighborhoodScoreRating.toLowerCase()} resilience to SPB.`}
              description="Based on surrounding forest conditions within a 2-mile radius."
              currentScore={
                wizardData?.scores[2].spb_indirect.current_score ?? 0
              }
              maxScore={wizardData?.scores[2].spb_indirect.max_score ?? 0}
            />
          </div>

          <div className="border border-dusk-50 rounded-lg px-6 py-4">
            <SPBOutbreakForecastCard
              heading="Regional SPB Outbreak Forecast"
              subheading={`The current forecast of SPB outbreak in your area is ${outbreakRating.toLowerCase()}. This is based on recent SPB activity, weather conditions, and forest health in your region.`}
              description={
                <>
                  Source:{" "}
                  <a
                    href="https://www.spbpredict.com/"
                    className="text-leaf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    spbpredict.com
                  </a>
                </>
              }
              rating={wizardData?.additional_data.regional}
            />
          </div>
        </div>
      </SPBCard>
    </div>
  )
}

export default SPBRiskAssessmentCards
