import { useEffect, useState } from "react"
import cx from "classnames"

import ADVISOR_SIGNUP_SAMPLE_1 from "../../images/become-a-member-sample-1.jpg"
import ADVISOR_SIGNUP_SAMPLE_2 from "../../images/become-a-member-sample-2.jpg"
import ADVISOR_SIGNUP_SAMPLE_3 from "../../images/become-a-member-sample-3.jpg"

const images = [
  ADVISOR_SIGNUP_SAMPLE_1,
  ADVISOR_SIGNUP_SAMPLE_2,
  ADVISOR_SIGNUP_SAMPLE_3,
]

const AdvisorySignupSamples = () => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="w-full max-w-[816px] mx-auto mt-8">
      <h3 className="text-2xl text-autumn sm:text-center font-semibold leading-[124%] tracking-[0.48px]">
        Preview a sample plan & recommendations
      </h3>

      <div className="relative mx-auto w-full max-w-[456px] mt-5">
        {/* DEV: Hidden image maintains the dimensions of the parent div due to rest of the images being absolutely positioned.  */}
        <img
          src={images[currentIndex]}
          alt={`Current Slide ${currentIndex + 1}`}
          className="block w-full h-auto opacity-0"
        />
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Slide ${index + 1}`}
            className={cx(
              "block absolute top-0 left-0 w-full h-auto transition-opacity duration-1000 ease-in-out",
              index === currentIndex ? "opacity-100" : "opacity-0"
            )}
          />
        ))}
      </div>
    </div>
  )
}

export default AdvisorySignupSamples
