import { Link } from "react-router"
import cx from "classnames"

import { useAccountUrlPrefix } from "../../../hooks"
import SPB_CARD_IMG from "../../../images/spb-wizard-card.svg"
import { SPBWizardType } from "@/types/wizards"

export interface SPBWizardCardProps {
  wizardData: SPBWizardType | undefined
}

const SPBWizardCard = ({ wizardData }: SPBWizardCardProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  if (!wizardData) {
    return null
  }

  return (
    <div className="bg-cloud-50 flex gap-4 border-2 border-[#ECA674] rounded-sm p-4">
      <div className="shrink-0">
        <img src={SPB_CARD_IMG} alt="spb" width={84} height={138} />
      </div>

      <div>
        <h3 className="font-bold leading-[130%] font-body tracking-[0.32px]">
          {wizardData.completed
            ? "Your Plan Summary: Protect Against Southern Pine Beetle"
            : "Are you prepared for Southern Pine Beetle season? We can help!"}
        </h3>

        <p className="font-body leading-[130%] tracking-[0.32px] mt-2">
          {wizardData.completed
            ? "Review your plan summary or make changes to your plan."
            : "Find out now if your land is at risk, create a plan to increase your forest health, and get implementation assistance from NCX."}
        </p>

        <Link
          to={`${accountUrlPrefix}/checkup/spb`}
          className={cx("btn2 btn2-primary font-bold px-6 py-[11px] mt-4", {
            "spb-widget-review-plan-btn": wizardData.completed,
            "spb-widget-get-started-btn": !wizardData.completed,
          })}
        >
          {wizardData.completed ? "Review Plan" : "Get Started"}
        </Link>
      </div>
    </div>
  )
}

export default SPBWizardCard
