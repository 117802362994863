import { shortenAcreage } from "../../utils"

interface ThreatsHeaderProps {
  acreage: number | undefined
  firstName: string | null | undefined
}

const ThreatsHeader = ({ acreage, firstName }: ThreatsHeaderProps) => {
  const shortenedAcreage = acreage ? shortenAcreage(acreage) : "0"

  return (
    <div className="border-b border-dusk-500 mb-7">
      <h2 className="text-charcoal-500 text-3xl font-medium leading-[53px] tracking-[0.6px] mt-5 mb-4">{`${firstName}'s ${shortenedAcreage} Acres`}</h2>

      <p className="text-dusk-500 text-sm font-overline font-medium uppercase leading-[150%] tracking-[1.68px] mb-2">
        Loss Detection
      </p>
    </div>
  )
}

export default ThreatsHeader
