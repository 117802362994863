import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { useDialogState } from "reakit"
import cx from "classnames"

import { Toast } from "../../../components/Toast"
import ProjectComparisonHeader from "./ProjectComparisonHeader"
import ProjectComparisonContent from "./ProjectComparisonContent"
import ProjectComparisonModal from "./ProjectComparisonModal"
import useIsSticky from "../../../hooks/useIsSticky"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import {
  useAccountId,
  useGetCompare,
  useGetComparisonReport,
} from "../../../hooks"
import { genericErrMsg } from "../../../api/auth"
import { ProsConsDataType } from "@/types/comparison"

interface ProjectComparisonProps {
  isMember: boolean | undefined
}

const ProjectComparison = ({ isMember }: ProjectComparisonProps) => {
  const accountId = useAccountId()
  const queryClient = useQueryClient()
  const { comparisonOn, comparison } = useProjectsStore()
  const { stickyRef, isSticky } = useIsSticky(comparisonOn)
  const dialog = useDialogState({ animated: true, modal: false })
  const [comparisonContentOpen, setComparisonContentOpen] =
    useState<boolean>(false)
  const [PDFDownloadLink, setPDFDownloadLink] = useState<string | null>(null)

  const {
    mutateAsync: getCompareReport,
    isPending: isGetCompareReportPending,
  } = useGetComparisonReport(queryClient, accountId, {
    onSuccess: (data: BlobPart) => {
      const blob = new Blob([data], { type: "application/pdf" })
      const url = window.URL.createObjectURL(blob)
      setPDFDownloadLink(url)
    },
    onError: (error: Error) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const handleGetCompareReport = async (data: ProsConsDataType) => {
    await getCompareReport(data)
  }

  const programIds: number[] = comparison.map((program) => program.id)

  const {
    data: compareData,
    mutateAsync: getCompare,
    isPending: isGetComparePending,
  } = useGetCompare(queryClient, accountId, false, {
    onSuccess: (data: ProsConsDataType) => {
      handleGetCompareReport({ ...data, program_ids: programIds })
    },
    onError: (error: Error) => {
      Toast.error(error?.message || genericErrMsg)
    },
  })

  const handleGetCompare = async () => {
    dialog.show()

    await getCompare(programIds)
  }

  if (!comparisonOn || !isMember) {
    return null
  }

  const handleComparisonContentOpen = () => {
    setComparisonContentOpen((oldState) => !oldState)
  }

  return (
    <>
      <div
        ref={stickyRef}
        className={cx(
          "w-full bg-white border-t border-b border-dusk-100 shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.06)] md:shadow-none pt-[18px] mb-5 z-9",
          {
            "sticky top-[64px] md:top-0 z-10": comparisonOn,
            "md:shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.06)]! border-t-transparent":
              isSticky,
          }
        )}
      >
        <div className="container max-w-[2400px]">
          <ProjectComparisonHeader
            comparisonContentOpen={comparisonContentOpen}
            handleComparisonContentOpen={handleComparisonContentOpen}
            handleGetCompare={() => {
              handleGetCompare()
            }}
          />

          <ProjectComparisonContent
            comparisonContentOpen={comparisonContentOpen}
          />
        </div>
      </div>

      <ProjectComparisonModal
        dialog={dialog}
        isFetching={isGetComparePending || isGetCompareReportPending}
        compareData={compareData}
        PDFDownloadLink={PDFDownloadLink}
      />
    </>
  )
}

export default ProjectComparison
