import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { getComparisonReport } from "../../../api/data"
import { useMutationWithRefresh } from "../utils"
import { ProsConsDataType } from "@/types/comparison"

export function useGetComparisonReport<
  TData,
  TError,
  TVariables extends ProsConsDataType,
>(
  queryClient: QueryClient,
  accountId: string,
  config = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => getComparisonReport(accountId, values),
    config
  )
}
