import { ReactNode } from "react"
import { Link } from "react-router"
import cx from "classnames"

export interface PaginationLinkProps {
  children: ReactNode
  to: string | undefined
  className: string
}

const PaginationLink = ({ children, to, className }: PaginationLinkProps) => {
  if (to === undefined) {
    return (
      <div
        className={cx(
          "flex justify-center items-center text-grass-200",
          className
        )}
      >
        {children}
      </div>
    )
  }

  return (
    <Link
      to={to}
      className={cx(
        "flex justify-center items-center text-leaf hover:text-grass-900 active:text-grass-900",
        "transition-colors duration-200 ease-[cubic-bezier(0.4, 0, 0.2, 1)]",
        className
      )}
      data-testid={`pagination-link-${to}`}
    >
      {children}
    </Link>
  )
}

export default PaginationLink
