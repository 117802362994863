import { Dispatch, SetStateAction } from "react"
import { Form, FormikProps } from "formik"

import ErrorCard from "../../components/ErrorCard"
import { SubmitButton } from "../../components/SubmitButton"
import { WrappedInput } from "../../components/Input"
import { useQueryParam } from "../../hooks"
import NCX from "../../images/ncx-login-logo.png"
import { GettingStartedFormValues } from "./GettingStartedForm"

interface GettingStartedFormComponentTypes
  extends FormikProps<GettingStartedFormValues> {
  errorMessage: string | null
  setAuthData: Dispatch<SetStateAction<GettingStartedFormValues>>
}

const GettingStartedFormComponent = ({
  values, // form values
  errorMessage,
  isSubmitting,
  setAuthData,
}: GettingStartedFormComponentTypes) => {
  const queryEmail = useQueryParam("email")

  function handleBlur() {
    setAuthData({
      email: values.email,
    })
  }

  return (
    <>
      <div className="flex justify-center mb-4">
        <img
          src={NCX}
          alt="NCX Logo"
          className="block w-auto h-[51px] lg:h-[46px]"
        />
      </div>

      <h2 className="text-center text-lg leading-[130%] tracking-[0.378px] mb-2">
        Welcome to the NCX Pro Network
      </h2>

      <p className="text-center text-base leading-[130%] tracking-[0.32px] mb-4">
        Log in or Sign Up
      </p>

      <Form className="space-y-4" onBlur={handleBlur}>
        {errorMessage && <ErrorCard>{errorMessage}</ErrorCard>}
        <div className="space-y-1">
          <WrappedInput
            data-test="input-email"
            label="Email address"
            name="email"
            placeholder="name@example.com"
            type="text"
            as="email"
          />
          {queryEmail && queryEmail !== values.email && (
            <div className="text-sm leading-5 tracking-[0.01em] text-dusk-500">
              Changing your email will lose the invite
            </div>
          )}
        </div>

        <SubmitButton
          className="btn2 btn2-primary btn2-block font-semibold"
          isSubmitting={isSubmitting}
          data-test="continue-with-email"
        >
          Continue
        </SubmitButton>

        <div className="text-base leading-[1.50] tracking-[0.02em] text-dusk-500 text-center">
          By creating an account, you agree to the NCX{" "}
          <a
            href="https://ncx.com/terms/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="https://ncx.com/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Privacy Policy
          </a>
          .
        </div>
        <div className="text-base leading-[1.50] tracking-[0.02em] text-dusk-500 text-center">
          NCX Pro Network{" "}
          <a
            href="https://help.ncx.com/hc/en-us/articles/24976346232091-NCX-Pro-Network-Terms-Conditions"
            target="_blank"
            rel="noopener noreferrer"
            className="link"
          >
            Terms & Conditions
          </a>{" "}
          apply.
        </div>

        <hr className="border-charcoal-50" />

        <div className="text-center text-dusk-500">
          Need help?{" "}
          <a
            className="link--underline-only text-base leading-[1.50] tracking-[0.02em] text-dusk-500"
            href="mailto:landowners@ncx.com"
            data-test="having-trouble"
          >
            <span className="link">landowners@ncx.com</span>
          </a>
        </div>
      </Form>
    </>
  )
}

export default GettingStartedFormComponent
