import { Navigate, useLocation } from "react-router"
import { Spinner } from "../../components/Spinner"

import SPBContentIntroduction from "./SPBContentIntroduction"
import SPBContentRiskAssessment from "./SPBContentRiskAssessment"
import SPBContentSelectApproach from "./SPBContentSelectApproach"
import SPBContentTakeAction from "./SPBContentTakeAction"
import { useSPBStore } from "../../stores/useSPBStore"
import { useAccessToken } from "../../stores"
import { Profile } from "@/types"
import { AccountInterestTypes } from "@/types/accountInterest"
import { SPBWizardType, TileUrlsType } from "@/types/wizards"
import { ProjectListTypes } from "@/types/program"
import SPBContentPlanSummary from "./SPBContentPlanSummary"
import { AccountProperty } from "@/types/property"
import { AccountTypes } from "@/types/account"
import SPBContentNoActionPlanSummary from "./SPBContentNoActionPlanSummary"

export interface SPBContentProps {
  profile: Profile | undefined
  property: AccountProperty | undefined
  account: AccountTypes | undefined
  accountInterestData: AccountInterestTypes | undefined
  wizardData: SPBWizardType | undefined
  spbOverallMapTileData: TileUrlsType | undefined
  spbActionMapsTileData: TileUrlsType | undefined
  projectList: ProjectListTypes | undefined
  isLoading: boolean
  spbActionMapsTileDataIsLoading: boolean
}

const SPBContent = ({
  profile,
  property,
  account,
  accountInterestData,
  wizardData,
  spbOverallMapTileData,
  spbActionMapsTileData,
  projectList,
  isLoading,
  spbActionMapsTileDataIsLoading,
}: SPBContentProps) => {
  const location = useLocation()
  const accessToken = useAccessToken()
  const {
    activeStep,
    enabledSteps,
    selectedActionGroup,
    setActiveStep,
    setEnabledSteps,
  } = useSPBStore()

  const isLoggedIn = !!accessToken

  const handleNext = () => {
    const newEnabledSteps = [...enabledSteps]
    newEnabledSteps[activeStep + 1] = true

    setEnabledSteps(newEnabledSteps)
    setActiveStep(activeStep + 1)
  }

  const handlePrevious = () => {
    setActiveStep(activeStep - 1)
  }

  if (!isLoggedIn) {
    return <Navigate replace to="/getting-started" state={{ from: location }} />
  }

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (!wizardData) {
    return <Navigate replace to="/" />
  }

  if (activeStep === 0) {
    return (
      <SPBContentIntroduction
        accountInterestData={accountInterestData}
        wizardData={wizardData}
        handleNext={handleNext}
      />
    )
  }

  if (activeStep === 1) {
    return spbActionMapsTileDataIsLoading ? (
      <div className="h-screen flex items-center justify-center">
        <Spinner />
      </div>
    ) : (
      <SPBContentRiskAssessment
        wizardData={wizardData}
        spbOverallMapTileData={spbOverallMapTileData}
        spbActionMapsTileData={spbActionMapsTileData}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
    )
  }

  if (activeStep === 2) {
    return (
      <SPBContentSelectApproach
        wizardData={wizardData}
        projectList={projectList}
        spbActionMapsTileData={spbActionMapsTileData}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
    )
  }

  if (activeStep === 3) {
    return (
      <SPBContentTakeAction
        profile={profile}
        handleNext={handleNext}
        handlePrevious={handlePrevious}
      />
    )
  }

  if (activeStep === 4) {
    const showNoActionSummary =
      selectedActionGroup === "spb_no_action" ||
      (spbActionMapsTileData && Object.keys(spbActionMapsTileData).length === 0)

    return spbActionMapsTileDataIsLoading ? (
      <div className="h-screen flex items-center justify-center">
        <Spinner />
      </div>
    ) : showNoActionSummary ? (
      <SPBContentNoActionPlanSummary
        wizardData={wizardData}
        account={account}
      />
    ) : (
      <SPBContentPlanSummary
        property={property}
        wizardData={wizardData}
        account={account}
        projectList={projectList}
        spbActionMapsTileData={spbActionMapsTileData}
      />
    )
  }

  return null
}

export default SPBContent
