export interface FigureProps {
  img: string
  figcaption: string
  alt?: string
  className?: string
}

const Figure = ({ img, figcaption, alt, className }: FigureProps) => (
  <figure className={className}>
    <img src={img} alt={alt ?? figcaption} />

    <figcaption className="text-dusk-500 text-xs italic leading-[124%] tracking-[0.34px] mt-1">
      {figcaption}
    </figcaption>
  </figure>
)

export default Figure
