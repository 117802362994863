import ScrollToBottom from "../../components/ScrollToBottom"
import { ReactNode } from "react"

export interface SPBFooterProps {
  children: ReactNode
}

const SPBFooter = ({ children }: SPBFooterProps) => (
  <div className="bg-white fixed z-[2] bottom-0 left-0 right-0 flex justify-between shadow-[0px_-2px_2px_0px_rgba(0,0,0,0.10)] px-6 lg:px-[70px] pb-4 pt-6">
    {children}

    <ScrollToBottom className="!bottom-25" />
  </div>
)

export default SPBFooter
