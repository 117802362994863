import { ReactNode } from "react"
import ToggleSwitch from "../ToggleSwitch"

export interface ToggleEligibilityLayerProps {
  show: boolean
  value: boolean
  label: ReactNode
  onChange: () => void
}

const ToggleEligibilityLayer = ({
  show,
  value,
  label,
  onChange,
}: ToggleEligibilityLayerProps) => {
  if (!show) {
    return null
  }

  return (
    <div className="absolute top-4 left-4 block bg-white border border-dusk-50 rounded-sm px-2 py-[7px]">
      <ToggleSwitch label={label} value={value} onChange={onChange} />
    </div>
  )
}

export default ToggleEligibilityLayer
