import { SubNavigation } from "../../components/SubNavigation"

export interface DashboardSubNavigationProps {
  show: boolean
  title: string | null | undefined
}

const DashboardSubNavigation = ({
  show,
  title,
}: DashboardSubNavigationProps) => {
  if (!show) {
    return null
  }

  return (
    <SubNavigation
      aria-label="Back to dashboard"
      title={title}
      backUrl="/"
      isAccountsPage={true}
    />
  )
}

export default DashboardSubNavigation
