import SPBImpactScore from "./SPBImpactScore"
import { SPBWizardType } from "@/types/wizards"

export interface SPBPlanSummaryImpactScoreProps {
  wizardData: SPBWizardType | undefined
  selectedActionGroupIndex: number
}

const SPBPlanSummaryImpactScore = ({
  wizardData,
  selectedActionGroupIndex,
}: SPBPlanSummaryImpactScoreProps) => {
  const improvement =
    wizardData &&
    wizardData?.action_groups[selectedActionGroupIndex].new_scores.spb_overall
      .new_score -
      wizardData?.action_groups[selectedActionGroupIndex].new_scores.spb_overall
        .current_score

  return (
    <SPBImpactScore
      subheading={
        improvement && improvement > 0
          ? `Estimated ${improvement} point improvement in resilience score`
          : "No expected impact."
      }
      currentScore={
        wizardData?.action_groups[selectedActionGroupIndex].new_scores
          .spb_overall.current_score
      }
      maxScore={
        wizardData?.action_groups[selectedActionGroupIndex].new_scores
          .spb_overall.max_score
      }
      newScore={
        wizardData?.action_groups[selectedActionGroupIndex].new_scores
          .spb_overall.new_score
      }
    />
  )
}

export default SPBPlanSummaryImpactScore
