import { Dispatch, SetStateAction } from "react"
import { Link, useParams } from "react-router"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import EligibilityQuizHeader from "./EligibilityQuizHeader"
import EligibilityQuizBtnContainer from "./EligibilityQuizBtnContainer"
import EligibilityQuizCategoryStats from "./EligibilityQuizCategoryStats"
import {
  useAccountId,
  useAccountUrlPrefix,
  useProjectList,
  useRefetchAttestationQuiz,
} from "../../hooks"
import { PROJECT_TYPES } from "../../shared/constants"
import { getProjectsData } from "../../shared/utils"
import IneligibilityReasons from "./IneligibilityReasons"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "@/types/constants"
import { QuizDataTypes } from "@/types/eligibilityQuiz"
import { ProgramTypeStats } from "@/types/eligibility"
import { ProjectListTypes } from "@/types/program"

interface EligibilityQuizCompletedTypes {
  someNotCompleted: boolean | undefined
  numEligible: number | undefined
  projCategoryStats: ProgramTypeStats | undefined
  quizData: QuizDataTypes | undefined
  setQuizStep: Dispatch<SetStateAction<number | undefined>>
  setCompleted: Dispatch<SetStateAction<boolean>>
}

const EligibilityQuizCompleted = ({
  someNotCompleted,
  numEligible,
  projCategoryStats,
  quizData,
  setQuizStep,
  setCompleted,
}: EligibilityQuizCompletedTypes) => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const { quizCategory } = useParams()
  const accountUrlPrefix = useAccountUrlPrefix()

  const { data } = useProjectList<ProjectListTypes, Error>(
    queryClient,
    accountId,
    {}
  )

  const projectsData = getProjectsData(data).filter(
    (project) => project.type === quizCategory
  )

  const { mutateAsync: refetchAttestationQuiz } = useRefetchAttestationQuiz(
    queryClient,
    accountId,
    quizCategory as EligibilityQuizCategoryType,
    true,
    {
      onSuccess: () => {
        setQuizStep(0)
        setCompleted(false)
      },
    }
  )

  const isEligible = numEligible && numEligible > 0
  const quizProjectCategory = quizData?.project_type

  const handleRefetchAttestationQuiz = async () => {
    await refetchAttestationQuiz(undefined)
  }

  return (
    <div className="mt-2">
      <EligibilityQuizHeader
        eyebrow="Eligibility check"
        text={
          isEligible
            ? `Congrats! You're Eligible for ${numEligible} ${
                PROJECT_TYPES[quizProjectCategory as ProgramCategoryType].long
              } ${numEligible === 1 ? "Program" : "Programs"}`
            : `You're Not Eligible for Any Current ${
                PROJECT_TYPES[quizProjectCategory as ProgramCategoryType].long
              } Programs`
        }
        imageUrl={quizData?.image_url}
        quizType={quizData?.quiz_type}
      />

      {isEligible ? (
        <>
          <p className="text-charcoal-500 leading-[130%] tracking-[0.32px] mt-8">
            NCX created a personalized list of eligible programs just for you.
          </p>

          <p className="text-charcoal-500 leading-[130%] tracking-[0.32px] mt-3">
            Browse eligible programs to find the one that's just right for you
            and your land.
          </p>

          <EligibilityQuizCategoryStats
            projCategoryStats={projCategoryStats}
            projCategory={quizProjectCategory}
          />
        </>
      ) : (
        <>
          <p className="text-charcoal-500 leading-[130%] tracking-[0.32px] mt-8">
            Based on your answers, your land is not currently eligible for any
            programs in this category.
          </p>

          <IneligibilityReasons projectsData={projectsData} />

          <p className="text-charcoal-500 leading-[130%] tracking-[0.32px] mt-3">
            Need to make a change to your answers?{" "}
            <button
              aria-label="Edit Land Details"
              className="link"
              type="button"
              onClick={() => {
                handleRefetchAttestationQuiz()
              }}
            >
              Edit Land Details
            </button>
          </p>
          <hr className="border-charcoal-50 my-6" />
          {someNotCompleted && (
            <p className="text-charcoal-500 leading-[130%] tracking-[0.32px] mt-3">
              Go to your dashboard and select another category to answer more
              questions and see your eligibility.
            </p>
          )}
        </>
      )}

      <EligibilityQuizBtnContainer>
        <div className="flex flex-col gap-2">
          <Link
            to={`${accountUrlPrefix}/${"programs/page/1"}`}
            className={cx(
              "quiz-view-eligible-programs",
              quizCategory,
              "btn2 btn2-primary text-center font-semibold md:self-start md:mt-6"
            )}
            state={{
              quizProjectCategory,
            }}
          >
            View Programs
          </Link>

          <Link
            to="/"
            className="quiz-dashboard-link link font-normal self-start md:mb-10"
          >
            Go to Dashboard
          </Link>
        </div>
      </EligibilityQuizBtnContainer>
    </div>
  )
}

export default EligibilityQuizCompleted
