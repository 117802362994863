import { ReactNode } from "react"
import cx from "classnames"

import useMediaQuery from "../../../hooks/useMediaQuery"

interface ProjectMapSkeletonTypes {
  message?: ReactNode
  animate?: boolean
}

const ProjectMapSkeleton = ({
  message,
  animate = false,
}: ProjectMapSkeletonTypes) => {
  const isMaxWidthLg = useMediaQuery("(max-width: 1024px)")

  return (
    <div
      className={cx("w-full lg:flex", {
        "animate-pulse": animate,
      })}
    >
      <div
        className={cx(
          "bg-dusk-200 min-w-full lg:min-w-[250px] object-cover rounded-sm relative h-[400px] max-w-full mb-0 lg:mb-[inherit]",
          "rounded-t rounded-b-none",
          "lg:min-w-[250px] lg:grow lg:rounded-l lg:rounded-tr-none"
        )}
        style={{ aspectRatio: isMaxWidthLg ? "16 / 9" : "auto" }}
      >
        {message}
      </div>

      <div
        className={cx(
          "w-full",
          "border-l border-r border-b border-dusk-200",
          "rounded-b",
          "lg:h-[400px] lg:w-[244px] lg:flex lg:flex-col lg:order-2 lg:rounded-tr lg:rounded-bl-none"
        )}
      >
        <div
          className={cx(
            "flex lg:order-3",
            "mt-auto mb-0 px-4 py-2",
            "border-dusk-200",
            "lg:order-3 lg:border-t"
          )}
        >
          <div className="h-10 grow bg-dusk-200 rounded-sm mb-4 sm:mb-auto mr-4" />
          <div className="h-10 grow bg-dusk-200 rounded-sm" />
        </div>

        <div className="w-full flex lg:order-2 items-center gap-4 border-b border-t border-dusk-200 p-2">
          <div className="bg-dusk-200 rounded-sm grow h-5" />
          <div className="bg-dusk-200 rounded-sm w-12 h-12" />
        </div>

        <div className="w-full flex lg:order-2 items-center gap-4 border-dusk-200 lg:border-b p-2">
          <div className="bg-dusk-200 rounded-sm grow h-5" />
          <div className="bg-dusk-200 rounded-sm w-12 h-12" />
        </div>
      </div>
    </div>
  )
}

export default ProjectMapSkeleton
