const ThreatsHero = () => (
  <header className="bg-charcoal-600 pt-[60px] pb-7">
    <div className="container max-w-[1440px]">
      <h1 className="text-white text-xl font-medium leading-[140%] tracking-[0.4px]">
        Identify and review threats to your land.
      </h1>
    </div>
  </header>
)

export default ThreatsHero
