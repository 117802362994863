import MASK from "../../images/cat-mask.svg"
import cx from "classnames"

const ProjectListingSkeleton = () => (
  <div
    className="bg-cloud-50 overflow-hidden animate-pulse"
    style={{ height: "calc(100vh - 64px)" }}
  >
    <div className="bg-dusk-200 py-4">
      <div className="container max-w-[2400px] flex gap-[2.25rem] overflow-hidden">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((cat, i) => (
          <div key={cat} className="flex flex-col items-center gap-4">
            <img
              className={cx("h-[91px] w-[96px]", {
                "transform -scale-x-100": (i + 1) % 2 == 0,
                "transform -scale-y-100": (i + 1) % 3 == 0,
              })}
              src={MASK}
            />

            <div className="bg-white h-[18px] w-[100px] rounded-sm" />
          </div>
        ))}
      </div>
    </div>

    <div className="w-full border-b border-dusk-100">
      <div className="container max-w-[2400px] flex flex-wrap lg:flex-nowrap items-center gap-3 xs:gap-4 py-6">
        <div className="bg-dusk-200 w-[90px] h-10 rounded-sm shrink-0" />

        <div className="bg-dusk-200 w-[217px] h-10 rounded-sm shrink-0" />

        <div className="bg-dusk-200 min-w-[252px] h-10 rounded-sm shrink-0" />

        <div className="bg-dusk-200 hidden lg:block w-full max-w-[343px] h-10 rounded-sm grow-0 ml-auto" />
      </div>
    </div>

    <div className="w-full border-b border-dusk-100">
      <div className="container max-w-[2400px] flex items-center gap-4 py-[18px]">
        <div className="bg-dusk-200 w-[105px] lg:w-[300px] h-6 grow-0 rounded-sm" />

        <div className="bg-dusk-200 w-[80px] h-6 rounded-sm shrink-0 ml-auto" />

        <div className="bg-dusk-200 w-[85px] h-[38px] rounded-sm shrink-0 ounded" />
      </div>
    </div>

    <div className="container max-w-[2400px] grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 pt-5">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((card) => (
        <div key={card} className="rounded-sm border border-dusk-100 p-4">
          <div className="flex items-center mb-3">
            <div className="w-[74px] h-[30px] bg-dusk-200 rounded-sm" />

            <div className="w-4 h-4 bg-dusk-200 rounded-xs ml-auto mr-2" />

            <div className="w-[67px] h-[16px] bg-dusk-200 rounded-sm mr-3" />

            <div className="w-7 h-7 bg-dusk-200 rounded-full" />
          </div>

          <div className="w-full h-[121px] sm:h-[222px] bg-dusk-200 rounded-sm mb-3" />

          <div className="w-10/12 h-[24px] bg-dusk-200 rounded-sm mb-2" />

          <div className="h-5 flex items-center">
            <div className="h-4 w-full bg-dusk-200 rounded-sm" />
          </div>

          <div className="h-5 flex items-center mb-[14px]">
            <div className="h-4 w-11/12 bg-dusk-200 rounded-sm" />
          </div>

          <div className="w-full grid grid-cols-[repeat(2,max-content)] gap-2 mt-auto mb-0 grid-rows-2">
            {[1, 2, 3, 4].map((card) => (
              <div key={card} className="flex items-center gap-1">
                <div className="w-6 h-6 bg-dusk-200 rounded-sm" />
                <div className="w-28 h-[14px] bg-dusk-200 rounded-full" />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default ProjectListingSkeleton
