import { Link } from "react-router"

import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import ContentCard from "../../components/ContentCard"
import { useAccountUrlPrefix } from "../../hooks"
import { AccountRoleType } from "../../types/account"

interface BookAnExpertConsultationProps {
  show: boolean
  accountRole: AccountRoleType
  isMember: boolean | undefined
}

const BookAnExpertConsultation = ({
  show,
  accountRole,
  isMember,
}: BookAnExpertConsultationProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  if (!show) {
    return null
  }

  return (
    <ContentCard className="shadow-sm" roundedMd>
      <h2 className="text-xl md:text-3xl text-autumn font-medium leading-[140%] md:leading-[124%] tracking-[0.4px] md:tracking-[0.6px]">
        Your land. Your goals. Expert strategy.
      </h2>

      <p className="leading-[140%] tracking-[0.32px] mt-4">
        $3,000 in income opportunities or your money back
      </p>

      <ActionPermissionWrapper accountRole={accountRole} action="editAccount">
        <Link
          className="dashboard-nca max-w-40 btn2 btn2-primary font-semibold btn2-block mt-4 mb-0"
          to={`${accountUrlPrefix}/${
            isMember ? "advisor-signup" : "become-a-member"
          }`}
          state={{ includeAdvisor: true }}
        >
          Learn More
        </Link>
      </ActionPermissionWrapper>
    </ContentCard>
  )
}

export default BookAnExpertConsultation
