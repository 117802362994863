import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useSPBStore } from "../../stores/useSPBStore"
import { getSelectedActionHeading } from "./helpers"

const SPBPlanSummaryHeader = () => {
  const { selectedActionGroup } = useSPBStore()

  const { selectedActionHeading, selectedActionSubheading } =
    getSelectedActionHeading(selectedActionGroup)

  return (
    <>
      <div>
        <h1 className="text-3xl font-display font-medium leading-[124%] tracking-[0.6px]">
          Your Plan Summary
        </h1>

        <p className="text-dusk-700 font-body leading-[140%] tracking-[0.32px] mt-4">
          Protect Against Southern Pine Beetle (SPB)
        </p>
      </div>

      <h3 className="flex items-center gap-3 text-leaf text-2xl font-display font-medium leading-[124%] tracking-[0.48px]">
        {selectedActionHeading}
        <FontAwesomeIcon icon={faCircleCheck} />
      </h3>

      <p className="font-body leading-[140%] tracking-[0.32px]">
        {selectedActionSubheading}
      </p>
    </>
  )
}

export default SPBPlanSummaryHeader
