import OnboardingOverviewIntro from "./OnboardingOverviewIntro"
import OnboardingOverviewAssets from "./OnboardingOverviewAssets"
import OnboardingOverviewScores from "./OnboardingOverviewScores"
import OnboardingOverviewPrograms from "./OnboardingOverviewPrograms"
import OnboardingOverviewPersonalization from "./OnboardingOverviewPersonalization"
import OnboardingOverviewRecommendedPrograms from "./OnboardingOverviewRecommendedPrograms"
import { ProjectDataTypes } from "@/types/program"
import { OnboardingRecsType } from "@/types/onboardingRecs"
import { ProgramCategoryType } from "@/types/constants"
import { ProsConsDataType } from "@/types/comparison"

export interface OnboardingOverviewStepsProps {
  overviewStep: number
  handleOverviewStep: (newStep?: number) => void
  onboardingRecs: OnboardingRecsType | undefined
  programsData: ProjectDataTypes[]
  compareData: ProsConsDataType | undefined
  selectedCategory: ProgramCategoryType | null
  handleSetSelectedCategory: (selectedCategory: ProgramCategoryType) => void
  handleGetCompare: () => void
  isGetComparePending: boolean
}

const OnboardingOverviewSteps = ({
  overviewStep,
  handleOverviewStep,
  onboardingRecs,
  programsData,
  compareData,
  selectedCategory,
  handleSetSelectedCategory,
  handleGetCompare,
  isGetComparePending,
}: OnboardingOverviewStepsProps) => {
  if (overviewStep === 1) {
    return <OnboardingOverviewIntro handleOverviewStep={handleOverviewStep} />
  }

  if (overviewStep === 2) {
    return <OnboardingOverviewAssets handleOverviewStep={handleOverviewStep} />
  }

  if (overviewStep === 3) {
    return <OnboardingOverviewScores handleOverviewStep={handleOverviewStep} />
  }

  if (overviewStep === 4) {
    return (
      <OnboardingOverviewPrograms
        onboardingRecs={onboardingRecs}
        programsData={programsData}
        handleOverviewStep={handleOverviewStep}
      />
    )
  }

  if (overviewStep === 5) {
    return (
      <OnboardingOverviewPersonalization
        onboardingRecs={onboardingRecs}
        selectedCategory={selectedCategory}
        handleSetSelectedCategory={handleSetSelectedCategory}
        handleGetCompare={handleGetCompare}
      />
    )
  }

  if (overviewStep === 6) {
    return (
      <OnboardingOverviewRecommendedPrograms
        programs={
          onboardingRecs
            ? onboardingRecs[selectedCategory as ProgramCategoryType]?.projects
            : []
        }
        compareData={compareData}
        programsData={programsData}
        isGetComparePending={isGetComparePending}
      />
    )
  }
}

export default OnboardingOverviewSteps
