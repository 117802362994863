import { QueryClient, UseQueryResult } from "@tanstack/react-query"
import { useAccountQueryWithRefresh } from "../utils"
import { getWizards } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export function useGetWizards<TData, TError>(
  queryClient: QueryClient,
  accountId: string,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> {
  const query = useAccountQueryWithRefresh<TData, TError>(
    queryClient,
    ["accounts", accountId, "wizards"],
    () => getWizards(accountId),
    config
  )
  return query
}
