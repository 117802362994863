import SPBDynamicCheckoutCardItem from "./SPBDynamicCheckoutCardItem"
import THREAT_PAGE from "../../images/paywall-threat-page.svg"
import ACTION_CALENDAR from "../../images/paywall-action-calendar.svg"
import RECOMMENDED_FUNDING from "../../images/paywall-recommend-funding.svg"

const SPBDynamicCheckoutCardItems = () => (
  <div className="flex flex-col gap-2 py-[14px] sm:px-[21px]">
    <SPBDynamicCheckoutCardItem
      img={THREAT_PAGE}
      alt="threat page"
      heading="Threat monitoring and alerts"
      text="Get notified about tree loss and risks like fire and insect outbreaks before they damage your forest."
    />

    <SPBDynamicCheckoutCardItem
      img={ACTION_CALENDAR}
      alt="Action calendar"
      heading="Personalized action plan"
      text="Know exactly what to do, where on your property, and when to protect your investment."
    />

    <SPBDynamicCheckoutCardItem
      img={RECOMMENDED_FUNDING}
      alt="Recommended funding"
      heading="Recommended funding programs that fit your needs"
      text="We sift through thousands of grants, leases, and subsidies to recommend the right ones for you and your land."
    />
  </div>
)

export default SPBDynamicCheckoutCardItems
