import { useEffect } from "react"

import { SubmitButton } from "../../../components/SubmitButton"
import OnboardingOverviewPersonalizationCard from "./OnboardingOverviewPersonalizationCard"
import OnboardingOverviewFooter from "./OnboardingOverviewFooter"
import { PROJECT_TYPES } from "../../../shared/constants"
import { ProgramCategoryType } from "../../../types/constants"
import { OnboardingRecsType } from "@/types/onboardingRecs"

export interface CategoryDataType {
  id: ProgramCategoryType
  category: string
  potenial_earnings: number | null
  description: string
}

export interface OnboardingOverviewPersonalizationProps {
  onboardingRecs: OnboardingRecsType | undefined
  selectedCategory: ProgramCategoryType | null
  handleSetSelectedCategory: (selectedCategory: ProgramCategoryType) => void
  handleGetCompare: () => void
}

const OnboardingOverviewPersonalization = ({
  onboardingRecs,
  selectedCategory,
  handleSetSelectedCategory,
  handleGetCompare,
}: OnboardingOverviewPersonalizationProps) => {
  const onboardingRecsCategories: CategoryDataType[] | undefined =
    onboardingRecs &&
    (Object.entries(onboardingRecs)?.map(([key, value]) => {
      return {
        id: key,
        category: PROJECT_TYPES[key as ProgramCategoryType].long,
        potenial_earnings: value.potenial_earnings,
        description: PROJECT_TYPES[key as ProgramCategoryType].description,
      }
    }) as CategoryDataType[])

  useEffect(() => {
    if (onboardingRecsCategories && onboardingRecsCategories?.length === 1) {
      handleSetSelectedCategory(onboardingRecsCategories[0]?.id)
    }
  }, [handleSetSelectedCategory, onboardingRecsCategories])

  return (
    <>
      <div className="container max-w-[1992px] flex-flex-col pt-6 sm:pt-24 pb-[104px] sm:px-6 lg:px-18">
        <div className="w-full max-w-[533px] justify-self-center">
          <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-[0.48px]">
            Personalization complete! Let's find the best path for your land.
          </h1>

          <p className="text-dusk-500 text-base leading-[140%] tracking-[0.32px] mt-3">
            We've analyzed your land's eligibility and income potential to
            recommend program categories that best match your goals.
          </p>
        </div>

        <div className="mt-16">
          {onboardingRecsCategories && onboardingRecsCategories?.length > 1 ? (
            <p className="text-charcoal-500 leading-[140%] tracking-[0.32px] mb-8">
              Select a recommended category to get started.
            </p>
          ) : null}

          <div
            className={`category-count-${
              onboardingRecsCategories?.length ?? 0
            } grid grid-cols-1 md:grid-cols-3 gap-6`}
          >
            {onboardingRecsCategories?.map((categoryData) => (
              <OnboardingOverviewPersonalizationCard
                key={categoryData.id}
                categoryData={categoryData}
                selectedCategory={
                  onboardingRecsCategories?.length > 1 ? selectedCategory : null
                }
                onClick={() => {
                  handleSetSelectedCategory(categoryData.id)
                }}
              />
            ))}
          </div>
        </div>
      </div>

      <OnboardingOverviewFooter>
        {onboardingRecsCategories && onboardingRecsCategories?.length > 1 ? (
          <p className="hidden md:flex md:items-center text-overline">
            Select a category to continue
          </p>
        ) : null}

        <SubmitButton
          className="onboarding-next btn2 btn2-primary font-bold tracking-[0.32px] ml-auto"
          isSubmitting={false}
          onClick={handleGetCompare}
          disabled={
            Array.isArray(onboardingRecsCategories) &&
            onboardingRecsCategories.length > 1 &&
            !selectedCategory
          }
        >
          Next
        </SubmitButton>
      </OnboardingOverviewFooter>
    </>
  )
}

export default OnboardingOverviewPersonalization
