import { Form, Formik, FormikValues } from "formik"
import { useQueryClient } from "@tanstack/react-query"
import * as yup from "yup"

import { MultiSelect } from "../../components/MultiSelect"
import { WrappedInput } from "../../components/Input"
import { SubmitButton } from "../../components/SubmitButton"
import SPBFooter from "./SPBFooter"
import {
  CONTACT_METHODS,
  PHONE_NUMBER,
  PREFERRED_CONTACT_METHOD,
  phoneRegex,
} from "../../shared/constants"
import { useUpdateProfile } from "../../hooks"
import { useHeapTrackOnView } from "../../hooks/useHeapTrackOnView"
import { Profile } from "@/types"
import SPBHeader from "./SPBHeader"
import SPBCard from "./SPBCard"

export interface SPBTakeActionFormProps {
  profile: Profile | undefined
  handleResetContactView: () => void
  handleSubmit: () => void
  isPending: boolean
}

const validationSchema = (isValidPhoneNumber: boolean) => {
  return yup.object().shape({
    [PREFERRED_CONTACT_METHOD]: yup
      .array()
      .min(1, "Please provide a preferred contact method")
      .of(yup.string().required())
      .required("Please provide a preferred contact method"),
    [PHONE_NUMBER]: yup
      .string()
      .when(PREFERRED_CONTACT_METHOD, (methodsArray, schema) => {
        // methodsArray is an array of 1 array strings
        const methods = [...methodsArray[0]]
        return !isValidPhoneNumber &&
          (methods.includes("phone") || methods.includes("text"))
          ? yup
              .string()
              .required("Please enter a phone number.")
              .matches(phoneRegex, "Please provide a valid phone number")
          : schema
      }),
  })
}

const SPBTakeActionForm = ({
  profile,
  handleResetContactView,
  handleSubmit,
  isPending,
}: SPBTakeActionFormProps) => {
  const queryClient = useQueryClient()

  const { mutateAsync: updateProfile } = useUpdateProfile(queryClient, {
    onSuccess: () => {
      handleSubmit()
    },
  })

  const handleUpdateProfile = async (values: FormikValues) => {
    const newValues = {
      preferred_contact_method: values.preferred_contact_method,
      ...(values.preferred_contact_method.includes("phone") ||
      values.preferred_contact_method.includes("text")
        ? { phone_number: values.phone_number }
        : {}),
    }

    await updateProfile(newValues)
  }

  const contactFormRef = useHeapTrackOnView({
    event: "componentview-spb-wizard-contact-form",
    observerOptions: { threshold: 0.5 },
  })

  return (
    <>
      <SPBHeader
        heading="How can we best reach you?"
        subheading="We're excited to help you implement your plan!"
      />

      <div
        ref={contactFormRef}
        className="container w-[752px] max-w-full flex flex-col gap-6 px-5 lg:px-10 mt-7"
      >
        <SPBCard paddingSize="sm">
          <Formik
            initialValues={{
              [PREFERRED_CONTACT_METHOD]:
                profile?.preferred_contact_method || [],
              [PHONE_NUMBER]: profile?.phone_number || "",
            }}
            validationSchema={validationSchema}
            enableReinitialize={false}
            onSubmit={handleUpdateProfile}
          >
            {(formikProps) => {
              const showPhoneField =
                formikProps.values.preferred_contact_method.includes("phone") ||
                formikProps.values.preferred_contact_method.includes("text")

              return (
                <Form>
                  <p className="text-dusk-500 text-sm font-bold leading-[120$] tracking-[0.84] uppercase">
                    Add contact preferences
                  </p>

                  <p className="text-charcoal-500 leading-[130%] tracking-[0.32px] mt-4 mb-2">
                    Once you've submitted contact preferences, an NCX Services
                    representative will reach out discuss your plan and
                    implementation options. You can update your contact
                    preferences at any time in Account Settings.
                  </p>

                  <div className="w-[276px] max-w-full flex flex-col gap-4">
                    <div>
                      <MultiSelect
                        labelClass="label font-bold mb-2 leading-[18px]"
                        aria-label="Contact method"
                        label="Contact method"
                        name="preferred_contact_method"
                        placeholder="Select contact preference"
                        options={Object.entries(CONTACT_METHODS).map(
                          ([value, label]) => ({
                            label,
                            value,
                          })
                        )}
                      />
                    </div>

                    {showPhoneField ? (
                      <div>
                        <WrappedInput
                          labelClass="label font-bold mb-2 leading-[18px]"
                          aria-label="Phone number"
                          label="Phone number"
                          placeholder="Phone number"
                          type="text"
                          name="phone_number"
                        />
                      </div>
                    ) : null}
                  </div>

                  <SPBFooter>
                    <p className="hidden md:flex md:items-center text-overline">
                      Add contact preferences to continue
                    </p>

                    <div className="flex gap-4 ml-auto">
                      <button
                        type="button"
                        className="spb-take-action-go-back-contact btn2 btn2-outline-primary font-bold tracking-[0.32px] whitespace-nowrap"
                        onClick={handleResetContactView}
                      >
                        Go Back
                      </button>

                      <SubmitButton
                        className="spb-take-action-save-and-continue-contact btn2 btn2-primary font-bold tracking-[0.32px]"
                        isSubmitting={formikProps.isSubmitting || isPending}
                        disabled={!formikProps.dirty}
                      >
                        Save and Continue
                      </SubmitButton>
                    </div>
                  </SPBFooter>
                </Form>
              )
            }}
          </Formik>
        </SPBCard>
      </div>
    </>
  )
}

export default SPBTakeActionForm
