import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  CSSProperties,
} from "react"
import { AxiosError } from "axios"
import { useQueryClient } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/pro-duotone-svg-icons"
import {
  faTrees,
  faList,
  faHourglassEnd,
  faQuestion,
  faFile,
  faUsers,
  faCircleCheck as faCircleCheckSolid,
} from "@fortawesome/pro-solid-svg-icons"
import {
  DialogDisclosure,
  DialogStateReturn,
  useDialogState,
} from "reakit/Dialog"
import DOMPurify from "dompurify"
import cx from "classnames"

import AcceptModal from "../Inbox/AcceptModal"
import InvitationEligibilityModal from "../Inbox/InvitationEligibilityModal"
import { Toast } from "../../components/Toast"
import { ProjectBadge } from "../../components/ProjectBadge"
import ProjectVerificationBadge from "../../components/ProjectVerificationBadge"
import IconText from "../../components/IconText"
import IconLink from "../../components/IconLink"
import { ButtonPair } from "../../components/ButtonPair"
import { SubmitButton } from "../../components/SubmitButton"
import { ActionPermissionWrapper } from "../../components/ActionPermissionWrapper"
import ProjectDetailsHeroInactiveProgram from "./ProjectDetailsHero/ProjectDetailsHeroInactiveProgram"
import NotInterestedModal from "./NotInterestedModal"
import RequestInformationBtn from "./RequestInformationBtn"
import RequestInformationModal from "./RequestInformationModal"
import IneligibleModal from "./IneligibleModal"
import EligibilityInterestModal from "./EligibilityInterestModal"
import OutOfCoverageModal from "./OutOfCoverageModal"
import ProjectDetailsHeroIneligibilityReasons from "./ProjectDetailsHero/ProjectDetailsHeroIneligibilityReasons"
import ProjectDetailsHeroEditLandDetails from "./ProjectDetailsHero/ProjectDetailsHeroEditLandDetails"

import { LANDOWNER_STATUS } from "../../shared/constants"
import {
  allAttestationsHaveValue,
  getEnrollmentDeadline,
  getProjectType,
  getProjectBadgeType,
  getProjectBadgeText,
  secondsToMinutes,
} from "../../shared/utils"
import { useSaveAccountProject } from "../../hooks"
import useMediaQuery from "../../hooks/useMediaQuery"
import { shortenAcreage } from "../../utils"
import { genericErrMsg } from "../../api/auth"
import { AccountProjectDataTypes, ProgramType } from "@/types/program"
import { AttestationsType } from "@/types/attestations"
import { ProjectBadgeType } from "@/types/constants"
import { AccountRoleType } from "@/types/account"
import { Profile } from "@/types"
import { NotificationTypes } from "@/types/notifications"
import ProjectDetailsHeroIconText from "./ProjectDetailsHero/ProjectDetailsHeroIconText"

interface ProjectDetailsHeroTypes {
  data: ProgramType
  attestationsData: AttestationsType | undefined
  accountId: string
  projectId: string
  accountRole: AccountRoleType
  profile: Profile | undefined
  similarPrograms: ProgramType[]
  isMultiAccount: boolean
  stackablePrograms: ProgramType[]
  eligibilityDialog: DialogStateReturn
  acceptInvite: boolean | null
  setAcceptInvite: Dispatch<SetStateAction<boolean | null>>
  invite: NotificationTypes | undefined
  isMember: boolean | undefined
}

const ProjectDetailsHero = ({
  data,
  attestationsData = {},
  accountId,
  projectId,
  accountRole,
  profile,
  similarPrograms,
  isMultiAccount,
  stackablePrograms,
  eligibilityDialog,
  acceptInvite,
  setAcceptInvite,
  invite,
  isMember,
}: ProjectDetailsHeroTypes) => {
  const {
    id,
    landowner_status,
    type,
    enrollment_deadline_type,
    enrollment_deadline_date = null,
    image_url,
    description_long,
    name,
    sample_contract_url = null,
    faq_url = null,
    badge_display,
    is_eligible,
    cta_override,
    cta_override_url,
    requires_service_provider,
    has_service_provider_coverage,
    eligible_acres,
    is_verified,
    is_active,
    is_saved,
  } = data

  const [animateBadge, setAnimateBadge] = useState<boolean>(false)

  const queryClient = useQueryClient()
  const requestInfoDialog = useDialogState({ animated: true })
  const notInterestedDialog = useDialogState({ animated: true })
  const ineligibleDialog = useDialogState({ animated: true })
  const acceptDialog = useDialogState({ animated: true })
  const acceptAttestationDialog = useDialogState({ animated: true })
  const outOfCoverageDialog = useDialogState({ animated: true })
  const badgeType: ProjectBadgeType = getProjectBadgeType(badge_display)
  const badgeText = getProjectBadgeText(badge_display)
  const projectType = getProjectType(type)
  const enrollmentDeadline = getEnrollmentDeadline(
    enrollment_deadline_type,
    enrollment_deadline_date
  )
  const isMaxWidthLg = useMediaQuery("(max-width: 1024px)")

  const unansweredAttestationsNum = Object.values(attestationsData).filter(
    (attestation) => !Object.prototype.hasOwnProperty.call(attestation, "value")
  ).length

  const showTimeToComplete =
    is_eligible !== false && unansweredAttestationsNum > 0

  useEffect(() => {
    if (acceptInvite) {
      // TODO Change this to show AcceptModal
      acceptDialog.show()
    }

    if (acceptInvite === false) {
      notInterestedDialog.show()
    }

    return () => setAcceptInvite(null)
  }, [acceptInvite, setAcceptInvite, acceptDialog, notInterestedDialog])

  const isCustomCta: boolean =
    cta_override?.length > 0 && cta_override_url?.length > 0

  const isInvited = invite?.extra_fields?.project_id === data.id

  const redirectToPaywall =
    !isMember &&
    !data.is_government_program &&
    !isCustomCta &&
    !data.is_sponsored &&
    !isInvited

  const {
    mutateAsync: saveAccountProject,
    isPending: isSaveAccountProjectPending,
  } = useSaveAccountProject(queryClient, accountId, id.toString(), {
    onSuccess: (data: AccountProjectDataTypes) => {
      Toast.success(
        data.is_saved ? "Program has been saved." : "Program has been unsaved"
      )
    },
    onError: (error) => {
      const err = error as AxiosError

      Toast.error(err?.message || genericErrMsg)
    },
  })

  const handleSaveAccountProject = async () => {
    await saveAccountProject({ save: !is_saved })
  }

  return (
    <div
      className={cx(
        "relative w-full py-12 bg-grass-50",
        is_active === false
          ? "outline-[#f9303f] outline-[6px] -outline-offset-[6px]"
          : ""
      )}
    >
      <ProjectDetailsHeroInactiveProgram show={is_active === false} />

      <div className="container px-5 lg:flex xl:max-w-[1120px]">
        <img
          src={image_url}
          alt="project details"
          className="min-w-full lg:min-w-[250px] object-cover lg:shrink-0 rounded-sm mb-6 lg:mb-[inherit]"
          style={{ aspectRatio: isMaxWidthLg ? "16 / 9" : "auto" }}
          width="250"
        />

        <div className="lg:w-[190px] lg:shrink-0 lg:order-3 mb-3">
          <div className="flex gap-2 items-center mb-4">
            <ProjectBadge type={badgeType} animated={animateBadge}>
              <span className="whitespace-nowrap">{badgeText}</span>
            </ProjectBadge>

            <ProjectDetailsHeroIneligibilityReasons
              show={
                badge_display === LANDOWNER_STATUS.ineligible &&
                Array.isArray(data.ineligibility_reasons)
              }
              ineligibilityReasons={data.ineligibility_reasons}
            />
          </div>

          <ProjectDetailsHeroEditLandDetails
            show={Object.values(attestationsData).length > 0}
            onClick={() => {
              eligibilityDialog.show()
            }}
          />

          <div className="flex lg:flex-col">
            <div className="mr-7 lg:mr-0">
              <div className="verification-badge-details">
                <ProjectVerificationBadge
                  isVerified={is_verified}
                  disclosureClassName="!no-underline cursor-pointer leading-6 [&_img]:w-5 mb-3"
                  includeLabel
                />
              </div>

              <ProjectDetailsHeroIconText
                icon={faTrees}
                text={`${shortenAcreage(eligible_acres ?? 0)} eligible acres`}
                show={typeof eligible_acres === "number"}
              />

              <ProjectDetailsHeroIconText icon={faList} text={projectType} />

              <ProjectDetailsHeroIconText
                icon={faHourglassEnd}
                text={enrollmentDeadline}
              />
            </div>

            <div>
              {data?.num_accounts_interested > 0 ? (
                <ProjectDetailsHeroIconText
                  icon={faUsers}
                  text={`${data?.num_accounts_interested} interested`}
                />
              ) : null}

              {!faq_url || faq_url?.length === 0 ? (
                <IconText
                  icon={faQuestion}
                  className="mb-3 leading-6 text-dusk-300"
                  iconClass="text-dusk-300 mr-1"
                >
                  FAQ not provided
                </IconText>
              ) : (
                <IconLink
                  to={faq_url}
                  icon={faQuestion}
                  text="FAQ"
                  className="mb-3 leading-6"
                  external
                />
              )}

              {!sample_contract_url || sample_contract_url?.length === 0 ? (
                <IconText
                  icon={faFile}
                  className="mb-3 leading-6 text-dusk-300 sm:whitespace-nowrap"
                  iconClass="text-dusk-300 mr-1"
                >
                  Sample Contract not provided
                </IconText>
              ) : (
                <IconLink
                  to={sample_contract_url}
                  icon={faFile}
                  text="Sample Contract"
                  className="mb-3 leading-6"
                  external
                />
              )}
            </div>
          </div>
        </div>

        <div className="lg:grow lg:pl-12 lg:pr-2 text-lg leading-[1.50] tracking-[0.02em] lg:order-2">
          <h1 className="text-3xl lg:text-5xl lg:tracking-[0.03em] lg:leading-[1.20] mb-4">
            {name}
          </h1>

          <div
            className="[&>p:not(:last-child)]:mb-3 [&>p>a]:text-leaf [&>p>a:hover]:underline"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description_long, {
                ADD_ATTR: ["target"],
              }),
            }}
          />

          {landowner_status !== LANDOWNER_STATUS.under_contract && (
            <>
              <div className="sm:flex sm:gap-4 sm:items-center">
                <ButtonPair
                  className="mx-auto sm:mx-0 mt-6"
                  secondaryHidden={
                    landowner_status === LANDOWNER_STATUS.not_interested ||
                    landowner_status === LANDOWNER_STATUS.lo_not_interested
                  }
                  primary={
                    is_eligible === false ? (
                      <DialogDisclosure
                        {...ineligibleDialog}
                        className="text-base leading-[1.50] tracking-[0.02em] interested-btn-project-details"
                        disabled={
                          landowner_status ===
                          LANDOWNER_STATUS.request_information
                        }
                      >
                        I'm Interested
                      </DialogDisclosure>
                    ) : redirectToPaywall ||
                      is_eligible ||
                      allAttestationsHaveValue(attestationsData) ||
                      Object.keys(attestationsData).length === 0 ? (
                      <RequestInformationBtn
                        sourceIdentifier="project-details"
                        requestInfoDialog={requestInfoDialog}
                        landowner_status={landowner_status}
                        projectId={projectId}
                        accountId={accountId}
                        accountRole={accountRole}
                        enrollmentDeadlineType={enrollment_deadline_type}
                        ctaOverride={cta_override}
                        ctaOverrideUrl={cta_override_url}
                        requires_service_provider={requires_service_provider}
                        has_service_provider_coverage={
                          has_service_provider_coverage
                        }
                        // DEV: the RequestInformationBtn will handle updating AP status,
                        // this makes sure we open the custom CTA after
                        onRequestCallSubmit={
                          cta_override_url?.length > 0
                            ? () => {
                                window.open(
                                  cta_override_url,
                                  "_blank",
                                  "noopener"
                                )
                              }
                            : null
                        }
                        isMember={isMember}
                        isGovernmentProgram={data.is_government_program}
                        isSponsored={data.is_sponsored}
                        isInvited={isInvited}
                        isEligible={is_eligible}
                      />
                    ) : (
                      <DialogDisclosure
                        {...eligibilityDialog}
                        className="text-base leading-[1.50] tracking-[0.02em]"
                      >
                        Check Eligibility
                      </DialogDisclosure>
                    )
                  }
                  // eslint-disable-next-line react/no-unstable-nested-components
                  secondary={(secondaryProps) => (
                    <ActionPermissionWrapper
                      accountRole={accountRole}
                      action="editAccount"
                    >
                      <DialogDisclosure
                        {...notInterestedDialog}
                        className={cx(
                          secondaryProps.className,
                          "bg-cloud-50 text-base leading-[1.50] tracking-[0.02em] font-bold! py-[7px] whitespace-nowrap not-interested-btn-project-details"
                        )}
                      >
                        Not Interested
                      </DialogDisclosure>
                    </ActionPermissionWrapper>
                  )}
                />

                <SubmitButton
                  onClick={() => {
                    handleSaveAccountProject()
                  }}
                  isSubmitting={isSaveAccountProjectPending}
                  className={cx(
                    is_saved ? "unsave-program" : "save-program",
                    "text-grass-500 font-body font-bold leading-[140%] tracking-[0.36px] hover:cursor-pointer disabled:cursor-auto mt-6"
                  )}
                  typeButton
                >
                  <span className="flex gap-1">
                    <span
                      className={cx({
                        "block w-[18px] h-[18px] relative": !is_saved,
                        "before:absolute before:top-[2px] before:left-0 before:content-[''] before:w-[18px] before:h-[18px] before:rounded-full before:border-2 before:border-grass-500":
                          !is_saved,
                      })}
                    >
                      <FontAwesomeIcon
                        icon={is_saved ? faCircleCheckSolid : faCircleCheck}
                        style={
                          !is_saved
                            ? ({
                                "--fa-primary-opacity": 1,
                                "--fa-secondary-opacity": 0,
                              } as CSSProperties)
                            : {}
                        }
                      />
                    </span>

                    {is_saved ? "Saved" : "Save for later"}
                  </span>
                </SubmitButton>
              </div>

              {showTimeToComplete && (
                <p className="text-xs text-charcoal-400 leading-[130%] mt-4">
                  Check your eligibility in about{" "}
                  {secondsToMinutes(unansweredAttestationsNum * 30)}!
                </p>
              )}

              {is_eligible === false && (
                <p className="text-xs text-charcoal-400 leading-[130%] mt-4">
                  You're not eligible for this program. Express your interest to
                  help us find new opportunities like this for you.
                </p>
              )}
            </>
          )}
        </div>
      </div>

      <IneligibleModal
        dialog={ineligibleDialog}
        accountId={accountId}
        projectId={projectId}
      />

      <OutOfCoverageModal
        dialog={outOfCoverageDialog}
        accountId={accountId}
        projectId={projectId}
      />

      <EligibilityInterestModal
        dialog={eligibilityDialog}
        attestationsData={attestationsData}
        accountId={accountId}
        projectId={projectId}
        landowner_status={landowner_status}
        is_eligible={is_eligible}
        setAnimateBadge={setAnimateBadge}
        accountRole={accountRole}
        enrollmentDeadlineType={enrollment_deadline_type}
        img={image_url}
        profile={profile}
        ctaOverride={cta_override}
        ctaOverrideUrl={cta_override_url}
        similarPrograms={similarPrograms}
        stackablePrograms={stackablePrograms}
        isMultiAccount={isMultiAccount}
        requires_service_provider={requires_service_provider}
        has_service_provider_coverage={has_service_provider_coverage}
        isMember={isMember}
        isGovernmentProgram={data.is_government_program}
        isSponsored={data.is_sponsored}
        isInvited={isInvited}
      />

      <NotInterestedModal
        dialog={notInterestedDialog}
        accountId={accountId}
        projectId={projectId}
      />

      <RequestInformationModal
        dialog={requestInfoDialog}
        accountId={accountId}
        projectId={projectId}
        accountRole={accountRole}
        enrollmentDeadlineType={enrollment_deadline_type}
        img={image_url}
        profile={profile as Profile}
        requires_service_provider={requires_service_provider}
        has_service_provider_coverage={has_service_provider_coverage}
        ctaOverride={cta_override}
        ctaOverrideUrl={cta_override_url}
        landowner_status={landowner_status}
        isMember={isMember}
        isGovernmentProgram={data.is_government_program}
        isSponsored={data.is_sponsored}
        isInvited={isInvited}
      />

      <AcceptModal
        dialog={acceptDialog}
        attestationsDialog={acceptAttestationDialog}
        accountId={accountId}
        projectId={projectId}
        sender={name}
        badgeDisplay={badge_display}
        profile={profile}
        notificationId={invite?.id}
      />

      <InvitationEligibilityModal
        dialog={acceptAttestationDialog}
        attestationsData={attestationsData}
        accountId={accountId}
        projectId={projectId}
        accountRole={accountRole}
        sender={invite?.extra_fields?.sender}
      />
    </div>
  )
}

export default ProjectDetailsHero
