import { ReactNode } from "react"
import cx from "classnames"
import DOMPurify from "dompurify"

export interface ScoreCardSubHeadingProps {
  text: string | ReactNode
  className?: string
}

const ScoreCardSubHeading = ({
  text,
  className = "",
}: ScoreCardSubHeadingProps) => {
  const paragraphClass =
    "text-dusk-900 text-sm text-left font-body font-medium leading-[140%] tracking-[0.42px]"

  if (typeof text === "string") {
    return (
      <p
        className={cx(paragraphClass, className)}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
      />
    )
  }

  return <p className={cx(paragraphClass, className)}>{text}</p>
}

export default ScoreCardSubHeading
