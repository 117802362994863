import { Spinner } from "../../components/Spinner"
import { SylviaResponseTypes } from "@/types/sylvia"

interface SylviaContentProps {
  data: SylviaResponseTypes | undefined
  isPending: boolean
}

const SylviaContent = ({ data, isPending }: SylviaContentProps) => {
  if (isPending) {
    return (
      <div className="flex justify-center bg-white border border-charcoal-50 rounded-sm p-4 mt-10">
        <Spinner />
      </div>
    )
  }

  if (data === undefined) {
    return null
  }

  return (
    <div className="bg-white border border-charcoal-50 rounded-sm p-4 mt-10">
      {data?.candidates[0]?.content?.parts.map((part, i) => (
        <p key={i} className={`${i === 0 ? "" : "mt-3"}`}>
          {part?.text}
        </p>
      ))}
    </div>
  )
}

export default SylviaContent
