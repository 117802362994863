import { create } from "zustand"
import {
  SelectedActionGroupType,
  SelectedImplementationType,
} from "@/types/wizards"

export interface StateTypes {
  activeStep: number
  enabledSteps: boolean[]
  selectedActionGroup: SelectedActionGroupType | null
  selectedImplementation: SelectedImplementationType
}

export interface ActionTypes {
  setActiveStep: (activeStep: number) => void
  setEnabledSteps: (enabledSteps: boolean[]) => void
  setSelectedActionGroup: (selectedActionGroup: SelectedActionGroupType) => void
  setSelectedImplementation: (
    selectedAction: SelectedImplementationType
  ) => void
}

export type SPBStoreTypes = StateTypes & ActionTypes

export const initialState: StateTypes = {
  activeStep: 0,
  enabledSteps: [true, false, false, false, false],
  selectedActionGroup: null,
  selectedImplementation: null,
}

export const useSPBStore = create<SPBStoreTypes>((set) => ({
  ...initialState,

  setActiveStep: (activeStep) => set({ activeStep }),
  setEnabledSteps: (enabledSteps) => set({ enabledSteps }),
  setSelectedActionGroup: (selectedActionGroup) => set({ selectedActionGroup }),
  setSelectedImplementation: (selectedImplementation) =>
    set({ selectedImplementation }),
}))
