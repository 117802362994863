import useMediaQuery from "../../hooks/useMediaQuery"

const Skeleton = () => {
  const isMaxWidthLg = useMediaQuery("(max-width: 1024px)")

  return (
    <div className="bg-cloud-50">
      <div className="w-full py-12 bg-dusk-50">
        <div className="container lg:flex xl:max-w-[1120px]">
          <div
            className="bg-dusk-200 min-w-full lg:min-w-[250px] object-cover lg:shrink-0 rounded-sm mb-6 lg:mb-[inherit]"
            style={{ aspectRatio: isMaxWidthLg ? "16 / 9" : "auto" }}
          />
          <div className="w-full lg:w-[190px] lg:shrink-0 lg:order-3 mb-3">
            <div className="mb-4">
              <div className="w-20 h-10 rounded-full bg-dusk-200" />
            </div>
            <div className="flex flex-col">
              <div className="w-full">
                <div className="w-[92px] lg:w-full h-6 lg:h-5 bg-dusk-200 mb-3" />
              </div>
              <div className="flex lg:flex-col">
                <div className="w-[222px] lg:w-auto mr-7 lg:mr-0">
                  <div className="w-full h-6 lg:h-5 bg-dusk-200 mb-3" />
                  <div className="w-full h-6 lg:h-5 bg-dusk-200 mb-3" />
                </div>
                <div className="w-[222px] lg:w-auto mr-7 lg:mr-0">
                  <div className="w-full h-6 lg:h-5 bg-dusk-200 mb-3" />
                  <div className="w-full h-6 lg:h-5 bg-dusk-200" />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:grow lg:pl-12 lg:pr-2 text-lg leading-[1.50] tracking-[0.02em] lg:order-2">
            <div className="w-full h-12 bg-dusk-200 mb-4" />
            <div className="w-full h-12 bg-dusk-200 mb-4" />

            <div className="w-full h-4 bg-dusk-200 mb-2" />
            <div className="w-full h-4 bg-dusk-200 mb-2" />
            <div className="w-full h-4 bg-dusk-200 mb-2" />
            <div className="w-full h-4 bg-dusk-200" />

            <div className="mx-auto sm:mx-0 mt-6 sm:flex">
              <div className="w-full sm:w-40 h-10 bg-dusk-200 rounded-sm mb-4 sm:mb-auto sm:mr-4" />
              <div className="w-full sm:w-40 h-10 bg-dusk-200 rounded-sm" />
            </div>
          </div>
        </div>
      </div>

      <div className="py-12 bg-cloud-300 mb-6 lg:mb-12">
        <div className="container flex justify-between lg:justify-center items-center">
          <div className="w-32 h-10 bg-dusk-200 lg:mr-8" />
          <div className="w-32 h-10 bg-dusk-200 lg:ml-8" />
        </div>
      </div>

      <div className="container lg:flex xl:max-w-[1120px] lg:justify-between">
        <div className="py-2 lg:p-3 w-full lg:max-w-[552px] mb-8 lg:mb-8">
          <div className="w-full h-[2.25rem] bg-dusk-200 mb-4" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-8" />

          <div className="w-full h-[2.25rem] bg-dusk-200 mb-4" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-8" />

          <div className="w-full h-[2.25rem] bg-dusk-200 mb-4" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200 mb-2" />
          <div className="w-full h-6 bg-dusk-200" />
        </div>

        <div
          className="w-full lg:w-[396px] rounded-sm mb-14 lg:mb-0 bg-dusk-200"
          style={{ height: 176 }}
        />
      </div>
    </div>
  )
}

export default Skeleton
