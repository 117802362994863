import { useMemo } from "react"
import { Link } from "react-router"

import InfoPopover from "../../components/InfoPopover"
import SPBList from "./SPBList"
import { useAccountUrlPrefix } from "../../hooks"
import { ProjectListTypes } from "@/types/program"

export interface SPBEligibleFundingProgramsProps {
  projectIds: number[]
  projectList: ProjectListTypes | undefined
  hasLinks?: boolean
}

const SPBEligibleFundingPrograms = ({
  projectIds,
  projectList,
  hasLinks = false,
}: SPBEligibleFundingProgramsProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  const projectNameList = useMemo(() => {
    const projectNames =
      projectList?.project_data
        .filter((project) => projectIds.includes(project.id))
        .map((project) => {
          if (hasLinks) {
            return (
              <Link
                key={project.id}
                to={`${accountUrlPrefix}/programs/${project.id}`}
                className="link"
              >
                {`View Program: ${project.name}`}
              </Link>
            )
          }

          return project.name
        }) || []

    return projectNames?.length > 0 ? projectNames : ["No eligible programs."]
  }, [accountUrlPrefix, hasLinks, projectIds, projectList?.project_data])

  return (
    <SPBList
      caption={
        <>
          Eligible Funding Programs{" "}
          <span onClick={(e) => e.stopPropagation()}>
            <InfoPopover text="Eligible programs are ways to offset implementation costs, which vary based on your land’s needs. Plans may require financial investments when you are ready to implement the proposed actions." />
          </span>
        </>
      }
      list={projectNameList}
    />
  )
}

export default SPBEligibleFundingPrograms
