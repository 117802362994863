import SPBTakeActionCard from "./SPBTakeActionCard"
import { useSPBStore } from "../../stores/useSPBStore"

const SPBTakeActionCards = () => {
  const { selectedImplementation, setSelectedImplementation } = useSPBStore()

  return (
    <div className="container w-[996px] max-w-full grid grid-cols-1 md:grid-cols-2 gap-6 px-5 lg:px-10 mt-10 md:mt-16">
      <SPBTakeActionCard
        selected={selectedImplementation === "self"}
        heading="Manage it myself"
        subheading="Use NCX as your assistant, while you take action"
        list={[
          "Save your selected management approach to your plan",
          "Implement your plan at your own pace",
          "Locate your own contractors or implement actions yourself",
          "Monitor your land's risk status",
        ]}
        onClick={() => setSelectedImplementation("self")}
      />

      <SPBTakeActionCard
        selected={selectedImplementation === "ncx_services"}
        heading="Book Free NCX Consult"
        subheading="30 min session with NCX professional forester"
        list={[
          "Plan a time on your schedule to discuss your approach and action plan by phone or video chat ",
          "We will help you understand your risks and the tradeoffs of different plan actions",
          "We will assist you in locating a trusted contractor and help ensure the work is high quality",
        ]}
        onClick={() => setSelectedImplementation("ncx_services")}
      />
    </div>
  )
}

export default SPBTakeActionCards
