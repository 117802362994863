import { CSSProperties } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"

import { RoiModalContentMobileTabButtonProps } from "./types"

const RoiModalContentMobileTabButton = ({
  icon,
  onClick,
  active,
  name,
}: RoiModalContentMobileTabButtonProps) => (
  <button
    type="button"
    className={cx(
      "h-[2.25rem] w-12 flex justify-center items-center font-normal",
      active
        ? "btn2 btn2-primary btn2-sm cursor-default"
        : "btn btn-secondary btn-xs shadow-xs active:bg-white"
    )}
    onClick={onClick}
    data-testid={`roi-modal-content-mobile-tab-button-${name}`}
  >
    <FontAwesomeIcon
      icon={icon}
      size="xs"
      style={
        {
          "--fa-primary-opacity": 1,
          "--fa-secondary-opacity": 1,
        } as CSSProperties
      }
      fixedWidth
    />
  </button>
)

export default RoiModalContentMobileTabButton
