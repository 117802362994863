import { ChangeEvent } from "react"
import { InputLabel } from "./Input"

interface RangeSliderInputTypes {
  id: string
  label: string
  ariaLabel: string
  value: number
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  min: number
  max: number
  step: number
}

const RangeSliderInput = ({
  id,
  label,
  ariaLabel,
  value,
  onChange,
  min,
  max,
  step,
}: RangeSliderInputTypes) => (
  <div className="range-slider-control">
    <InputLabel className="leading-[18px] text-charcoal-500 mb-2" htmlFor={id}>
      {label}
    </InputLabel>

    <input
      id={id}
      aria-label={`${ariaLabel}`}
      type="number"
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      className="h-10 w-[120px] border border-charcoal-50 rounded-sm text-base leading-[22px] tracking-[0.02em] px-3 py-2"
    />
  </div>
)

export default RangeSliderInput
