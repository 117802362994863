import cx from "classnames"

import { CategoryDataType } from "./OnboardingOverviewPersonalization"
import { getProjectEarningsNullValue } from "../../../shared/utils"
import { formatCurrency } from "../../../utils"
import { personalizationCardBg } from "./helpers"
import { ProgramCategoryType } from "../../../types/constants"

export interface OnboardingOverviewPersonalizationCardProps {
  categoryData: CategoryDataType
  selectedCategory: ProgramCategoryType | null
  onClick: () => void
}

const OnboardingOverviewPersonalizationCard = ({
  categoryData,
  selectedCategory,
  onClick,
}: OnboardingOverviewPersonalizationCardProps) => {
  const bgImage = personalizationCardBg(categoryData.id)

  const isBiodiversity = categoryData.id === ProgramCategoryType.biodiversity
  const isRegenAg = categoryData.id === ProgramCategoryType.regen_ag
  const isOther = categoryData.id === ProgramCategoryType.other

  return (
    <button
      className={cx(
        `category-${categoryData.id}`,
        "relative cursor-pointer px-6 py-11 rounded-sm bg-[#D3D3D3] bg-cover bg-no-repeat",
        {
          "bg-top": isBiodiversity,
          "bg-[50%_40%]": isRegenAg,
          "bg-[50%_8%]": isOther,
          "bg-center": !isRegenAg && !isOther && !isBiodiversity,
          "after:content-[''] after:absolute after:inset-0 after:border-4 after:border-west-side after:rounded-sm after:pointer-events-none":
            selectedCategory === categoryData.id,
        }
      )}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(36, 36, 38, 0.7) 0%, rgba(36, 36, 38, 0.7) 100%), url(${bgImage})`,
      }}
      onClick={onClick}
      aria-label={categoryData.category}
      id={categoryData.id}
    >
      <div className="flex flex-col items-start justify-items-start h-full">
        <div className="w-full flex flex-col xs:flex-row md:flex-col xl:flex-row xs:justify-between text-left">
          <h3 className="text-white text-lg font-body font-bold leading-[140%] tracking-[0.36px]">
            {categoryData.category}
          </h3>

          <p className="text-white text-lg font-body font-bold leading-[140%] tracking-[0.32px]">
            {categoryData?.potenial_earnings === null ||
            categoryData?.potenial_earnings === undefined
              ? getProjectEarningsNullValue(categoryData?.id)
              : `${formatCurrency(categoryData?.potenial_earnings)}/acre`}
          </p>
        </div>

        <p className="text-white text-left text-base font-body leading-[130%] tracking-[0.32px] mt-2">
          {categoryData.description}
        </p>
      </div>
    </button>
  )
}

export default OnboardingOverviewPersonalizationCard
