import { ReactNode } from "react"
import { useParams } from "react-router"
import { DialogStateReturn } from "reakit"

import ProjectMapHeader from "./ProjectMapHeader"
import { useHeapTrackOnView } from "../../../hooks/useHeapTrackOnView"

interface ProjectMapContainerProps {
  dialog: DialogStateReturn
  children: ReactNode
}

const ProjectMapContainer = ({
  dialog,
  children,
}: ProjectMapContainerProps) => {
  const { projectId } = useParams()

  const projectMapRef = useHeapTrackOnView({
    event: "componentview-program-eligibility-map",
    properties: { program: projectId },
    observerOptions: { threshold: 0.5 },
  })

  return (
    <div
      ref={projectMapRef}
      className="container xl:max-w-[1120px] mt-[66px] px-5 lg:px-16"
    >
      <ProjectMapHeader dialog={dialog} />

      <div className="lg:max-w-[844px] lg:h-[400px] lg:flex relative rounded-sm overflow-hidden">
        {children}
      </div>
    </div>
  )
}

export default ProjectMapContainer
