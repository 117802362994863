import { useState, useEffect, SetStateAction } from "react"
import { useNavigate } from "react-router"
import { Viewport } from "@urbica/react-map-gl"
import { useFormik } from "formik"
import * as Yup from "yup"
import {
  Feature,
  Geometry,
  GeometryCollection,
  Properties,
} from "@turf/helpers"

import { PARCEL_LAYER_MIN_ZOOM } from "../../components/ParcelSelectLayer"
import {
  useQueryParam,
  useUserCurrentPosition,
  useAddressLatLng,
} from "../../hooks"
import useLocalStorage from "../../hooks/useLocalStorage"
import { DEFAULT_ZOOM_LEVEL, getViewportFromFeatures } from "../../shared/utils"
import { convertMultiPolygonToPolygons, calcAcres } from "../../utils"
import { FeatureProperties, FeaturesProperties } from "../../pages/TeaserMap"

export const useResolveViewport = (initialFeatures: FeaturesProperties) => {
  const [viewport, setViewport] = useState<Viewport>(
    getViewportFromFeatures(initialFeatures) as Viewport
  )
  const [viewportResolved, setViewportResolved] = useState(false)

  const searchAddress = useQueryParam("search") || ""
  const decodedAddress = searchAddress ? decodeURIComponent(searchAddress) : ""

  const {
    data: propertyAddressLatLng,
    fetchStatus: propertyAddressLatLngFetchStatus,
  } = useAddressLatLng<{ lat: number; lng: number }, Error>(decodedAddress, {
    enabled: !viewportResolved && decodedAddress !== "",
  })

  const { data: userPosition, fetchStatus: userPositionFetchStatus } =
    useUserCurrentPosition<
      { latitude: number; longitude: number; default: boolean },
      Error
    >({
      enabled: !viewportResolved,
    })

  useEffect(() => {
    if (viewportResolved) return

    if (propertyAddressLatLngFetchStatus === "fetching") return

    if (propertyAddressLatLng) {
      setViewport((oldViewport) => ({
        latitude: propertyAddressLatLng.lat,
        longitude: propertyAddressLatLng.lng,
        zoom: oldViewport?.zoom || PARCEL_LAYER_MIN_ZOOM,
      }))
      setViewportResolved(true)
      return
    }

    if (userPositionFetchStatus === "fetching") return

    if (userPosition) {
      setViewport((oldViewport) => ({
        latitude: userPosition.latitude,
        longitude: userPosition.longitude,
        zoom: userPosition.default
          ? 4
          : oldViewport?.zoom || DEFAULT_ZOOM_LEVEL,
      }))
    }
    setViewportResolved(true)
  }, [
    viewportResolved,
    propertyAddressLatLng,
    propertyAddressLatLngFetchStatus,
    userPositionFetchStatus,
    userPosition,
    setViewportResolved,
    setViewport,
  ])

  return { viewport, setViewport, viewportResolved, decodedAddress }
}

export const useFeatureHandlers = (
  initialFeatures: Feature<Geometry | GeometryCollection, Properties>[]
) => {
  const [acreage, setAcreage] = useState<number>(0)
  const [parcelIds, setParcelIds] = useState<string[]>([])
  const [, setFeatureStorage] = useLocalStorage("storedFeatures", [])
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues: { features: initialFeatures },
    validationSchema: Yup.object({
      features: Yup.array(),
    }),
    // DEV: Avoid `enableReinitialize=true`, see https://app.asana.com/0/0/1200063952843264/f
    enableReinitialize: false,
    onSubmit: () => {
      navigate("/getting-started")
    },
  })

  useEffect(() => {
    setAcreage(calcAcres(formik.values.features))
    const featureParcelIds = [
      ...new Set(
        formik.values.features
          .map((f) => f.properties?.parcelId)
          .filter(Boolean)
      ),
    ]
    setParcelIds(featureParcelIds)
    setFeatureStorage(formik.values.features as SetStateAction<never[]>)
  }, [formik.values.features, setFeatureStorage])

  const handleCreateFeatures = (features: FeaturesProperties) => {
    if (features.length > 0) {
      formik.setFieldValue("features", [features[0]]) // Only keep the first feature
    }
  }

  // DEV: `update` will sometimes come back with a feature with only 2 points, it gets a `delete` call shortly after
  //   but we still fire a failing HTTP request before then =/
  //   https://app.asana.com/0/1199976942355619/1200117645347666/f
  const filterEmptyFeatures = (features: Feature<Geometry, Properties>[]) => {
    return features.filter((feature) => feature.geometry.coordinates.length > 0)
  }

  const ensurePolygon = (features: FeaturesProperties) => {
    // if none MultiPolygons, return directly to avoid unnecessary mapping
    if (!features.some((feature) => feature.geometry.type === "MultiPolygon"))
      return features

    return features.map(convertMultiPolygonToPolygons).flat()
  }

  const handleUpdateFeatures = (updatedFeatures: FeaturesProperties) => {
    const features = formik.values.features.map(
      (currFeature: FeatureProperties) => {
        // DEV: If this becomes inefficient (n*m; m should always be 10 at most), then switch to set/hash lookup (n*1)
        const updatedFeature = updatedFeatures.find(
          (updatedFeature) => updatedFeature.id === currFeature.id
        )
        return updatedFeature || currFeature
      }
    )
    formik.setFieldValue(
      "features",
      filterEmptyFeatures(
        ensurePolygon(features) as Feature<Geometry, Properties>[]
      )
    )
  }

  const handleDeleteFeatures = (deletedFeatures: any[]) => {
    const features = formik.values.features.filter(
      (currFeature: Feature<Geometry | GeometryCollection, Properties>) => {
        // DEV: If this becomes inefficient (n*m; m should always be 10 at most), then switch to set/hash lookup (n*1)
        const wasDeleted = deletedFeatures.find(
          (deletedFeature) => deletedFeature.id === currFeature.id
        )
        return !wasDeleted
      }
    )
    formik.setFieldValue("features", features)
  }

  const handleClearFeatures = () => {
    formik.setFieldValue("features", [])
  }

  return {
    formik,
    acreage,
    parcelIds,
    handleCreateFeatures,
    handleUpdateFeatures,
    handleDeleteFeatures,
    handleClearFeatures,
  }
}
