import cx from "classnames"

import QuizBanner from "./QuizBanner"
import GuidesBanner from "./GuidesBanner"
import BookConsultationBanner from "./BookConsultationBanner"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import { getGuide } from "./helpers"
import { AccountTypes } from "@/types/account"
import { EligibilityQuizzes } from "@/types/eligibility"
import { EligibilityQuizCategoryType } from "@/types/constants"

interface BannersTypes {
  account: AccountTypes | undefined
  quizesData: EligibilityQuizzes | undefined
  quizCategory: EligibilityQuizCategoryType | undefined
}

const Banners = ({ account, quizesData, quizCategory }: BannersTypes) => {
  const { category } = useProjectsStore()

  const guide = getGuide(quizCategory)

  const showGuidesBanner = category !== null && guide !== null
  const showBookConsultationBanner =
    category !== null && account?.has_purchased_nca_report === false
  const showQuizBanner =
    category !== null &&
    quizesData !== undefined &&
    quizesData[quizCategory as keyof typeof quizesData] !== undefined &&
    quizesData[quizCategory as keyof typeof quizesData]?.completed === false

  const gridSize =
    (showQuizBanner ? 1 : 0) +
    (showBookConsultationBanner ? 1 : 0) +
    (showGuidesBanner ? 1 : 0)

  if (!showQuizBanner && !showGuidesBanner && !showBookConsultationBanner) {
    return null
  }

  return (
    <div
      className={cx(
        "container max-w-[2400px] my-6 lg:mt-0 grid gap-4",
        { flex: gridSize === 1 },
        { "grid grid-cols-1 lg:grid-cols-2": gridSize === 2 },
        { "grid grid-cols-1 lg:grid-cols-3": gridSize === 3 }
      )}
    >
      <QuizBanner
        category={quizCategory}
        quizesData={quizesData}
        show={showQuizBanner}
      />

      <GuidesBanner guide={guide} category={quizCategory} />

      <BookConsultationBanner
        show={showBookConsultationBanner}
        isMember={account?.is_member}
      />
    </div>
  )
}

export default Banners
