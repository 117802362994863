import { SubmitButton } from "../../components/SubmitButton"
import SPBFooter from "./SPBFooter"
import SPBHeader from "./SPBHeader"
import SPBTakeActionCards from "./SPBTakeActionCards"
import { useSPBStore } from "../../stores/useSPBStore"

export interface SPBTakeActionImplementationsProps {
  handlePrevious: () => void
  handleSubmit: () => void
  isPending: boolean
}

const SPBTakeActionImplementations = ({
  handlePrevious,
  handleSubmit,
  isPending,
}: SPBTakeActionImplementationsProps) => {
  const { selectedImplementation } = useSPBStore()

  return (
    <>
      <SPBHeader heading="How do you want to take action on your new approach plan?" />

      <SPBTakeActionCards />

      <SPBFooter>
        <p className="hidden md:flex md:items-center text-overline">
          Select an option to continue
        </p>

        <div className="flex gap-4 ml-auto">
          <button
            type="button"
            className="spb-take-action-go-back btn2 btn2-outline-primary font-bold tracking-[0.32px] whitespace-nowrap"
            onClick={handlePrevious}
          >
            Go Back
          </button>

          <SubmitButton
            className="spb-take-action-save-and-continue btn2 btn2-primary font-bold tracking-[0.32px]"
            isSubmitting={isPending}
            onClick={handleSubmit}
            disabled={!selectedImplementation}
          >
            Save and Continue
          </SubmitButton>
        </div>
      </SPBFooter>
    </>
  )
}

export default SPBTakeActionImplementations
