import assert from "assert"
import { Link } from "react-router"

export interface SubNavigationTypes {
  title: string | null | undefined
  backUrl: string
  isAccountsPage?: boolean
  "aria-label": string
}

export const SubNavigation = ({
  title,
  backUrl,
  isAccountsPage,
  "aria-label": ariaLabel,
}: SubNavigationTypes) => {
  assert(ariaLabel, "aria-label required by SubNavigation for back arrow a11y")

  return (
    <div
      className="bg-white border-b border-cloud-500 py-5 md:py-6"
      data-testid="subnavigation"
    >
      <div
        className={`container ${
          !isAccountsPage ? "max-w-[1120px] px-5" : "max-w-[2400px] px-14"
        }`}
      >
        <div className="inner-container mx-auto flex items-center">
          <Link
            aria-label={ariaLabel}
            className="flex justify-center items-center w-9 h-9 mr-4 md:w-10 md:h-10 border shadow-sm rounded-full focus:outline-hidden focus:ring-3 focus:ring-blue-400/[.45]"
            to={backUrl}
          >
            <i className="fas fa-arrow-left text-moss-500" />
          </Link>

          {title && (
            <div className="text-lg text-carbon font-semibold leading-[1.50] tracking-[0.02em]">
              {title}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
