import { ReactNode } from "react"
import assert from "assert"
import {
  Radio,
  RadioGroup as ReakitRadioGroup,
  useRadioState,
} from "reakit/Radio"
import { useField } from "formik"
import cx from "classnames"

export type ValueContentTuple = [string, string | ReactNode]

export interface RadioGroupTypes {
  valueContentTuples: ValueContentTuple[]
  name: string
  className?: string
  state?: string | number
}

export const RadioGroup = ({
  valueContentTuples, // [ ['choice_one', <>This is choice one</>], ... ]
  name, // `name` prop to use for all radios
  className,
  state,
}: RadioGroupTypes) => {
  assert.notStrictEqual(name, undefined, "`name` prop should be provided")
  const [field] = useField(name)
  const radio = useRadioState({ state: state })

  return (
    <ReakitRadioGroup
      state={state}
      className={cx("reakit-radiogroup", className)}
    >
      {valueContentTuples.map(([value, content]) => (
        <label
          key={value}
          className="relative z-0 flex flex-row items-baseline px-2 py-4"
        >
          <Radio
            {...field}
            {...radio}
            name={name}
            value={value}
            className={cx(
              "peer border-none relative w-5",
              "before:w-5 before:h-5 before:top-0 before:left-0 before:absolute before:rounded-full before:border-2 before:border-grass-900",
              "hover:border-none hover:ring-0 hover:ring-offset-0 hover:shadow-none",
              "focus:border-none focus:ring-0 focus:ring-offset-0 focus:shadow-none",
              "active:border-none active:ring-0 active:ring-offset-0 active:shadow-none",
              "checked:border-none checked:bg-transparent checked:rounded-none checked:shadow-none",
              "checked:hover:border-none checked:hover:bg-transparent",
              "checked:focus:border-none checked:focus:bg-transparent",
              "checked:active:border-none checked:active:bg-transparent",
              "checked:after:w-2.5 checked:after:h-2.5 checked:after:absolute checked:after:bg-grass-900 checked:after:rounded-full checked:after:top-[5px] checked:after:left-[5px]"
            )}
          />
          <span
            className={cx(
              "flex grow pl-2",
              "peer-checked:relative",
              "peer-checked:before:absolute",
              "peer-checked:before:-top-4 peer-checked:before:-bottom-4 peer-checked:before:-left-7 peer-checked:before:-right-2",
              "peer-checked:before:bg-grass-50 peer-checked:before:-z-1"
            )}
          >
            {content}
          </span>
        </label>
      ))}
    </ReakitRadioGroup>
  )
}
