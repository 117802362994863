import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import cx from "classnames"

import InfoPopover from "../../../components/InfoPopover"
import { starIcon } from "../../../shared/icons"

interface ProjectComparisonModalNoRecommendedProps {
  show: boolean
  className?: string
  reason: string | undefined
}

const ProjectComparisonModalNoRecommended = ({
  show,
  className,
  reason,
}: ProjectComparisonModalNoRecommendedProps) => {
  if (!show) {
    return null
  }

  return (
    <div
      className={cx(
        "inline-flex items-center text-charcoal-500 text-sm leading-[150%] tracking-[0.14px] border border-dusk-50 rounded-sm px-2 py-[2px] mr-auto grow-0 self-center",
        className
      )}
    >
      <FontAwesomeIcon
        icon={starIcon as IconProp}
        className="inline-block w-4 h-4 ml-[3px] mr-[7px] [&>path]:fill-white! stroke-[#F29D13] stroke-[1.5px]"
        fill="white"
      />
      No Recommended Programs
      <InfoPopover text={reason} />
    </div>
  )
}

export default ProjectComparisonModalNoRecommended
