import cx from "classnames"

import ComparisonContentToggleButton from "./ComparisonContentToggleButton"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { useProjectsStore } from "../../../stores/useProjectsStore"

interface ProjectComparisonHeaderProps {
  comparisonContentOpen: boolean
  handleComparisonContentOpen: () => void
  handleGetCompare: () => void
}
const ProjectComparisonHeader = ({
  comparisonContentOpen,
  handleComparisonContentOpen,
  handleGetCompare,
}: ProjectComparisonHeaderProps) => {
  const { comparison, clearComparison } = useProjectsStore()
  const isMaxWidthLg = useMediaQuery("(max-width: 1024px)")

  const comparisonNumberTextDesktop = comparison.length
    ? `${comparison.length}/3`
    : "(Select Up to 3)"

  const comparisonNumberText = isMaxWidthLg
    ? `Compare (${comparison.length})`
    : `Compare to Get a Recommendation ${comparisonNumberTextDesktop}`

  return (
    <header className="relative flex items-center justify-between gap-4 mb-[18px]">
      <h4 className="text-lg font-body font-bold leading-[140%] tracking-[0.36px]">
        {comparisonNumberText}
      </h4>

      <ComparisonContentToggleButton
        open={comparisonContentOpen}
        onClick={handleComparisonContentOpen}
        show={isMaxWidthLg}
        className="absolute -bottom-4 left-0"
      />

      <button
        type="button"
        className={cx("ml-auto disabled:text-dusk-200", {
          "cursor-pointer": comparison.length > 0,
        })}
        onClick={clearComparison}
        disabled={comparison.length === 0}
      >
        Remove All
      </button>

      <button
        type="button"
        className="btn2 btn2-outline-primary text-charcoal-500 leading-[130%] tracking-[0.32px] p-2"
        onClick={() => {
          handleGetCompare()
        }}
        disabled={comparison.length < 2}
      >
        Recommend
      </button>
    </header>
  )
}

export default ProjectComparisonHeader
