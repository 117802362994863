import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

interface ComparisonContentToggleButtonProps {
  open: boolean
  onClick: () => void
  show?: boolean
  className?: string
}

const ComparisonContentToggleButton = ({
  open,
  onClick,
  show = true,
  className,
}: ComparisonContentToggleButtonProps) => {
  if (!show) {
    return null
  }

  return (
    <button type="button" className={cx("", className)} onClick={onClick}>
      <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
    </button>
  )
}

export default ComparisonContentToggleButton
