import {
  useTooltipState,
  Tooltip as ReakitTooltip,
  TooltipReference,
  TooltipArrow,
} from "reakit/Tooltip"

import { AccountRoleType } from "@/types/account"

export interface SPBDynamicCheckoutCardSubmitProps {
  role: AccountRoleType | undefined
  handleStripeSubmit: () => void
}

const SPBDynamicCheckoutCardSubmit = ({
  role,
  handleStripeSubmit,
}: SPBDynamicCheckoutCardSubmitProps) => {
  const tooltip = useTooltipState()

  return (
    <>
      <TooltipReference {...tooltip} className="ring-0">
        <button
          className="spb-wizard member-widget-join-now btn2 btn2-primary whitespace-nowrap font-body font-bold leading-[130%] tracking-[0.32px] py-[10px] px-4 xs:px-7 mb-0"
          onClick={() => {
            handleStripeSubmit()
          }}
          disabled={role !== "admin"}
        >
          Get Southern Pine Beetle Action Plan
        </button>
      </TooltipReference>

      {role !== "admin" ? (
        <ReakitTooltip {...tooltip} className="st-tooltip">
          <div className="text-sm leading-[1.50] tracking-[0.01em] max-w-[664px] p-3 st-tooltip-inner">
            <TooltipArrow {...tooltip} className="fill-charcoal-500" />
            Only admins can subscribe.
          </div>
        </ReakitTooltip>
      ) : null}
    </>
  )
}

export default SPBDynamicCheckoutCardSubmit
