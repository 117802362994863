import { CSSProperties, useEffect, useMemo, useRef } from "react"
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSort } from "@fortawesome/pro-duotone-svg-icons"
import cx from "classnames"
import scrollIntoView from "scroll-into-view-if-needed"

import { getTableData } from "./helpers"
import { columns } from "./constants"
import { GetTableDataReturn, RoiTableProps } from "./types"
import RoiTablePagination from "./RoiTablePagination"

const RoiTable = ({
  roiData,
  isMobile,
  activeYear,
  setActiveYear,
}: RoiTableProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)

  const tableData: GetTableDataReturn[] = useMemo(
    () =>
      getTableData({
        revenue: roiData.yearly_revenue,
        cost: roiData.yearly_cost,
        profit: roiData.yearly_profit,
        total_npv: roiData.total_npv,
      }),
    [
      roiData.yearly_revenue,
      roiData.yearly_cost,
      roiData.yearly_profit,
      roiData.total_npv,
    ]
  )

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: isMobile ? getPaginationRowModel() : undefined,
  })

  // DEV: Center and highlight the corresponding table row inside table container whenever activeYear changes.
  useEffect(() => {
    const rowToScroll = document.getElementById(`${activeYear}_year`)
    const currentContainerRef = containerRef.current

    if (rowToScroll) {
      scrollIntoView(rowToScroll, {
        behavior: "smooth",
        block: "center",
        boundary: currentContainerRef,
      })
      rowToScroll.classList.add("bg-grass-200!")
    }

    // DEV: Unselect the active row on click outside of table.
    const handleClickOutside = (e: MouseEvent) => {
      if (
        currentContainerRef &&
        !currentContainerRef.contains(e.target as Node)
      ) {
        setActiveYear(null)
      }
    }
    document.addEventListener("click", handleClickOutside)

    return () => {
      if (rowToScroll) {
        document.removeEventListener("click", handleClickOutside)
        rowToScroll.classList.remove("bg-grass-200!")
      }
    }
  }, [activeYear, setActiveYear])

  const numShowing =
    table.getState().pagination.pageSize *
    (table.getState().pagination.pageIndex + 1)

  return (
    <div className="mt-6 lg:mt-7 lg:ml-10" data-testid="roi-table">
      <div
        ref={containerRef}
        className={cx(
          "relative overflow-x-auto border border-charcoal-50 rounded-t",
          { "max-h-[204px]": !isMobile }
        )}
      >
        <table
          ref={tableRef}
          className="w-full text-sm text-left leading-[130%] tracking-[0.14px] text-charcoal-500 whitespace-nowrap border-separate border-spacing-0 roi-table"
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <thead key={headerGroup.id} className="font-bold">
              <tr className="sticky top-0 bg-white z-2">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="p-4 border-b-2 border-b-charcoal-50"
                  >
                    {typeof header.column.columnDef.header === "function"
                      ? flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )
                      : header.column.columnDef.header}
                    {header.column.getCanSort() && (
                      <FontAwesomeIcon
                        icon={faSort}
                        className={cx(
                          "cursor-pointer inline-block w-4 h-4 ml-1",
                          {
                            "fa-swap-opacity":
                              header.column.getNextSortingOrder() === "asc",
                          }
                        )}
                        style={
                          {
                            "--fa-primary-opacity":
                              header.column.getIsSorted() === false ? 0.4 : 1,
                          } as CSSProperties
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
          ))}

          <tbody className="roi-table">
            {table.getRowModel().rows.map((row, i, arr) => (
              <tr
                key={row.id}
                tabIndex={0}
                id={`${row.original.year}_year`}
                className={cx("roi-table-row hover:bg-grass-200 focus:ring-0", {
                  "bg-grass-50": i % 2 !== 0,
                })}
                onClick={() => setActiveYear(row.original.year)}
                onFocus={() => setActiveYear(row.original.year)}
                onBlur={() => setActiveYear(null)}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cx("px-4 py-[10px]", {
                      "border-b border-b-charcoal-50": i < arr.length - 1,
                    })}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <RoiTablePagination
        show={isMobile}
        numShowing={numShowing}
        table={table}
      />
    </div>
  )
}

export default RoiTable
