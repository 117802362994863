export const OUTBREAK_RATING = {
  Low: "Unlikely",
  Medium: "Likely",
  High: "Very Likely",
}

export const SPB_TILE_MAPTYPES = {
  overall: "overall",
  actions: "actions",
}

export const IMPACT_TO_YOUR_TIMBER = {
  income: [
    "Beetle infestations can kill marketable pines",
    "Can reduce timber revenue if cutting early or low quality",
    "Costly replanting and lower property values from degraded forests",
  ],
  environmental: [
    "Beetle infestations disrupt ecosystems, harming wildlife, soil, and watersheds",
    "Dead trees raise fire risk, enable invasives, and alter forest succession",
  ],
  wildlife: [
    "Southern Pine Beetles kill mature pines",
    "Can create nesting sites and diverse habitats over time",
    "Species reliant on old-growth pines may decline",
  ],
  hunting: [
    "Southern Pine Beetles kill pine habitat",
    "Can improve deer browse and bedding over time",
    "Fallen timber and new growth attract game but take years to develop",
  ],
  recreation_non_hunting: [
    "Beetle infestations leave dead trees, ruining scenic forests, and create hazards",
    "This in turn reduces hiking, camping, and outdoor enjoyment for years",
  ],
  land_improvement: ["Southern Pine Beetle has no direct impact on this goal"],
  undefined: [],
}
