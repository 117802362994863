const AskAQuestionHeader = () => (
  <div className="mb-6">
    <h1 className="text-charcoal-500 text-2xl leading-[1.40] tracking-wide font-medium">
      Get advice from NCX experts
    </h1>

    <p className="text-gray-500 text-base font-normal leading-snug tracking-tight">
      Share your top question or concern. We'll help guide you towards the right
      path based on your land, goals, and market conditions.
    </p>
  </div>
)

export default AskAQuestionHeader
