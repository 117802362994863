import { useEffect, useRef, useState } from "react"
import cx from "classnames"

import { useAccountUrlPrefix } from "../hooks"
import BLOB from "../images/blob.png"
import DEER from "../images/deer.gif"

export interface RecommendationsLoaderProps {
  isModal?: boolean
}

const RecommendationsLoader = ({
  isModal = false,
}: RecommendationsLoaderProps) => {
  const [loadingStep, setLoadingStep] = useState(0)
  const accountUrlPrefix = useAccountUrlPrefix()

  const steps = [
    "Reviewing your goals",
    "Analyzing your land's potential",
    "Checking eligibility requirements",
    "Summarizing pros and cons",
    "Creating recommendations",
  ]

  const intervalId = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (loadingStep === steps.length - 1) {
        intervalId.current && clearInterval(intervalId.current)
      } else {
        setLoadingStep((loadingStep) => loadingStep + 1)
      }
    }, 1250)

    return () => {
      intervalId.current && clearInterval(intervalId.current)
    }
  }, [loadingStep, steps.length, accountUrlPrefix])

  return (
    <div
      className={cx("", {
        "fixed sm:static top-14 left-0 right-0 bottom-0 overflow-auto py-8 sm:py-20 px-5":
          isModal,
        "container xl:max-w-[1396px] pt-6 sm:pt-[118px] pb-8 sm:pb-[76px] mx-auto":
          !isModal,
      })}
    >
      <div className="flex flex-col-reverse sm:flex-row justify-between gap-8">
        <div className="flex justify-center w-auto max-w-[528px]">
          <div className="relative self-start w-[242px] md:w-auto max-w-full">
            <img src={BLOB} className="w-full" alt="blob" />

            <img
              src={DEER}
              alt="deer"
              className="w-[77.589%] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </div>

        <div className="md:w-[571px] max-w-full flex flex-col justify-center">
          <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-[0.48px]">
            Preparing program recommendations!
          </h1>

          <div className="mt-8">
            {steps.map((step, index) => (
              <div
                key={index}
                className="flex items-center space-x-2 py-4 px-2"
              >
                {loadingStep >= index ? (
                  <>
                    <i className="fas fa-check text-green-700" />
                    <p className="text-neutral-800 text-lg font-normal">
                      {step}
                    </p>
                  </>
                ) : (
                  <>
                    <i className="fas fa-lg fa-spinner-third fa-spin text-green-700 opacity-40" />
                    <p className="text-neutral-800 opacity-40 text-lg font-normal">{`${step}...`}</p>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecommendationsLoader
