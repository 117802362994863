import { ReactNode } from "react"
import cx from "classnames"

import ScoreCardHeading from "../ViewAccount/Scorecard/ScoreCardHeading"
import ScoreCardSubHeading from "../ViewAccount/Scorecard/ScoreCardSubHeading"
import ScoreCardDescription from "../ViewAccount/Scorecard/ScoreCardDescription"
import ScoreCardHeadingPill from "../ViewAccount/Scorecard/ScoreCardHeadingPill"
import ScoreCardScore from "../ViewAccount/Scorecard/ScoreCardScore"
import {
  getScoreRating,
  getScoreColorClass,
} from "../ViewAccount/Scorecard/helpers"

export interface SPBScoreCardProps {
  heading: string
  subheading: ReactNode
  description: ReactNode
  currentScore: number | null
  maxScore: number | null
}

const SPBScoreCard = ({
  heading,
  subheading,
  description,
  currentScore,
  maxScore,
}: SPBScoreCardProps) => {
  const scoreRating = getScoreRating(
    currentScore ? Math.round(currentScore) : 0
  )
  const scoreColorClass = getScoreColorClass(currentScore ?? 0)

  return (
    <div className="scorecard-spb flex flex-col sm:flex-row w-full justify-between gap-8 pt-4 sm:pt-0">
      <div>
        <ScoreCardHeading className="flex">{heading}</ScoreCardHeading>

        <ScoreCardSubHeading text={subheading} className="mt-2.5" />

        <ScoreCardDescription text={description} className="mt-1.5 mb-3" />
      </div>

      <div className="flex flex-col gap-2">
        <ScoreCardScore
          currentScore={currentScore}
          maxScore={maxScore}
          scoreColorClass={scoreColorClass}
          hasScoreBarLegend={false}
        />

        <ScoreCardHeadingPill
          scoreColorClass={cx("!ml-auto whitespace-nowrap", scoreColorClass)}
          scoreRating={scoreRating}
        />
      </div>
    </div>
  )
}

export default SPBScoreCard
