import { genericErrMsg } from "../../api/auth"

interface PropertyMapErrorMessageProps {
  isError: boolean
}

const PropertyMapErrorMessage = ({ isError }: PropertyMapErrorMessageProps) => {
  if (!isError) {
    return null
  }

  return (
    <div className="relative h-[400px] xl:max-w-full xl:flex rounded-sm overflow-hidden p-6">
      <div className="w-full h-full">
        <div className="w-full lg:w-2/3 xxl:w-1/2 mx-auto mt-1/4 bg-white p-12">
          {genericErrMsg}
        </div>
      </div>
    </div>
  )
}

export default PropertyMapErrorMessage
