import cx from "classnames"

import { ProgramCategoryType, ProjectBadgeType } from "@/types/constants"

// https://github.com/dockwa/simple-react-validator/blob/master/src/simple-react-validator.js#L42
export const phoneRegex =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)$/

export const DATETIME_TIMEZONE = "America/Los_Angeles"
// https://moment.github.io/luxon/#/formatting?id=table-of-tokens
// DATETIME_MONTH_DAY = "January 1" (no zero padding)
export const DATETIME_MONTH_DAY = "MMMM d"
// DATETIME_MONTH_DAY_YEAR = "January 1, 2022"(no zero padding)
export const DATETIME_MONTH_DAY_YEAR = "MMMM d, yyyy"

export const HELP_CENTER_URL = "https://help.ncx.com/hc/en-us"

export const HOTJAR_EVENTS: string[] = [
  "click_program_compare_button",
  "complete_spb_wizard",
]

export const PROJECT_BADGE_TYPES: Record<ProjectBadgeType, ProjectBadgeType> = {
  primary: "primary",
  warning: "warning",
  error: "error",
  neutral: "neutral",
  info: "info",
}

export const LANDOWNER_STATUS = {
  eligible: "eligible",
  ineligible: "ineligible",
  in_progress: "in_progress",
  not_interested: "not_interested",
  request_information: "request_information",
  determining_eligibility: "determining_eligibility",
  under_contract: "under_contract",
  information_needed: "information_needed",
  datapack_shareable: "datapack_shareable",
  lo_not_interested: "lo_not_interested",
  internal_dq: "internal_dq",
}

export const LANDOWNER_STATUS_MESSAGE = {
  eligible: "Eligible",
  ineligible: "Ineligible",
  not_interested: "Not Interested",
  request_information: "Requested",
  determining_eligibility: "Determining Eligibility",
  under_contract: "Contract Signed",
  information_needed: "Action Required",
}

export const PROJECT_SORTING = {
  RECENTLY_ADDED: "Recently added",
  VERIFIED: "Verified",
  ELIGIBILITY: "Eligibility",
  ENROLLMENT_DEADLINE: "Enrollment deadline",
  POTENTIAL_EARNINGS: "Potential earnings",
  MOST_POPULAR: "Most popular",
}

export const PROJECT_SORTING_QS = {
  recently_added: PROJECT_SORTING.RECENTLY_ADDED,
  is_verified: PROJECT_SORTING.VERIFIED,
  eligibility: PROJECT_SORTING.ELIGIBILITY,
  enrollment_deadline: PROJECT_SORTING.ENROLLMENT_DEADLINE,
  potential_earnings: PROJECT_SORTING.POTENTIAL_EARNINGS,
  most_popular: PROJECT_SORTING.MOST_POPULAR,
}

export const DEADLINE_TYPE = {
  specific_date: "specific_date",
  open_enrollment: "open_enrollment",
  pending_interest: "pending_interest",
}

export const NOT_INTERESTED_REASONS = {
  KEY_OBLIGATION: "key_obligation",
  TERM_LENGTH: "term_length",
  LANDOWNER_COSTS: "landowner_costs",
  ELIGIBILITY_REQUIREMENTS: "eligibility_requirements",
  EARNINGS_AND_PENALTIES: "earnings_and_penalties",
  RESTRICTIONS: "restrictions",
  LANDOWNER_RIGHTS: "landowner_rights",
  PROPERTY_ACCESS: "property_access",
  OTHER: "other",
}

interface ProjectsTypeTypes {
  id: string
  long: string
  short?: string
  description: string
  earningsNullLabel?: string
}

type ProjectsTypesTypes = Record<ProgramCategoryType, ProjectsTypeTypes>

export const PROJECT_TYPES: ProjectsTypesTypes = {
  tree_planting: {
    id: "tree_planting",
    long: "Tree Planting",
    description:
      "Replant after a recent harvest or convert fields to forests to earn carbon and or timber revenue.",
  },
  renewable_infrastructure: {
    id: "renewable_infrastructure",
    long: "Renewable Energy",
    description:
      "Lease development rights for solar, wind, and other renewable energy sources.",
  },
  timber: {
    id: "timber",
    long: "Timber Harvest",
    description:
      "Harvest mature timber to earn significant revenue and alter your forest's structure and composition.",
    // we only have null in cases where we don't have all the data needed
    earningsNullLabel: "Unavailable",
  },
  recreation: {
    id: "recreation",
    long: "Recreation",
    description:
      "Lease your land for limited recreational use like hunting, fishing, farm tours, camping, and more.",
  },
  harvest_deferral: {
    id: "harvest_deferral",
    long: "Forest Carbon",
    description: "Generate carbon credits by allowing trees to grow older.",
  },
  forest_carbon: {
    id: "forest_carbon",
    long: "Forest Carbon",
    description: "Generate carbon credits by allowing trees to grow older.",
  },
  water: {
    id: "water",
    long: "Water",
    description:
      "Protect water quality and quantity through a variety of cost share programs.",
  },
  biodiversity: {
    id: "biodiversity",
    long: "Biodiversity",
    description:
      "Restore native habitat, protect pollinators, create connectivity corridors, and more.",
  },
  wildfire: {
    id: "wildfire",
    long: "Forest Health",
    description:
      "Improve forest health by reducing risk from threats and recovering degraded areas.",
  },
  regen_ag: {
    id: "regen_ag",
    long: "Regenerative Agriculture",
    short: "Regenerative Ag",
    description:
      "Improve soil health, carbon sequestration, and operating productivity through rotational grazing and other regenerative practices.",
  },
  advisory_services: {
    id: "advisory_services",
    long: "Advisory Services",
    description:
      "Connect with experts in forestry, wildlife, and real estate to assist your land management and planning",
  },
  other: {
    id: "other",
    long: "Advisory Services",
    description:
      "Connect with experts in forestry, wildlife, and real estate to assist your land management and planning",
  },
}

export const PROJECT_INTEREST = {
  interested_in_biodiversity: "Biodiversity",
  interested_in_harvested_deferral: "Forest Carbon",
  interested_in_ncx_recommendations: "Programs that NCX recommends",
  interested_in_reforestation: "Tree Planting/Revegetation",
  interested_in_renewable_energy: "Renewable Energy",
  interested_in_regen_ag: "Regenerative Agriculture",
  interested_in_recreation: "Recreation",
  interested_in_timber: "Timber Harvest",
  interested_in_water: "Water Quality/Yield",
  interested_in_wildfire: "Forest Health",
  interested_in_advisory_services: "Advisory Services",
}

export const LAND_GOALS = {
  income: "Income Generation",
  environmental: "Environmental and Forest Health",
  wildlife: "Wildlife Conservation",
  hunting: "Hunting Opportunities",
  recreation_non_hunting: "Recreation (Non-Hunting)",
  land_improvement: "Land Improvement/Construction",
  unknown: "Unknown",
}

export const FEATURE_FLAGS = {
  nci_rollout: "nci_rollout",
}

export const QUALIFIED_LEADS = "qualified_leads"
export const ELIGIBLE_LEADS = "eligible_leads"

export const PAYMENT_TYPES = {
  annuity: "Annuity (Payment over time)",
  lump_sum: "Lump sum (Payment up front)",
  revenue_share: "Revenue share",
  cost_share: "Cost share",
}

export const CONTACT_METHODS = {
  phone: "Phone call",
  text: "Text message",
  email: "Email",
  video: "Video call (Zoom, Google Meet, etc.)",
}

export const PREFERRED_CONTACT_METHOD = "preferred_contact_method"
export const PHONE_NUMBER = "phone_number"

export const CALCULATOR_INPUTS = {
  ELIGIBLE_ACRES: "eligible_acres",
  YEARLY_REVENUE: "yearly_revenue",
  STARTUP_COST: "startup_cost",
  ANNUAL_COST: "annual_cost",
  INFLATION_RATE: "inflation_rate",
  DISCOUNT_RATE: "discount_rate",
}

export const PARTNERS_ACCOUNT_STATUSES = [
  { value: "pd_attempting_contact", label: "Attempting Contact" },
  { value: "awaiting_more_land", label: "Awaiting More Land" },
  { value: "under_contract", label: "Contract Signed" },
  { value: "pd_contract_sent", label: "Contractual Offer Sent" },
  { value: "pd_contacted", label: "Conversation in Progress" },
  { value: "pd_existing_relationship", label: "Lost: Existing" },
  { value: "lo_offer_declined", label: "Lost: Offer Declined" },
  { value: "pd_not_accepted", label: "Lost: Rejected Ineligible" },
  { value: "lo_not_interested", label: "Lost: Uninterested" },
  { value: "lo_unresponsive", label: "Lost: Unresponsive" },
]

export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic2lsdmlhdGVycmEiLCJhIjoiY2tocWthOTUzMDJtbDJ5cXB4d2tmbjdtdCJ9.kvPKEDnUGQBtyBmUCPafdA"

//DEV: this is in order we want to display the landcover data
export const LANDCOVER_TYPE_LABELS = {
  forest: "Forested",
  cultivated: "Cultivated",
  herbaceous: "Grassland",
  shrub: "Shrubland",
  water: "Water",
  wetlands: "Wetlands",
  woody_wetland: "Woody Wetlands",
  developed: "Developed",
  barren: "Barren",
}

export const mapStyles = {
  aerial: {
    url: "mapbox://styles/silviaterra/ckhb6hcco09os19pshbd2afaq",
    label: "Aerial",
  },
  topo: {
    url: "mapbox://styles/silviaterra/ckhb69dl509dr19mu7iio922e",
    label: "Topography",
  },
  road: {
    url: "mapbox://styles/mapbox/light-v9",
    label: "Road",
  },
}

export const ACRES_PER_SQUARE_METER = 0.000247105

export const NOTIFICATIONS = {
  invite_inaccessible: "invite_inaccessible",
  pd_invite: "pd_invite",
  change_detected: "change_detected",
}

export const formInputClass = cx(
  "bg-white border-charcoal-50 appearance-none border rounded-sm text-base py-[7px] px-3",
  "focus:outline-hidden focus:ring-3 focus:ring-blue-400/[.45] focus:border-[#a4cafe]",
  "[&::placeholder]:text-dusk-500"
)
