import { ReactElement } from "react"
import { Tooltip, TooltipArrow, TooltipStateReturn } from "reakit/Tooltip"

export interface TooltipContentTypes {
  children: ReactElement | string
  tooltip: TooltipStateReturn
}

const TooltipContent = ({ children, tooltip }: TooltipContentTypes) => (
  <Tooltip {...tooltip} className="st-tooltip ">
    <div className="bg-charcoal-500 text-white rounded text-sm leading-[1.50] tracking-[0.01em] max-w-[664px] p-3 st-tooltip-inner">
      <TooltipArrow {...tooltip} className="fill-charcoal-500" />
      {children}
    </div>
  </Tooltip>
)

export default TooltipContent
