import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useMutationWithRefresh } from "../utils"
import { postWizards } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { FormikValues } from "formik"
import { SPBWizardType, WizardsType } from "@/types/wizards"

export const useUpdateWizards = <
  TData extends SPBWizardType,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  accountId: string,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh(
    queryClient,
    (values: TVariables) => postWizards(accountId, values),
    {
      _onSuccess: (data) => {
        const wizards = queryClient.getQueryData<WizardsType>([
          "accounts",
          accountId,
          "wizards",
        ])

        const newWizards = wizards
          ? wizards.map((wizard) => (wizard.key === data.key ? data : wizard))
          : [data]

        queryClient.setQueryData(["accounts", accountId, "wizards"], newWizards)
      },
      ...config,
    }
  )
}
