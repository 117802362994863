import { useMemo } from "react"
import { LineChart, Line, XAxis, YAxis, Tooltip } from "recharts"

import RoiChartCustomTooltip from "./RoiChartCustomTooltip"
import { getChartData } from "./helpers"
import { RoiChartProps } from "./types"

const RoiChart = ({
  roiData,
  isMobile,
  width,
  height,
  setActiveYear,
}: RoiChartProps) => {
  const chartData = useMemo(
    () =>
      getChartData({
        revenue: roiData.total_revenue,
        profit: roiData.total_profit,
      }),
    [roiData.total_revenue, roiData.total_profit]
  )

  // DEV: Set activeYear on chart dot onMouseOver event, so it can be used to center amd highlight the corresponding table row inside the table container.
  const handleMouseOver = (_: unknown, data: any) => {
    if (!isMobile) {
      setActiveYear(data?.payload?.year as number)
    }
  }

  return (
    <LineChart
      width={width}
      height={height}
      data={chartData}
      margin={{
        top: 20,
        right: 28,
        left: isMobile ? 28 : 102,
        bottom: 0,
      }}
    >
      <XAxis
        type="number"
        label={{
          value: "Years",
          fill: "#262624",
          stroke: "none",
          position: "insideBottomCenter",
          offset: 0,
          fontSize: 14,
          lineHeight: "130%",
          letterSpacing: "0.32px",
        }}
        axisLine={{ stroke: "#6B7280", strokeWidth: "1px" }}
        dataKey="year"
        height={90}
        domain={[0, "dataMax"]}
        tickLine={false}
        tickCount={6}
        dy={5}
        tick={{
          fill: "#6B7280",
          stroke: "none",
          fontWeight: "normal",
          strokeWidth: 0,
          fontSize: isMobile ? 12 : 16,
          letterSpacing: "0.32px",
        }}
      />

      <YAxis
        type="number"
        axisLine={false}
        domain={["dataMin", "auto"]}
        tickFormatter={(v: number) => {
          if (v < 0) {
            return `-$${Math.abs(v).toLocaleString("en-US")}`
          }
          return `$${v.toLocaleString("en-US")}`
        }}
        tickCount={6}
        dx={isMobile ? -10 : -50}
        tickLine={false}
        tick={{
          fill: "#6B7280",
          stroke: "none",
          fontWeight: "normal",
          strokeWidth: 0,
          fontSize: isMobile ? 12 : 16,
          letterSpacing: "0.32px",
        }}
      />

      <Tooltip content={<RoiChartCustomTooltip />} />

      <Line
        type="monotone"
        dataKey="revenue"
        stroke="#008561"
        dot={false}
        activeDot={{ onMouseOver: handleMouseOver }}
      />

      <Line
        type="monotone"
        dataKey="profit"
        stroke="#979CA6"
        dot={false}
        activeDot={{ onMouseOver: handleMouseOver }}
      />
    </LineChart>
  )
}

export default RoiChart
