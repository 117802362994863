import useHasNewMessages from "../../hooks/useHasNewMessages"

const NavBarInboxLinkIndicator = () => {
  const hasNewMessages = useHasNewMessages()

  if (!hasNewMessages) {
    return null
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      className="absolute right-px top-px"
      data-testid="nav-bar-inbox-link-indicator"
    >
      <circle cx="4" cy="4" r="4" fill="#D46565" />
    </svg>
  )
}

export default NavBarInboxLinkIndicator
