import cx from "classnames"

export interface ScoreCardScoreNumberProps {
  scoreColorClass: string
  currentScore: number | null
}

const ScoreCardScoreNumber = ({
  scoreColorClass,
  currentScore,
}: ScoreCardScoreNumberProps) => (
  <div
    className={cx(
      "flex items-center justify-center rounded-sm w-9 h-9 text-white text-lg font-bold leading-[140%] tracking-[0.36px] mr-4",
      scoreColorClass
    )}
    data-testid="score-card-score-number"
  >
    {currentScore ?? 0}
  </div>
)

export default ScoreCardScoreNumber
