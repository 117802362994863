import { Field, FieldProps } from "formik"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { formInputClass } from "../../shared/constants"
import { getSVGURI } from "../../shared/utils"
import { SelectInputProps } from "./types"

const SelectInput = ({
  className,
  placeholder,
  options,
  ...rest
}: SelectInputProps) => (
  <Field {...rest}>
    {({ field, meta }: FieldProps) => (
      <select
        className={cx(
          formInputClass,
          "block w-full disabled:bg-gray-100",
          "form-select bg-[length:14px] bg-[right_1rem_center] hover:not-disabled:cursor-pointer",
          className,
          {
            "border-red-500 focus:border-red-300 focus:ring-3 focus:ring-red-300/[.45] [&::placeholder]:text-red-600 [&_.placeholder]:text-red-600":
              meta.touched && meta.error,
            "text-gray2": field.value === "",
          }
        )}
        style={{
          backgroundImage: `url(${getSVGURI(faChevronDown, "#6B7280")})`,
        }}
        {...field}
        {...rest}
      >
        <option value="" disabled={true} className="text-gray2">
          {placeholder || "Select"}
        </option>

        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="text-black"
          >
            {option.label}
          </option>
        ))}
      </select>
    )}
  </Field>
)

export default SelectInput
