import cx from "classnames"

export interface ScoreCardScoreBarProps {
  currentScoreHeight: number
  maxScorePosition: number
  scoreColorClass: string
  hidePotentialScore?: boolean
}

const ScoreCardScoreBar = ({
  currentScoreHeight,
  maxScorePosition,
  scoreColorClass,
  hidePotentialScore = false,
}: ScoreCardScoreBarProps) => (
  <div
    className="relative w-[22px] h-[86px] self-center"
    data-testid="score-card-score-bar"
  >
    <div className="w-full h-full flex flex-col-reverse bg-dusk-200 rounded-sm overflow-hidden">
      {/* DEV: Current score bar */}
      <div
        className={cx("w-full", scoreColorClass)}
        style={{ height: `${currentScoreHeight}%` }}
      />
    </div>

    {/* DEV: Max score dashed line */}
    {!hidePotentialScore && (
      <div
        data-testid="score-card-score-bar-potential"
        className="absolute w-full h-[2px] bg-bottom bg-repeat-x"
        style={{
          bottom: `${maxScorePosition}%`,
          backgroundImage:
            "url('data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 width=%2224%22 height=%222%22 viewBox=%220%200%2024%202%22 fill=%22none%22><path d=%22M1 1H23%22 stroke=%22%234E5563%22 stroke-linecap=%22square%22 stroke-linejoin=%22bevel%22 stroke-dasharray=%222%202%22/></svg>')",
        }}
      />
    )}
  </div>
)

export default ScoreCardScoreBar
