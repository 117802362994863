import { ReactNode } from "react"
import { Link } from "react-router"
import cx from "classnames"
import { CloseButton } from "./CloseButton"

export interface NotificationTypes {
  title?: ReactNode
  className?: string
  children: ReactNode
  variant?: "warning" | "success" | "error"
  border?: string
  onDismiss?: () => void
  image?: string
  link?: string
  linkText?: string
}

export const Notification = ({
  title,
  className,
  children,
  variant = "warning",
  border = "left",
  onDismiss,
  image,
  link,
  linkText,
}: NotificationTypes) => {
  let variantClassName

  if (variant === "warning") {
    variantClassName = "border-yellow-[7.5rem]0"
  } else if (variant === "success") {
    variantClassName = "border-green-brand"
  } else if (variant === "error") {
    variantClassName = "border-orange-900"
  } else {
    throw new Error("Unrecognized Notification variant")
  }

  return (
    <div
      className={cx(
        "bg-white shadow-sm rounded-none border-solid p-4",
        { "border-l-2": border === "left" },
        { "border-2": border === "full" },
        variantClassName,
        className
      )}
      data-testid="notification"
    >
      <div className="flex flex-row">
        {image ? (
          <div className="flex-auto shrink-0 pr-4">
            <img src={image} alt="" className="w-[92px] sm:w-[84px]" />
          </div>
        ) : null}

        <div className="flex-auto">
          {title && (
            <div className="mb-2">
              <strong className="font-semibold">{title}</strong>
            </div>
          )}

          <div>{children}</div>

          {link ? (
            <Link
              to={{
                pathname: link,
              }}
              className="btn2 btn2-primary font-bold mt-4 hidden sm:inline-block"
            >
              {linkText}
            </Link>
          ) : null}
        </div>

        <div className="shrink-0 ml-[0.625rem]">
          {/* DEV: No need for Formik since no submitting state */}
          {onDismiss && (
            <CloseButton
              aria-label="Dismiss notification"
              onClick={onDismiss}
            />
          )}
        </div>
      </div>

      {link ? (
        <div className="block sm:hidden">
          <Link
            to={{
              pathname: link,
            }}
            className="btn2 btn2-primary text-center font-bold mt-4 block"
          >
            {linkText}
          </Link>
        </div>
      ) : null}
    </div>
  )
}
