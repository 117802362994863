import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"

import ProjectInterestComponent from "./ProjectInterestComponent"
import { genericErrMsg } from "../../api/auth"
import { useAccountId, useUpdateAccount } from "../../hooks"
import {
  ProjectDataTypes,
  ProjectInterestFormProps,
  ProjectInterestKeysTypes,
} from "./types"

const ProjectInterestForm = ({
  onSuccess,
  submitText,
  accountData,
}: ProjectInterestFormProps) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const accountId = useAccountId()
  const queryClient = useQueryClient()

  const projectData: ProjectDataTypes = {
    interested_in_reforestation: accountData.interested_in_reforestation,
    interested_in_harvested_deferral:
      accountData.interested_in_harvested_deferral,
    interested_in_recreation: accountData.interested_in_recreation,
    interested_in_biodiversity: accountData.interested_in_biodiversity,
    interested_in_timber: accountData.interested_in_timber,
    interested_in_wildfire: accountData.interested_in_wildfire,
    interested_in_water: accountData.interested_in_water,
    interested_in_regen_ag: accountData.interested_in_regen_ag,
    interested_in_renewable_energy: accountData.interested_in_renewable_energy,
    interested_in_ncx_recommendations:
      accountData.interested_in_ncx_recommendations,
    interested_in_advisory_services:
      accountData.interested_in_advisory_services,
  }

  const [projects, setProjects] = useState<ProjectDataTypes>(projectData)

  const { mutateAsync: updateAccount, status: updateAccountStatus } =
    useUpdateAccount(queryClient, accountId, {
      onSuccess,
      onError: (error: { detail?: string }) => {
        setErrorMessage(error?.detail || genericErrMsg)
      },
    })

  const handleSubmit = async () => {
    setErrorMessage(null)

    const updatedAccountData = { ...accountData, ...projects }

    await updateAccount(updatedAccountData)
  }

  const onProjectClick = (project: ProjectInterestKeysTypes) => {
    setProjects((prev) => ({
      ...prev,
      [project]: !prev[project],
    }))
  }

  return (
    <div className="flex mb-10  md:mb-0 md:order-2 md:w-full md:grid-cols-3 justify-between">
      <div className="w-auto lg:max-w-[664px] md:order-1 md:col-span-full">
        <div>
          <p className="mb-2 text-sm text-charcoal-500 italic leading-[130%] tracking-[0.01em]">
            Select all programs you're interested in.
          </p>
        </div>

        <ProjectInterestComponent
          submitText={submitText}
          projects={projects}
          onProjectClick={onProjectClick}
          handleSubmit={() => {
            handleSubmit()
          }}
          errorMessage={errorMessage}
          status={updateAccountStatus}
        />
      </div>
    </div>
  )
}

export default ProjectInterestForm
