import { useEffect, useRef } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { DialogStateReturn } from "reakit/Dialog"
import cx from "classnames"

import { Modal } from "../../../components/Modal"
import { AlgorithmicRequirementsTypes } from "@/types/program"

export interface HowWeAssessYourLandModalProps {
  dialog: DialogStateReturn
  assessmentCriteria: AlgorithmicRequirementsTypes[] | undefined
}

const HowWeAssessYourLandModal = ({
  dialog,
  assessmentCriteria,
}: HowWeAssessYourLandModalProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  // DEV: Fix dialog body scrolling issue on iOS
  useEffect(() => {
    const scrollBox = scrollRef.current as HTMLDivElement

    if (dialog.visible) {
      disableBodyScroll(scrollBox)
    }

    return () => enableBodyScroll(scrollBox)
  }, [dialog.visible])

  return (
    <Modal
      header="How we assess your land"
      aria-label="How we assess your land"
      dialog={dialog}
      className="p-6"
    >
      <div ref={scrollRef} className="overflow-y-auto max-h-[236px] mb-6">
        <p className="text-base text-charcoal-500 leading-[130%] tracking-[0.32px] mb-4">
          NCX uses remote assessments to estimate eligibility for programs.
          Actual eligibility varies and will be determined by the program
          developer based on your land and other data.
        </p>

        <p className="text-base text-charcoal-500 leading-[130%] tracking-[0.32px]">
          For this program, we assessed:
        </p>

        <ul className="ul-custom px-6 mt-4">
          <li className="text-charcoal-500 text-base leading-[130%] tracking-[0.32px]">
            In program area
          </li>

          {assessmentCriteria?.map((item, i, arr) => (
            <li
              key={i}
              className={cx(
                "text-charcoal-500 text-base leading-[130%] tracking-[0.32px]",
                { "mb-0!": i === arr.length - 1 }
              )}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>

      <div className="-ml-2 -mr-2 -mb-2 pt-4">
        <button
          type="button"
          className="btn2 btn2-primary w-full md:w-auto font-semibold leading-[130%]"
          onClick={dialog.hide}
        >
          Got It
        </button>
      </div>
    </Modal>
  )
}

export default HowWeAssessYourLandModal
