import CheckmarkListItem from "./CheckmarkListItem"

export interface PremiumCheckmarkListProps {
  className?: string
}

const PremiumCheckmarkList = ({ className }: PremiumCheckmarkListProps) => {
  return (
    <ul className={className} data-testid="premium-checkmark-list">
      <CheckmarkListItem>Assigned Expert Advisor</CheckmarkListItem>

      <CheckmarkListItem className="mt-3">
        1-on-1 strategy session to understand your goals
      </CheckmarkListItem>

      <CheckmarkListItem className="mt-3">
        Personalized analysis of your land’s potential and local market
        conditions
      </CheckmarkListItem>

      <CheckmarkListItem className="mt-3">
        Documented plan so you know what to do, when to do it, and recommended
        programs to fund your goals
      </CheckmarkListItem>
    </ul>
  )
}

export default PremiumCheckmarkList
