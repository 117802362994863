import ScoreCardHeading from "./ScoreCardHeading"
import { Link, useLocation } from "react-router"

import { getScoreColorClass } from "./helpers"
import { useAccountUrlPrefix } from "../../../hooks"
import ScoreCardStateComparisonScore from "./ScoreCardStateComparisonScore"
import { stateOptions, USStateOption } from "../../../fixtures"
import { ComparisonType } from "@/types/property"
import LOCK from "../../../images/lock.svg"
import { getOrdinalSuffix } from "../../../shared/utils"

export interface ScoreCardItemStateComparisonProps {
  isMember: boolean | undefined
  currentScore: number | null
  comparisonData: ComparisonType | undefined
}

const ScoreCardStateComparison = ({
  isMember,
  currentScore,
  comparisonData,
}: ScoreCardItemStateComparisonProps) => {
  const location = useLocation()
  const accountUrlPrefix = useAccountUrlPrefix()
  const scoreColorClass = getScoreColorClass(currentScore ?? 0)

  if (!isMember) {
    return (
      <>
        <ScoreCardHeading className="flex items-center gap-2">
          <span>State Ranking</span>

          <img src={LOCK} alt="lock" height={16} width={16} />
        </ScoreCardHeading>

        <p className="text-dusk-900 text-sm text-left font-body font-medium leading-[140%] tracking-[0.42px] mt-2">
          <Link
            to={`${accountUrlPrefix}/become-a-member`}
            className="link scorecard-state-comparison-membership"
            state={{ scrollToTop: true, prevPath: location.pathname }}
          >
            Become a member
          </Link>{" "}
          to view detailed scores and unlock more insights
        </p>
      </>
    )
  }

  if (!comparisonData) {
    return null
  }

  const stateName = stateOptions.find(
    (stateOption: USStateOption) => stateOption[0] === comparisonData.state.name
  )?.[1]

  const rank = comparisonData?.state?.rank

  return (
    <>
      <ScoreCardHeading className="mt-4">State Ranking</ScoreCardHeading>
      <div className="mt-2">
        <div className="flex flex-col gap-4 md:flex-row md:justify-between">
          <div className="flex items-baseline min-w-fit">
            <p className="text-dusk-900 text-sm text-left font-body font-medium leading-[140%] tracking-[0.42px] mt-2">
              <span className="text[24px] font-bold leading-[1.40] tracking-[0.03em]">
                {rank}
                <sup>{rank && getOrdinalSuffix(rank)}</sup>
              </span>{" "}
              out of <strong>{comparisonData?.state?.rank_count}</strong> NCX
              landowner properties in {stateName}
            </p>
          </div>

          <div className="flex items-center gap-4">
            <ScoreCardStateComparisonScore
              scoreColorClass={scoreColorClass}
              score={Math.round(currentScore || 0)}
              text="Your Score"
            />

            <ScoreCardStateComparisonScore
              scoreColorClass="bg-dusk-200"
              score={Number(comparisonData?.state?.average.toFixed(1))}
              text="State Average"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ScoreCardStateComparison
