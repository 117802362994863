import { MouseEventHandler } from "react"
import { CloseButton } from "../../../components/CloseButton"
import {
  useProjectsStore,
  initialState,
} from "../../../stores/useProjectsStore"

export interface ProjectFiltersDrawerHeaderProps {
  handleFirstPageRedirect: () => void
  handleClose: MouseEventHandler<HTMLButtonElement>
}

const ProjectFiltersDrawerHeader = ({
  handleFirstPageRedirect,
  handleClose,
}: ProjectFiltersDrawerHeaderProps) => {
  const { setMultiState } = useProjectsStore()

  const handleSetMultistate = () => {
    setMultiState({
      govtProjectsOn: initialState.govtProjectsOn,
      ineligibleProjectsOn: initialState.ineligibleProjectsOn,
      hasLandownerCost: initialState.hasLandownerCost,
      termLengthFilter: initialState.termLengthFilter,
      paymentTypes: initialState.paymentTypes,
    })
    handleFirstPageRedirect()
  }

  return (
    <div className="flex bg-dusk-50 p-4">
      <button
        type="button"
        className="link hover:cursor-pointer focus:outline-hidden"
        onClick={handleSetMultistate}
      >
        Clear
      </button>

      <h4 className="grow text-center text-lg font-bold leading-[130%] tracking-[0.02em] mr-[0.625rem]">
        Filters
      </h4>

      <CloseButton
        className="shrink-0 ml-4 rounded-sm focus:outline-hidden text-charcoal-500 font-bold"
        aria-label="Close filters"
        onClick={handleClose}
      />
    </div>
  )
}

export default ProjectFiltersDrawerHeader
