import ToggleSwitch from "../../components/ToggleSwitch"
import NothingFound from "../../components/NothingFound"
import ProjectCard from "../../components/ProjectCard"
import { useFirstPageRedirect } from "../../hooks/useFirstPageRedirect"
import { useProjectsStore } from "../../stores/useProjectsStore"
import { ProjectDataTypes } from "../../types/program"
import SAVE_IMG from "../../images/save-program.gif"

interface ProjectCardsProps {
  pageSize: number
  pageId: string | undefined
  projectsData: ProjectDataTypes[]
  projectsDataIneligibleOnLength: number
  isMember: boolean | undefined
}

const ProjectCards = ({
  pageSize,
  pageId,
  projectsData,
  projectsDataIneligibleOnLength,
  isMember,
}: ProjectCardsProps) => {
  const {
    category,
    setCategory,
    ineligibleProjectsOn,
    setIneligibleProjectsOn,
    comparisonOn,
  } = useProjectsStore()

  const handleFirstPageRedirect = useFirstPageRedirect()

  let nothingFoundTitle: string = ""
  let nothingFoundText: string = ""
  let nothingFoundImg

  if (category === "saved_programs") {
    nothingFoundTitle = "No programs saved yet!"
    nothingFoundText =
      "When you view a program and save it, it will show up here."
    nothingFoundImg = SAVE_IMG
  } else if (projectsDataIneligibleOnLength === 0) {
    nothingFoundTitle = "No programs found"
    nothingFoundText =
      "There are no project opportunities with those filters. Clear a filter to try again."
  } else {
    nothingFoundTitle = "No eligible programs"
    nothingFoundText =
      "You're not eligible for any programs in this category. To see more, turn on ineligible programs."
  }

  if (projectsData.length === 0) {
    return (
      <NothingFound
        title={nothingFoundTitle}
        text={nothingFoundText}
        customImg={nothingFoundImg}
        linkText="View Dashboard"
        className="col-span-full py-[4.625rem]"
        component={
          category === "saved_programs" ? (
            <button
              type="button"
              className="link hover:cursor-pointer"
              onClick={() => setCategory(null)}
            >
              View All Programs
            </button>
          ) : projectsDataIneligibleOnLength > 0 ? (
            <div className="flex p-[0.4375rem] border border-dusk-50 rounded-sm order-3">
              <ToggleSwitch
                label="Ineligible programs"
                value={ineligibleProjectsOn}
                onChange={() => {
                  setIneligibleProjectsOn(!ineligibleProjectsOn)
                  handleFirstPageRedirect()
                }}
              />
            </div>
          ) : null
        }
        small
      />
    )
  }

  const projectsDataPage = projectsData.slice(
    (Number(pageId) - 1) * pageSize,
    (Number(pageId) - 1) * pageSize + pageSize
  )

  return projectsDataPage.map((program) => (
    <ProjectCard
      key={program.id}
      programData={program}
      compare={comparisonOn}
      isMember={isMember}
    />
  ))
}

export default ProjectCards
