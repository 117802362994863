export interface ProjectCardDescriptionProps {
  showMinimalInfo: boolean
  description: string
}

const ProjectCardDescription = ({
  showMinimalInfo,
  description,
}: ProjectCardDescriptionProps) => {
  if (showMinimalInfo) {
    return null
  }

  return (
    <div className="line-clamp-2 mb-[0.875rem]">
      <p className="text-base leading-[130%] tracking-[0.02em] mb-0">
        {description}
      </p>
    </div>
  )
}

export default ProjectCardDescription
