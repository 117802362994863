import HelperCard from "../../components/HelperCard"
import { useSPBStore } from "../../stores/useSPBStore"
import { AccountProperty } from "@/types/property"

export interface SPBPlanSummaryHelperCardProps {
  property: AccountProperty | undefined
}

const SPBPlanSummaryHelperCard = ({
  property,
}: SPBPlanSummaryHelperCardProps) => {
  const { selectedImplementation, setActiveStep, setSelectedImplementation } =
    useSPBStore()

  const woodyWetlandAcres = property?.landcover_data?.woody_wetland?.acres || 0
  const forestAcres = property?.landcover_data?.forest.acres || 0
  const forestedAcres = forestAcres + woodyWetlandAcres

  const handleOnClick = () => {
    setActiveStep(3)
    setSelectedImplementation("ncx_services")
  }

  if (selectedImplementation === "self" && forestedAcres <= 20) {
    return (
      <HelperCard className="!p-3">
        <p className="text-charcoal-500 text-sm leading-[140%] tracking-[0.28px]">
          Your forest size may make finding contractors difficult. NCX can help!
          <button
            type="button"
            className="spb-low-forest-acreage-request-ncx-services link hover:cursor-pointer"
            onClick={handleOnClick}
          >
            Request NCX assistance
          </button>{" "}
          so that we can identify like minded landowners to conduct the actions
          needed to achieve your goals.
        </p>
      </HelperCard>
    )
  }

  return null
}

export default SPBPlanSummaryHelperCard
