export interface SPBDynamicCheckoutCardItemProps {
  img: string
  alt: string
  heading: string
  text: string
}

const SPBDynamicCheckoutCardItem = ({
  img,
  alt,
  heading,
  text,
}: SPBDynamicCheckoutCardItemProps) => (
  <div className="flex gap-2 sm:items-center sm:gap-3">
    <div className="w-14 sm:w-26 shrink-0">
      <img src={img} alt={alt} className="block w-full h-auto" />
    </div>

    <div className="max-w-[450px]">
      <h4 className="font-body text-lg text-charcoal-900 font-semibold leading-[140%] tracking-[0.36px] mt-1 sm:mt-0">
        {heading}
      </h4>

      <p className="font-body text-charcoal-500 leading-[140%] tracking-[0.32px]">
        {text}
      </p>
    </div>
  </div>
)

export default SPBDynamicCheckoutCardItem
