const ThreatsMapLegend = () => (
  <div className="absolute bg-charcoal-500/[.71] p-3 bottom-4 right-4">
    <ul className="flex flex-col gap-2 text-white text-base font-body font-normal leading-[12px] tracking-[0.32px]">
      <li className="flex h-3 gap-1">
        <span className="block w-3 h-3 bg-[#E13530]" />
        Major loss
      </li>

      <li className="flex h-3 gap-1">
        <span className="block w-3 h-3 bg-[#228DC8]" />
        Minor loss
      </li>
    </ul>
  </div>
)

export default ThreatsMapLegend
