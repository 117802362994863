import { ReactNode } from "react"
import { Link } from "react-router"
import cx from "classnames"

import EMPTY_CARD from "../images/empty-card.svg"

interface NothingFoundLinkProps {
  to?: string
  linkText?: string
}

export interface NothingFoundProps {
  title: string
  text: ReactNode
  customImg?: string
  linkText?: string
  to?: string
  className?: string
  component?: ReactNode
  small?: boolean
}

const NothingFoundLink = ({ to, linkText }: NothingFoundLinkProps) => {
  if (!to) {
    return null
  }

  return (
    <Link to={to} className="btn2 btn2-primary font-semibold mb-12">
      {linkText}
    </Link>
  )
}

const NothingFound = ({
  title,
  text,
  customImg,
  linkText,
  to,
  className,
  component,
  small = false,
}: NothingFoundProps) => (
  <div className={cx(className ? className : "container py-12 md:py-32")}>
    <div
      className={cx(
        customImg ? "max-w-[700px]" : "max-w-[430px]",
        "flex flex-col items-center text-center mx-auto "
      )}
    >
      {customImg ? (
        <div className="w-full">
          <img src={customImg} alt={title} className="w-full h-auto" />
        </div>
      ) : (
        <div
          className={cx(
            "h-[222px] w-[222px] bg-grass-50 flex justify-center items-center rounded-full",
            small
              ? "md:h-[10.375rem] md:w-[10.375rem]"
              : "md:h-[320px] md:w-[320px]"
          )}
          data-testid={`nothing-found-img-container${small ? "-small" : ""}`}
        >
          <img
            src={EMPTY_CARD}
            alt={title}
            className={
              small ? "w-[92px] md:w-[4.375rem]" : "w-[92px] md:w-[134px]"
            }
          />
        </div>
      )}

      <h2 className="text-xl leading-[1.50] tracking-[0.03em] mt-8 mb-2">
        {title}
      </h2>

      <p className="font-normal text-dusk-500 mb-6">{text}</p>

      {component}

      <NothingFoundLink to={to} linkText={linkText} />
    </div>
  </div>
)

export default NothingFound
