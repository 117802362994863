import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

import IconText from "../../../components/IconText"

export interface ProjectDetailsHeroIconTextProps {
  icon: IconDefinition
  text: string
  show?: boolean
}

const ProjectDetailsHeroIconText = ({
  icon,
  text,
  show = true,
}: ProjectDetailsHeroIconTextProps) => {
  if (!show) {
    return null
  }

  return (
    <IconText
      icon={icon}
      className="text-sm text-dusk-700 mb-3 leading-6 tracking-[0.01em]"
      iconClass="text-base leading-[1.50] tracking-[0.02em] mr-1"
    >
      {text}
    </IconText>
  )
}

export default ProjectDetailsHeroIconText
