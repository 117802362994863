import { components, DropdownIndicatorProps } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown } from "@fortawesome/pro-duotone-svg-icons"

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props} data-testid="dropdown-indicator">
    <FontAwesomeIcon icon={faChevronDown} />
  </components.DropdownIndicator>
)

export default DropdownIndicator
