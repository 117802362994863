import { useNavigate } from "react-router"
import isEmpty from "lodash/isEmpty"
import cx from "classnames"

import OnboardingOverviewContainer from "./OnboardingOverviewContainer"
import OnboardingOverviewFooter from "./OnboardingOverviewFooter"
import ProjectCard from "../../../components/ProjectCard"
import { useAccountUrlPrefix } from "../../../hooks"
import { ProjectDataTypes } from "@/types/program"
import { OnboardingRecsType } from "@/types/onboardingRecs"

export interface OnboardingOverviewProgramsProps {
  onboardingRecs: OnboardingRecsType | undefined
  programsData: ProjectDataTypes[]
  handleOverviewStep: (newStep?: number) => void
}

const OnboardingOverviewPrograms = ({
  onboardingRecs,
  programsData,
  handleOverviewStep,
}: OnboardingOverviewProgramsProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const navigate = useNavigate()

  const programs: ProjectDataTypes[] = programsData.filter(
    (program) => program.id === 3 || program.id === 11 || program.id === 100
  )

  const noOnboardingRecs = isEmpty(onboardingRecs)
  const redirectToPaywall = () =>
    navigate(`${accountUrlPrefix}/become-a-member`, {
      replace: true,
    })

  const handleNext = () => {
    noOnboardingRecs ? redirectToPaywall() : handleOverviewStep()
  }

  return (
    <OnboardingOverviewContainer className="pb-[104px]">
      <div className="w-full flex flex-col-reverse xl:flex-row xl:justify-between gap-[30px] xl:gap-14 pt-[24px] xl:pt-24">
        <div className="grid md:grid-cols-3 gap-2 w-full">
          {programs.map((program) => (
            <ProjectCard
              key={program.id}
              programData={program}
              className={"xl:w-[218px]"}
              showMinimalInfo
              shortened
              disabled
            />
          ))}
        </div>

        <div className="xl:w-[500px] w-full">
          <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-[0.48px]">
            Generate income from your Assets with Programs.
          </h1>

          <p className="text-dusk-500 text-base font-body leading-[140%] tracking-[0.32px] mt-3">
            Programs are income opportunities for your assets. Through leasing
            or selling the rights to your assets for a period of time, you can
            turn your assets into cash.
          </p>
        </div>
      </div>

      <OnboardingOverviewFooter>
        <button
          className={cx("btn2 btn2-primary ml-auto", {
            "skip-to-paywall": noOnboardingRecs,
          })}
          onClick={handleNext}
        >
          Next
        </button>
      </OnboardingOverviewFooter>
    </OnboardingOverviewContainer>
  )
}

export default OnboardingOverviewPrograms
