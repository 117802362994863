import { ReactNode } from "react"
import cx from "classnames"

export interface ScoreCardDescriptionProps {
  text: ReactNode
  className?: string
}

const ScoreCardDescription = ({
  text,
  className,
}: ScoreCardDescriptionProps) => (
  <p
    className={cx(
      "text-dusk-700 text-xs text-left font-body leading-[130%] tracking-[0.36px]",
      className
    )}
  >
    {text}
  </p>
)

export default ScoreCardDescription
