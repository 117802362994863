import { Link } from "react-router"

import HelperCard from "../../components/HelperCard"
import { useAccountUrlPrefix } from "../../hooks"

const ThreatsText = () => {
  const accountUrlPrefix = useAccountUrlPrefix()

  return (
    <div className="w-full xl:max-w-[466px]">
      <div className="space-y-p my-4">
        <p>
          Loss Detection alerts you when a new tree loss event occurs on your
          land. Detection updates weekly with new confirmed disturbances to your
          forested land based on satellite imagery.
        </p>

        <p>
          Major severity loss means total tree loss was observed. Minor severity
          loss means partial tree loss was observed.
        </p>

        <p>
          Learn more about{" "}
          <a
            className="link"
            href="https://ncx.com/learning-hub/protect-your-land-with-loss-detection/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Loss Detection
          </a>
          .
        </p>
      </div>

      <HelperCard className="mt-8 xl:mt-16">
        Not seeing all your land?{" "}
        <Link
          to={{
            pathname: `${accountUrlPrefix}/settings/property-boundaries`,
          }}
          state={{ from: { pathname: `${accountUrlPrefix}/threats` } }}
          className="add-more-parcels-link link"
        >
          Add more parcels
        </Link>
      </HelperCard>

      <HelperCard className="mt-4">
        Looking for loss events that may have occurred 1 month prior to the
        detection date? Request historical loss detection data by submitting a
        request to{" "}
        <a
          className="link"
          href="mailto:landowners@ncx.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          landowners@ncx.com
        </a>
        .
      </HelperCard>
    </div>
  )
}

export default ThreatsText
