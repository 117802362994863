import {
  Popover as PopoverBase,
  PopoverDisclosure,
  PopoverArrow,
  PopoverStateReturn,
} from "reakit/Popover"
import cx from "classnames"
import { ReactNode } from "react"

export interface PopoverTypes {
  popover: PopoverStateReturn
  label: ReactNode
  content?: ReactNode
  className?: string
  disclosureClassName?: string
  children: ReactNode
  ariaLabel?: string
}

const Popover = ({
  popover,
  label = "Popover",
  className,
  disclosureClassName,
  children,
  content,
  ariaLabel = "Popover",
}: PopoverTypes) => (
  <>
    <PopoverDisclosure
      {...popover}
      className={cx(
        "text-dusk-500 text-sm tracking-[0.14px] underline whitespace-nowrap hover:cursor-pointer",
        disclosureClassName
      )}
      data-testid="popover-disclosure"
    >
      {content || label}
    </PopoverDisclosure>

    {popover.visible ? (
      <PopoverBase
        {...popover}
        tabIndex={0}
        aria-label={ariaLabel}
        className={cx(
          "bg-charcoal-500 text-white rounded-sm ring-0 shadow-md p-3",
          className
        )}
      >
        <PopoverArrow {...popover} size={20} className="fill-charcoal-500" />

        {children}
      </PopoverBase>
    ) : null}
  </>
)

export default Popover
