import { useRef, useEffect } from "react"
import { Dialog, useDialogState } from "reakit/Dialog"

import ModalBackdrop from "../../components/ModalBackdrop"

export interface InboxModalProps {
  showDialog: boolean
  onCloseDialog: () => void
}

const InboxModal = ({ showDialog, onCloseDialog }: InboxModalProps) => {
  const dialogRef = useRef<HTMLDivElement | null>(null)
  const dialog = useDialogState({
    animated: true,
    visible: showDialog === null ? true : showDialog,
    modal: false,
  })

  useEffect(() => {
    const close = (e: MouseEvent) => {
      const target = e.target as Node
      if (dialogRef.current && !dialogRef.current.contains(target)) {
        onCloseDialog()
      }
    }
    document.body.addEventListener("click", close)

    return () => document.body.removeEventListener("click", close)
  }, [onCloseDialog])

  useEffect(() => {
    if (dialog.visible) {
      dialogRef?.current?.focus()
    }
  }, [dialog.visible])

  return (
    <ModalBackdrop dialog={dialog}>
      <Dialog
        {...dialog}
        ref={dialogRef}
        tabIndex={dialog.visible ? 0 : -1}
        aria-label="Welcome to your new Inbox"
        className="st-dialog transition delay-700 duration-300 ease-in-out p-5"
        hideOnEsc={false}
        hideOnClickOutside={true}
      >
        <div>
          <h4 className="text-xl leading-tight tracking-[0.03em]">
            Welcome to your new Inbox
          </h4>

          <p className="text-base leading-[1.50] tracking-[0.02em] mt-4">
            With the new messaging inbox, you'll get exclusive program
            invitations from partners and important account updates from NCX.
          </p>

          <button
            type="button"
            className="btn2 btn2-primary w-full md:w-auto font-semibold mt-4"
            onClick={() => {
              dialog.hide()
              onCloseDialog()
            }}
          >
            Explore Inbox
          </button>
        </div>
      </Dialog>
    </ModalBackdrop>
  )
}

export default InboxModal
