import SearchField from "../../components/SearchField"
import { useFirstPageRedirect } from "../../hooks/useFirstPageRedirect"
import { useProjectsStore } from "../../stores/useProjectsStore"

const PageHeroSearch = () => {
  const handleFirstPageRedirect = useFirstPageRedirect()
  const { searchProjects, setSearchProjects } = useProjectsStore()

  const handleOnChange = (value: string) => {
    setSearchProjects(value)
    handleFirstPageRedirect()
  }

  return (
    <div className="max-w-full w-[343px] mx-auto mt-6">
      <SearchField
        id="search-projects"
        initialValue={searchProjects}
        onChange={handleOnChange}
        placeholder="Search by keyword or program type"
        className="order-1"
      />
    </div>
  )
}

export default PageHeroSearch
