import { ReactNode } from "react"
import cx from "classnames"

export interface ContentCardProps {
  className?: string
  children: ReactNode
  roundedMd?: boolean
}

const ContentCard = ({
  className,
  roundedMd = false,
  children,
}: ContentCardProps) => (
  <div
    className={cx(
      "bg-white",
      roundedMd ? "rounded-md" : "rounded-sm",
      className
    )}
    data-test="content-card"
    data-testid="content-card"
  >
    <div className="p-4 md:px-12 md:py-6">{children}</div>
  </div>
)

export default ContentCard
