export interface ProgressBarTypes {
  value: number
  max: number
  className?: string
}

export const ProgressBar = ({ value, max, className }: ProgressBarTypes) => {
  return (
    <progress
      className={className}
      value={value}
      max={max}
      data-testid="progress-bar"
    />
  )
}
