import { useEffect, useState } from "react"
import { AxiosError } from "axios"
import { useLocation } from "react-router"
import { useQueryClient } from "@tanstack/react-query"

import BecomeAMemberWidgetOption from "./BecomeAMemberWidgetOption"
import BecomeAMemberToggle from "./BecomeAMemberToggle"
import BecomeAMemberWidgetOptionList from "./BecomeAMemberWidgetOptionList"
import BecomeAMemberWidgetJoin from "./BecomeAMemberWidgetJoin"
import CornerRibbon from "../../components/CornerRibbon"
import PremiumCheckmarkList from "../../components/PremiumCheckmarkList "
import { Toast } from "../../components/Toast"
import { useAccount, useAccountId, useCreateStripeSession } from "../../hooks"
import { genericErrMsg } from "../../api/auth"
import { formatPrice, isDateAndState } from "../../shared/utils"
import { AccountTypes } from "@/types/account"

export interface BecomeAMemberWidgetProps {
  joinBtnClass?: string
}

const BecomeAMemberWidget = ({ joinBtnClass }: BecomeAMemberWidgetProps) => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const location = useLocation()
  const [isPremium, setIsPremium] = useState<boolean>(false)
  const [isMonthly, setIsMonthly] = useState<boolean>(true)
  const { prevPath } = location.state ?? { prevPath: "/" }

  const { data: account, isLoading: accountIsLoading } = useAccount<
    AccountTypes,
    Error
  >(queryClient, accountId)

  const { mutateAsync: createStripeSession } = useCreateStripeSession(
    queryClient,
    accountId,
    {
      onSuccess: (data: { url: string }) => {
        window.location.href = data.url
      },
      onError: (error) => {
        const axiosError = error as AxiosError
        Toast.error(axiosError?.message || genericErrMsg)
      },
    }
  )

  const handleStripeSubmit = async () => {
    const formattedPrevPath = prevPath?.startsWith("/")
      ? prevPath.substring(1)
      : prevPath

    const postData = {
      lookup_key: isPremium || !isMonthly ? "basic_annual" : "basic_monthly",
      addon_keys: isPremium ? ["nca_onetime"] : [],
      redirect_path: formattedPrevPath,
    }

    await createStripeSession(postData)
  }

  const handleIsMontly = () => {
    setIsMonthly((oldIsMonthly) => !oldIsMonthly)
  }

  useEffect(() => {
    if (location.state?.includeAdvisor || account?.is_member) {
      setIsPremium(true)
    }
  }, [account?.is_member, location.state])

  if (accountIsLoading || !account) {
    return null
  }

  const isMS = isDateAndState(
    "2025-09-30T23:59:59",
    "MS",
    account?.primary_property_state
  )

  const priceYearly = formatPrice(account?.subscription_pricing.basic_annual)
  let price = isMS
    ? "$0"
    : formatPrice(account?.subscription_pricing.basic_monthly)

  if (isPremium) {
    price = formatPrice(
      account?.subscription_pricing.nca_onetime +
        account?.subscription_pricing.basic_annual
    )
  } else if (!isMonthly) {
    price = formatPrice(account?.subscription_pricing.basic_annual / 12)
  }

  const isHighAcreage = account?.subscription_pricing.nca_onetime === null

  return (
    <div className="w-full max-w-[916px] mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <BecomeAMemberWidgetOption
          heading="Basic"
          subheading="The Membership Every Landowner Needs"
          subheadingClass="w-[calc(100%-50px)]"
          enabled={!account?.is_member}
          active={!isPremium}
          onClick={() => {
            !account?.is_member ? setIsPremium(false) : null
          }}
        >
          <CornerRibbon text="Most Popular" />

          <BecomeAMemberWidgetOptionList />
        </BecomeAMemberWidgetOption>

        <BecomeAMemberWidgetOption
          heading="Premium"
          subheading="Includes 1 year of Basic, plus:"
          active={isPremium}
          onClick={() => {
            setIsPremium(true)
          }}
        >
          <PremiumCheckmarkList className="mt-5" />
        </BecomeAMemberWidgetOption>
      </div>

      <BecomeAMemberToggle
        show={!isPremium}
        selection={isMonthly}
        setSelection={handleIsMontly}
      />

      <BecomeAMemberWidgetJoin
        price={price}
        isPremium={isPremium}
        isMonthly={isMonthly}
        priceYearly={priceYearly}
        isMS={isMS}
        isHighAcreage={isHighAcreage}
        handleStripeSubmit={() => {
          handleStripeSubmit()
        }}
        role={account?.role}
        className="mt-4"
        joinBtnClass={joinBtnClass}
      />
    </div>
  )
}

export default BecomeAMemberWidget
