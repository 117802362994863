import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { Toast } from "../../components/Toast"
import SPBDynamicCheckoutCard from "./SPBDynamicCheckoutCard"
import SPBRiskNotification from "./SPBRiskNotification"
import SPBDynamicCheckoutInfo from "./SPBDynamicCheckoutInfo"
import { useAccountId, useCreateStripeSession } from "../../hooks"
import { formatPrice, isDateAndState } from "../../shared/utils"
import { genericErrMsg } from "../../api/auth"
import { AccountTypes } from "@/types/account"
import { SPBWizardType } from "@/types/wizards"

export interface SPBDynamicCheckoutProps {
  account: AccountTypes | undefined
  wizardData: SPBWizardType | undefined
}

const SPBDynamicCheckout = ({
  account,
  wizardData,
}: SPBDynamicCheckoutProps) => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const { mutateAsync: createStripeSession } = useCreateStripeSession(
    queryClient,
    accountId,
    {
      onSuccess: (data: { url: string }) => {
        window.location.href = data.url
      },
      onError: (error) => {
        const axiosError = error as AxiosError
        Toast.error(axiosError?.message || genericErrMsg)
      },
    }
  )

  const handleStripeSubmit = async () => {
    const postData = {
      lookup_key: "basic_monthly",
      addon_keys: [],
      redirect_path: "checkup/spb",
    }

    await createStripeSession(postData)
  }

  const isMS = isDateAndState(
    "2025-09-30T23:59:59",
    "MS",
    account?.primary_property_state
  )

  const price = formatPrice(account?.subscription_pricing.basic_monthly)

  if (account?.is_member) {
    return null
  }

  return (
    <div className="fixed inset-0 perspective-midrange bg-gray-500/75 overflow-auto z-2147483642">
      <div className="bg-white pt-[30px] pb-[45px] mt-[calc(100dvh-200px)] sm:mt-[calc(100dvh-278px)]">
        <div className="container max-w-[1244px] px-4">
          <h1 className="text-2xl md:text-4xl text-charcoal-500 text-center font-display font-medium leading-[124%] tracking-[0.72px]">
            Sign up to get your personalized action plan
          </h1>

          <h2 className="text-autumn text-xl md:text-2xl text-center font-medium leading-[124%] tracking-[0.48px] mt-[14px]">
            {isMS ? (
              <>
                <span className="font-semibold">$0 for your action plan</span> +
                all NCX benefits (normally $12/mo)*
              </>
            ) : (
              `Get all NCX benefits for only ${price}/month`
            )}
          </h2>
        </div>

        <div className="container max-w-[752px] mt-7 px-4">
          <SPBDynamicCheckoutCard
            role={account?.role}
            isMS={isMS}
            price={price}
            handleStripeSubmit={() => {
              handleStripeSubmit()
            }}
          />

          {isMS ? (
            <p className="text-charcoal-500 text-xs sm:text-center text-wrap leading-[140%] tracking-[0.36px] mt-1">
              *NCX is able to offer free membership to Mississippi landowners
              until Sept 2025 through a grant from NRCS.
            </p>
          ) : null}

          <SPBRiskNotification
            wizardData={wizardData}
            className="mt-[26px] mx-7"
          />

          <SPBDynamicCheckoutInfo isMS={isMS} />
        </div>
      </div>
    </div>
  )
}

export default SPBDynamicCheckout
