import IconButton from "../../../components/IconButton"
import { faPen } from "@fortawesome/pro-solid-svg-icons"

export interface ProjectDetailsHeroEditLandDetailsProps {
  show: boolean
  onClick: () => void
}

const ProjectDetailsHeroEditLandDetails = ({
  show,
  onClick,
}: ProjectDetailsHeroEditLandDetailsProps) => {
  if (!show) {
    return null
  }

  return (
    <IconButton
      onClick={onClick}
      icon={faPen}
      text="Edit Land Details"
      className="mb-3"
    />
  )
}

export default ProjectDetailsHeroEditLandDetails
