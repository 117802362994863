import { ReactNode } from "react"
import { DialogDisclosure, DialogStateReturn } from "reakit"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

export interface ScoreCardDisclosureProps {
  dialog: DialogStateReturn
  children: ReactNode
  onClick?: () => void
  ariaLabel: string
}

const ScoreCardDisclosure = ({
  dialog,
  children,
  onClick,
  ariaLabel,
}: ScoreCardDisclosureProps) => (
  <DialogDisclosure
    {...dialog}
    className={cx(
      "relative flex w-full border border-dusk-50 rounded-lg transition-shadow sm:pt-4 px-6 pb-3 sm:mt-0 mt-4",
      "hover:shadow-[0_4px_8px_0px_rgba(0,0,0,0.08)]"
    )}
    onClick={onClick}
    aria-label={ariaLabel}
  >
    {children}

    <div className="absolute right-0 top-0 bottom-0 flex items-center justify-center w-6">
      <FontAwesomeIcon icon={faAngleRight} size="sm" color="#979CA6" />
    </div>
  </DialogDisclosure>
)

export default ScoreCardDisclosure
