import { ProjectBadge } from "../ProjectBadge"
import { ProjectBadgeType } from "@/types/constants"

export interface ProjectCardStatusBadgeProps {
  showMinimalInfo: boolean
  badgeType: ProjectBadgeType
  badgeText: string
}

const ProjectCardStatusBadge = ({
  showMinimalInfo,
  badgeType,
  badgeText,
}: ProjectCardStatusBadgeProps) => {
  if (showMinimalInfo) {
    return null
  }

  return (
    <div className="mb-3">
      <ProjectBadge type={badgeType} size="sm">
        {badgeText}
      </ProjectBadge>
    </div>
  )
}

export default ProjectCardStatusBadge
