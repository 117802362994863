import { Table } from "@tanstack/react-table"

import { GetTableDataReturn } from "./types"

export interface RoiTablePaginationProps {
  show: boolean
  numShowing: number
  table: Table<GetTableDataReturn>
}

const RoiTablePagination = ({
  show,
  numShowing,
  table,
}: RoiTablePaginationProps) => {
  if (!show) {
    return null
  }

  return (
    <div className="mt-2">
      <p className="text-sm text-dusk-500 leading-[130%] tracking-[0.14px]">
        Showing {numShowing - 9}-{numShowing} of{" "}
        {Object.keys(table.getRowModel().rowsById).length} rows
      </p>

      <div className="flex gap-6 mt-[0.3125rem]">
        <button
          type="button"
          className="text-base text-leaf disabled:text-dusk-300 font-semibold leading-[130%] tracking-[0.32px]"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </button>

        <button
          type="button"
          className="text-base text-leaf disabled:text-dusk-300 font-semibold leading-[130%] tracking-[0.32px]"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default RoiTablePagination
