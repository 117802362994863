import {
  ProjectInterestComponentProps,
  ProjectInterestKeysTypes,
} from "./types"
import ErrorCard from "../../components/ErrorCard"
import ProjectTypeHolder from "./ProjectTypeHolder"
import { PROJECT_INTEREST } from "../../shared/constants"
import { SubmitButton } from "../../components/SubmitButton"

const ProjectInterestComponent = ({
  submitText,
  projects,
  onProjectClick,
  handleSubmit,
  errorMessage,
  status,
}: ProjectInterestComponentProps) => (
  <div className="grid grid-cols-1 md:gap-y-2">
    {errorMessage && <ErrorCard className="mb-6">{errorMessage}</ErrorCard>}

    <div className="flex gap-x-2 gap-y-4 flex-wrap md:pr-20 mb-2">
      {Object.keys(projects).map((project) => (
        <ProjectTypeHolder
          key={project}
          onClick={() =>
            onProjectClick(project as ProjectInterestKeysTypes, projects)
          }
          isSelected={projects[project as keyof typeof projects]}
          text={PROJECT_INTEREST[project as keyof typeof PROJECT_INTEREST]}
        />
      ))}
    </div>

    <p>
      <SubmitButton
        disabled={!Object.values(projects).includes(true)}
        className="btn2 btn2-primary font-semibold"
        onClick={() => handleSubmit()}
        isSubmitting={status === "pending"}
      >
        {submitText}
      </SubmitButton>
    </p>
  </div>
)

export default ProjectInterestComponent
