import { Link } from "react-router"

import { useAccountId, useIsMultiAccount } from "../../../hooks"
import ScoreCardDialogActionsGuideURL from "./ScoreCardDialogActionsGuideURL"
import { SCORE_CARDS } from "./constants"
import { getCategoryFromType } from "./helpers"

export interface ScoreCardDialogActionsProps {
  title: string
  activeScoreCardItem: string
}

const ScoreCardDialogActions = ({
  title,
  activeScoreCardItem,
}: ScoreCardDialogActionsProps) => {
  const accountId = useAccountId()
  const isMultiAccount = useIsMultiAccount()
  const category = getCategoryFromType(activeScoreCardItem)

  const guideURL = SCORE_CARDS[activeScoreCardItem].guideURL

  return (
    <div className="flex flex-col md:flex-row-reverse md:justify-between w-full mt-3 items-center">
      <Link
        to={{
          pathname: isMultiAccount
            ? `/accounts/${accountId}/programs/page/1`
            : "/programs/page/1",
          search: `?category=${category}`,
        }}
        className="btn2 btn2-outline-primary font-bold mb-2 md:mb-0"
      >
        View All Programs
      </Link>

      <ScoreCardDialogActionsGuideURL guideURL={guideURL} title={title} />
    </div>
  )
}

export default ScoreCardDialogActions
