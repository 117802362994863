import SPBMap from "./SPBMap"
import { useSPBStore } from "../../stores/useSPBStore"
import { TileUrlsType } from "@/types/wizards"
import { TileURLsTypes } from "@/types/tiles"

export interface SPBSelectApproachModalContentProps {
  visible: boolean
  tileURLs: TileUrlsType | undefined
  hide: () => void
  handleContinue: () => void
}

const SPBSelectApproachModalContent = ({
  visible = false,
  tileURLs,
  hide,
  handleContinue,
}: SPBSelectApproachModalContentProps) => {
  const { selectedActionGroup } = useSPBStore()

  if (!visible || selectedActionGroup === null || tileURLs === undefined) {
    return null
  }

  const tileData = {
    url: tileURLs[selectedActionGroup],
  } as TileURLsTypes

  return (
    <div className="flex flex-col gap-4">
      <SPBMap
        tileData={tileData}
        lowText="Commercial Harvest"
        moderateText="Thinning"
        highText="Monitor"
        description="This map shows recommended actions and approximately where on your land to implement them for improved SPB resilience. This is not a harvest plan. If you do not see any color on the map, there is no action to take."
      />

      <div className="w-full flex flex-col-reverse md:flex-row justify-end gap-4 ml-auto">
        <button
          type="button"
          className="spb-select-approach-modal-go-back btn2 btn2-outline-primary font-bold tracking-[0.32px]"
          onClick={hide}
        >
          Go Back
        </button>

        <button
          type="button"
          className="spb-select-approach-modal-save-and-continue btn2 btn2-primary font-bold tracking-[0.32px]"
          onClick={handleContinue}
        >
          Save and Continue
        </button>
      </div>
    </div>
  )
}

export default SPBSelectApproachModalContent
