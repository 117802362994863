import { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

interface BackToTopTypes {
  scrollY: number
}

const BackToTop = ({ scrollY = 200 }: BackToTopTypes) => {
  const [isVisible, setIsVisible] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollY) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [scrollY])

  return (
    <div
      className={cx(
        "hidden sm:flex sm:flex-col sm:items-center sm:gap-1.5 sm:fixed sm:left-9 sm:bottom-9 lg:bottom-5 sm:opacity-0 sm:pointer-events-none sm:z-2",
        {
          "sm:opacity-100 sm:!pointer-events-auto": isVisible,
        }
      )}
      data-testid="back-to-top"
    >
      <button
        type="button"
        aria-label="back to top"
        className={cx(
          "h-[2.75rem] w-[2.75rem] flex items-center justify-center cursor-pointer",
          "bg-grass-700 hover:bg-grass-900 rounded-full",
          "font-black text-white text-base leading-[130%] tracking-[0.32px]",
          "transition ease-in-out duration-150 focus:outline-hidden focus:ring-3 focus:ring-blue-400/[.45]"
        )}
        onClick={scrollToTop}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button>

      <span className="lg:block text-base text-charcoal-500 leading-6 tracking-[0.32px] pointer-events-none hidden">
        Back to top
      </span>
    </div>
  )
}

export default BackToTop
