import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import { Toast } from "../../../components/Toast"
import { SubmitButton } from "../../../components/SubmitButton"
import ProjectComparisonModalRecommendation from "./ProjectComparisonModalRecommendation"
import ProjectComparisonModalCardContainer from "./ProjectComparisonModalCardContainer"
import { formatCurrency } from "../../../utils"
import { useAccountId, useSaveAccountProject } from "../../../hooks"
import { AccountProjectDataTypes, ProjectDataTypes } from "@/types/program"
import { ProsConsEntryType, RecommendationType } from "@/types/comparison"
import { AxiosError } from "axios"
import { MouseEvent } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"

interface ProjectComparisonModalCardProps {
  program: ProjectDataTypes
  prosConsEntryData: ProsConsEntryType | undefined
  recommendation: RecommendationType | null
  className?: string
  disabled?: boolean
  onSaveProject?: (program: ProjectDataTypes) => void
  saveBtnClass?: string
}

const ProjectComparisonModalCard = ({
  program,
  prosConsEntryData,
  recommendation,
  className,
  disabled = false,
  onSaveProject,
  saveBtnClass,
}: ProjectComparisonModalCardProps) => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const {
    mutateAsync: saveAccountProject,
    isPending: isSaveAccountProjectPending,
  } = useSaveAccountProject(queryClient, accountId, program.id.toString(), {
    onSuccess: (data: AccountProjectDataTypes) => {
      Toast.success("Program has been saved.")

      if (onSaveProject) {
        onSaveProject({ ...program, is_saved: data.is_saved })
      }
    },
    onError: (error) => {
      const err = error as AxiosError

      Toast.error(err?.message || "An error occurred while saving program.")
    },
  })

  const handleSaveAccountProject = async () => {
    await saveAccountProject({ save: true })
  }

  const termText = program?.term
    ? `${program?.term} year term`
    : "Term length varies"

  const potentialEarningsText = program?.potential_earnings
    ? `${formatCurrency(
        program.potential_earnings
      )} per acre for the total contract term length`
    : "Earnings vary"

  return (
    <ProjectComparisonModalCardContainer
      reason={recommendation?.reason}
      id={program.id}
      className={className}
      disabled={disabled}
    >
      <h3 className="text-charcoal-900 text-lg font-body font-bold leading-[140%] tracking-[0.36px]">
        {program.name}
      </h3>

      <h4 className="text-dusk-500 text-base font-body font-normal leading-[140%] tracking-[0.32px] mt-2">
        Potential earnings
      </h4>

      <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-[0.32px] mt-2">
        {potentialEarningsText}
      </p>

      <h4 className="text-dusk-500 text-base font-body font-normal leading-[140%] tracking-[0.32px] mt-3">
        Term length
      </h4>

      <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-[0.32px] mt-2">
        {termText}
      </p>

      {prosConsEntryData ? (
        <>
          <h4 className="text-dusk-500 text-base font-body font-normal leading-[140%] tracking-[0.32px] mt-3">
            Pros
          </h4>

          <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-[0.32px] mt-2">
            {prosConsEntryData?.pros}
          </p>

          <h4 className="text-dusk-500 text-base font-body font-normal leading-[140%] tracking-[0.32px] mt-3">
            Cons
          </h4>

          <p className="text-charcoal-500 text-base font-body leading-[140%] tracking-[0.32px] mt-2">
            {prosConsEntryData?.cons}
          </p>
        </>
      ) : null}

      <ProjectComparisonModalRecommendation recommendation={recommendation} />

      {program?.is_saved ? (
        <p className="h-[42px] w-full flex justify-center items-center gap-1 text-grass-500 text-lg font-bold leading-[140%] tracking-[0.36px] mt-4">
          <FontAwesomeIcon icon={faCircleCheck} size="lg" />
          Saved
        </p>
      ) : (
        <div>
          <SubmitButton
            className={cx(
              saveBtnClass,
              "btn2 btn2-outline-primary w-full font-bold mt-4"
            )}
            onClick={(e: MouseEvent<HTMLElement>) => {
              e.preventDefault()
              handleSaveAccountProject()
            }}
            isSubmitting={isSaveAccountProjectPending}
            typeButton
          >
            Save Program
          </SubmitButton>
        </div>
      )}
    </ProjectComparisonModalCardContainer>
  )
}

export default ProjectComparisonModalCard
