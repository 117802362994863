import { useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { Link } from "react-router"
import { findKey } from "lodash"

import ContentCard from "../../components/ContentCard"
import HelperCard from "../../components/HelperCard"
import LandProfileQuizCard from "./LandProfileQuizCard"
import { allProgramTypes } from "../Onboarding/helpers"
import { useAccountUrlPrefix } from "../../hooks"
import { EligibilityQuizzes } from "@/types/eligibility"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "../../types/constants"

interface LandProfileTypes {
  quizzesData: EligibilityQuizzes
}

const LandProfile = ({ quizzesData }: LandProfileTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()

  const quizCardData = useMemo(() => {
    return allProgramTypes
      .map((type) => {
        const quizCategory = findKey(
          quizzesData,
          (d) => d.project_type === type
        ) as EligibilityQuizCategoryType

        const completed = quizzesData[quizCategory]?.completed
        const numEligiblePrograms =
          quizzesData[quizCategory]?.num_eligible_projects

        let potentialEarnings: number | null | undefined = 0 // default value
        if (type === ProgramCategoryType.timber) {
          potentialEarnings =
            quizzesData[quizCategory]?.project_type_stats
              ?.potential_earnings_per_acre?.avg
        } else if (numEligiblePrograms > 0) {
          potentialEarnings = quizzesData[quizCategory]?.project_type_stats
            ?.potential_earnings_per_acre?.max as number
        }

        return {
          quizCategory,
          completed,
          projectType: type,
          potentialEarnings,
          numEligiblePrograms,
        }
      })
      .filter((d) => {
        return Boolean(d.quizCategory) && d.quizCategory !== "advisory_services"
      })
      .sort((a, b) => {
        return a.potentialEarnings === b.potentialEarnings
          ? 0
          : a.potentialEarnings
            ? -1
            : 1
      })
  }, [quizzesData])

  return (
    <ContentCard className="shadow-sm" roundedMd>
      <h4 className="text-overline">Programs</h4>

      <p className="text-charcoal-500 text-base leading-[130%] tracking-[0.32px] mt-2">
        Select a category to check your eligibility, discover potential
        earnings, and view eligible programs.
      </p>

      <div className="grid grid-cols-1 xl:grid-cols-2 3_5xl:grid-cols-3 gap-6 mt-4">
        <Link
          className="saved-programs-card cursor-pointer px-6 py-3 rounded-sm bg-grass-700 bg-cover bg-no-repeat"
          to={`${accountUrlPrefix}/programs/page/1?category=saved_programs`}
          aria-label="Saved Programs"
        >
          <div className="w-full flex justify-between">
            <h3 className="text-white text-base leading-[140%] tracking-[0.32px]">
              Saved Programs
            </h3>

            <p className="text-white text-base w-[18px] h-[18px]">
              <FontAwesomeIcon icon={faCircleCheck} />
            </p>
          </div>

          <p className="text-white text-left text-xs leading-[140%] tracking-[0.36px] mt-1">
            Review your saved programs.
          </p>
        </Link>

        {quizCardData.map((cardData) => {
          return cardData.quizCategory ? (
            <LandProfileQuizCard
              key={cardData.projectType}
              quizCategory={cardData.quizCategory}
              completed={cardData.completed}
              projectType={cardData.projectType}
              potentialEarnings={cardData.potentialEarnings}
              className={`your-ncp-quiz-card your-ncp-${cardData.projectType}`}
            />
          ) : null
        })}
      </div>

      <HelperCard className="mt-4">
        All value estimates are the maximum potential earnings projections based
        on program offers. Estimates are not a guarantee of returns and are not
        financial advice.
      </HelperCard>
    </ContentCard>
  )
}

export default LandProfile
