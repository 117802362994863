import { ReactNode } from "react"
import cx from "classnames"

export interface IconCardProps {
  className?: string
  children: ReactNode
  icon: ReactNode
  dataTest?: string
}

const IconCard = ({ className, icon, dataTest, children }: IconCardProps) => (
  <div
    className={cx("space-y-4", className)}
    data-test={dataTest}
    data-testid={dataTest}
  >
    <div className="flex items-start gap-x-4">
      <div>{icon}</div>
      <div className="flex-1 leading-5">{children}</div>
    </div>
  </div>
)

export default IconCard
