import { Link } from "react-router"
import cx from "classnames"

import RecommendationsLoader from "../../../components/RecommendationsLoader"
import ProjectComparisonModalCard from "../../ProjectListing/ProjectComparison/ProjectComparisonModalCard"
import ProjectComparisonModalSlider from "../../ProjectListing/ProjectComparison/ProjectComparisonModalSlider"
import OnboardingOverviewFooter from "./OnboardingOverviewFooter"
import useMediaQuery from "../../../hooks/useMediaQuery"
import { useAccountUrlPrefix } from "../../../hooks"
import { ProsConsDataType } from "@/types/comparison"
import { ProgramType, ProjectDataTypes } from "@/types/program"

export interface OnboardingOverviewRecommendedProgramsProps {
  programs: ProgramType[]
  compareData: ProsConsDataType | undefined
  programsData: ProjectDataTypes[]
  isGetComparePending: boolean
}

const OnboardingOverviewRecommendedPrograms = ({
  programs,
  compareData,
  programsData,
  isGetComparePending,
}: OnboardingOverviewRecommendedProgramsProps) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const isMaxWidthMd = useMediaQuery("(max-width: 767px)")

  const programIds = programs.map((program) => program.id)

  const newPrograms = programsData.filter((program) =>
    programIds.includes(program.id)
  )

  if (isGetComparePending && compareData === undefined) {
    return <RecommendationsLoader />
  }

  if (isMaxWidthMd) {
    return (
      <ProjectComparisonModalSlider
        compareData={compareData}
        comparison={newPrograms}
        className="pt-8"
        customButton={
          <Link
            to={`${accountUrlPrefix}/become-a-member`}
            className="btn2 btn2-primary ml-auto"
            state={{
              scrollToTop: true,
              joinBtnClass: "onboarding",
              subHeading:
                "Get full access to personalized recommendations and advice",
            }}
          >
            Next
          </Link>
        }
        saveBtnClass="onboarding-save-program-button"
        cardsDisabled
      />
    )
  }

  return (
    <div className="container pt-6 sm:pt-9 pb-8 sm:pb-[76px] mx-auto">
      <h1 className="text-charcoal-500 text-2xl font-body font-medium leading-[124%] tracking-[0.48px] mb-">
        Recommended programs for you
      </h1>

      <div
        className={cx(
          "w-full hidden md:grid gap-4 lg:gap-8 mt-8",
          programs.length === 3 ? "grid-cols-3" : "grid-cols-2",
          `recomended-programs-num-${programs.length}`
        )}
      >
        {newPrograms.map((program) => (
          <ProjectComparisonModalCard
            key={program.id}
            program={program}
            prosConsEntryData={
              compareData && compareData?.pros_cons[program.id]
            }
            recommendation={
              program.id.toString() ===
              compareData?.recommendation.recommended_project_id
                ? compareData?.recommendation
                : null
            }
            className={
              !isNaN(Number(compareData?.recommendation.recommended_project_id))
                ? "row-span-11"
                : "row-span-10"
            }
            saveBtnClass="onboarding-save-program-button"
            disabled
          />
        ))}
      </div>

      <OnboardingOverviewFooter>
        <p className="hidden md:flex md:items-center text-overline">
          Save programs for review
        </p>

        <Link
          to={`${accountUrlPrefix}/become-a-member`}
          className="btn2 btn2-primary"
          state={{
            scrollToTop: true,
            joinBtnClass: "onboarding",
            subHeading:
              "Get full access to personalized recommendations and advice",
          }}
        >
          Next
        </Link>
      </OnboardingOverviewFooter>
    </div>
  )
}

export default OnboardingOverviewRecommendedPrograms
