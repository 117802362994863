import { create } from "zustand"
import { PAYMENT_TYPES } from "../shared/constants"
import { ProgramCategoryType } from "../types/constants"
import { PaymentTypeKeysType, ProjectDataTypes } from "../types/program"

export interface StateTypes {
  category: ProgramCategoryType | null
  govtProjectsOn: boolean
  ineligibleProjectsOn: boolean
  hasLandownerCost: boolean
  termLengthFilter: [number, number]
  paymentTypes: Record<PaymentTypeKeysType, boolean>
  searchProjects: string
  comparisonOn: boolean
  comparison: ProjectDataTypes[]
}

export const initialState: StateTypes = {
  category: null,
  govtProjectsOn: true,
  ineligibleProjectsOn: false,
  hasLandownerCost: true,
  termLengthFilter: [0, 100],
  paymentTypes: Object.keys(PAYMENT_TYPES).reduce(
    (acc, paymentType) => {
      return { ...acc, [paymentType]: false }
    },
    {} as Record<string, boolean>
  ),
  searchProjects: "",
  comparisonOn: true,
  comparison: [],
}

export const useProjectsStore = create<{
  category: ProgramCategoryType | "saved_programs" | null
  govtProjectsOn: boolean
  ineligibleProjectsOn: boolean
  hasLandownerCost: boolean
  termLengthFilter: [number, number]
  paymentTypes: Record<PaymentTypeKeysType, boolean>
  searchProjects: string
  comparisonOn: boolean
  comparison: ProjectDataTypes[]

  setCategory: (category: ProgramCategoryType | "saved_programs" | null) => void
  setGovtProjectsOn: (govtProjectsOn: boolean) => void
  setIneligibleProjectsOn: (ineligibleProjectsOn: boolean) => void
  setHasLandownerCost: (hasLandownerCost: boolean) => void
  setTermLengthFilter: (filter: [number, number]) => void
  setPaymentTypes: (paymentTypes: Record<PaymentTypeKeysType, boolean>) => void
  setSearchProjects: (searchProjects: string) => void
  setMultiState: (newState: Partial<StateTypes>) => void
  setComparisonOn: (comparisonOn: boolean) => void
  toggleComparison: (item: ProjectDataTypes) => void
  updateComparisonItem: (updatedItem: ProjectDataTypes) => void
  removeFromComparison: (id: number) => void
  clearComparison: () => void
}>((set) => ({
  // Initial values for each piece of state
  ...initialState,

  // Actions
  setCategory: (category) => set({ category }),
  setGovtProjectsOn: (govtProjectsOn) => set({ govtProjectsOn }),
  setIneligibleProjectsOn: (ineligibleProjectsOn) =>
    set({ ineligibleProjectsOn }),
  setHasLandownerCost: (hasLandownerCost) => set({ hasLandownerCost }),
  setTermLengthFilter: (filter) => set({ termLengthFilter: filter }),
  setPaymentTypes: (paymentTypes) => set({ paymentTypes }),
  setSearchProjects: (searchProjects) => set({ searchProjects }),
  setComparisonOn: (comparisonOn) => set({ comparisonOn }),
  toggleComparison: (item) =>
    set((state) => {
      const exists = state.comparison.some((i) => i.id === item.id)
      return {
        comparison: exists
          ? state.comparison.filter((i) => i.id !== item.id)
          : [...state.comparison, item],
      }
    }),
  updateComparisonItem: (updatedItem) =>
    set((state) => ({
      comparison: state.comparison.map((item) =>
        item.id === updatedItem.id ? updatedItem : item
      ),
    })),
  removeFromComparison: (id) =>
    set((state) => ({
      comparison: state.comparison.filter((item) => item.id !== id),
    })),
  clearComparison: () => set({ comparison: [] }),

  // Set multiple properties at once
  setMultiState: (newState) => set(newState),
}))
