import { Link } from "react-router"
import cx from "classnames"

import { useAccountUrlPrefix } from "../../hooks"
import { eligibilityQuizBackground } from "../EligibilityQuiz/helpers"
import { PROJECT_TYPES } from "../../shared/constants"
import { getProjectEarningsNullValue } from "../../shared/utils"
import { formatCurrency } from "../../utils"
import {
  EligibilityQuizCategoryType,
  ProgramCategoryType,
} from "../../types/constants"

export interface LandProfileQuizCardTypes {
  quizCategory: EligibilityQuizCategoryType
  completed: boolean
  projectType: ProgramCategoryType
  potentialEarnings?: number | null
  className?: string
}

const LandProfileQuizCard = ({
  quizCategory,
  completed,
  projectType,
  potentialEarnings,
  className,
}: LandProfileQuizCardTypes) => {
  const accountUrlPrefix = useAccountUrlPrefix()
  const bgImage = eligibilityQuizBackground(quizCategory)

  const isRegenAg = quizCategory === "regen_ag"
  const isGeneral = quizCategory === "general"

  return (
    <Link
      className={cx(
        "quiz-card cursor-pointer px-6 py-3 rounded-sm bg-[#D3D3D3] bg-cover bg-no-repeat",
        {
          "bg-[50%_40%]": isRegenAg,
          "bg-[50%_8%]": isGeneral,
          "bg-center": !isRegenAg && !isGeneral,
        },
        className
      )}
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(36, 36, 38, 0.7) 0%, rgba(36, 36, 38, 0.7) 100%), url(${bgImage})`,
      }}
      to={
        completed
          ? `${accountUrlPrefix}/programs/page/1?category=${projectType}`
          : `${accountUrlPrefix}/eligibility-quiz/${quizCategory}`
      }
      aria-label={PROJECT_TYPES[projectType]?.long}
      id={quizCategory}
    >
      <div className="w-full flex justify-between">
        <h3 className="text-white text-base leading-[140%] tracking-[0.32px]">
          {PROJECT_TYPES[projectType]?.long}
        </h3>

        <p className="text-white text-base leading-[140%] tracking-[0.32px]">
          {potentialEarnings === null || potentialEarnings === undefined
            ? getProjectEarningsNullValue(projectType)
            : `${formatCurrency(potentialEarnings)}/acre`}
        </p>
      </div>

      <p className="text-white text-left text-xs leading-[140%] tracking-[0.36px] mt-1">
        {PROJECT_TYPES[projectType]?.description}
      </p>
    </Link>
  )
}

export default LandProfileQuizCard
