import { useEffect, useMemo, useState } from "react"
import { useFormikContext } from "formik"
import debounce from "lodash/debounce"
import assert from "assert"

import MailCheck from "../MailCheck"
import { EmailSuggestionProps, SuggestionProps } from "./types"

const EmailSuggestion = ({ inputProps }: EmailSuggestionProps) => {
  const { values, setFieldValue, initialValues } = useFormikContext<any>()

  const inputName = inputProps.name
  assert(inputName, "EmailSuggestion requires input to have `name` prop")

  const initialEmail = initialValues[inputName]
  const [email, setEmail] = useState(initialEmail)

  const debouncedSetEmail = useMemo(() => debounce(setEmail, 500), [setEmail])

  useEffect(() => {
    const emailField = values[inputName]
    debouncedSetEmail(emailField)
  }, [values, inputName, debouncedSetEmail])

  const emailIsDirty = initialEmail !== email

  return (
    emailIsDirty && (
      <MailCheck email={email}>
        {(suggestion: SuggestionProps) =>
          suggestion && (
            <button
              type="button"
              onClick={() => {
                setFieldValue(inputName, suggestion.full)
                setEmail(suggestion.full)
              }}
              className="text-sm leading-[1.50] tracking-[0.01em] text-dusk-500 link--underline-only"
              data-testid="email-suggestion"
            >
              Did you mean{" "}
              <span className="text-moss-500">
                {suggestion.address}@<strong>{suggestion.domain}</strong>
              </span>
              ?
            </button>
          )
        }
      </MailCheck>
    )
  )
}

export default EmailSuggestion
