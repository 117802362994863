import { memo } from "react"
import cx from "classnames"

export interface CategoryFilterItemProps {
  tag: string
  image: string
  selected: boolean
  onChange: () => void
}

const CategoryFilterItem = memo(
  ({ tag, image, selected, onChange }: CategoryFilterItemProps) => (
    <button
      id={tag}
      className={cx(
        "category-filter-item group",
        "flex flex-col items-center",
        "font-overline font-medium leading-[150%] text-xs text-white text-nowrap uppercase select-none",
        "pt-4 pb-8 lg:pb-4",
        "hover:cursor-pointer hover:text-autumn",
        "focus:ring-0",
        {
          "text-autumn!": selected,
        }
      )}
      aria-label={`Filter programs by ${tag}`}
      onClick={onChange}
    >
      <div className="w-24 mb-4">
        <img
          src={image}
          alt={tag}
          className="block h-[91px] w-full pointer-events-none"
        />
      </div>

      <span className="group-focus:ring-3 group-focus:ring-blue-400/[.45]">
        {tag}
      </span>
    </button>
  )
)

export default CategoryFilterItem
