interface UnverifiedBannerTypes {
  ctaOverrideUrl?: string | null | undefined
  isVerified: boolean | undefined
}

const UnverifiedBanner = ({
  ctaOverrideUrl,
  isVerified,
}: UnverifiedBannerTypes) => {
  if (isVerified !== false) {
    return null
  }

  return (
    <div className="w-full lg:pl-5 pr-1 mb-6">
      <div className="bg-white shadow-sm rounded-none border-solid p-4 border-yellow-[7.5rem]0 border-l-2">
        <div className="flex flex-row">
          <div className="flex-auto">
            <div className="mb-2">
              <h2 className="text-lg leading-[1.50] tracking-[0.02em] mb-5 lg:mb-[1.125rem]">
                This program's details have not been verified by the developer.
              </h2>
            </div>
            <>
              {ctaOverrideUrl ? (
                <p className="leading-[140%] tracking-[0.32px] my-4">
                  Some details may be missing or incomplete. Please visit the{" "}
                  <a
                    href={ctaOverrideUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="link"
                  >
                    program website
                  </a>{" "}
                  for more information.
                </p>
              ) : (
                <p className="leading-[140%] tracking-[0.32px] my-4">
                  Some details may be missing or incomplete. Please contact the
                  program developer directly for more information.
                </p>
              )}
              <div className="mt-6 lg:mt-0">
                <a
                  href="https://help.ncx.com/hc/en-us/articles/27881997151131-Program-Verification-Status"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="link"
                >
                  Click here{" "}
                </a>
                to learn more about our program verification process.
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UnverifiedBanner
