import { ReactNode } from "react"
import cx from "classnames"

export interface SidebarTypes {
  title: string
  description: string
  children: ReactNode
  className?: string
}

const Sidebar = ({ title, description, children, className }: SidebarTypes) => (
  <div
    className={cx(
      "border border-solid border-grass-200 rounded-sm w-full lg:w-[396px] overflow-hidden self-start",
      className
    )}
    data-testid="sidebar"
  >
    <div className="bg-grass-50 border-b border-b-solid border-b-grass-200">
      <h4 className="text-grass-500 text-xl leading-[1.50] tracking-[0.03em] px-4 pt-4">
        {title}
      </h4>

      <div className="px-4 pb-4 pt-1 text-grass-500 text-base leading-[1.50] tracking-[0.02em]">
        {description}
      </div>
    </div>

    <div className="p-4">{children}</div>
  </div>
)

export default Sidebar
