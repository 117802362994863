import { Link } from "react-router"

import { useAccountUrlPrefix } from "../../hooks"

const DashboardQuickLinks = () => {
  const accountUrlPrefix = useAccountUrlPrefix()

  return (
    <div className=" bg-white shadow-sm rounded-md px-6 pt-6 pb-4 mb-4 last:mb-0">
      <h4 className="text-overline mb-3">Quick links</h4>

      <div>
        <Link
          className="quicklinks-saved-programs link"
          to={`${accountUrlPrefix}/programs/page/1?category=saved_programs`}
        >
          View Saved Programs
        </Link>
      </div>
    </div>
  )
}

export default DashboardQuickLinks
