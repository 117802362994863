import { ReactNode } from "react"
import cx from "classnames"

import ProjectBadgeAnimation from "./ProjectBadgeAnimation"
import { ProjectBadgeType } from "../../types/constants"

export interface ProjectBadgeTypes {
  type: ProjectBadgeType
  size?: "sm" | "lg"
  animated?: boolean
  block?: boolean
  children: ReactNode
}

export const ProjectBadge = ({
  type = "neutral",
  size = "lg",
  animated = false,
  block = false,
  children,
}: ProjectBadgeTypes) => {
  const classNames = cx(
    "relative shrink-0 text-sm tracking-[0.01em] font-bold rounded-full border border-solid",
    {
      "px-4 py-2": size === "lg",
      "leading-[1.50]": size === "lg",
      "leading-5 px-3 py-1": size === "sm",
      "border-dusk-900 bg-dusk-50 text-dusk-900": type === "neutral",
      "border-grass-900 bg-grass-100 text-grass-900": type === "primary",
      "border-orange-900 bg-[#F8F1E0] text-orange-900": type === "info",
      "border-orange-900 bg-orange-50 text-orange-900": type === "warning",
      "border-fire-900 bg-fire-50 text-fire-900": type === "error",
    },
    block ? "block" : "inline-block"
  )

  return (
    <div
      className={cx("relative text-center", block ? "block" : "inline-block")}
    >
      {animated ? <ProjectBadgeAnimation type={type} /> : null}

      <span className={classNames}>{children}</span>
    </div>
  )
}
