import { shortenAcreage } from "../../../utils"

export interface LandHeaderProps {
  acreage: number
  firstName: string | null | undefined
}

const LandHeader = ({ acreage, firstName }: LandHeaderProps) => (
  <div className="mb-4 text-black text-xl font-medium leading-7 tracking-[0.4px]">
    {`${firstName}'s ${shortenAcreage(acreage)} Acres`}
  </div>
)

export default LandHeader
