import { Accordion } from "react-accessible-accordion"

import RangeSlider from "../../../components/RangeSlider"
import { Checkbox } from "../../../components/Checkbox"
import FilterAccordionItem from "./FilterAccordionItem"
import { PAYMENT_TYPES } from "../../../shared/constants"
import { useProjectsStore } from "../../../stores/useProjectsStore"

interface ProjectFiltersDrawerAccordionProps {
  handleTermLength: (e: [number, number]) => void
  handleFirstPageRedirect: () => void
}

const ProjectFiltersDrawerAccordion = ({
  handleTermLength,
  handleFirstPageRedirect,
}: ProjectFiltersDrawerAccordionProps) => {
  const { termLengthFilter, paymentTypes, setPaymentTypes } = useProjectsStore()

  const handleChange = (action: () => void) => {
    action()
    handleFirstPageRedirect()
  }

  return (
    <Accordion
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      className="divide-y mt-6"
    >
      <FilterAccordionItem heading="Term length">
        <RangeSlider
          id="term-length"
          ariaLabel="Term length"
          value={termLengthFilter}
          onChange={(e) => handleChange(() => handleTermLength(e))}
          lowerInputLabel="Min term length"
          upperInputLabel="Max term length"
        />
      </FilterAccordionItem>

      <FilterAccordionItem heading="Payment terms">
        {Object.entries(PAYMENT_TYPES).map(([key, val]) => (
          <Checkbox
            key={key}
            id={key}
            label={val}
            className="mb-2"
            checked={paymentTypes[key as keyof typeof paymentTypes]}
            onChange={(e) =>
              handleChange(() =>
                setPaymentTypes({
                  ...paymentTypes,
                  [key]: e.target.checked,
                })
              )
            }
          />
        ))}
      </FilterAccordionItem>
    </Accordion>
  )
}

export default ProjectFiltersDrawerAccordion
