import IconText from "../../../components/IconText"
import Heading from "./Heading"
import Text from "./Text"
import { ProgramHeadersTypes } from "@/types/program"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

interface SectionTypes {
  title: string
  data: ProgramHeadersTypes
  icon: IconDefinition
  className: string
}

const Section = ({ title, data, icon, className }: SectionTypes) => (
  <IconText
    icon={icon}
    className={className}
    iconClass="text-base text-charcoal-900 mr-2 leading-6 tracking-[0.02em]"
  >
    <h4 className="font-body text-lg text-charcoal-900 font-semibold leading-6 tracking-[0.02em] mb-1">
      {title}
    </h4>

    {Object.values(data).every((value) => value.length === 0) ? (
      <Heading heading="Information not readily available" />
    ) : (
      <>
        <Heading heading={data?.header_1 as string} />
        <Text textArray={data?.header_1_text as string[]} />
        <Heading heading={data?.header_2 as string} />
        <Text textArray={data?.header_2_text as string[]} />
        <Heading heading={data?.header_3 as string} />
        <Text textArray={data?.header_3_text as string[]} />
      </>
    )}
  </IconText>
)

export default Section
