import { usePopoverState } from "reakit/Popover"
import cx from "classnames"

import Popover from "../Popover"
import ProjectVerificationBadgeLearnMoreLink from "./ProjectVerificationBadgeLearnMoreLink"
import { ProjectVerificationBadgeTypes } from "./types"
import ProjectVerificationBadgeContent from "./ProjectVerificationBadgeContent"

const ProjectVerificationBadge = ({
  isVerified,
  includeLabel,
  disclosureClassName,
  popoverClassName,
}: ProjectVerificationBadgeTypes) => {
  const verificationPopover = usePopoverState({
    placement: "bottom",
  })

  const tooltipText = isVerified
    ? "This program's details have been verified."
    : "This developer has not verified their program details."

  return (
    <Popover
      popover={verificationPopover}
      content={
        <ProjectVerificationBadgeContent
          isVerified={isVerified}
          includeLabel={includeLabel}
        />
      }
      label={isVerified ? "Verified" : "Unverified"}
      className={cx("w-64", popoverClassName)}
      disclosureClassName={disclosureClassName}
    >
      {tooltipText} <ProjectVerificationBadgeLearnMoreLink />
    </Popover>
  )
}

export default ProjectVerificationBadge
