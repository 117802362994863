import { mapIneligibilityReason } from "../../shared/ineligibilityReason"
import { ProgramType } from "@/types/program"
import { IneligibilityReasonsType } from "@/types/constants"

interface IneligibilityReasonstTypes {
  projectsData: ProgramType[]
}

const IneligibilityReasons = ({ projectsData }: IneligibilityReasonstTypes) => {
  const ineligibilityReasons = projectsData.reduce<IneligibilityReasonsType[]>(
    (acc, project) => {
      if (project?.ineligibility_reasons) {
        project.ineligibility_reasons.forEach((reason) => {
          if (!acc.includes(reason)) {
            acc.push(reason)
          }
        })
      }
      return acc
    },
    []
  )

  if (ineligibilityReasons.length === 0) {
    return null
  }

  return (
    <>
      <p className="text-charcoal-500 font-bold leading-[130%] tracking-[0.32px] mt-3">
        Why am I ineligible?
      </p>

      <ul className="ul-custom pl-4 leading-[130%] tracking-[0.32px] mt-3">
        {ineligibilityReasons.map((reason) => (
          <li key={reason}>{mapIneligibilityReason(reason)}</li>
        ))}
      </ul>
    </>
  )
}

export default IneligibilityReasons
