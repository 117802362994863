import cx from "classnames"

export interface AccountStatusProps {
  isMember: boolean | undefined
  memberSince?: string | null
}

const AccountStatus = ({ isMember, memberSince }: AccountStatusProps) => (
  <div className="mb-6">
    <h4 className="text-overline">Account status</h4>

    <p
      className={cx(
        "text-base leading-[140%] tracking-[0.32px] mt-2 text-charcoal-500"
      )}
    >
      {isMember ? `Member since ${memberSince}` : "Non-Member Free Tier"}
    </p>
  </div>
)

export default AccountStatus
