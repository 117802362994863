import { useState } from "react"
import { useQueryClient } from "@tanstack/react-query"

import SPBTakeActionImplementations from "./SPBTakeActionImplementations"
import SPBTakeActionForm from "./SPBTakeActionForm"
import { useSPBStore } from "../../stores/useSPBStore"
import { useAccountId, useUpdateWizards } from "../../hooks"
import { sendHotjarEvent } from "../../api/integrations"
import { Profile } from "@/types"

export interface SPBContentTakeActionProps {
  profile: Profile | undefined
  handleNext: () => void
  handlePrevious: () => void
}

const SPBContentTakeAction = ({
  profile,
  handleNext,
  handlePrevious,
}: SPBContentTakeActionProps) => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  const [contactView, setContactView] = useState<boolean>(false)
  const { selectedActionGroup, selectedImplementation } = useSPBStore()

  const { mutateAsync: updateWizards, isPending: isPendingUpdateWizards } =
    useUpdateWizards(queryClient, accountId, {
      onSuccess: () => {
        handleNext()
        sendHotjarEvent("complete_spb_wizard")
      },
    })

  const handleSubmit = async () => {
    const postData = {
      wizard_key: "spb",
      action_group_key: selectedActionGroup,
      implementation: selectedImplementation,
    }

    if (
      !contactView &&
      profile?.preferred_contact_method.length === 0 &&
      selectedImplementation === "ncx_services"
    ) {
      setContactView(true)
    } else {
      await updateWizards(postData)
    }
  }

  const handleResetContactView = () => {
    setContactView(false)
  }

  return (
    <div className="pt-10 lg:pt-13 pb-31">
      {contactView ? (
        <SPBTakeActionForm
          profile={profile}
          handleResetContactView={handleResetContactView}
          handleSubmit={() => {
            handleSubmit()
          }}
          isPending={isPendingUpdateWizards}
        />
      ) : (
        <SPBTakeActionImplementations
          handlePrevious={handlePrevious}
          handleSubmit={() => {
            handleSubmit()
          }}
          isPending={isPendingUpdateWizards}
        />
      )}
    </div>
  )
}

export default SPBContentTakeAction
