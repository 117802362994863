import { components, MultiValueRemoveProps } from "react-select"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/pro-duotone-svg-icons"

const MultiValueRemove = (props: MultiValueRemoveProps) => (
  <components.MultiValueRemove {...props}>
    <span className="flex h-6 w-6 justify-center items-center">
      <FontAwesomeIcon icon={faXmark} />
    </span>
  </components.MultiValueRemove>
)

export default MultiValueRemove
