import ReactSlider from "react-slider"

import RangeSliderInput from "./RangeSliderInput"

export interface RangeSliderTypes {
  id: string
  ariaLabel: string
  value: [number, number]
  onChange: (value: [number, number]) => void
  min?: number
  max?: number
  step?: number
  lowerInputLabel: string
  upperInputLabel: string
}

const RangeSlider = ({
  id,
  ariaLabel,
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  lowerInputLabel,
  upperInputLabel,
}: RangeSliderTypes) => (
  <div>
    <div className="flex justify-between text-sm text-charcoal-500 leading-[130%] tracking-[0.14px]">
      <span>{min}</span>
      <span>{max}+</span>
    </div>

    {/* @ts-ignore */}
    <ReactSlider
      className="relative block w-full h-4"
      thumbClassName="bg-leaf cursor-pointer w-4 h-4 rounded-full range-slider-thumb"
      trackClassName="relative h-1 bg-charcoal-50 rounded-full top-[6px] range-slider-track"
      value={value}
      aria-label={[`${ariaLabel} lower thumb`, `${ariaLabel} upper thumb`]}
      renderThumb={(props) => {
        const { key, ...otherProps } = props
        //@ts-ignore
        return <div key={key} {...otherProps} data-testid={key} />
      }}
      onChange={onChange}
      min={min}
      max={max}
      step={step}
      pearling
    />

    <div className="flex justify-between mt-4">
      <RangeSliderInput
        id={`${id}-lower-input`}
        label={lowerInputLabel}
        ariaLabel={`${ariaLabel} lower input`}
        value={value[0]}
        onChange={(e) => {
          const newValue = Number(e.target.value)
          onChange([newValue, value[1]])
        }}
        min={min}
        max={value[1] || max}
        step={step}
      />

      <span className="flex items-center h-10 self-end">to</span>

      <RangeSliderInput
        id={`${id}-upper-input`}
        label={upperInputLabel}
        ariaLabel={`${ariaLabel} upper input`}
        value={value[1]}
        onChange={(e) => {
          const newValue = Number(e.target.value)
          onChange([value[0], newValue])
        }}
        min={value[0] || min}
        max={max}
        step={step}
      />
    </div>
  </div>
)

export default RangeSlider
