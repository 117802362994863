import { ChangeEvent, ReactNode } from "react"
import { Field } from "formik"
import cx from "classnames"

export interface CheckboxProps {
  id?: string
  label?: ReactNode
  className?: string
  labelClass?: string
  checked: boolean
  disabled?: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export interface Checkbox2Props {
  id?: string
  name: string
  label?: ReactNode
  disabled?: boolean
  className?: string
  "aria-label"?: string
  tabIndex?: number
}

export const Checkbox = ({
  id,
  label,
  className,
  labelClass,
  checked,
  disabled,
  onChange,
}: CheckboxProps) => (
  <label
    className={cx("flex flex-row cursor-pointer gap-2", className)}
    htmlFor={id}
    data-testid="checkbox"
  >
    <span className="flex shrink-0 items-center relative">
      <input
        id={id}
        type="checkbox"
        className="peer w-0 h-0 opacity-0 relative border-0 left-2"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        data-testid="checkbox-input"
      />

      <span
        className={cx(
          "relative block w-4 h-4 border border-charcoal-300 rounded-xs pointer-events-none bg-white",
          "peer-checked:after:block peer-checked:border-grass-900 peer-disabled:border-dusk-100 peer-disabled:bg-charcoal-50 peer-focus:shadow-[0_0_0_3px_rgba(118,169,250,0.45)]",
          "after:content-[''] after:absolute after:hidden after:bg-checkmark after:top-[-1px] after:right-[-1px] after:w-4 after:h-4"
        )}
      />
    </span>

    <span className={labelClass}>{label}</span>
  </label>
)

export const Checkbox2 = ({
  id,
  name,
  label,
  disabled,
  className,
  "aria-label": ariaLabel,
  tabIndex,
}: Checkbox2Props) => {
  id = id || name

  return (
    <label
      htmlFor={id}
      className={cx(
        "flex flex-row cursor-pointer",
        className ? className : "mb-2",
        { "text-dusk-300": disabled }
      )}
    >
      <span className="flex mr-2 shrink-0 items-center relative">
        <Field
          id={id}
          type="checkbox"
          name={name}
          className="peer w-0 h-0 opacity-0 relative border-0 left-2"
          disabled={disabled}
          aria-label={ariaLabel}
          tabIndex={tabIndex}
        />
        <span
          className={cx(
            "relative block w-4 h-4 border border-charcoal-300 rounded-xs pointer-events-none bg-white",
            "peer-checked:after:block peer-checked:border-grass-900 peer-disabled:border-dusk-100 peer-disabled:bg-charcoal-50 peer-focus:shadow-[0_0_0_3px_rgba(118,169,250,0.45)]",
            "after:content-[''] after:absolute after:hidden after:bg-checkmark after:top-[-1px] after:right-[-1px] after:w-4 after:h-4"
          )}
        />
      </span>

      <span>{label}</span>
    </label>
  )
}
