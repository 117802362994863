import { useQuery, UseQueryResult } from "@tanstack/react-query"

import { getSpbTiles } from "../../../api/data"

export const useGetSpbTiles = <TData, TError>(
  accountId: string,
  mapType: string,
  config = {}
): UseQueryResult<TData, TError> => {
  return useQuery({
    queryKey: ["accounts", accountId, "tile", "links", mapType],
    queryFn: async () => getSpbTiles(accountId, mapType),
    ...config,
  })
}
