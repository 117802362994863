import { ReactNode } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import SPBCard from "./SPBCard"
import SPBImpactScore from "./SPBImpactScore"
import SPBList from "./SPBList"
import SPBEligibleFundingPrograms from "./SPBEligibleFundingPrograms"
import { ProjectListTypes } from "@/types/program"

export interface SPBSelectApproachCardProps {
  show: boolean
  selected: boolean
  heading: string
  description: ReactNode
  subheading: string
  currentScore: number
  maxScore: number
  newScore: number
  actions: ReactNode[]
  projectIds: number[]
  projectList: ProjectListTypes | undefined
  onClick: () => void
}

const SPBSelectApproachCard = ({
  show,
  selected,
  heading,
  description,
  subheading,
  currentScore,
  maxScore,
  newScore,
  actions,
  projectIds,
  projectList,
  onClick,
}: SPBSelectApproachCardProps) => {
  if (!show) {
    return null
  }

  return (
    <SPBCard>
      <div
        className={cx(
          "relative h-full flex flex-col gap-6 hover:cursor-pointer p-6",
          {
            "before:content-[''] before:absolute before:-inset-[2px] before:border-4 before:border-leaf before:rounded-2xl before:pointer-events-none":
              selected,
          }
        )}
        onClick={onClick}
      >
        <h3
          className={cx(
            "flex items-center gap-3 text-2xl font-display font-medium leading-[124%] tracking-[0.48px]",
            {
              "text-charcoal-500": !selected,
              "text-leaf": selected,
            }
          )}
        >
          {heading}
          {selected ? <FontAwesomeIcon icon={faCircleCheck} /> : null}
        </h3>

        <p className="font-body leading-[140%] tracking-[0.32px]">
          {description}
        </p>

        <SPBImpactScore
          subheading={subheading}
          currentScore={currentScore}
          maxScore={maxScore}
          newScore={newScore}
        />

        <SPBList caption="Actions" list={actions} />

        <SPBEligibleFundingPrograms
          projectIds={projectIds}
          projectList={projectList}
        />
      </div>
    </SPBCard>
  )
}

export default SPBSelectApproachCard
