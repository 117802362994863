import { DialogDisclosure, useDialogState } from "reakit"
import { Spinner } from "../../components/Spinner"

import { Modal } from "../../components/Modal"
import SPBHeader from "./SPBHeader"
import SPBSelectApproachCards from "./SPBSelectApproachCards"
import SPBFooter from "./SPBFooter"
import SPBSelectApproachModalContent from "./SPBSelectApproachModalContent"
import { useSPBStore } from "../../stores/useSPBStore"
import {
  SPBWizardType,
  TileUrlsType,
  SelectedActionGroupType,
} from "@/types/wizards"
import { ProjectListTypes } from "@/types/program"

export interface SPBContentSelectApproachProps {
  wizardData: SPBWizardType | undefined
  projectList: ProjectListTypes | undefined
  spbActionMapsTileData: TileUrlsType | undefined
  handleNext: () => void
  handlePrevious: () => void
}

const SPBContentSelectApproach = ({
  wizardData,
  projectList,
  spbActionMapsTileData,
  handleNext,
  handlePrevious,
}: SPBContentSelectApproachProps) => {
  const dialog = useDialogState({ animated: true })

  const { selectedActionGroup } = useSPBStore()

  let modalHeader = "Action Map: Monitor Only"

  if (selectedActionGroup === "spb_thinning") {
    modalHeader = "Action Map: Enhanced Resilience"
  } else if (selectedActionGroup === "spb_replant") {
    modalHeader = "Action Map: Maximum Protection"
  }
  // tiles not loaded yet
  if (spbActionMapsTileData === undefined) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  // available options based on available tile data
  const actionGroupOptions = Object.keys(
    spbActionMapsTileData
  ) as SelectedActionGroupType[]

  return (
    <div className="pt-10 lg:pt-13 pb-31">
      <SPBHeader
        heading="How do you want to manage your approach to Southern Pine Beetles?"
        subheading="The approach you select will be added to your dashboard for tracking."
      />

      <SPBSelectApproachCards
        wizardData={wizardData}
        projectList={projectList}
        actionGroupOptions={actionGroupOptions}
      />

      <SPBFooter>
        <p className="hidden md:flex md:items-center text-overline">
          Select a management approach to continue
        </p>

        <div className="flex gap-4 ml-auto">
          <button
            type="button"
            className="spb-select-approach-go-back btn2 btn2-outline-primary font-bold tracking-[0.32px] whitespace-nowrap"
            onClick={handlePrevious}
          >
            Go Back
          </button>

          <DialogDisclosure
            {...dialog}
            className="spb-select-approach-preview-action-map btn2 btn2-primary font-bold tracking-[0.32px] whitespace-nowrap"
            disabled={!selectedActionGroup}
          >
            Preview Action Map
          </DialogDisclosure>
        </div>
      </SPBFooter>

      <Modal
        header={modalHeader}
        aria-label="Help dialog"
        dialog={dialog}
        data-testid="help-card-modal"
        className="max-w-[656px] max-h-[calc(100vh-40px)] px-7 py-6"
      >
        <SPBSelectApproachModalContent
          visible={dialog.visible}
          tileURLs={spbActionMapsTileData}
          hide={dialog.hide}
          handleContinue={handleNext}
        />
      </Modal>
    </div>
  )
}

export default SPBContentSelectApproach
