import { useMemo } from "react"

import OrderedList from "../../components/OrderedList"
import SPBCard from "./SPBCard"
import SPBPlanSummaryHeader from "./SPBPlanSummaryHeader"
import SPBPlanSummaryImpactScore from "./SPBPlanSummaryImpactScore"
import SPBList from "./SPBList"
import SPBMap from "./SPBMap"
import SPBEligibleFundingPrograms from "./SPBEligibleFundingPrograms"
import SPBPlanSummaryHelperCard from "./SPBPlanSummaryHelperCard"
import { formatActions, getNextSteps, getActionFromGroup } from "./helpers"
import { useSPBStore } from "../../stores/useSPBStore"
import {
  SelectedActionGroupType,
  SPBWizardType,
  TileUrlsType,
} from "@/types/wizards"
import { TileURLsTypes } from "@/types/tiles"
import { ProjectListTypes } from "@/types/program"
import { AccountProperty } from "@/types/property"

export interface SPBSummaryCardProps {
  property: AccountProperty | undefined
  wizardData: SPBWizardType | undefined
  selectedActionGroupIndex: number
  projectList: ProjectListTypes | undefined
  spbActionMapsTileData?: TileUrlsType | undefined
}

const SPBSummaryCard = ({
  property,
  wizardData,
  selectedActionGroupIndex,
  projectList,
  spbActionMapsTileData,
}: SPBSummaryCardProps) => {
  const { selectedActionGroup, selectedImplementation } = useSPBStore()
  // DEV: hopefully temporary hardcoding of the actions list while we figure out proper ordering in backend
  const actions = useMemo(() => {
    return formatActions(
      getActionFromGroup(
        wizardData?.action_groups[selectedActionGroupIndex]
          ?.key as SelectedActionGroupType
      )
    )
  }, [selectedActionGroupIndex, wizardData?.action_groups])

  let tileData
  if (
    selectedActionGroup &&
    spbActionMapsTileData &&
    spbActionMapsTileData[selectedActionGroup]
  ) {
    tileData = {
      url: spbActionMapsTileData[selectedActionGroup],
    } as TileURLsTypes
  }

  const nextSteps = useMemo(
    () => getNextSteps(selectedImplementation),
    [selectedImplementation]
  )

  return (
    <div className="container w-[780px] max-w-full flex flex-col gap-6 px-5 lg:px-10">
      <SPBCard paddingSize="md-y">
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl font-display font-medium leading-[124%] tracking-[0.6px]">
            Next Steps
          </h1>

          <OrderedList list={nextSteps} />

          <SPBEligibleFundingPrograms
            projectIds={
              wizardData?.action_groups[selectedActionGroupIndex].project_ids ??
              []
            }
            projectList={projectList}
            hasLinks
          />

          <SPBPlanSummaryHelperCard property={property} />
        </div>
      </SPBCard>

      <SPBCard paddingSize="md-y">
        <div className="flex flex-col gap-6">
          <SPBPlanSummaryHeader />

          <SPBPlanSummaryImpactScore
            wizardData={wizardData}
            selectedActionGroupIndex={selectedActionGroupIndex}
          />

          <SPBList caption="Actions" list={actions} />

          <div className="flex flex-col gap-4">
            <SPBMap
              tileData={tileData}
              lowText="Commercial Harvest"
              moderateText="Thinning"
              highText="Monitor"
              description="This map shows recommended actions and where on your land to implement them. If you do not see any color on the map, there is no action to take."
            />
          </div>
        </div>
      </SPBCard>
    </div>
  )
}

export default SPBSummaryCard
