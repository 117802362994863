import ScoreCardHeading from "../ViewAccount/Scorecard/ScoreCardHeading"
import ScoreCardSubHeading from "../ViewAccount/Scorecard/ScoreCardSubHeading"
import ScoreCardScore from "../ViewAccount/Scorecard/ScoreCardScore"
import { getScoreColorClass } from "../ViewAccount/Scorecard/helpers"

export interface SPBImpactScoreProps {
  subheading: string
  currentScore: number | undefined
  maxScore: number | undefined
  newScore: number | undefined
}

const SPBImpactScore = ({
  subheading,
  currentScore,
  maxScore,
  newScore,
}: SPBImpactScoreProps) => {
  if (!currentScore || !maxScore || !newScore) {
    return null
  }

  const currentScoreColorClass = getScoreColorClass(currentScore ?? 0)
  const newScoreCollorClass = getScoreColorClass(newScore ?? 0)

  return (
    <div
      className="scorecard-spb flex flex-col sm:flex-row w-full justify-between gap-4 pt-4 sm:pt-0"
      data-testid="spb-impact-score"
    >
      <div>
        <ScoreCardHeading className="flex">Expected Impact</ScoreCardHeading>

        <ScoreCardSubHeading text={subheading} className="mt-2.5" />
      </div>

      <div className="flex gap-4">
        <div>
          <p className="text-dusk-500 text-xs font-body leading-[140%] tracking-[0.24px]">
            Before
          </p>

          <ScoreCardScore
            currentScore={currentScore}
            maxScore={maxScore}
            scoreColorClass={currentScoreColorClass}
            hasScoreBarLegend={false}
          />
        </div>

        <div>
          <p className="text-charcoal-500 text-xs font-body leading-[140%] tracking-[0.24px]">
            After
          </p>

          <ScoreCardScore
            currentScore={newScore}
            maxScore={maxScore}
            scoreColorClass={newScoreCollorClass}
            hasScoreBarLegend={false}
          />
        </div>
      </div>
    </div>
  )
}

export default SPBImpactScore
