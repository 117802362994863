import { DialogStateReturn } from "reakit"
import cx from "classnames"

import { Modal } from "../../../components/Modal"
import ProjectComparisonModalContent from "./ProjectComparisonModalContent"
import { useProjectsStore } from "../../../stores/useProjectsStore"
import { sendHotjarEvent } from "../../../api/integrations"
import { ProsConsDataType } from "@/types/comparison"

interface ProjectComparisonModalProps {
  dialog: DialogStateReturn
  isFetching: boolean
  compareData: ProsConsDataType | undefined
  PDFDownloadLink: string | null
}

const ProjectComparisonModal = ({
  dialog,
  isFetching,
  compareData,
  PDFDownloadLink,
}: ProjectComparisonModalProps) => {
  const { comparison } = useProjectsStore()

  const handleOnDismiss = () => {
    sendHotjarEvent("click_program_compare_button")
  }

  return (
    <Modal
      header="My Recommendation"
      aria-label="Accept invitation?"
      className={cx(
        "h-full! md:h-auto! max-h-full md:max-h-[calc(100%-48px)] w-full! md:w-[calc(100vw-48px)]! rounded-none! md:rounded-sm! lg:p-6",
        comparison.length === 3 ? "max-w-[1372px]" : "max-w-[915px]"
      )}
      headerClass={cx(
        "h-auto fixed md:static top-0 left-0 right-0 h-14 shadow-[0px_1px_3px_0px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.06)] md:shadow-none px-4 md:px-0 py-4 md:pt-0 md:pb-3 mb-0!",
        "[&>h2]:text-base md:[&>h2]:text-xl [&>h2]:leading-[1.50] [&>h2]:tracking-[0.02em] md:[&>h2]:tracking-[0.03em] [&>h2]:font-body"
      )}
      dialog={dialog}
      onDismiss={handleOnDismiss}
    >
      <ProjectComparisonModalContent
        isFetching={isFetching}
        compareData={compareData}
        PDFDownloadLink={PDFDownloadLink}
      />
    </Modal>
  )
}

export default ProjectComparisonModal
