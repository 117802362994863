import NumberedListItem from "../../components/NumberedListItem"
import { AuthLayoutTypes } from "../../types/auth"
import logoIcon from "../../images/ncx-login-logo.png"

const AuthLayout = ({ form }: AuthLayoutTypes) => (
  <div className="flex flex-auto flex-col-reverse lg:flex-row">
    <div className="flex items-center justify-center w-full lg:w-1/2 bg-tree-img">
      <div className="max-w-[491px] text-white px-4 pt-16 pb-[74px] lg:pb-[82px]">
        <h1 className="text-xl lg:text-3xl leading-[120%] lg:leading-[42px] tracking-[0.4px] lg:tracking-[0.9px]">
          Join a Community of 25,000+ Landowners and Make Your Vision for Your
          Land Reality.
        </h1>

        <ol className="list-none mt-6 lg:mt-10 relative">
          <NumberedListItem
            heading="Claim Your Land"
            text="Start for free and share your goals to get a personalized experience tailored to your needs."
            className="before:content-['1'] mb-8"
          />

          <NumberedListItem
            heading="Explore Your Land's Potential"
            text="Discover eligible income opportunities and management options that increase long-term value and generate income."
            className="before:content-['2'] mb-8"
          />

          <NumberedListItem
            heading="Build a Winning Plan"
            text="Turn your vision into action with expert tools and guidance at every step."
            className="before:content-['3']"
          />
        </ol>
      </div>
    </div>

    <div className="flex flex-col items-center w-full lg:w-1/2 bg-white pt-6 pb-5 lg:pb-0 grow">
      <figure className="w-[440px] max-w-full lg:mt-auto">
        <img src={logoIcon} alt="NCX logo" className="h-[46px] block mx-auto" />
      </figure>

      <div className="w-[440px] max-w-full">{form}</div>

      <div
        className="hidden lg:block text-sm leading-[1.50] tracking-[0.01em] text-center text-gray-500 space-x-5 justify-center mb-12 mt-auto"
        data-test="terms-privacy"
      >
        <a
          className="link text-leaf"
          href="https://www.ncx.com/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </a>

        <a
          className="link text-leaf"
          href="https://www.ncx.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  </div>
)

export default AuthLayout
