import cx from "classnames"
import { useMenuState, Menu, MenuItem, MenuButton } from "reakit/Menu"

import { LayerType } from "../types/mapbox"
import { mapStyles } from "../shared/constants"
import LAYER_ICON from "../images/layers-icon.svg"

export interface LayerPickerTypes {
  layer: LayerType
  onLayerChange: (layer: LayerType) => void
  className: string
}

export const LayerPicker = ({
  layer,
  onLayerChange,
  className,
}: LayerPickerTypes) => {
  const menu = useMenuState()

  const handleLayerChange = (key: LayerType) => {
    onLayerChange(key)
    menu.hide()
  }

  return (
    <div className={cx("absolute", className)}>
      <div className="relative">
        <MenuButton className="btn btn-secondary border-0 p-0" {...menu}>
          <div className="flex items-center space-x-2">
            <img src={LAYER_ICON} alt="layer" className="block w-9 h-9" />
          </div>
        </MenuButton>

        <Menu
          className={cx(
            "absolute left-0 right-0 z-2147483641",
            "py-1 min-w-32 transform translate-y-2",
            "rounded-md bg-white ring-1 ring-black/5 focus:outline-hidden"
          )}
          {...menu}
          aria-label="Layer Options"
        >
          {Object.keys(mapStyles).map((key) => {
            const layerItem = mapStyles[key as LayerType]

            return (
              <MenuItem
                key={key}
                className="block w-full text-left px-4 py-2 text-sm leading-5 tracking-[0.01em] text-gray-700 hover:bg-gray-100 focus:outline-hidden focus:bg-gray-100 transition duration-150 ease-in-out first:rounded-tl first:rounded-tr last:rounded-bl last:rounded-br"
                {...menu}
                onClick={() => handleLayerChange(key as LayerType)}
              >
                <span className={layer === key ? "text-green-600" : ""}>
                  {layerItem.label}
                </span>
              </MenuItem>
            )
          })}
        </Menu>
      </div>
    </div>
  )
}
