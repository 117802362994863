import { ReactNode } from "react"

import SPBListItem from "./SPBListItem"

export interface SPBListProps {
  caption: ReactNode
  list: ReactNode[] | undefined
}

const SPBList = ({ caption, list }: SPBListProps) => {
  if (!list) {
    return null
  }

  return (
    <div>
      <div className="font-bold leading-[24px] tracking-[0.84px]">
        {caption}
      </div>

      <ul className="pl-5 space-y-1 mt-1">
        {list.map((item, index) => (
          <SPBListItem key={index} item={item} />
        ))}
      </ul>
    </div>
  )
}

export default SPBList
