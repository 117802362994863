import { CHART, TABLE } from "./constants"
import RoiCalculator from "./RoiCalculator"
import RoiChart from "./RoiChart"
import RoiTable from "./RoiTable"
import { RoiModalContentMobileTabProps } from "./types"

const RoiModalContentMobileTab = ({
  activeTab,
  roiData,
  isMobile,
  width,
  height,
  formikProps,
  handleReset,
  isReseting,
  activeYear,
  setActiveYear,
}: RoiModalContentMobileTabProps) => {
  if (activeTab === CHART) {
    return (
      <div className="w-full mt-6">
        <p className="text-xs text-dusk-700 text-center leading-[130%] mb-4">
          Tap on the chart to see revenue and profit.
        </p>

        <RoiChart
          roiData={roiData}
          isMobile={isMobile}
          width={width}
          height={height}
          setActiveYear={setActiveYear}
        />

        <p className="text-xs text-dusk-700 text-center leading-[130%] -mt-3">
          These calculations are not financial advice. They are a projection
          estimate based on programs and not a guarantee of returns.
        </p>
      </div>
    )
  }

  if (activeTab === TABLE) {
    return (
      <RoiTable
        roiData={roiData}
        isMobile={isMobile}
        activeYear={activeYear}
        setActiveYear={setActiveYear}
      />
    )
  }

  return (
    <div className="mt-6">
      <p className="text-xs text-dusk-700 leading-[130%] mb-4">
        Edit any field and recalculate for the latest data.
      </p>

      <RoiCalculator
        formikProps={formikProps}
        handleReset={handleReset}
        isReseting={isReseting}
      />
    </div>
  )
}

export default RoiModalContentMobileTab
