import { Form, FormikValues, useFormikContext } from "formik"
import cx from "classnames"

import { WrappedInput } from "../../components/Input"
import { SubmitButton } from "../../components/SubmitButton"
import { Checkbox, Checkbox2 } from "../../components/Checkbox"

interface SylviaFormComponentProps {
  showOptional: boolean
  handleShowOptional: () => void
}

const SylviaFormComponent = ({
  showOptional,
  handleShowOptional,
}: SylviaFormComponentProps) => {
  const { isSubmitting } = useFormikContext<FormikValues>()

  return (
    <Form className="mt-6">
      <WrappedInput
        label="Query"
        name="query"
        as="textarea"
        placeholder="Ask Sylvia anything"
        className=""
        maxLength={99999}
      />

      <div className="mb-6">
        <Checkbox
          className="mb-2"
          label="Show optional fields"
          checked={showOptional}
          onChange={handleShowOptional}
        />
      </div>

      <div
        className={cx("my-4 grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-3", {
          hidden: !showOptional,
        })}
      >
        <div>
          <WrappedInput label="Corpus name" type="text" name="corpus_name" />
        </div>

        <div>
          <WrappedInput label="Model name" type="text" name="model_name" />
        </div>

        <div>
          <WrappedInput label="Top K" type="number" name="top_k" />
        </div>

        <div>
          <WrappedInput
            label="Vector distance threshold"
            type="number"
            name="vector_distance_threshold"
          />
        </div>

        <div>
          <WrappedInput
            label="Vector similarity threshold"
            type="number"
            name="vector_similarity_threshold"
          />
        </div>

        <div className="col-span-full">
          <WrappedInput
            label="Metadata filter"
            type="text"
            name="metadata_filter"
          />
        </div>

        <div className="col-span-full">
          <WrappedInput
            label="System instruction"
            name="system_instruction"
            as="textarea"
            maxLength={99999}
          />
        </div>

        <div>
          <Checkbox2 label="Use RAG" name="use_rag" />
        </div>
      </div>

      <SubmitButton
        className="btn2 btn2-primary font-semibold mt-6"
        isSubmitting={isSubmitting}
        data-test="sign-up"
      >
        Submit
      </SubmitButton>
    </Form>
  )
}

export default SylviaFormComponent
