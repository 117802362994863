import SPBNavigationItem from "./SPBNavigationItem"
import useMediaQuery from "../../hooks/useMediaQuery"
import { useSPBStore } from "../../stores/useSPBStore"

const SPBNavigation = () => {
  const isMaxWidthLg = useMediaQuery("(max-width: 1024px)")

  const { activeStep, enabledSteps, setActiveStep } = useSPBStore()

  const handleStepClick = (index: number) => {
    if (enabledSteps[index]) {
      setActiveStep(index)
    }
  }

  if (isMaxWidthLg) {
    return (
      <p className="text-sm text-left uppercase font-body font-bold leading-6 tracking-[0.84px]">
        Step {activeStep + 1} of 5
      </p>
    )
  }

  return (
    <div
      className="flex flex-col lg:flex-row items-start gap-2 md:gap-6"
      data-testid="spb-navigation"
    >
      <SPBNavigationItem
        className="spb-navigation-item-introduction"
        text="1. Introduction"
        disabled={!enabledSteps[0]}
        onClick={() => handleStepClick(0)}
        active={activeStep === 0}
      />

      <SPBNavigationItem
        className="spb-navigation-item-introduction-risk-assessment"
        text="2. Risk Assessment"
        disabled={!enabledSteps[1]}
        onClick={() => handleStepClick(1)}
        active={activeStep === 1}
      />

      <SPBNavigationItem
        className="spb-navigation-item-select-approach"
        text="3. Select Approach"
        disabled={!enabledSteps[2]}
        onClick={() => handleStepClick(2)}
        active={activeStep === 2}
      />

      <SPBNavigationItem
        className="spb-navigation-item-take-action"
        text="4. Take action"
        disabled={!enabledSteps[3]}
        onClick={() => handleStepClick(3)}
        active={activeStep === 3}
      />

      <SPBNavigationItem
        className="spb-navigation-item-plan-summary"
        text="5. Plan Summary"
        disabled={!enabledSteps[4]}
        onClick={() => handleStepClick(4)}
        active={activeStep === 4}
      />
    </div>
  )
}

export default SPBNavigation
