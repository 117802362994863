import PageHeroSearch from "./PageHeroSearch"
import useMediaQuery from "../../hooks/useMediaQuery"

const PageHero = () => {
  const isMaxWidthLg = useMediaQuery("(max-width: 1024px)")

  if (!isMaxWidthLg) {
    return null
  }

  return (
    <header className="bg-charcoal-600 py-6 md:py-12">
      <div className="container max-w-[2400px]">
        <p className="text-cloud-50 text-lg text-center font-normal leading-[130%] tracking-[0.36px]">
          Browse program categories or search keywords to find programs.
        </p>

        <PageHeroSearch />
      </div>
    </header>
  )
}

export default PageHero
