import { Link } from "react-router"

import SPBFooter from "./SPBFooter"
import { AccountInterestTypes } from "@/types/accountInterest"
import { SPBWizardType } from "@/types/wizards"
import SPBIntroductionCards from "./SPBIntroductionCards"

export interface SPBContentIntroductionProps {
  wizardData: SPBWizardType | undefined
  accountInterestData: AccountInterestTypes | undefined
  handleNext: () => void
}

const SPBContentIntroduction = ({
  wizardData,
  accountInterestData,
  handleNext,
}: SPBContentIntroductionProps) => (
  <div className="pt-10 lg:pt-13 pb-31">
    <div className="container w-[780px] max-w-full flex flex-col gap-6 px-5 lg:px-10">
      <div>
        <p className="text-overline">Your Checkup</p>

        <h1 className="text-3xl font-display font-medium leading-[124%] tracking-[0.6px] mt-2">
          Protect Against Southern Pine Beetle (SPB)
        </h1>
      </div>

      <SPBIntroductionCards
        wizardData={wizardData}
        primaryLandGoal={accountInterestData?.primary_land_goal}
      />
    </div>

    <SPBFooter>
      <div className="flex gap-4 ml-auto">
        <Link
          to="/"
          className="spb-introduction-exit-to-dashboard btn2 btn2-outline-primary font-bold tracking-[0.32px]"
        >
          Exit to Dashboard
        </Link>

        <button
          type="button"
          className="spb-introduction-next btn2 btn2-primary font-bold tracking-[0.32px]"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </SPBFooter>
  </div>
)

export default SPBContentIntroduction
