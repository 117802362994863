import { roundTo } from "../../../utils"
import { GetChartDataProps, GetTableDataReturn, TableData } from "./types"

export const getTableData = (data: TableData): GetTableDataReturn[] => {
  return data.revenue.map((_, i) => {
    const newObj = { year: i + 1 }

    Object.keys(data).forEach((key) => {
      const cents = data[key as keyof TableData][i] < 10 ? 1 : 10
      newObj[key as keyof typeof newObj] = roundTo(
        data[key as keyof TableData][i],
        cents
      )
    })

    return newObj as GetTableDataReturn
  })
}

export const getChartData = (data: GetChartDataProps) => {
  return data.revenue.map((_, i) => {
    const newObj = { year: i + 1 }

    Object.keys(data).forEach((key) => {
      const cents = data[key as keyof typeof data][i] < 10 ? 1 : 10
      newObj[key as keyof typeof newObj] = roundTo(
        data[key as keyof typeof data][i],
        cents
      )
    })

    return newObj
  })
}
