import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { postSylviaQuery } from "../../../api/data"
import { useMutationWithRefresh } from "../utils"

export function usePostSylviaQuery<
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  config = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (values: TVariables) => postSylviaQuery(values),
    config
  )
}
