import { Link, useNavigate, useLocation } from "react-router"

import { Notification } from "../../components/Notification"
import { NotificationTypes } from "@/types/notifications"
import AccountNotification from "./NotificationContent/AccountNotification"

export interface NotificationContentProps {
  accountId: string
  accountsCount: number | undefined
  notifications: NotificationTypes[]
}

// DEV: Minimize loading spinner for content by receiving data from parent
const NotificationContent = ({
  accountId,
  accountsCount,
  notifications,
}: NotificationContentProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const notificationEls = notifications.map((notification) => (
    <AccountNotification
      key={notification.id}
      accountId={accountId}
      notification={notification}
    />
  ))

  if (location.state?.createNextNotification) {
    notificationEls.push(
      <Notification
        key="create_next_notification"
        onDismiss={() => {
          navigate(location.pathname, { replace: true })
        }}
        title={
          <>
            Great! You've requested {accountsCount === 1 ? "your first" : "an"}{" "}
            eligibility report. What do you want to do next?
          </>
        }
        variant="success"
      >
        <Link
          className="btn2 btn2-outline-primary font-semibold mt-3 mr-2 mb-2"
          to="/"
        >
          View Dashboard
        </Link>

        <Link className="btn2 btn2-primary font-semibold" to="/accounts/new">
          Add Another Account
        </Link>
      </Notification>
    )
  }

  return notificationEls
}

export default NotificationContent
